import React, { LegacyRef, useCallback } from "react";

import {
    StyleSheet,
    Platform,
    // Customizable Area Start
    // Customizable Area End
} from "react-native";
// Customizable Area Start

import { Container, Box, Typography, withStyles, Button, Grid, Modal, TextField } from "@material-ui/core";
import { banner, successImage } from './assets';
import { Formik } from "formik";
import LandingPage2Controller, {
    Props,
} from "./LandingPage2Controller.web";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const muiStyles = (theme: any) => ({
    textWhite: {
        color: "#ffffff"
    },
    button: {
        color: "#ffffff",
        borderColor: "#ffffff",
        borderRadius: 7,
        padding: "1rem 2rem"
    },
    banner: {
        padding: "1rem 2rem",
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [theme.breakpoints.up('sm')]: {
            padding: "10rem 8rem",
        },
    },
    candidateArea: {
        [theme.breakpoints.down('xs')]: {
            marginTop: "3rem",
        },
    },
    loginBtn: {
        marginRight: "1rem"
    },
    featureIcons: {
        margin: "30px auto",
        display: "flex"
    },
    featureTxt: {
        lineHeight: "1.6rem",
        fontWeight: 400,
        marginTop: "1.2rem",
        marginBottom: "1.2rem"
    },
    testimonialsContainer: {
        backgroundColor: theme.palette.secondary.main + '30'
    },
    clientTxt: {
        color: "#000",
        lineHeight: "1.8rem"
    },
    clientDes: {
        marginTop: "1.1rem",
        fontStyle: "italic",
        fontSize: "0.8rem",
        fontWeight: 500
    },
    clientName: {
        fontSize: "1rem",
    },
    demo: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
        },
    },
    separatorBox: {
        justifyContent: "center",
        display: "flex"
    },
    separator: {
        height: "100%",
        width: 1,
        backgroundColor: theme.palette.secondary.main
    },
    playIcon: {
        width: 64,
        height: 64,
        cursor: 'pointer'
    },
    player: {
        borderRadius: "30px"
    },
    overlay: {
        border: "20px solid #ececec",
        borderRadius: "70px",
        top: "-21px",
        left: "-20px",
    },
    Bgrey: {
        backgroundColor: "#ececec"
    },
    link: {
        textDecoration: 'none'
    },
    phoneNumberContainer: {
        "& .PhoneInputInput": {
            border: 0,
            borderBottom: "1px solid #949494",
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "rgb(125, 124, 132)",
            letterSpacing: "0.15008px"
        }
    },
    successImg: {
        height: "auto",
        width: 100
    },
    successModelContent: {
        marginLeft: 110
    },
    boxContainer: {
        top: "50%",
        left: "50%",
        width: "25rem",
        padding: "32px",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        background: "#fff",

        [theme.breakpoints.down('xs')]: {
            width: "21rem",
        }
    }
});

// Customizable Area End
class RequestDemoModal extends LandingPage2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    openSuccessModel = () => {
        this.setState({ openSuccesModel: true })
    }

    render() {
        const { classes } = this.props;

        return (
            <>
                <Modal
                    open={this.props.showRequestDemoModal}
                    onClose={this.props.handleCloseRequestDemoModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <>
                        <Box className={classes.boxContainer}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box flex="1">
                                    <Typography color="primary" align="center" variant="h5">
                                        Request a Demo
                                    </Typography>
                                </Box>
                                <IconButton onClick={this.props.handleCloseRequestDemoModal} aria-label="Close">
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Container maxWidth="sm">
                                <Box>
                                    {/* Request demo form start */}
                                    <Formik
                                        initialValues={{
                                            first_name: "",
                                            last_name: "",
                                            phone: this.state.phone,
                                            email: "",
                                            company_name: "",
                                        }}
                                        onSubmit={(values) => {
                                            this.setRequestForDemo(values);
                                            // this.props.sendRequestForDemoData(values)
                                            // this.props.sendRequestForDemoData(values)
                                        }}>
                                        {(formikProps) => (
                                            <form onSubmit={formikProps.handleSubmit}>
                                                <Box my={5}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                id="first_name"
                                                                name="first_name"
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                value={formikProps.values.first_name}
                                                                error={formikProps.touched.first_name && Boolean(formikProps.errors.first_name)}
                                                                helperText={formikProps.touched.first_name && formikProps.errors.first_name}
                                                                InputProps={{
                                                                    startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/name.png')} />,
                                                                }}
                                                                placeholder="First Name" />
                                                        </Grid>
                                                        <Grid item xs={6}>

                                                            <TextField
                                                                id="last_name"
                                                                name="last_name"
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                value={formikProps.values.last_name}
                                                                error={formikProps.touched.last_name && Boolean(formikProps.errors.last_name)}
                                                                helperText={formikProps.touched.last_name && formikProps.errors.last_name}
                                                                InputProps={{
                                                                    startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/name.png')} />,
                                                                }}
                                                                placeholder="Last Name" />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box my={5}>
                                                    <TextField
                                                        style={{ width: "100%" }}
                                                        id="email"
                                                        name="email"
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        value={formikProps.values.email}
                                                        error={formikProps.touched.email && Boolean(formikProps.errors.email)}
                                                        helperText={formikProps.touched.email && formikProps.errors.email}
                                                        InputProps={{
                                                            startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/email.png')} />,
                                                        }}
                                                        placeholder="Enter Your Email Id" />
                                                </Box>
                                                <Box my={5}>
                                                    <TextField
                                                        className={classes.message}
                                                        style={{ width: "100%" }}
                                                        id="company_name"
                                                        name="company_name"
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        value={formikProps.values.company_name}
                                                        error={formikProps.touched.company_name && Boolean(formikProps.errors.company_name)}
                                                        helperText={formikProps.touched.company_name && formikProps.errors.company_name}
                                                        InputProps={{
                                                            startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/Company_Name.png')} />,
                                                        }}
                                                        placeholder="Enter Your Company Name" />
                                                </Box>
                                                <Box my={5} className={classes.phoneNumberContainer}>
                                                    <PhoneInput
                                                        countryOptionsOrder={["US", "GB", "IN"]}
                                                        name="phone"
                                                        autoComplete="off"
                                                        className={classes.PhoneInputInput}
                                                        placeholder="Phone Number"
                                                        onChange={(e: any) => {
                                                            formikProps.handleChange;
                                                            this.setState({
                                                                phone: e,
                                                                phoneError: e
                                                                    && !isValidPhoneNumber(String(e))
                                                                    ? true : false
                                                            }, () => { formikProps.setFieldValue('phone', this.state.phone) })
                                                        }} />
                                                    <Typography variant="caption" style={{ color: "#f44336", display: !this.state.phoneError ? 'none' : 'flex' }}>
                                                        {this.state.phoneError ? "Phone Number is invalid" : ""}
                                                    </Typography>
                                                    <Typography variant="caption" style={{ color: "#f44336", display: Boolean(formikProps.errors.phone) && !this.state.phone ? 'flex' : 'none' }}>
                                                        Phone Number is required
                                                    </Typography>
                                                </Box>
                                                <Box justifyContent="center" display="flex">
                                                    <Button
                                                        //    onClick={this.openSuccessModel}
                                                        type="submit" className={classes.fullBtn} disableRipple variant="contained" color="primary">
                                                        <Typography variant="body2">
                                                            Submit
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </form>
                                        )}
                                    </Formik>
                                    {/* Request demo form end */}
                                </Box>
                            </Container>
                        </Box>
                        {/* Snackbar for display success and failed messages. */}
                        <Snackbar
                            open={this.state.snackBar.show}
                            autoHideDuration={3000}
                            onClose={this.closeSnackBarHandler}
                        >
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                onClose={this.closeSnackBarHandler}
                                severity={this.state.snackBar.type}
                            >
                                {this.state.snackBar.message}
                            </MuiAlert>
                        </Snackbar>
                    </>
                </Modal >
            </>
        );
    }
}

// Customizable Area Start
// @ts-ignore
export default withStyles(muiStyles)(RequestDemoModal)
// Customizable Area End
