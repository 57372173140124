import React, { LegacyRef, useCallback } from "react";

import {
    StyleSheet,
    Platform,
    // Customizable Area Start
    // Customizable Area End
} from "react-native";
// Customizable Area Start

import { Container, Box, Typography, withStyles, Button, Grid, Modal, TextField } from "@material-ui/core";
import { Formik } from "formik";
import QuestionBankController, {
    Props,
} from "./QuestionBankController.web";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { DraftImg, ProfileImg, Messages } from './assets';
import { withRouter } from "react-router-dom";

const muiStyles = (theme: any) => ({
    textWhite: {
        color: "#ffffff"
    },
    button: {
        color: "#ffffff",
        borderColor: "#ffffff",
        borderRadius: 7,
        padding: "1rem 2rem"
    },
    candidateArea: {
        [theme.breakpoints.down('xs')]: {
            marginTop: "3rem",
        },
    },
    loginBtn: {
        marginRight: "1rem"
    },
    featureIcons: {
        margin: "30px auto",
        display: "flex"
    },
    featureTxt: {
        lineHeight: "1.6rem",
        fontWeight: 400,
        marginTop: "1.2rem",
        marginBottom: "1.2rem"
    },
    testimonialsContainer: {
        backgroundColor: theme.palette.secondary.main + '30'
    },
    clientTxt: {
        color: "#000",
        lineHeight: "1.8rem"
    },
    clientDes: {
        marginTop: "1.1rem",
        fontStyle: "italic",
        fontSize: "0.8rem",
        fontWeight: 500
    },
    clientName: {
        fontSize: "1rem",
    },
    demo: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
        },
    },
    separatorBox: {
        justifyContent: "center",
        display: "flex"
    },
    separator: {
        height: "100%",
        width: 1,
        backgroundColor: theme.palette.secondary.main
    },
    playIcon: {
        width: 64,
        height: 64,
        cursor: 'pointer'
    },
    player: {
        borderRadius: "30px"
    },
    overlay: {
        border: "20px solid #ececec",
        borderRadius: "70px",
        top: "-21px",
        left: "-20px",
    },
    Bgrey: {
        backgroundColor: "#ececec"
    },
    link: {
        textDecoration: 'none'
    },
    phoneNumberContainer: {
        "& .PhoneInputInput": {
            border: 0,
            borderBottom: "1px solid #949494",
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "rgb(125, 124, 132)",
            letterSpacing: "0.15008px"
        }
    },
    fullBtnCancel: {
        backgroundColor: "#EBEBEB",
        marginRight: "0.5em"
    },
    fullBtnSubmit: {
        marginLeft: "0.5em"
    },
    modalContainer: {
        top: "50%",
        left: "50%",
        width: "37.125em",
        padding: "32px",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        background: "#fff",

        [theme.breakpoints.down('sm')]: {
            width: "22.5rem",
        }
    }
});

// Customizable Area End
class EditProfileModal extends QuestionBankController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            <Modal
                open={this.props.showEditProfileModal}
                onClose={this.props.handleCloseEditProfileModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modalContainer}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex="1">
                            <Typography color="primary" align="center" variant="h5">
                                Edit Info
                            </Typography>
                        </Box>
                        {/* <IconButton onClick={this.props.handleCloseEditProfileModal} aria-label="Close">
                            <CloseIcon />
                        </IconButton> */}
                    </Box>
                    <Container maxWidth="sm">
                        <Box>
                            <Formik
                                initialValues={{
                                    first_name: this.props.profileData?.full_name.trim().split(" ")[0] || "",
                                    last_name: this.props.profileData?.full_name.trim().split(" ")[1] || "",
                                    phone_number: "" + this.props.profileData?.phone_number || this.state.phone,
                                    email: this.props.profileData?.email || "",
                                    current_city: this.props.profileData?.current_city || "",
                                }}
                                validationSchema={this.state.updateProfileValidationSchema}
                                onSubmit={(values) => {
                                    this.props.sendRequestForUpdateProfile(values);
                                }}>
                                {(formikProps) => (
                                    <form onSubmit={formikProps.handleSubmit}>
                                        <Box my={5}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="first_name"
                                                        name="first_name"
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        value={formikProps.values.first_name}
                                                        error={formikProps.touched.first_name && Boolean(formikProps.errors.first_name)}
                                                        helperText={formikProps.touched.first_name && formikProps.errors.first_name}
                                                        InputProps={{
                                                            startAdornment: <img width="16px" style={{ marginRight: 15 }} src={ProfileImg} />,
                                                        }}
                                                        placeholder="First Name" />
                                                </Grid>
                                                <Grid item xs={6}>

                                                    <TextField
                                                        id="last_name"
                                                        name="last_name"
                                                        onChange={formikProps.handleChange}
                                                        onBlur={formikProps.handleBlur}
                                                        value={formikProps.values.last_name}
                                                        error={formikProps.touched.last_name && Boolean(formikProps.errors.last_name)}
                                                        helperText={formikProps.touched.last_name && formikProps.errors.last_name}
                                                        InputProps={{
                                                            startAdornment: <img width="16px" style={{ marginRight: 15 }} src={ProfileImg} />,
                                                        }}
                                                        placeholder="Last Name" />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box my={5}>
                                            <TextField
                                                className={classes.message}
                                                style={{ width: "100%" }}
                                                id="current_city"
                                                name="current_city"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.current_city}
                                                error={formikProps.touched.current_city && Boolean(formikProps.errors.current_city)}
                                                helperText={formikProps.touched.current_city && formikProps.errors.current_city}
                                                InputProps={{
                                                    startAdornment: <img width="16px" style={{ marginRight: 15 }} src={DraftImg} />,
                                                }}
                                                placeholder="Enter Your Current City" />
                                        </Box>
                                        <Box my={5} className={classes.phoneNumberContainer}>
                                            <PhoneInput
                                                countryOptionsOrder={["US", "GB", "IN"]}
                                                name="phone_number"
                                                autoComplete="off"
                                                className={classes.PhoneInputInput}
                                                placeholder="Phone Number"
                                                value={formikProps.values.phone_number}
                                                onChange={(e: any) => {
                                                    formikProps.handleChange;
                                                    this.setState({
                                                        phone: e,
                                                        phoneError: e
                                                            && !isValidPhoneNumber(String(e))
                                                            ? true : false
                                                    }, () => { formikProps.setFieldValue('phone_number', this.state.phone) })
                                                }} />
                                            <Typography variant="caption" style={{ color: "#f44336", display: !this.state.phoneError ? 'none' : 'flex' }}>
                                                {this.state.phoneError ? "Phone Number is invalid" : ""}
                                            </Typography>
                                            <Typography variant="caption" style={{ color: "#f44336", display: Boolean(formikProps.errors.phone_number) && !this.state.phone ? 'flex' : 'none' }}>
                                                Phone Number is required
                                            </Typography>
                                        </Box>
                                        <Box my={5}>
                                            <TextField
                                                style={{ width: "100%" }}
                                                id="email"
                                                name="email"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.email}
                                                error={formikProps.touched.email && Boolean(formikProps.errors.email)}
                                                helperText={"You can't change your primary email."}
                                                InputProps={{
                                                    startAdornment: <img width="16px" style={{ marginRight: 15 }} src={Messages} />,
                                                }}
                                                disabled
                                                placeholder="Enter Your Email Id" />
                                        </Box>


                                        <Box justifyContent="center" display="flex">
                                            <Button
                                                type="button" className={classes.fullBtnCancel} disableRipple variant="contained" color="secondary" onClick={this.props.handleCloseEditProfileModal}>
                                                <Typography variant="body2">
                                                    Cancel
                                                </Typography>
                                            </Button>
                                            <Button
                                                type="submit" className={classes.fullBtnSubmit} disableRipple variant="contained" color="primary">
                                                <Typography variant="body2">
                                                    Save Changes
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Container>
                </Box>
            </Modal >
        );
    }
}

// Customizable Area Start
// @ts-ignore
export default withStyles(muiStyles)(withRouter(EditProfileModal))
// Customizable Area End
