import React, { useEffect, useRef } from "react";

import { Box, Typography, Grid, makeStyles, Divider, Card, CardContent, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  count: {
    color: "#55DAA5",
    fontSize: '1em',
    fontFamily: 'Poppins-SemiBold'
  },
  heading: {
    textDecoration: "none",
    "& h5": {
      fontWeight: 700,
      color: "#171725",
      fontSize: "1.4em",
      cursor: "pointer",
    }
  },
  date: {
    color: "#92929D",
    fontSize: "0.875em",
    paddingLeft: "5px"
  },
  counthead: {
    color: "#696974",
    fontSize: '0.875em',
    fontFamily: 'Poppins-Regular',
    display: 'flex',
    alignItems: 'center'
  },
  textStyle: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }
}));

const DashboardCardsCounter = (props: any) => {
  const classes = useStyles();
  return (
    <Card variant="outlined">
      <CardContent>
        <Box mb={1}>
          <Link to={`${props.roleType == 'openRole' ? "/open-roles/role/" : "/closed-roles/role/"}${props.jobId}${props.clientIDLink}`} className={classes.heading}>
            <Typography
              variant="h5"
              color="textSecondary"
              className={classes.textStyle}
            >
              {props.title}
            </Typography></Link>
          <Typography className={classes.date} variant="subtitle1">
            Date Opened : {props.date}
          </Typography>
        </Box>
        <Divider light />
        <Box mt={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" className={classes.counthead}>
              <img src={require('./assets/profileIcon.png')} style={{ marginRight: '0.540625em', height: '1.125em', width: "0.9375em" }} />

              Shortlisted Candidates
            </Typography>
            <Typography variant="h6" className={classes.count}>
              {props.countshortlist}
            </Typography>
          </Box>
          <Box mt="10px" display="flex" justifyContent="space-between">
            <Typography variant="body2" className={classes.counthead}>
              <img src={require('./assets/chartIcon.png')} style={{ marginRight: '0.540625em', height: '1.125em', width: "0.9375em" }} />
              Under Assessment
            </Typography>
            <Typography variant="h6" className={classes.count}>
              {props.countunder}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default DashboardCardsCounter;