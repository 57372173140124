import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import DropDownMenu from "./TopNav_DropDown";
import { EmailOutlined } from "@material-ui/icons";
import Notifications from './Notifications.web';
import CandidateContext from "../../web/src/Context/CandidateContext.web";
import InterviewContext from "../../web/src/Context/InterviewContext.web";

export const useStyles = makeStyles(theme => ({
    iconStyle: {
        color: "#24325f",
        marginRight: "1.7rem",
        cursor: "pointer",
        transform: "scale(1.3)",

        [theme.breakpoints.down('md')]: {
            marginRight: "1rem",
            marginLeft: ".4rem"
        },
    },
    dividerStyle: {
        border: "1px solid #F1F1F5",
        background: "#F1F1F5",
        height: "2.3rem",
        marginRight: "1rem",
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    profileImage: {
        width: "2.5rem",
        height: "2.5rem",
        marginRight: ".8rem",
        borderRadius: "50%"
    },
    profileName: {
        color: "#171725",
        textTransform: "capitalize",
        fontSize: "1.1rem",
        fontWeight: "bold",
    },
    designationText: {
        color: "#92929D",
        fontSize: ".9rem",
        marginLeft: ".2rem"
    },
    notiCount: {
        position: "absolute",
        top: "-0.45rem",
        right: "1.3rem",
        background: "#24325f",
        borderRadius: "50%",
        width: "1.3rem",
        height: "1.3rem",
        display: "flex",
        alignItems: "center",

        "& > h6": {
            color: "#fff",
            fontSize: "0.7rem"
        }
    },
}));


const CandidateMenu = ({ history, isMobile }: { history: any, isMobile: boolean }) => {
    const classes = useStyles();
    const ctx = React.useContext(CandidateContext);
    const interCtx = React.useContext(InterviewContext);

    const navigateTo = (route: string) => {
        history.push(route);
    }

    return (
        <Grid container justifyContent="flex-end" alignItems="center">
            {/* icons */}
            <Box display="flex" alignItems="center">
                <Box>
                    {!isMobile &&
                        <Box style={{ marginTop: ".25rem" }}>
                            <EmailOutlined
                                onClick={() => navigateTo('/profile-setting/chats')}
                                className={classes.iconStyle}
                            />
                        </Box>
                    }
                </Box>
                {!isMobile &&
                    <Notifications classes={classes} history={history} />
                }
            </Box>

            {/* divider */}
            <div className={classes.dividerStyle} />

            {/* profile, name and dropdown menu */}
            <img src={ctx.profile_picture} className={classes.profileImage} />

            {/* user details */}
            <Box style={{ marginRight: "1.5rem" }}>
                <Typography variant="body1" className={classes.profileName}>
                    {interCtx.candidate_name ? interCtx.candidate_name :
                        `${sessionStorage.getItem("first_name")} ${sessionStorage.getItem("last_name")}`}
                </Typography>
                <Typography variant="body1" className={classes.designationText}>
                    {sessionStorage.getItem("user_role")}
                </Typography>
            </Box>

            {/* drop-down menu */}
            <DropDownMenu history={history} isMobile={isMobile} />
        </Grid >

    );
}

export default CandidateMenu;