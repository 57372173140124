// this file contains all the styles for the components, to keep our component code clean and easy managable.

import { createTheme, Theme } from "@material-ui/core";

// candidate details(CD) style sheet
export const useCDStyles = (theme: Theme) => {
    return {
        container: {
            margin: "6rem 2rem",
        },
        header: {
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: theme.spacing(2),

            "& svg": {
                color: "white",
                width: "2rem",
                height: "2rem",
                padding: ".5rem",
                borderRadius: ".5rem",

                "&:not(:last-child)": {
                    marginRight: "1.5rem",
                }
            },

            "& a": {
                marginRight: "1.5rem",
            }
        },
        currentRoute: {
            color: "#9e9ea8",
        },
        description: {
            backgroundColor: "#24325F",
            cursor: "pointer",
        },
        whatsapp: {
            backgroundColor: "#25D366",
            cursor: "pointer",
        },
        mailout: {
            backgroundColor: "#24325F",
            cursor: "pointer",
        },
        innerContainer: {
            backgroundColor: "#fff",
            padding: "2.1rem",
            borderRadius: ".5rem",
            marginTop: theme.spacing(3),

            "& img": {
                width: "6rem",
                height: "6rem",
                borderRadius: ".5rem",
                marginRight: "2rem"
            },

            "& button": {
                backgroundColor: "#24325F",
                color: "#fff",
                borderRadius: ".5rem",
                padding: ".5rem 1.5rem",
                marginRight: "2rem",
            },

            "& hr": {
                margin: "1.5rem 0",
            },
        },
        details: {
            alignItems: "center",
        },
        name: {
            flex: 1,

            "& p": {
                marginBottom: ".8rem",
            },

            "& h5": {
                textTransform: "capitalize",
            }
        },
        workExprience: {
            "& h6": {
                fontSize: "1.2rem",
                fontWeight: "600",
                marginBottom: ".5rem",
            },

            "& p": {
                marginBottom: "1rem",

                "&:first-child": {
                    color: "#404040",
                },

                "&:last-child": {
                    color: "#6e6e6e",
                }
            }
        },
        skill: {
            "& p": {
                backgroundColor: "#ebebeb",
                margin: "0 .4rem 1rem",
                padding: ".2rem 1rem",
                borderRadius: ".3rem",

                "&:not(:last-child)": {
                }
            }
        },
        mobileContainer: {
            margin: "6rem 0rem",
        },
        assesmentBtn: {
            marginTop: "1em",
            '& a': {
                color: "#fff",
                fontSize: "inherit",
            }
        }
    }
};


// Theme for the modal
export const theme = createTheme({
    typography: {
        allVariants: {
            textTransform: 'none'
        },
        h1: {
            fontFamily: "Poppins-Medium",
            fontSize: "24px"
        },
        h2: {
            fontFamily: "Poppins-Semibold",
            fontSize: "22px"
        },
        h3: {
            fontFamily: "Poppins-Medium",
            fontSize: "16px"
        },
        h4: {
            fontFamily: "Poppins-Medium",
            fontSize: "14px"
        },
        subtitle1: {
            fontFamily: "Poppins-Regular",
            fontSize: "16px"
        }
    },
    palette: {
        primary: {
            main: "#171725"
        },
        secondary: {
            main: "#a8d0e7"
        },
        text: {
            primary: "#171725",
            secondary: '#e2e2ea'
        }
    },
});