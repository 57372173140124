Object.defineProperty(exports, "__esModule", {
    value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "QuestionBank";
exports.labelBodyText = "QuestionBank Body";

exports.btnExampleTitle = "CLICK ME";

exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.getAPIMethod = "GET";
exports.jsonApiContentType = "application/json";
exports.getProfileDataAPIEndPoint = "bx_block_profile/profile_show";
exports.updateProfileDataAPIEndPoint = "account_block/accounts/update_account";
exports.updateOtherDetailsAPIEndPoint = "bx_block_profile/profile";
exports.updateRolesAPIEndPoint = "bx_block_profile/preferred_roles";
exports.getAllAvailableMatchingJobsAPiEndPoint =
    "bx_block_profile/recommended_roles";
exports.getAppliedJobListAPIEndPoint = "bx_block_profile/appied_job_list";
exports.getPastAppliedJobListAPIEndPoint =
    "bx_block_profile/past_appied_job_list";
exports.getSavedJobListAPIEndPoint = "bx_block_save_job/show";
exports.favouriteJobAPiEndPoint = "bx_block_save_job/save_jobs";
exports.uploadResumeEndPoint = "bx_block_sovren/update_resume";
exports.removeResumeEndPoint = "bx_block_sovren/remove_resume";
exports.applyForJobAPiEndPoint = "bx_block_roles_permissions/applied_jobs";
exports.selectSlotApiEndPoint = "bx_block_scheduling/schedule_interviews/";
// Customizable Area End
