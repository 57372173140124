import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Platform,
} from "react-native";
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Container,
  Typography,
  Card,
  withStyles,
  Button,
  TextField,
} from "@material-ui/core";
import { BrowserRouter as Router, Link, withRouter, useHistory } from "react-router-dom";
import { banner } from "./assets";
// Customizable Area End
import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";
import { Formik } from "formik";
import * as Yup from "yup";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  paddingLeft: "20px",
  paddingRight: "20px",
  overflow: 'scroll',
  height: '80%',
  display: 'block'
};
const blockStyle = (theme: any) => ({
  banner: {
    //customizable area start
    backgroundColor: "whitesmoke",
    //customizable area ends
    padding: "1rem 2rem",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPositionY: "-15rem",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
    },
  },
  textWhite: {
    color: "#ffffff",
  },
  loginCard: {
    paddingTop: "2em",
    padding: "1.3em",
    border: "2px solid #eaeaea",
    //customizable area start
    marginTop: "80px",
    marginBottom: "80px"
    //customizable area ends
  },

  //customizable area start
  image: {
    display: "block",
    marginRight: "auto",
    marginLeft: "auto"
  },
  //customizable area ends
  fullBtn: {
    width: "100%",
    marginTop: "2em"
  },
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#ffffff",
  },
  input: {
    display: "none",
  },
});

const validationSchema = Yup.object({
  first_name: Yup.string()
    .trim()
    .min(3, "First name should be of minimum 8 characters length")
    .max(10, "First name should be of maximum 10 characters length")
    .required("First name is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  last_name: Yup.string()
    .trim()
    .min(3, "Last name should be of minimum 8 characters length")
    .max(10, "Last name should be of maximum 10 characters length")
    .required("Last name is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string()
    .trim()
    .email("Email is invalid")
    .required("Email is a required field")
    .max(250, "Email should be of maximum 250 characters length"),
  password: Yup.string()
    .trim()
    .required("Password is required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      "Pasword must contain minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  repeat_pass: Yup.string()
    .trim()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  city: Yup.string()
    .trim()
    .required("City is a required field")
    .max(200, "City name should be of maximum 200 characters length"),
  agree: Yup.boolean().required('You need to mark this as checked')
  // resume: Yup
  //   .mixed()
  //   .test("fileSize", "The file is too large", (value) => {
  //     if (!value.length) return true // attachment is optional
  //     return value[0].size <= 2000000
  //   })
  //   .test(
  //     "fileFormat",
  //     "Unsupported Format",
  //     value => value && ["application/pdf", "application/rtf"].includes(value.type)
  //   )
});

class ForgotPasswordOTP extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }
  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Box className={classes.banner}>
        {/* //customizable area ends */}
        <Container maxWidth="sm">
          <Card className={classes.loginCard}>
            <Typography align="center" style={{ fontSize: 28, fontWeight: 1000 }} color="primary">
              Forgot Password ?
            </Typography>
            <br />
            <Typography align="center" style={{ color: "darkslategray", lineHeight: 1.5 }}>
              OTP to reset password was sent to your email.<br />
              Please enter the code and create new password.
            </Typography>

            <Formik
              initialValues={{
                email: "",
                password: "",
                repeat_pass: ""
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => { console.log("Forgert Password", values) }}
            >
              {({ handleChange,
                handleSubmit,
                handleBlur,
                errors,
                setFieldTouched,
                touched,
                values }) => (
                <form onSubmit={handleSubmit}>
                  <Box flex="1" my={5}>
                    <TextField
                      fullWidth
                      placeholder="Enter Your Email ID"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={touched.email && Boolean(errors.email) || Boolean(this.state.errorMsg)}
                      helperText={this.state.errorMsg ? this.state.errorMsg : touched.email && errors.email}
                      InputProps={{
                        startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/email.png')} />,
                      }}
                    />
                  </Box>
                  <Box flex="1" my={5}>
                    <TextField
                      fullWidth
                      placeholder="Create Password"
                      id="password"
                      name="password"
                      type={
                        this.state.enablePasswordField
                          ? "password"
                          : "text"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={
                        touched.password &&
                        Boolean(errors.password)
                      }
                      helperText={
                        touched.password &&
                        errors.password
                      }
                      InputProps={{
                        startAdornment: (
                          <img
                            width="16px"
                            style={{ marginRight: 15 }}
                            src={require("../assets/password.png")}
                          />
                        ),
                        endAdornment: (
                          <img
                            width="24px"
                            onClick={() => {
                              this.setState({
                                enablePasswordField: !this.state
                                  .enablePasswordField,
                              });
                            }}
                            style={{ marginRight: 15 }}
                            src={
                              this.state.enablePasswordField
                                ? require("../assets/show_password.png")
                                : require("../assets/hide_password.png")
                            }
                          />
                        ),
                      }}
                    />
                  </Box>
                  <Box flex="1" my={5}>
                    <TextField
                      fullWidth
                      placeholder="Re-enter Password"
                      id="repeat_pass"
                      name="repeat_pass"
                      type={
                        this.state.enablePasswordField
                          ? "password"
                          : "text"
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.repeat_pass}
                      error={
                        touched.repeat_pass &&
                        Boolean(errors.repeat_pass)
                      }
                      helperText={
                        touched.repeat_pass &&
                        errors.repeat_pass
                      }
                      InputProps={{
                        startAdornment: (
                          <img
                            width="16px"
                            style={{ marginRight: 15 }}
                            src={require("../assets/password.png")}
                          />
                        ),
                        endAdornment: (
                          <img
                            width="24px"
                            onClick={() => {
                              this.setState({
                                enablePasswordField: !this.state
                                  .enablePasswordField,
                              });
                            }}
                            style={{ marginRight: 15 }}
                            src={
                              this.state.enablePasswordField
                                ? require("../assets/show_password.png")
                                : require("../assets/hide_password.png")
                            }
                          />
                        ),
                      }}
                    />
                  </Box>
                  <Button type="submit" className={classes.fullBtn} disableRipple variant="contained" color="primary">
                    <Typography variant="body2">
                      Request OTP
                    </Typography>
                  </Button>

                </form>)}
            </Formik>
          </Card>
        </Container>

      </Box>
    );
    //Customizable Area End
  }


}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold",
  },
});
// Customizable Area End
// @ts-ignore
export default withStyles(blockStyle)(withRouter(ForgotPasswordOTP));



// import React from "react";

// // Customizable Area Start
// import {
//   StyleSheet,
//   Text,
//   View,
//   TextInput,
//   Button,
//   ScrollView,
//   TouchableWithoutFeedback,
//   Platform
// } from "react-native";
// // Customizable Area End

// import OTPInputAuthController, {
//   Props
// } from "../../otp-input-confirmation/src/OTPInputAuthController";

// export default class ForgotPasswordOTP extends OTPInputAuthController {
//   constructor(props: Props) {
//     super(props);
//     // Customizable Area Start
//     // Customizable Area End
//   }

//   render() {
//     return (
//       <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
//         <TouchableWithoutFeedback
//           onPress={() => {
//             this.hideKeyboard();
//           }}
//         >
//           {/* Customizable Area Start */}
//           <View>
//             <Text style={styles.titleWhySignUp}>{this.state.labelInfo}</Text>
//             <TextInput
//               testID="txtMobilePhoneOTP"
//               style={
//                 Platform.OS === "web"
//                   ? styles.phoneInputWeb
//                   : styles.phoneInputMobile
//               }
//               placeholder={this.placeHolderOtp}
//               onChangeText={text => this.setState({ otp: text })}
//               keyboardType="numeric"
//             />

//             <Button
//               testID="btnSubmitOTP"
//               title={this.btnTxtSubmitOtp}
//               color={this.submitButtonColor}
//               onPress={() => this.submitOtp()}
//             />
//           </View>
//           {/* Customizable Area End */}
//         </TouchableWithoutFeedback>
//       </ScrollView>
//     );
//   }
// }

// // Customizable Area Start
// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     padding: 16,
//     width: Platform.OS === "web" ? "75%" : "100%",
//     marginLeft: "auto",
//     marginRight: "auto",
//     maxWidth: 650,
//     backgroundColor: "#fff"
//   },
//   titleWhySignUp: {
//     marginBottom: 32,
//     fontSize: 16,
//     textAlign: "left",
//     marginVertical: 8
//   },
//   titleOtpInfo: {
//     marginBottom: 32,
//     fontSize: 16,
//     textAlign: "left",
//     marginVertical: 8
//   },

//   phoneInputMobile: {
//     flexDirection: "row",
//     fontSize: 16,
//     textAlign: "left",
//     backgroundColor: "#00000000",
//     marginBottom: 64,
//     borderWidth: 1,
//     borderColor: "#767676",
//     borderRadius: 2,
//     includeFontPadding: true,
//     padding: 10
//   },

//   phoneInputWeb: {
//     flex: 1,
//     flexDirection: "row",
//     marginBottom: 64,
//     fontSize: 18,
//     padding: 10,
//     borderBottomColor: "#767676",
//     borderBottomWidth: 1
//   },

//   bgRectBorder: {
//     borderWidth: 1,
//     borderColor: "#767676",
//     borderRadius: 2,
//     marginBottom: 10,
//     padding: 10
//   }
// });
// // Customizable Area End
