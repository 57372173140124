import React, { useState } from "react";

export const NoProfilePictureURL = "https://thebenclark.files.wordpress.com/2014/03/facebook-default-no-profile-pic.jpg";
const CandidateContext = React.createContext({
    profile_picture: "",
    setCandidateProfilePicture: (profile_picture: string) => { },
    removeCandidateProfilePicture: () => { },
});

export const CandidateConsumer = CandidateContext.Consumer;


export const CandidateProvider: React.FC = (props) => {
    const [profile, setProfile] = useState("");

    const handleSetProfile = (profile_picture: string) => {
        if (profile_picture === null)
            setProfile(NoProfilePictureURL);
        else
            setProfile(profile_picture);
    };

    const handleDeteProfile = () => {
        // set a default profile picture
        setProfile(NoProfilePictureURL);
    }

    return (
        <CandidateContext.Provider value={{
            profile_picture: profile,
            setCandidateProfilePicture: handleSetProfile,
            removeCandidateProfilePicture: handleDeteProfile
        }}>
            {props.children}
        </CandidateContext.Provider>
    );
}

export default CandidateContext;