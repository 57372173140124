import { Avatar, Box, Button, CircularProgress, Divider, List, ListItem, ListItemAvatar, ListItemText, Snackbar, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import WhatsappController from './WhatsappAdminController';
import MuiAlert from "@material-ui/lab/Alert";
import SendIcon from '@material-ui/icons/Send';
import { withRouter } from "react-router-dom";

const useStyles = (theme: Theme) => ({
    container: {
        background: theme.palette.common.white,
        position: "absolute",
        top: "30%",
        left: " 50%",
        transform: "translate(-50%, -50%)",
        boxShadow: "0 0 5px rgba(0,0,0,.5)",
        padding: "1.5rem 3rem 4rem",
        borderRadius: "1rem",

        "& .MuiTextField-root": {
            marginBottom: "1rem",
            "& input": {
                paddingLeft: ".5rem"
            }
        },

        ['@media (max-height:900px)']: {
            top: "15%",
            padding: "1rem 0.5rem 3rem",
        },

        ['@media (max-height:700px)']: {
            top: "10%",

            "& form": {
                width: "25rem",
            }
        }
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        height: "97%",
        overflow: "auto",

        "& li": {
            cursor: "pointer",
            transition: "all .5s ease",

            "&:hover": {
                background: "#ccc"
            },
        }
    },
    inline: {
        display: 'inline',
    },
    users: {
        backgroundColor: theme.palette.common.white,
        marginRight: ".5rem",
        flex: .5,
        [theme.breakpoints.down('md')]: {
            flex: 1,
        },
        marginBottom: ".5rem",
    },
    chatBox: {
        backgroundColor: theme.palette.common.white,
        position: "relative",
        maxHeight: "99%"
    },
    messages: {
        listStyle: "none",
        maxHeight: "90%",
        overflow: "auto",

        "& div": {
            background: "#ebebeb",
            padding: ".5rem 1rem",
            borderRadius: "1rem",
            marginBottom: ".8rem",
            maxWidth: "30%",
            [theme.breakpoints.down('md')]: {
                maxWidth: "50%",
            },

            "&:last-child": {
                marginBottom: "3.5rem"
            }
        },
    },
    selected: {
        background: "#ccc",
    },
    send: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        background: "#fff",
    },
    send_btn: {
        cursor: "pointer",
        marginRight: "1rem",
        marginBottom: ".5rem",

        "& svg": {
            transition: "all .5s ease",
            "&:hover": {
                transform: "scale(1.3)",
            },

            "&:active": {
                transform: "scale(0.9)",
            }
        }
    }
});

const ranges = [
    '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
    '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
    '\ud83d[\ude80-\udeff]', // U+1F680 to U+1F6FF
    ' ', // Also allow spaces
].join('|');

const removeEmoji = (str: string) => str.replace(new RegExp(ranges, 'g'), '');

const isOnlyEmojis = (str: string) => !removeEmoji(str).length;

interface SMProps {
    classes: any,
    selectedChat: any,
    loading: boolean,
    chats: any,
}

const ShowMessages: React.FC<SMProps> = ({ classes, loading, selectedChat, chats }) => {
    const ref = useRef<HTMLDivElement>(null);
    const ulRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (selectedChat) {
            setTimeout(() => {
                if (ref.current) {
                    ref!.current!.scrollIntoView({
                        block: "center",
                        behavior: 'smooth'
                    });
                }
            }, 500);
        }
    }, [selectedChat, chats]);

    return (
        <ul className={classes.messages} ref={ulRef}>
            {selectedChat && !loading &&
                chats.length && chats.map((chat: any, index: number) => {
                    const isEmoji = isOnlyEmojis(chat.attributes.message);
                    return (
                        <div
                            key={index}
                            style={{
                                marginLeft: chat.attributes.type !== "receiver" ? "auto" : "",
                                background: isEmoji ? "transparent" : "",
                                padding: isEmoji ? 0 : "",
                                borderBottomRightRadius: chat.attributes.type === "send" ? 0 : "1rem",
                                borderBottomLeftRadius: chat.attributes.type === "receiver" ? 0 : "1rem",
                            }}
                            ref={(chats.length - 1) === index ? ref : undefined}
                        >
                            <Typography style={{ fontSize: isEmoji ? "2rem" : "1rem" }}>{chat.attributes.message}</Typography>
                            <Typography style={{ display: "flex", justifyContent: "flex-end", fontSize: ".5rem" }}>
                                {new Date(chat.attributes.created_at).toLocaleString()}
                            </Typography>
                        </div>
                    )
                })}
        </ul >
    );
}

interface SendMessageProps {
    classes: any,
    adminMessage: string,
    setMessage: (message: string) => void;
    handleSendMessage: (e: React.FormEvent) => void;
    selectedUser: any;
}

const SendContainer: React.FC<SendMessageProps> = ({ classes, adminMessage, handleSendMessage, setMessage, selectedUser }) => {
    return (
        <Box display="flex" alignItems="center" component="form" onSubmit={(e) => handleSendMessage(e)} className={classes.send} >
            <Box ml={1} pr="1rem" width="100%">
                <TextField
                    placeholder='Send a Message'
                    variant='outlined'
                    style={{ paddingBottom: ".5rem" }}
                    value={adminMessage}
                    onChange={(e) => setMessage(e.target.value)}
                    fullWidth
                    autoFocus
                />
            </Box>
            <Button className={classes.send_btn}
                component="button" type="submit"
            >
                <SendIcon color="primary" />
            </Button>
        </Box>
    )
}


class WhatsappAdmin extends WhatsappController {

    render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <>
                {this.state.isLoggedIn && (
                    <Box display="flex" style={{
                        height: "89vh",
                        overflow: "hidden"
                    }}>

                        {this.state.loading && !this.state.selectedChat && (
                            <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%" mt={20}>
                                <CircularProgress color='primary' />
                            </Box>
                        )}

                        <Box className={classes.users}>
                            <List className={classes.root}>
                                {this.state.users.length ? this.state.users.map((user: any, index: number) => {
                                    return (
                                        <Box key={user.id} >
                                            <ListItem alignItems="flex-start"
                                                onClick={() => this.handleChatSelect(user)}
                                                className={user.attributes.chat_id === this.state.selectedChat?.attributes.chat_id ? classes.selected : ""}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp"
                                                        src={user.attributes.photo || `https://www.bootdey.com/img/Content/avatar/avatar${(index % 8) + 1}.png`}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={user.attributes.user_name}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                className={classes.inline}
                                                                color="textPrimary"
                                                            >
                                                                {user.attributes.phone_number}
                                                            </Typography>
                                                            {/* {" — Last "} */}
                                                        </React.Fragment>
                                                    }
                                                />
                                                {user.attributes.has_new_msg && (
                                                    <Box
                                                        style={{
                                                            width: "1rem", height: "1rem", background: "red",
                                                            borderRadius: "50%", marginTop: "1.2rem"
                                                        }}
                                                    />
                                                )}
                                            </ListItem>

                                            <Divider />
                                        </Box>
                                    )
                                }) : null}

                            </List>
                        </Box>

                        {this.state.users.length ? (
                            <Box flex={2} className={classes.chatBox}>
                                {!this.state.selectedChat && (
                                    <Box display="flex" alignItems="center" justifyContent="center" mt={20}>
                                        <Typography>Please select a chat to start chatting !!</Typography>
                                    </Box>
                                )}

                                {this.state.selectedChat && this.state.loading && (
                                    <Box display="flex" alignItems="center" justifyContent="center" height="100%" width="100%" mt={3}>
                                        <CircularProgress color='primary' />
                                    </Box>
                                )}

                                <ShowMessages
                                    chats={this.state.chats}
                                    classes={classes}
                                    loading={this.state.loading}
                                    selectedChat={this.state.selectedChat}
                                />

                                {this.state.selectedChat && !this.state.loading && (
                                    <SendContainer
                                        adminMessage={this.state.adminMessage}
                                        classes={classes}
                                        handleSendMessage={this.handleSendMessage}
                                        setMessage={(val: string) => this.setState({ adminMessage: val })}
                                        selectedUser={this.state.selectedChat}
                                    />
                                )}
                            </Box>
                        ) : null}

                    </Box>
                )
                }

                <Snackbar
                    open={this.state.snackBar.show}
                    autoHideDuration={3000}
                    onClose={this.closeSnackBarHandler}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.closeSnackBarHandler}
                        severity={this.state.snackBar.type}
                    >
                        {this.state.snackBar.message}
                    </MuiAlert>
                </Snackbar>
            </>
        );
    }
}

// @ts-ignore
export default withStyles(useStyles)(withRouter(WhatsappAdmin));