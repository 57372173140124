import React from "react";

//Customizable Area Start
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Typography, withStyles, Card, Container, Button, TextField, CircularProgress } from "@material-ui/core";
import { banner } from '../../email-account-login/src/assets';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AfterInterviewFeedbackController, { Props } from "./AfterInterviewFeedbackController.web";
import { Rating } from "@material-ui/lab";

const blockStyle = (theme: any) => ({
    banner: {
        backgroundColor: "#f2f2f2",
        padding: "1rem 2rem",
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${banner})`,
        backgroundSize: "cover",
        backgroundPositionY: "-15rem",
        [theme.breakpoints.up('sm')]: {
            padding: "2rem",
        },
        height: "100%",
    },
    textWhite: {
        color: "#ffffff",
    },
    loginCard: {
        paddingTop: "2em",
        padding: "1.3em",
        minHeight: "16em"
    },
    fullBtn: {
        width: '100%'
    },
    container: {
        flex: 1,
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
    },
    goBackArrow: { width: "5%", },
    forgetText: { width: "90%", },
    ratingCl: {
        fontSize: "3em!important",
        [theme.breakpoints.down('xs')]: {
            "& span": {
                fontSize: "1.4em!important"
            },
        }
    },
    ratingBoxError: {
        color: "#f44336",
        fontSize: "0.75rem",
        margin: "3px 0 0",
        textAlign: "center",
        fontFamily: "Poppins-Regular",
        fontWeight: "400",
        lineHeight: "1.5rem",
        letterSpacing: "0.03333rem",
        textTransform: "none",
    }
});
//Customizable Area End

class AfterInterviewFeedback extends AfterInterviewFeedbackController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }
    //Customizable Area Start
    componentDidMount(): Promise<void> {
        window.addEventListener('resize', this.checkWindowResize)
        window.scrollTo(0, 0);
        let token = this.props.location?.pathname?.split("/");
        this.setState({ pageToken: token[2] }, () => this.checkInterviewData())
        return super.componentDidMount();
    }

    //Customizable Area End


    render() {
        //Customizable Area Start
        const { classes } = this.props;
        const { navigation } = this.props;

        return (
            <>
                <Box className={classes.container} mb={8}>
                    <Box className={classes.banner}>
                        <Box style={{ marginTop: "9em" }}>
                            <Container maxWidth="sm">
                                <Card className={classes.loginCard}>
                                    <Box>
                                        <Box marginTop={this.state.successText ? "2rem" : "0"}  >
                                            <Typography color="primary" align="center" variant="h5">
                                                Candidate Feedback
                                            </Typography>
                                        </Box>
                                        <Box my={2}>
                                            <Typography color="primary" align="center" variant="body1">
                                                {this.state.successText ? `We have successfully received your feedback.`
                                                    : (this.state.feedBackExpired ? `Feedback link has been expired` : `Please give your feedback for the interview`)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box display='flex' alignItems="center">
                                        {!this.state.successText && !this.state.feedBackExpired ?
                                            <Formik
                                                initialValues={{ feedback: "", rating: null }}
                                                validationSchema={this.state.feedbackSchema}
                                                onSubmit={(values: any) => {
                                                    this.sendCandidateFeedback(values)
                                                }}
                                            >
                                                {(formikProps: any) => (
                                                    <Box flex="1" mt={2}>
                                                        <form >
                                                            <Box mb={4} display='flex' justifyContent='center'>
                                                                <Box>
                                                                    <Rating
                                                                        name="rating"
                                                                        value={formikProps?.values.rating || 0}
                                                                        className={classes.ratingCl}
                                                                        precision={0.5}
                                                                        onChange={(event, newValue) => {
                                                                            formikProps.setFieldValue("rating", newValue);
                                                                        }}
                                                                    />
                                                                    {formikProps.errors.rating && (
                                                                        <p className={classes.ratingBoxError}>Please give all the ratings</p>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                            <TextField
                                                                fullWidth
                                                                placeholder="Enter your feedback "
                                                                id="feedback"
                                                                name="feedback"
                                                                multiline
                                                                variant='outlined'
                                                                rows={4}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={formikProps.handleBlur}
                                                                value={formikProps.values.feedback}
                                                                error={formikProps.touched.feedback && Boolean(formikProps.errors.feedback)}
                                                                helperText={formikProps.touched.feedback && formikProps.errors.feedback}
                                                            />
                                                            <Box style={{ marginTop: "2em" }}>
                                                                <Button onClick={() => formikProps.handleSubmit()} className={classes.fullBtn} disableRipple variant="contained" color="primary" disabled={this.state.loader}>
                                                                    <Typography variant="body2">
                                                                        Submit
                                                                    </Typography>
                                                                    {this.state.loader && (
                                                                        <CircularProgress
                                                                            size={24}
                                                                            style={{
                                                                                color: "#24325f",
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                marginTop: '-12px',
                                                                                marginLeft: '-12px',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Button>
                                                            </Box>
                                                        </form>
                                                    </Box>
                                                )}
                                            </Formik>
                                            :
                                            <Button onClick={() => this.props.history.push("/")} className={classes.fullBtn} disableRipple variant="contained" color="primary" disabled={this.state.loader}>
                                                <Typography variant="body2">
                                                    Home Page
                                                </Typography>
                                                {this.state.loader && (
                                                    <CircularProgress
                                                        size={24}
                                                        style={{
                                                            color: "#24325f",
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Button>}
                                    </Box>
                                </Card>
                            </Container>
                        </Box>
                    </Box>
                </Box>
                {/* Snackbar for display success and failed messages. */}
                <Snackbar
                    open={this.state.snackBar.show}
                    autoHideDuration={3000}
                    onClose={this.closeSnackBarHandler}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.closeSnackBarHandler}
                        severity={this.state.snackBar.type}
                    >
                        {this.state.snackBar.message}
                    </MuiAlert>
                </Snackbar>
            </>
        )
        //Customizable Area End
    }

}

// Customizable Area Start
// @ts-ignore
export default withStyles(blockStyle)(withRouter(AfterInterviewFeedback))