Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";

exports.btnExampleTitle = "CLICK ME";
exports.putAPiMethod = "PUT";
exports.sendCandidateFeedbackAPIEndPoint = 'bx_block_scheduling/interviewer_feedback';
exports.checkInterviewDataAPiEndPoint = "bx_block_scheduling/get_interview_for_interviewer";
// Customizable Area End