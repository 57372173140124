export interface FDProps {
    id: number,
    filter: string,
    values: {
        id: number, name: string, vacancy: number,
    }[]
}


export const FilterData = [
    {
        "id": 1,
        "filter": "Employee Type",
        "values": [
            { "id": 1, "name": "Full Time Jobs", "vacancy": 23 },
            { "id": 2, "name": "Part Time Jobs", "vacancy": 20 },
            { "id": 3, "name": "Contract Jobs", "vacancy": 10 },
            { "id": 4, "name": "Remote Jobs", "vacancy": 5 },
            { "id": 5, "name": "Freelance Jobs", "vacancy": 3 },
        ]
    },
    {
        "id": 2,
        "filter": "Work Level",
        "values": [
            { "id": 1, "name": "Student", "vacancy": 23 },
            { "id": 2, "name": "Entry Level", "vacancy": 20 },
            { "id": 3, "name": "Mid Level", "vacancy": 10 },
            { "id": 4, "name": "Senior Level", "vacancy": 5 },
            { "id": 5, "name": "Directors", "vacancy": 3 },
        ]
    },
    {
        "id": 3,
        "filter": "Salary Range",
        "values": [
            { "id": 1, "name": "0 - 10,000", "vacancy": 23 },
            { "id": 2, "name": "10,000 - 20,000", "vacancy": 20 },
            { "id": 3, "name": "20,000 - 30,000", "vacancy": 10 },
            { "id": 4, "name": "30,000 - 40,000", "vacancy": 5 },
            { "id": 5, "name": "40,000 - 50,000", "vacancy": 3 },
        ]
    },
    {
        "id": 4,
        "filter": "Location",
        "values": [
            { "id": 1, "name": "Bangalore", "vacancy": 23 },
            { "id": 2, "name": "Chennai", "vacancy": 20 },
            { "id": 3, "name": "Hyderabad", "vacancy": 10 },
            { "id": 4, "name": "Pune", "vacancy": 5 },
            { "id": 5, "name": "Mumbai", "vacancy": 3 },
        ]
    },
    {
        "id": 5,
        "filter": "Language",
        "values": [
            { "id": 1, "name": "English", "vacancy": 23 },
            { "id": 2, "name": "Tamil", "vacancy": 20 },
            { "id": 3, "name": "Telugu", "vacancy": 10 },
            { "id": 4, "name": "Hindi", "vacancy": 5 },
            { "id": 5, "name": "Kannada", "vacancy": 3 },
        ]
    },
    {
        "id": 6,
        "filter": "Facility",
        "values": [
            { "id": 1, "name": "Laptop", "vacancy": 23 },
            { "id": 2, "name": "Projector", "vacancy": 20 },
            { "id": 3, "name": "Smart Board", "vacancy": 10 },
            { "id": 4, "name": "Smart Phone", "vacancy": 5 },
            { "id": 5, "name": "Tablet", "vacancy": 3 },
        ]
    }
]