import React, { ComponentClass } from "react";

// Customizable Area Start
//@ts-ignore
import { Box, Typography, withStyles, Card, Container, Button, TextField, CircularProgress } from "@material-ui/core";
import { banner } from './assets';
import _ from 'lodash';
import {
  BrowserRouter as Router,
  Link,
  withRouter
} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController.web";
import FormField from "../../../components/src/FormField";
import LandingPage2 from "../../LandingPage2/src/LandingPage2";
import { Formik } from 'formik';
import * as Yup from "yup"
import axios from "axios";
import EmailAccountRegistrationVerifyWeb from "../../email-account-registration/src/EmailAccountRegistrationVerify.web";
import EmailAccountRegistrationOTPWeb from "../../email-account-registration/src/EmailAccountRegistrationOTP.web";
interface MyFormValues {
  email: string;
  password: string;

}
// Customizable Area End

// Customizable Area Start
var loginSuccessStatus: boolean = true;
const blockStyle = (theme: any) => ({
  banner: {
    padding: "1rem 2rem",
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPositionY: "-15rem",
    [theme.breakpoints.down('sm')]: {
      padding: "1rem 0",
    },
  },
  displayNone: {
    display: "none",
  },
  displayNormal: {
    display: "",
  },
  textWhite: {
    color: "#ffffff",
  },
  loginCard: {
    paddingTop: "2em",
    padding: "1.3em",
  },
  fullBtn: {
    width: '100%'
  },
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  }
});

// Validations
const validationSchema = Yup.object({

  email: Yup.string().email('Email is invalid').required('Email is a required field'),
  password: Yup.string().required('Password is required field')
  // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/, "Pasword must contain minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character"),

});
// Customizable Area End

class EmailAccountLoginBlock extends EmailAccountLoginController {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0, 0);
    if (location.search === "") {
      this.props.navigation.navigate("LandingPage2")
    } else {
      let requestedLoginType = location.search.split('?')[1].split('=');
      if (requestedLoginType[0] !== "type" || !(_.includes(["client", "candidate", "admin"], requestedLoginType[1]))) {
        this.props.navigation.navigate("LandingPage2")
      } else {
        this.setState({ userType: requestedLoginType[1] });
      }
    }
  }

  handleChange(event: any) {
    this.setState({ email: event.target.value });
  }

  loggedInState(event: any) {
    loginSuccessStatus = true;
  }

  render() {
    const { classes } = this.props as any;

    return (
      <>
        {this.state.signInStep == "signin" ? (
          <Box className={classes.container} mb={8}>
            {/* <form > */}
            <Box className={classes.banner}>
              <Box mb={8}>
                <Typography align="center" className={classes.textWhite} variant="h2">
                  Welcome to Xcelyst
                </Typography>
              </Box>
              <Container maxWidth="sm">
                <Card className={classes.loginCard}>
                  <Typography align="center" style={{ color: "#737276" }} variant="body1">
                    {this.state.userType.toUpperCase()} LOGIN
                  </Typography>
                  <Box my={3}>
                    <Typography color="primary" align="center" variant="h5" style={{ whiteSpace: "pre", color: "#25335e", fontWeight: 1000 }}>
                      {this.state.userType == 'candidate' ? `Find the Million Opportunities \nto Level Up Your Career` : "Save Time \n Hire The Best Talent"}
                    </Typography>
                  </Box>
                  <Box>
                    {/* Form for log in flow start */}
                    <Formik
                      initialValues={{
                        email: '',
                        password: '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        this.doEmailLogIn(values);
                      }}>
                      {(formikProps) => (
                        <form onSubmit={formikProps.handleSubmit}>
                          <Box my={5}>
                            <TextField
                              onClick={this.loggedInState}
                              style={{ width: "100%" }}
                              // onChange={(e: any) => this.setState({ email: e.target.value })} 
                              id="email"
                              name="email"
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              value={formikProps.values.email}
                              error={formikProps.touched.email && Boolean(formikProps.errors.email)}
                              helperText={formikProps.touched.email && formikProps.errors.email}
                              InputProps={{
                                startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/email.png')} />,
                              }}
                              placeholder="Enter Your Email Id"

                            />
                          </Box>
                          <Box my={5}>
                            <TextField
                              onClick={this.loggedInState}
                              style={{ width: "100%" }}
                              placeholder="Enter Your Password"
                              id="password"
                              name="password"
                              type={this.state.enablePasswordField ? "password" : "text"}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              value={formikProps.values.password}
                              error={formikProps.touched.password && Boolean(formikProps.errors.password)}
                              helperText={formikProps.touched.password && formikProps.errors.password}
                              InputProps={{
                                startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/password.png')} />,
                                endAdornment: <img width="24px" onClick={() => { this.setState({ enablePasswordField: !this.state.enablePasswordField }) }} style={{ marginRight: 15 }} src={this.state.enablePasswordField ? require('../assets/show_password.png') : require('../assets/hide_password.png')} />
                              }}
                            />
                            <Typography style={{ fontSize: "0.75rem", textAlign: "left", fontFamily: "Poppins-Regular", fontWeight: 400, color: "#f44336", textTransform: "none", lineHeight: "1.5rem", letterSpacing: "0.03333cm" }} className={loginSuccessStatus ? classes.displayNone : classes.displayNormal} >
                              Invalid email or password. Please input valid email or password.
                            </Typography>
                          </Box>
                          {this.state.loader ?
                            <div style={{ textAlign: "center" }}><CircularProgress /></div> :
                            <Button type="submit" className={classes.fullBtn} disableRipple variant="contained" color="primary">
                              <Typography variant="body2">
                                Login
                              </Typography>
                            </Button>
                          }
                        </form>
                      )}
                    </Formik>
                    {/* Form for log in flow end */}
                  </Box>

                </Card>
                <Box mt={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                  <Box display="flex" justifyContent="center">
                    <Link to={`/forgot-password?type=${this.state.userType}`} style={{ textDecoration: "none" }}>
                      <Typography color="textSecondary" variant="h6">
                        Forgot Password
                      </Typography>
                    </Link>
                  </Box>
                  {/* If user type is "Candidate" - link for redirecting to Signup screen */}
                  {
                    this.state.userType == 'candidate' ?
                      (
                        <Box display="flex" justifyContent="center">
                          <Link to="/signup" style={{ textDecoration: "none" }}>
                            <Typography color="primary" variant="body2">
                              &nbsp; . &nbsp;Sign up for new user?
                            </Typography>
                          </Link>
                        </Box>
                      )
                      : <></>
                  }
                </Box>
              </Container>
            </Box>
            {/* </form> */}

          </Box>) : ""}

        {/* For email verification */}
        {this.state.signInStep == "verifyEmail" ? (
          //@ts-ignore
          <EmailAccountRegistrationVerifyWeb verifyIt={this.handleToOtpScreen} accountEmail={this.state.accountEmail} alreadyExistsMsg={this.state.isVerifyAccount} showLoader={this.state.loading}
          />
        ) : ""}

        {/* For otp verification received in email */}
        {this.state.signInStep == "otpVerify" ? (
          // @ts-ignore
          <EmailAccountRegistrationOTPWeb verifyIt={this.handleToOtpScreen} goToLogIn={() => this.setState({ signInStep: "signin" })} />
        ) : ""}
        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}
// Customizable Area End
// Customizable Area Start
// @ts-ignore
export default withStyles(blockStyle)(withRouter(EmailAccountLoginBlock))
// Customizable Area End