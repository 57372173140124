import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#fff",
        borderRadius: "20px",
        marginBottom: "1rem"
    },
    container: {
        padding: ".4rem 1rem",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        display: "flex",
        background: "#a8d0e7",
        color: "#24325f",
        justifyContent: "space-between",
        alignItems: "center",

        "& p": {
            letterSpacing: ".1rem",
            cursor: "not-allowed",

            "&:first-child": {
                fontWeight: 600
            }
        }
    },
    content: {
        "& > div": {
            padding: ".2rem",

            "& div": {
                height: "1rem",
                margin: ".4rem"
            }
        }
    },

    skeleton: {
        animation: "$skeleton-loading 1s linear infinite alternate",
        opacity: ".6",
        marginRight: ".5rem"
    },

    "@keyframes skeleton-loading": {
        "0%": {
            backgroundColor: "hsl(200, 20%, 70%)"
        },

        "100%": {
            backgroundColor: "hsl(200, 20%, 95%)"
        }
    }
});

const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const ShimmerDiv = ({ min, max }: { min: number, max: number }) => {
    const classes = useStyles();

    return (
        <Box>
            {Array(getRandomInt(min, max))
                .fill(0)
                .map((_, index) => (
                    <Box
                        style={{ width: `${getRandomInt(50, 98)}%` }}
                        className={classes.skeleton}
                        key={index}
                    />
                ))}
        </Box>
    );
};

const LoadingEffect = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <Typography component="p"> Loading... </Typography>
                <Typography component="p">VIEW ALL</Typography>
            </Box>
            <Box className={classes.content}>
                <ShimmerDiv min={4} max={8} />
            </Box>
        </Box>
    );
}

const RolesLoading = ({ count = 1 }: { count: number }) => {
    return (
        <Box>
            {Array(count)
                .fill(0)
                .map((_, index) => (
                    <LoadingEffect key={index} />
                ))}
        </Box>
    );
};

export default RolesLoading;