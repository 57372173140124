import React from "react";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

// Customizable Area Start
import { ReactNode } from "react";
import { Box, Typography, Grid, withStyles, Theme } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { clientRoutes } from "./TopNav";
import Contact from "../../blocks/LandingPage2/src/Contact.web";
import Notifications from "../../components/src/Notifications.web"
import { useStyles as useNotiStyles } from "../../components/src/TopNav_Candidate";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    history: any;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isModalOpen: boolean;
    links: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
const muiStyles = (theme: Theme) => ({
    container: {
        backgroundColor: "#ffffff",
        marginTop: "auto",

        [theme.breakpoints.down("md")]: {
            "& > div:first-child": {
                flexDirection: "column",

                "& > div:nth-child(2)": {
                    flexBasis: "100%",
                    maxWidth: "100%",
                }
            }
        }
    },
    footerBox: {
        marginTop: "1.5rem",
        marginBottom: "1.5rem",
        [theme.breakpoints.down('md')]: {
            margin: 0,

            "& p": {
                marginBottom: "1rem",
            },
            "& u": {
                fontSize: "1rem",
            }
        },
    },
    dispalayNone: {
        display: "none",
    },
    dispalayNormal: {
        display: "",
    },
    links: {
        textDecoration: "none",

        "&:not(:last-child)": {
            marginRight: "2rem",
        },

        "& svg": {
            display: "none",
        }
    },
    cc: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "center",

            "& h6": {
                marginBottom: "0.5rem",
            },
            "& p": {
                fontSize: "1rem"
            }
        },
        ['@media (min-width: 1220px) and (max-width: 1280px)']: {
            flexDirection: "column",
            alignItems: "center",

            "& h6": {
                marginBottom: "0.5rem",
            },
        }
    },
    logo: {
        [theme.breakpoints.down('md')]: {
            "& img": {
                width: "100px",
                paddingTop: "1.5rem",
            }
        },
    },
    socialIcons: {
        [theme.breakpoints.down('md')]: {
            "& img": {
                width: "30px",
                margin: "1rem 0 1rem .5rem",
            }
        },
    },
    routes: {
        [theme.breakpoints.down('md')]: {
            marginTop: "1rem",

            "& > div:first-child": {
                marginBottom: 0,
            }
        },
    }
});
// Customizable Area End

class Footer extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getLinksApiId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isModalOpen: false,
            links: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId != null) {
                if (
                    apiRequestCallId === this.getLinksApiId
                ) {
                    if (responseJson !== undefined && !responseJson?.errors) {
                        this.setState({ links: responseJson.data });
                    } else { }
                }
            }
            else {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getLinks();
    }

    toggleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    showProfile = () => {
        window.scrollTo(0, 0);
    }
    // Customizable Area End

    // Customizable Area Start

    // fetch all social media links
    getLinks = () => {
        const header = {
            "Content-Type": "application/json",
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getLinksApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_content_management/get_social_media_link"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

    // Customizable Area Start
    render(): ReactNode {
        const location = this.props.history.location.pathname.split("/")[1];
        const { classes } = this.props;
        const linkTo = location === "" || location === 'login' ? "/" : sessionStorage.getItem("user_role") === "candidate" ? "/profile-setting" : "/dashboard";
        console.log("location", location);
        if ((clientRoutes.includes(location) && sessionStorage.getItem("user_role") !== "candidate") || location === "whatsapp") {
            return null;
        }

        return (
            <>
                {/* contact Modal */}
                <Contact
                    open={this.state.isModalOpen}
                    onClose={this.toggleModal}
                    sendRequestForDemoData={() => { }}
                />

                <Box className={classes.container}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xl={3} lg={3} md={2} xs={12} sm={4}>
                            <Box className={`${classes.footerBox} ${classes.logo}`} justifyContent="center" display="flex">
                                <Link to={linkTo} href={linkTo}><img width="130px" src={require('./xcelyst_logo.png')} /></Link>
                            </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={8} xs={12} sm={4}>
                            <Box className={`${classes.footerBox} ${classes.routes}`}>
                                <Box mb={2} display="flex" justifyContent="center" flexWrap='wrap'>
                                    {sessionStorage.getItem("user_role") === "candidate" &&
                                        (location === 'profile-setting' || location.includes('job-listing') || location.includes("job-detail")) ?
                                        <>
                                            <Link to="/profile-setting/chats" className={classes.links} >
                                                <Typography color="primary" variant="body2">
                                                    Messages
                                                </Typography>
                                            </Link>
                                            <Link to="#" className={classes.links}>
                                                <Notifications classes={classes} history={this.props.history} >
                                                    <Typography color="primary" variant="body2">
                                                        Notifications
                                                    </Typography>
                                                </Notifications>
                                            </Link>

                                            <Link to="/profile-setting" onClick={this.showProfile} className={classes.links}>
                                                <Typography color="primary" variant="body2">
                                                    My Profile
                                                </Typography>
                                            </Link>
                                        </>
                                        : (
                                            <>
                                                <Link to="/" className={classes.links} >
                                                    <Typography color="primary" variant="body2">
                                                        Home
                                                    </Typography>
                                                </Link>
                                                <Link href="/about-us" to="/about-us" className={classes.links}>
                                                    <Typography color="primary" variant="body2">
                                                        About Us
                                                    </Typography>
                                                </Link>

                                                <Link to="/verticals" className={classes.links} >
                                                    <Typography color="primary" variant="body2">
                                                        Verticals
                                                    </Typography>
                                                </Link>

                                                <Link to="/database" className={classes.links} >
                                                    <Typography color="primary" variant="body2">
                                                        Database
                                                    </Typography>
                                                </Link>

                                                <Link to="/team" className={classes.links} >
                                                    <Typography color="primary" variant="body2">
                                                        Team
                                                    </Typography>
                                                </Link>

                                                <Typography
                                                    className={classes.links}
                                                    color="primary"
                                                    variant="body2"
                                                    onClick={this.toggleModal}
                                                >
                                                    Contact Us
                                                </Typography>
                                            </>)}
                                </Box>
                                <Box className={classes.cc}>
                                    <Typography align="center" variant="h6" style={{ color: "dimgrey", fontSize: "0.8rem", marginLeft: "20px" }}>
                                        {new Date().getFullYear()} Xcelyst Limited. All rights reserved.
                                    </Typography>
                                    {(location !== 'profile-setting' && !location.includes('job-listing') && !location.includes("job-detail")) && (
                                        <Link to="/terms-and-conditions" className={classes.links}>
                                            <Typography style={{ color: "dimgrey", fontSize: "0.8rem", marginLeft: "11px" }} variant="body1">
                                                <u>Terms and Conditions</u>
                                            </Typography>
                                        </Link>)}
                                </Box>
                            </Box>
                        </Grid>
                        {this.state.links.length > 0 && (
                            <Grid item xl={3} lg={3} md={2} xs={12} sm={4}>
                                <Box className={`${classes.footerBox} ${classes.socialIcons}`} justifyContent="center" display="flex">
                                    {this.state.links.map((each: any, index: number) => {
                                        return (
                                            <a key={index} href={`https://${each.link}`} target="_blank">
                                                <img width="32px" src={require(`./${each.title}.png`)} alt={`${each.title}_Icon`} />
                                            </a>
                                        )
                                    })}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box >
            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
// @ts-ignore
export default withStyles(muiStyles, useNotiStyles)(withRouter(Footer)) as Footer;
// Customizable Area End