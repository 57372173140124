import React, { useState } from 'react'
import {
    Button, Dialog, Grid, Theme, ThemeProvider, Typography,
    IconButton, InputAdornment, Snackbar, Select, MenuItem, Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, useTheme, TablePagination, TableFooter, Tooltip
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { ArrowBackSharp, ArrowLeft, ArrowRight, CloseOutlined, FirstPage, LastPage } from '@material-ui/icons';
import Location4Controller from './Location4Controller';
import { theme } from './styles.web';
import { withStyles } from '@material-ui/styles';
import { ReactComponent as Calender } from "../assets/calender.svg";
import { Formik } from 'formik';
import MuiAlert from "@material-ui/lab/Alert";
import { formatDate, isAnySlotAvaiable } from '../../QuestionBank/src/TabComponent.web';
import TimezoneSelect from 'react-timezone-select';


const useStyles = (theme: Theme) => ({
    modal: {
        "& > div > div": {
            width: "100%",
            padding: "1em 1.5em",
            margin: "1em"
        },
        "& div": {
            borderRadius: "1rem",
        }
    },

    container: {
        padding: "2rem 0",
        "& div": {
            justifyContent: 'center',
            alignItems: 'center',
        }
    },

    heading: {
        "& h5": {
            fontWeight: 600,
        },

        "& svg": {
            "&:first-child": {
                position: "absolute",
                left: theme.spacing(4),
            },

            "&:last-child": {
                position: "absolute",
                right: theme.spacing(4),
            },

            cursor: "pointer",
            transition: "all .3s ease-in-out",

            "&:hover": {
                transform: "scale(1.2)",
            }
        }
    },

    details: {
        margin: "2.5rem 0",

        "& p": {
            color: "#8a8a93",
            marginBottom: theme.spacing(1.5),
        },

        "& h5": {
            fontWeight: 600,
            textTransform: "capitalize",
        },

        "& div": {
            "&:first-child": {
                marginRight: "3rem",
            }
        }
    },

    timing: {
        "& p": {
            fontWeight: 600,
            marginBottom: theme.spacing(1.5),
            width: "100%",
            textAlign: "center",
        },

        "& div": {
            flexDirection: "column",

            "& h5": {
                fontSize: '1.2rem',
                color: "#8a8a93",
                marginBottom: theme.spacing(3),
            }
        }
    },

    buttons: {
        margin: "2.5rem 0 1.5rem",

        "& button": {
            textTransform: "capitalize",
            fontWeight: 600,
            padding: ".7rem 2rem",
            borderRadius: ".8rem",

            "&:first-child": {
                backgroundColor: "#a8d0e7",
                color: "#24325f",
                marginRight: theme.spacing(5),
            },

            "&:last-child": {
                backgroundColor: "#24325f",
                color: "#fff",
            }
        }
    },

    dateDatePick: {
        "& > div": {
            "&:before": {
                borderBottom: "1px solid #E2E2EA"
            },
            "&:after": {
                borderBottom: "2px solid #E2E2EA"
            },
            "& > input": {
                width: "26em"
            },
            "& > div": {
                position: "absolute",
                right: 0
            }
        }
    },

    dateTimeGrid: {
        "& div": {
            marginTop: theme.spacing(1),
        }
    },

    schedule_timings: {
        "& p": {
            marginBottom: theme.spacing(1.5),
            width: "100%",
            textAlign: "center",

            "&:first-child": {
                fontWeight: 600,
                fontSize: "130%"
            },

            "&:not(:first-child)": {
                fontSize: "120%"
            }
        },
    },

    requestAdminSupport: {
        '& h6': {
            margin: theme.spacing(4),
            fontWeight: 600,
            width: "100%",
            textAlign: "center"
        },

        "& button": {
            textTransform: "capitalize",
            fontWeight: 600,
            padding: ".7rem 2rem",
            borderRadius: ".8rem",
            backgroundColor: "#24325f",
            color: "#fff",

            "&:hover": {
                backgroundColor: "#a8d0e7",
            }
        }
    },

    mrLeft: {
        marginLeft: theme.spacing(1)
    },

    dropDowns: {
        marginBottom: theme.spacing(4),

        "& div": {
            "&:before": {
                borderBottom: "1px solid grey"
            },

            // "& div:focus": {
            //     backgroundColor: "#fff"
            // }
        }
    },

    select: {
        // width: '26rem',
        paddingLeft: '1.5rem',
        paddingBottom: '.3rem',
        color: "grey",
    },

    detailsBtn: {
        fontWeight: 600,
        padding: ".7rem 2rem",
        borderRadius: ".8rem",
        backgroundColor: "#71c5f5",
        fontSize: "70%",
        transition: "all .3s ease-in-out",
        color: "#000",

        "&:hover": {
            backgroundColor: "#24325f",
            color: "#fff"
        }
    },

    Interviewdetails: {
        margin: "0 2rem",

        "& table": {
            minWidth: 500,

            "& thead": {
                "& tr": {
                    "& th": {
                        fontWeight: 600,
                        color: "#8a8a93",
                        fontSize: "90%",
                        textTransform: "capitalize",
                    }
                }
            }
        }
    },

    pagination: {
        overflow: "unset",
        color: "#8a8a93",

        "& div": {
            justifyContent: "flex-start",
        }
    },

    wrapeerTimeZone: {
        width: "26.2rem",

        "& div": {
            marginTop: 0,
            fontFamily: 'Poppins-Regular',

            [theme.breakpoints.down("sm")]: {
                fontSize: ".9rem"
            }
        },

        "& > div > div": {
            borderColor: "transparent",
            boxShadow: "none",
            flexDirection: "row",
            border: "none",
            borderBottom: "1px solid #E2E2EA",
            borderRadius: 0,

            "& > div:first-child": {
                marginBottom: "-.8rem",
                justifyContent: "start",
                paddingLeft: "2px",
            }
        },

        "& > div > div:hover": {
            borderColor: "transparent",
            boxShadow: "none",
            borderBottom: "1.5px solid #000",
        },

        "& #react-select-2-listbox": {
            background: "#fff",
            minHeight: "3.5rem",
            borderBottomLeftRadius: ".5rem",
            borderBottomRightRadius: ".5rem",
            border: "1px solid",
            fontFamily: 'Poppins-Regular',
            overflowY: "hidden"
        }
    }
});

export function formatAMPM(string_date: string) {
    const time = string_date.split("T")[1].split(".")[0];
    const timeMin = time.split(":");
    let hour: number | string = Number(timeMin[0]);
    let minute: number | string = Number(timeMin[1]);
    let letAmPm = "AM";

    if (hour > 12) {
        hour = hour - 12;
        letAmPm = "PM";
    }

    if (hour < 10) hour = "0" + hour;
    if (minute < 10) minute = "0" + minute;

    return `${hour}:${minute} ${letAmPm}`;
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <ArrowRight /> : <ArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <ArrowLeft /> : <ArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Box>
    );
}

interface PIRProps {
    classes: any,
    scheduled_data: any
}

const PastInterviewRecords: React.FC<PIRProps> = (props) => {
    const { classes } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.scheduled_data.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <Grid container>
            <Grid item xs={12} className={classes.Interviewdetails}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Interviewer</TableCell>
                                <TableCell align="center">1st Slot</TableCell>
                                <TableCell align="center">2nd Slot</TableCell>
                                <TableCell align="center">3rd Slot</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Preferred Slot</TableCell>
                                <TableCell align="center">Admin Support</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(props.scheduled_data && rowsPerPage > 0
                                ? props.scheduled_data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : props.scheduled_data
                            )
                                .map((row: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {row.time_slots.interview_type ?
                                                capitalizeFirstLetter(row.time_slots.interview_type.split("_")[0]) : "____"}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.interviewer ? row.interviewer : "____"}
                                        </TableCell>
                                        <TableCell align="center">
                                            {formatDate(row.time_slots.first_slot)}
                                            <Typography style={{ fontSize: "90%" }}>{formatAMPM(row.time_slots.first_slot)}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {formatDate(row.time_slots.second_slot)}
                                            <Typography style={{ fontSize: "90%" }}>{formatAMPM(row.time_slots.second_slot)}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {formatDate(row.time_slots.third_slot)}
                                            <Typography style={{ fontSize: "90%" }}>{formatAMPM(row.time_slots.third_slot)}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.time_slots.is_accepted_by_candidate ? "Accepted" :
                                                isAnySlotAvaiable(row.time_slots) ? "Expried" : "Pending"}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.time_slots.preferred_slot ? row.time_slots.preferred_slot :
                                                isAnySlotAvaiable(row.time_slots) ? "Expried" : "Pending"}
                                        </TableCell>
                                        <TableCell align="center">{row.time_slots.require_admin_support ? "Yes" : "No"}</TableCell>
                                    </TableRow>
                                ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    className={classes.pagination}
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={props.scheduled_data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

class TimeSlotModal extends Location4Controller {

    async componentDidMount() {
        let clientID = +window.location?.search?.split("clientId=")[1];
        this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID }, () => {
            if (this.state.userType == 'client' || (this.state.userType == 'admin' && clientID)) {
                this.fetchManagerNames();
            }
            this.clientIDLink = this.state.userType == "admin" && this.state.clientID ? "clientId=" + this.state.clientID : "";
        });
        window.addEventListener('resize', this.checkWindowResize);

    }
    async componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowResize);
    }
    render() {
        const { classes } = this.props;
        const candidate_name = this.props.candidate?.attributes?.full_name;
        const role_name = this.props.candidate?.attributes?.role_name;

        return (
            <ThemeProvider theme={theme}>
                {/* modal */}
                <Dialog open={this.props.isOpen} fullWidth={true} maxWidth="md" className={classes.modal}>
                    {/* container */}
                    <Grid container className={classes.container}>
                        {/* heading */}
                        <Grid container className={classes.heading}>
                            {this.state.showDetails && <ArrowBackSharp onClick={this.handleShowDetails} />}
                            <Typography variant='h5'>Schedule Interview</Typography>
                            {!this.state.showDetails && <CloseOutlined onClick={() => {
                                this.props.toggleModal();
                                this.setState({ interviewer: null, interview_type: null });
                            }} />}
                        </Grid>
                        {/* job Details */}
                        <Grid container className={classes.details}>
                            <Grid item>
                                <Typography variant='body1'>Candidate Name</Typography>
                                {candidate_name &&
                                    <Tooltip title={candidate_name} arrow>
                                        <Typography variant='h5'>{candidate_name.length > 14 ? `${candidate_name.slice(0, 14)}...` : candidate_name}</Typography>
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item>
                                <Typography variant='body1'>Role Applied for</Typography>
                                {role_name &&
                                    <Tooltip title={role_name} arrow>
                                        <Typography variant='h5'>
                                            {role_name.length > 14 ? `${role_name.slice(0, 14)}...` : role_name}
                                        </Typography>
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>

                        {!this.state.showDetails ?
                            <Grid container className={classes.timing}>
                                <Formik
                                    // managers and assesTypes will load from api
                                    initialValues={{
                                        assesTypes: ["hr_assessment", "video_interview", "hiring_manger_assessment"],
                                        assesmentType: this.state.interview_type,
                                        manager: this.state.interviewer,
                                    }}
                                    //   validationSchema={this.state.createRoleValidationSchema}
                                    onSubmit={(values, { resetForm, }) => {
                                        this.scheduleInterview();
                                    }}>
                                    {(formikProps) => (
                                        <form onSubmit={formikProps.handleSubmit} className="createRole" style={{ width: "100%" }}>
                                            {/* Select Interviewer */}
                                            <Grid container className={classes.dropDowns}>
                                                <Select
                                                    label="Interviewer"
                                                    onChange={(e) => {
                                                        formikProps.setFieldValue("manager", e.target.value);
                                                        this.setState({ interviewer: e.target.value as string });
                                                    }}
                                                    className={classes.select}
                                                    displayEmpty
                                                    renderValue={this.state.interviewer !== null ?
                                                        undefined : () => "Select Interviewer"}
                                                    style={this.state.windowWidth > 960 ? { width: "26rem" } : { width: "100%" }}
                                                >
                                                    {this.state.managers?.map((manager: any) => (
                                                        <MenuItem key={manager.id} value={manager.id}>{manager.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>

                                            {/* Assessment Type */}

                                            <Grid container className={classes.dropDowns}>
                                                <Select
                                                    label="Assessment Type"
                                                    onChange={(e) => {
                                                        formikProps.setFieldValue("assesmentType", e.target.value);
                                                        this.setState({ interview_type: e.target.value as "hr_assessment" | "video_interview" | "hiring_manger_assessment" });
                                                    }}
                                                    className={classes.select}
                                                    displayEmpty
                                                    renderValue={this.state.interview_type !== null ?
                                                        undefined : () => "Select Assessment Type"}
                                                    style={this.state.windowWidth > 960 ? { width: "26rem", textTransform: "capitalize" } : { width: "100%", textTransform: "capitalize" }}
                                                >
                                                    {formikProps.initialValues.assesTypes.map((assType) => (
                                                        <MenuItem
                                                            key={assType}
                                                            value={assType}
                                                            style={{ textTransform: "capitalize" }}
                                                        >
                                                            {assType.split("_").join(" ")}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>

                                            {/* Interview Slots */}
                                            <Typography>Please Share 3 Times Slots with Time-Zone for the Interview</Typography>
                                            <Grid container className={classes.dateTimeGrid}>
                                                <Grid item className={classes.wrapeerTimeZone}>
                                                    <ThemeProvider theme={theme}>
                                                        <TimezoneSelect
                                                            value={this.state.selectedTimezone}
                                                            onChange={(e) => this.setState({ selectedTimezone: e.value })}
                                                        />
                                                    </ThemeProvider>
                                                </Grid>
                                                <Grid item>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DateTimePicker
                                                            className={classes.dateDatePick}
                                                            autoOk
                                                            disablePast
                                                            hideTabs
                                                            clearable
                                                            ampm={false}
                                                            allowKeyboardControl={false}
                                                            value={this.state.timeSlotList.first_slot}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    this.setState({
                                                                        timeSlotList: {
                                                                            ...this.state.timeSlotList,
                                                                            first_slot: new Date(e!.toString())
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton style={{ marginBottom: ".7rem" }}>
                                                                            <Calender />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DateTimePicker
                                                            className={classes.dateDatePick}
                                                            autoOk
                                                            disablePast
                                                            hideTabs
                                                            clearable
                                                            ampm={false}
                                                            allowKeyboardControl={false}
                                                            value={this.state.timeSlotList.second_slot}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    this.setState({
                                                                        timeSlotList: {
                                                                            ...this.state.timeSlotList,
                                                                            second_slot: new Date(e!.toString())
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton style={{ marginBottom: ".7rem" }}>
                                                                            <Calender />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DateTimePicker
                                                            className={classes.dateDatePick}
                                                            autoOk
                                                            disablePast
                                                            hideTabs
                                                            ampm={false}
                                                            allowKeyboardControl={false}
                                                            minDate={new Date("2018-01-01")}
                                                            value={this.state.timeSlotList.third_slot}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    this.setState({
                                                                        timeSlotList: {
                                                                            ...this.state.timeSlotList,
                                                                            third_slot: new Date(e!.toString())
                                                                        }
                                                                    });
                                                                }
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton style={{ marginBottom: ".7rem" }}>
                                                                            <Calender />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </Grid>
                                            {/* buttons */}
                                            <Grid className={classes.buttons} style={{ display: "flex", flexDirection: "unset" }}>
                                                <Button type='submit' onClick={this.handleRequireAdminSupport}>
                                                    {this.state.requireAdminSupport ? "Cancel Admin Support" : "Request Admin Support"}
                                                </Button>
                                                <Button type='submit'>
                                                    schedule interview
                                                </Button>
                                            </Grid>
                                            {((
                                                this.props.candidate?.attributes &&
                                                this.props.candidate.attributes.scheduled_data.length > 0
                                            ) || Object.keys(this.state.candidate).length !== 0) && (
                                                    <Box display="flex" justifyContent="center">
                                                        <Button
                                                            className={classes.detailsBtn}
                                                            onClick={() => {
                                                                this.handleShowDetails();
                                                                this.setState({ interviewer: null, interview_type: null });
                                                            }}>
                                                            Previous Interview Details
                                                        </Button>
                                                    </Box>
                                                )}
                                        </form>
                                    )}
                                </Formik>
                            </Grid> :
                            <PastInterviewRecords
                                classes={classes}
                                scheduled_data={Object.keys(this.state.candidate).length !== 0 ?
                                    this.state.candidate.attributes.scheduled_data :
                                    this.props.candidate?.attributes.scheduled_data}
                            />
                        }
                    </Grid>

                    {/* Previous Interview Details */}


                    {/* Snackbar for display success and failed messages. */}
                    <Snackbar
                        open={this.state.snackBar.show}
                        autoHideDuration={3000}
                        onClose={this.handleSnackBar}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={this.handleSnackBar}
                            severity={this.state.snackBar.type}
                        // style={{ color: "#fff" }}
                        >
                            {this.state.snackBar.message}
                        </MuiAlert>
                    </Snackbar>
                </Dialog>
            </ThemeProvider >
        )
    }
}

export default withStyles(useStyles)(TimeSlotModal);