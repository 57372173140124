import React from "react";
import { Card, Box, Typography, withStyles, Grid, AppBar } from "@material-ui/core";
import { SearchIcon, FilterBtn } from './assets';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AppSidebar from '../../../components/src/AppSidebar';
import {
  StyleSheet,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import LinkShareController, {
  Props,
  configJSON
} from "./LinkShareController";
import { withRouter } from "react-router-dom";

const ClosedrolesStyles = (theme: any) => ({
  root: {
    display: 'flex',
    backgroundColor: 'grey',
  },
  textWhite: {
    color: "#ffffff"
  },

  drawerContainer: {
    overflow: 'auto',
  },
  listIconeWidth: {
    minWidth: '47',
    color: 'White'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  BgHighlightBottom: {
    width: '100%',
    backgroundColor: '#24325f',
    height: '15px',
  },
  BgCardHeader: {
    backgroundColor: "#a8d0e7",
    color: '#24325F',

    "& h6": {
      fontWeight: 600
    }
  },
  BtnCreateNewRole: {
    backgroundColor: '#24325f',
    color: "white"
  },
  BgHighlightInterviewsBottom: {
    backgroundColor: '#e19954',
    height: '15',
    width: "100%"

  },
  InterveiwTextColor: {
    color: '#e19954'
  },
  CandidatesTextColor: {
    color: '#5f72b9'
  },
  BgHighlightcandidatesBottom: {
    backgroundColor: '#5f72b9',
    height: "15",
    width: '100%'
  },
  BgHighlightClosedRolesBottom: {
    backgroundColor: '#ff4e6d',
    height: "15",
    widht: '100%'
  },
  ClosedRolesTextColor: {
    color: '#ff4e6d'
  },
  inputTextField: {
    "& .MuiFilledInput-root": {
      background: "rgb(255, 255, 255)",
      color: "#000",
      padding: 0,
    },
    "& .MuiFilledInput-input": {
      padding: 0
    },
  },
  SelectFilterWidth: {
    backgroundColor: '#fff',
    padding: ".4rem 2rem",
    alignItems: "center",
    borderRadius: "1rem",

    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff"
    },

    "& svg": {
      borderLeft: "2px solid #F1F1F5",
    },
  },
  filter: {
    "& .MuiInput-underline::before": {
      border: 0
    },
    "& .MuiInput-underline::after": {
      border: 0
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: 0
    }
  },
  topAppBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffff"
  },
  ContentTopMargin: {
    marginTop: "70px",
    width: "100%"
  },
  search: {
    backgroundColor: '#fff',
    padding: ".6rem .8rem",
    alignItems: "center",
    borderRadius: ".8rem",

    "& img": {
      marginRight: "1rem"
    }
  },
});

class LinkShare extends LinkShareController {
  constructor(props: Props) {
    super(props);
  }

  filterRoles = (event: any) => {
    this.props.history.push({
      search: `?view=${this.state.view}&filter=${event.target.value}`
    });

    this.setState({ sort: event.target.value }, () => {
      // if (event.target.value === "asc") {
      //   this.setState({ filterRolesData: { data: sortRoles(JSON.parse(JSON.stringify(this.state.filterRolesData))) } });
      // } else {
      //   this.setState({ filterRolesData: this.state.rolesData })
      // }
    });
  }

  // SelectChangeEvent is not available in mui verison 4, so i used any.
  handleViewChange = (event: any) => {
    this.props.history.push({
      search: `?view=${event.target.value}&filter=${this.state.sort}`
    });

    if (event.target.value === "list")
      this.setState({ view: "list" });
    else
      this.setState({ view: "grid" });
  };

  componentDidMount(): Promise<void> {
    if (location.search.includes("view=list")) {
      this.setState({ view: "list" });
    } else {
      this.setState({ view: "grid" });
    }

    if (location.search.includes("filter=asc")) {
      this.setState({ sort: "asc" });
    } else {
      this.setState({ sort: "default" });
    }
    return super.componentDidMount();
  }

  render() {
    const { classes } = this.props;

    return (
      <Box display="flex">
        <AppSidebar />
        {console.log("url", window.location.pathname)}
        <Box color="primary" className={classes.ContentTopMargin} >
          <main className={classes.content}>
            {/* Dashboard User Details start */}
            <Box mb={3}>
              <Grid container>
                <Grid item xl={5} lg={3} md={3} sm={12}>
                  <Box mb={3}>
                    <Box mb={1}>
                      <Typography variant="h5">Closed Roles</Typography>

                    </Box>
                  </Box>
                </Grid>
                <Grid item xl={7} lg={9} md={9} sm={12}>
                  <Box
                    display="flex"
                    justifyContent="space-evenly"
                    flexWrap="wrap"
                    alignItems="center"
                    alignContent="center"
                    style={{ placeContent: "flex-end" }}
                    gridGap={15}
                  >
                    <div className={classes.margin}>
                      <Grid container className={classes.search}>
                        <Grid item>
                          <img src={SearchIcon} width="18px" />
                        </Grid>
                        <TextField
                          variant="filled"
                          placeholder="Search here"
                          className={classes.inputTextField}
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>
                    </div>
                    <Box className={classes.filter}>
                      <Select
                        labelId="product-subcategory"
                        className={classes.SelectFilterWidth}
                        id="demo-simple-select"
                        displayEmpty
                        onChange={this.handleViewChange}
                        value={this.state.view}
                      >
                        <MenuItem value="grid">
                          View: <span style={{ color: "#000", fontWeight: 600 }}>Grid</span>
                        </MenuItem>
                        <MenuItem value="list" >
                          View: <span style={{ color: "#000", fontWeight: 600 }}>List</span>
                        </MenuItem>
                      </Select>
                    </Box>
                    <Box className={classes.filter}>
                      <Select
                        className={classes.SelectFilterWidth}
                        labelId="product-subcategory"
                        id="demo-simple-select"
                        displayEmpty
                        value={this.state.sort}
                        onChange={this.filterRoles}
                      >
                        <MenuItem value="default">Sort By: <span style={{ color: "#000", fontWeight: 600 }}>Default</span></MenuItem>
                        <MenuItem value="asc">Sort By: <span style={{ color: "#000", fontWeight: 600 }}>A to Z</span></MenuItem>
                      </Select>
                    </Box>
                    <Box>
                      <img src={FilterBtn} width="20px" style={{ background: "#fff", padding: ".9rem .6rem", borderRadius: "1rem" }} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* Conditionally Render List & Grid View Of open roles */}
          </main>
        </Box >
      </Box >
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
// @ts-ignore
export default withStyles(ClosedrolesStyles)(withRouter(LinkShare));

