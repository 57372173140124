export const imgicWorking = require("./ic_Working.png");
export const imgicSearch = require("./ic_Search.png");
export const Imcandidate = require("./candidate.png");
export const ImgicEmail = require("./ic_Email.png");
export const SearchIcon = require("./assets/search.svg");
export const LocationIcon = require("./assets/location.svg");
export const JobIcon = require("./assets/job_type.svg");
export const SalaryIcon = require("./assets/salary.svg");
export const AdminProfile = require("./assets/Admin-Profile.png");

export { ReactComponent as DashboardIcon } from "./assets/dashboard.svg";

export { ReactComponent as CreateRoleIcon } from "./assets/createRole.svg";

export { ReactComponent as OpenRolesIcon } from "./assets/openRoles.svg";

export { ReactComponent as ClosedRolesIcon } from "./assets/closedRoles.svg";

export { ReactComponent as ScoreIcon } from "./assets/candidate.svg";

export { ReactComponent as MessageIcon } from "./assets/messages.svg";

