import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

interface CandidateProps {
  id: number,
  type: string,
  history: any;
  attributes: {
    full_name: string,
    skills: { id: number, name: string, created_at: string, updated_at: string }[] | "No skills found.",
    work_experience: string,
    role_name: string;
    resume: string;
    email: string;
    applied_id: string | number;
    phone_number: string,
    job_description: {
      id: number,
      company_description: string,
      currency: string,
      degree: string,
      fieldOfStudy: string,
      jd_type: string,
      job_responsibility: string,
      job_title: string,
      location: string
      minimum_salary: string
      parsed_jd: number
      parsed_jd_transaction_id: number
      preferred_overall_experience_id: number
      role_id: number
      role_title: string
    },
    photo: string,
    scheduled_data: {
      is_already_scheduled: boolean,
      time_slots: {
        updated_at: string,
        first_slot: string,
        second_slot: string,
        third_slot: string,
        require_admin_support: boolean,
        interview_type: string,
        interviewer: string,
        is_accepted_by_candidate: boolean,
        preferred_slot: string,
      }
    }[]
  }
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any,
  toggleModal: () => void;
  isOpen: boolean;
  history?: any;
  candidate?: CandidateProps;
  params?: any;
  location?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  ListView: boolean;
  // Customizable Area Start
  isModalOpen: boolean;
  interviewerList: any;
  candidate: CandidateProps;
  timeSlotList: { first_slot: Date, second_slot: Date, third_slot: Date };
  requireAdminSupport: boolean;
  interviewer: string | null;
  interview_type: "hr_assessment" | "video_interview" | "hiring_manger_assessment" | null;
  snackBar: {
    show: boolean;
    message: string;
    type: "success" | "info" | "warning" | "error"
  };
  managers: any;
  showDetails: boolean;
  windowWidth: string | number;
  isWapModalOpen: boolean;
  userType: string;
  clientID: number | string;
  selectedTimezone: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class Location4Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getCandidateDetailsApiId: string;
  scheduleInterviewApiId: string;
  getManagerNamesApiId: string;
  createConversationAPIId: string;
  clientIDLink: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      ListView: false,
      // Customizable Area Start
      isModalOpen: false,
      interviewerList: ["Interviewer1", "Interviewer2", "Interviewer3"],
      candidate: {} as CandidateProps,
      timeSlotList: {
        first_slot: new Date(),
        second_slot: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        third_slot: new Date(new Date().getTime() + 48 * 60 * 60 * 1000)
      },
      interview_type: null,
      interviewer: null,
      requireAdminSupport: false,
      snackBar: {
        show: false,
        message: "",
        type: "success",
      },
      managers: null,
      showDetails: false,
      windowWidth: window.innerWidth,
      isWapModalOpen: false,
      userType: sessionStorage.getItem("user_role") || "",
      clientID: "",
      selectedTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  getParameters(urlString: string) {
    let paramString = urlString?.split('?')[1];
    let queryString = new URLSearchParams(paramString);
    const result: { [key: string]: string } = {};
    for (let pair of queryString.entries()) {
      result[pair[0]] = pair[1];
    }
    return result;
  }

  // handle Interviewer & Assignments Types

  handleInterviewType = (interview_type: "hr_assessment" | "video_interview" | "hiring_manger_assessment") => {
    this.setState({ interview_type });
  }

  // convert Date to YYYY-MM-DD HH:MM:SS format
  convertDate = (date: Date) => {
    var seconds = date.getSeconds();
    var minutes = date.getMinutes();
    var hour = date.getHours();

    return `${date.toISOString().slice(0, 10)} ${hour}:${minutes}:${seconds}`;
  }

  // received two times as string input and return their difference in mintues
  getDifferenceBetweenTwoTime = (time_one: string, time_two: string) => {
    const date1 = new Date(time_one).valueOf();
    const date2 = new Date(time_two).valueOf();

    var dateDifference: number;
    if (date2 < date1) dateDifference = date2 - date1;
    else dateDifference = date1 - date2;

    return Math.abs(dateDifference / 60000);
  }

  // Handle Show Details
  handleShowDetails = () => {
    //@ts-ignore
    this.getCandidateDetails({ role_id: this.props.candidate.attributes.role_id, candidate_id: this.props.candidate.id });
    this.setState({ showDetails: !this.state.showDetails });
  }

  handleRequireAdminSupport = () => {
    this.setState({ requireAdminSupport: !this.state.requireAdminSupport });
  }

  handleModal = () => {
    this.setState(prev => ({ isModalOpen: !prev.isModalOpen }));
  }

  handleSnackBar = () => {
    this.setState({ snackBar: { ...this.state.snackBar, show: false } });
  }

  toggleWapModal = () => {
    this.setState(prev => ({ isWapModalOpen: !prev.isWapModalOpen }));
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        // conversation creation
        if (apiRequestCallId === this.createConversationAPIId) {
          if (responseJson) {
            if (responseJson?.table?.errors.includes("already")) {
              this.openSnackBarHandler("success", "Redirecting to chatting.....");
            } else {
              this.openSnackBarHandler("success", "Conversation Created, Redirecing.....");
            }
            let url: any = "/email-notifications?email=" + sessionStorage.getItem("candidate_email");
            if (this.state.userType == "admin" && this.state.clientID) {
              url += "&clientId=" + this.state.clientID;
            }
            setTimeout(() => {
              this.props.history.push(
                url
              );
            }, 1000);
          } else {
            this.openSnackBarHandler("error", "Someting went wrong ....!");
          }
        }
        //Create New Role API
        console.log("apiRequestCallId", apiRequestCallId);
        // handle manager names
        if (apiRequestCallId === this.getManagerNamesApiId) {
          if (responseJson !== undefined && !responseJson.errors) {
            console.log("----->", responseJson.interviews)
            this.setState({ managers: responseJson.interviews });
          }
        }
        // handle candidate details
        if (
          apiRequestCallId === this.getCandidateDetailsApiId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            // console.log("RESPONSE JSON --", responseJson);

            this.setState({ candidate: responseJson.data });
          } else {
            this.parseApiCatchErrorResponse(errorReponse);
            console.log("errorReponse in open roles", errorReponse);
            const errors = responseJson?.errors;
            if (errors?.name?.[0]) {
              console.log("errorReponse in candidate fetch", errors.name[0]);
            }
          }
        }
        // handle schedule interview
        if (
          apiRequestCallId === this.scheduleInterviewApiId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.setState({ snackBar: { show: true, type: "success", message: "Interview Scheduled Successfully." } }, () => {
              this.setState({ interviewer: null, interview_type: null });
              // @ts-ignore
              this.getCandidateDetails({ role_id: this.props.candidate.attributes.role_id, candidate_id: this.props.candidate.id });
            });
          } else {
            this.parseApiCatchErrorResponse(errorReponse);
            console.log("errorReponse in open roles", errorReponse);
            const errors = responseJson?.errors;
            if (errors?.name?.[0]) {
              console.log("errorReponse in candidate fetch", errors);
            }
            this.setState({ snackBar: { show: true, type: "error", message: errors[0] } });
          }
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error",
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  closeSnackBarHandler = (): void => {
    this.setState({ snackBar: { show: false, message: "", type: "success" } });
  }

  getCandidateDetails = (details: { [key: string]: string }) => {
    if (details) {
      const header = {
        "Content-Type": configJSON.jsonApiContentType,
        'token': sessionStorage.getItem('Token')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCandidateDetailsApiId = requestMessage.messageId;

      let endpoint = `${configJSON.candidateDetailsApiEndpoint}/${details.candidate_id}?role_id=${details.role_id}`;
      if (this.state.userType == "admin" && this.state.clientID) {
        endpoint = endpoint + `&client_id=${this.state.clientID}`
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  fetchManagerNames = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getManagerNamesApiId = requestMessage.messageId;

    let endpoint = configJSON.managerNamesApiEndpoint;
    if (this.state.userType == "admin" && this.state.clientID) {
      endpoint = endpoint + `?client_id=${this.state.clientID}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  scheduleInterview = () => {
    const { candidate_id, role_id } = this.getParameters(this.props.params);

    if (candidate_id && role_id) {

      // convert Date to string Format
      const first_slot = this.convertDate(this.state.timeSlotList.first_slot);
      const second_slot = this.convertDate(this.state.timeSlotList.second_slot);
      const third_slot = this.convertDate(this.state.timeSlotList.third_slot);

      // split date and time
      const first_slot_date = first_slot.split(" ")[0];
      const second_slot_date = second_slot.split(" ")[0];
      const third_slot_date = third_slot.split(" ")[0];


      // check time diffrence between slots
      if (
        first_slot_date === second_slot_date &&
        this.getDifferenceBetweenTwoTime(first_slot, second_slot) <= 30
      ) {
        this.setState({ snackBar: { show: true, type: "error", message: "There should be a minimum 30 mintues gap between time slot 1 and 2." } });
        return;
      } else if (
        first_slot_date === third_slot_date &&
        this.getDifferenceBetweenTwoTime(first_slot, third_slot) <= 30
      ) {
        this.setState({ snackBar: { show: true, type: "error", message: "There should be a minimum 30 mintues gap between time slot 1 and 3." } });
        return;
      } else if (
        second_slot_date === third_slot_date &&
        this.getDifferenceBetweenTwoTime(second_slot, third_slot) <= 30
      ) {
        this.setState({ snackBar: { show: true, type: "error", message: "There should be a minimum 30 mintues gap between time slot 2 and 3." } });
        return;
      } else {
        // check if client did'nt choose any interviewer
        if (this.state.interviewer === '') {
          this.setState({ snackBar: { show: true, type: "error", message: "Please select Interviewer." } });
          return;
        }

        // check if client did't choose assignment type
        if (this.state.interview_type === null) {
          this.setState({ snackBar: { show: true, type: "error", message: "Please select Interview Type." } });
          return;
        }

        // if everything is fine then schedule the interview
        let body: any = {
          schedule: {
            account_id: candidate_id,
            role_id,
            job_description_id: this.props.candidate?.attributes.job_description.id,
            first_slot, second_slot, third_slot,
            require_admin_support: this.state.requireAdminSupport,
            interviewer_id: this.state.interviewer,
            interview_type: this.state.interview_type,
            time_zone: this.state.selectedTimezone
          }
        }

        if (this.state.userType == "admin" && this.state.clientID) {
          body["client_id"] = this.state.clientID
        }

        const header = {
          "Content-Type": configJSON.jsonApiContentType,
          'token': sessionStorage.getItem('Token')
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.scheduleInterviewApiId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.schedulingApiEndpoint
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    }
  }

  // Chat Integration
  createCoversation = (candidate_email: string) => {
    console.log("candidate_email", candidate_email);
    this.openSnackBarHandler("success", "Search in the space...");
    if (candidate_email) {
      sessionStorage.setItem("candidate_email", candidate_email);
      const header = {
        "Content-Type": configJSON.jsonApiContentType,
        'token': sessionStorage.getItem('Token')
      };

      let httpBody: any = { "email": candidate_email }

      if (this.state.userType == "admin" && this.state.clientID) {
        httpBody['client_id'] = this.state.clientID;
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createConversationAPIId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_twilio/create_conversations"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.openSnackBarHandler("success", "Email not found ...");
    }
  }
  checkWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }
  // Customizable Area End
}
