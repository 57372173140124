//@ts-nocheck
import React from "react";
import ClientsListController, {
  Props,
  configJSON
} from "./ClientsListController.web";
// Customizable Area Start
import styles from "./Styles.module.scss";
import { AppBar, Box, Container, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import AppSidebar from "../../../components/src/AppSidebar";
import TopHeader from "../../../components/src/TopHeader";
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from "react-router-dom";
// Customizable Area End


class ClientsList extends ClientsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if (sessionStorage.getItem('user_role') == 'admin') {
      sessionStorage.removeItem("final_OTP");
      this.getClientsList({ page: 1, per_page: 10 });
      window.scrollTo(0, 0);
      window.addEventListener('resize', this.checkWindowResize);
    } else {
      this.props.history?.push('/login?type=admin');
    }
  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowResize);
  }
  // Handle Client Search 
  handleSearch = (keyword: string) => {
    this.setState({ searchString: keyword.target.value, pageNumber: 1 }, () => {
      if (this.state.searchString?.trim()?.length == 0) {
        this.getClientsList({ page: 1, per_page: 10 });
      } else { this.searchClients(this.state.searchString) }
    });
  }



  // Customizable Area End
  // Customizable Area Start
  render() {

    let pages = 0;
    if (this.state.scoreList?.meta?.total_record < this.state.pageLengthToShow) {
      pages = 1;
    } else if (this.state.scoreList?.meta?.total_record % this.state.pageLengthToShow != 0) {
      pages = ~~(this.state.scoreList?.meta?.total_record / this.state.pageLengthToShow) + 1;
    } else {
      pages = ~~(this.state.scoreList?.meta?.total_record / this.state.pageLengthToShow);
    }
    return (
      <>
        <div className={styles.pageWrap}>
          <div className={styles.pageSection}>
            <div className={styles.pageContentWrap}>
              <main className={styles.pageContent}>
                <div className={styles.headContainer} >
                  <div className={styles.leftItem}>
                    <h1 className={styles.pageHead}>Clients</h1>
                  </div>
                  <div className={styles.rightItem}>
                    <div className={`${styles.searchWrap} ${styles.subItem}`}>
                      <input className={styles.searchItem} onChange={(e) => this.handleSearch(e)} placeholder="Search here..." data-focusvisible-polyfill="false" />
                    </div>
                  </div>
                </div>
                <div className={styles.bodyContainer}>
                  <div className={`${styles.bodyCard} ${styles.bodyCardMain}`}>
                    <div className={styles.tablePagWrap}>
                      <Table className={styles.scoreTable}>
                        <TableHead>
                          <TableRow className={styles.shRow}>
                            <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "5%" }}>NO.</TableCell>
                            <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "25%" }}>CLIENT NAME</TableCell>
                            <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "60%" }}>EMAIL</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.scoreList?.data?.map((item: any, index: number) => (
                            <TableRow className={`${styles.sRRow} ${index % 2 !== 0 ? styles.evenRow : ""}`} key={item.id} onClick={() => {
                              //@ts-ignore
                              this.props.history.push(`/dashboard?clientId=${item?.id}`);
                            }}>
                              <TableCell className={`${styles.tableNoBorder} ${styles.sRCell}`}>{this.state.pageLengthToShow * (this.state.pageNumber - 1) + 1 + index}</TableCell>
                              <TableCell className={`${styles.tableNoBorder} ${styles.sRCell}`}>{item?.attributes?.full_name}</TableCell>
                              <TableCell className={`${styles.tableNoBorder} ${styles.sRCell}`}>{item?.attributes?.email}</TableCell>
                            </TableRow>))}
                        </TableBody>
                      </Table>
                      {(this.state.scoreList?.meta?.total_record === 0 || this.state.scoreList?.data?.length === 0) && <Typography style={{ padding: "1rem 2rem" }}>No Client Found.</Typography>}
                      {(this.state.scoreList && this.state.scoreList?.data?.length != 0) &&
                        <div className={styles.paginationWrap}>
                          <div className={styles.paginationCountWrap}>
                            <div className={styles.jobPagination}>
                              <Pagination
                                count={this.state.totalPages}
                                page={this.state.pageNumber}
                                variant="outlined"
                                shape="rounded"
                                onChange={(event: any, page: number) => {
                                  this.setState({ pageNumber: page });
                                  this.getClientsList({ page: page, per_page: this.state.pageLengthToShow })
                                }} />
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>

      </>
      // Customizable Area End
    );
  }
}
export default withRouter(ClientsList) as ClientsList;
