import React, { useState } from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import DescriptionIcon from "@material-ui/icons/Description";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import { ThemeProvider, makeStyles, createTheme } from "@material-ui/core/styles";
import { trimFeedback } from "./OpenRolesCards";
import { formatDate } from "../../blocks/QuestionBank/src/TabComponent.web";
import WhatsAppModalWeb from "../../web/src/common/components/WhatsAppModal.web";

const useStyles = makeStyles({
  iconall: {
    color: "white",
    fontSize: "30px"
  },

  iconboxmain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 10,
    gap: "1rem",

    "& div": {
      cursor: "pointer",
    }
  },

  iconbox1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#24325F",
    borderRadius: 8
  },
  iconbox2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#25D366",
    borderRadius: 8
  },
  iconbox3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#24325F",
    borderRadius: 8
  },
  iconbox4: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#3DD598",
    borderRadius: 8
  },
  iconbox5: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#FC5A5A",
    borderRadius: 8
  },

  icondotgreen: {
    padding: 0,
    color: "#3dd598",
    fontSize: "1.2rem",
    marginRight: "0px"
  },

  icondotgrey: {
    padding: 0,
    color: "#DDDDDD",
    fontSize: "1.2rem",
    marginRight: "0px"
  },
  talentbutton: {
    borderRadius: "8px",
    fontSize: "18px",
    paddingTop: "4px",
    paddingBottom: "4px",
    outline: "none",
    border: "none",
    background: "#edfbf6",
    color: "#3DD598",
    fontWeight: 500
  },

  talentheading: {
    color: "#555",
    paddingBottom: 6,

  },

  talentmain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },

  assessmentdata: {
    display: "flex",

    alignItems: "center",
    justifyContent: "center"
  },

  assessmentmain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },

  assessmenttalentmain: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem 0",
    borderBottom: "1px solid #ddd"
  },

  final_score_head: {
    color: "#555",
    display: "flex",
    alignItems: "center",
    fontSize: "0.87rem"
  },
  final_score_head_space: {
    paddingLeft: "10px",
  },
  final_score_head_space_mobile: {
    paddingLeft: "5px",
  },
  final_score_data: {
    fontSize: 18,
    color: "#3DD598",
    fontWeight: "bold",
    display: "inline-block"
  },
  final_score_data_space: {
    paddingLeft: 5,
  },
  final_score_data_space_mobile: {
    paddingLeft: 1,
  },
  username: {
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: "10px"
  },
  userdate_head: {
    fontSize: "0.87rem!important",
    color: "grey",

  },
  userdate_data: {
    fontSize: 15,
    fontWeight: "bold",
    display: "inline-block",
    color: "grey"
  },

  titlemain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",

    "& a": {
      textDecoration: "none",
      color: "inherit",
      textTransform: "capitalize",
      cursor: "pointer"
    }
  },
  icondhead: {
    width: 65,
    height: 65,
    borderRadius: "5px",
    border: "1px solid grey"
  },
  imgprofile: {
    borderRadius: 10,
    width: "45px",
    height: "45px",
    objectFit: "cover"
  },

  counthead: {
    color: "#A3A3AC"
  },
  statusButton: {
    width: 110,
    borderRadius: "8px",
    fontSize: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    textTransform: "capitalize",
    outline: "none",
    border: "none",
    fontWeight: 700
  },
  acceptedStatus: {
    background: "#E2F5F1",
    color: "#5BDBA8"
  },
  rejectedStatus: {
    background: "#fc5a6524",
    color: "#FC5A5A"
  },
  dates: {
    "& p": {
      fontSize: ".75rem"
    }
  }
});

const theme = createTheme({
  typography: {
    h1: {
      fontSize: 20,
      fontWeight: 700
    },
    h2: {
      fontWeight: 600,
      fontSize: 16,
      fontFamily: "Poppins-SemiBold"
    },
    h3: {
      fontWeight: 500,
      fontSize: 14,
      fontFamily: "Roboto-Regular"
    },

  }
});

export default function OpenRolesCards(props: any) {
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  const toggleModal = () => {
    setOpen(!isOpen);
  }

  return (
    <ThemeProvider theme={theme}>
      {isOpen && <WhatsAppModalWeb candidate={props} isOpen={isOpen} toggleModal={toggleModal} />}
      <Card>
        <Box display="flex" p={2} flexDirection="column" pt={0} pr="1rem" pb="1rem">
          <Box m={2} pb={2} borderBottom="1px solid #ddd" display="flex" style={{ margin: '1rem 0 0' }}>
            <Box>
              <img src={props.imageUrl} alt={props.Title} className={classes.imgprofile} />
            </Box>

            <Box className={classes.titlemain} pl={2}>
              <Link to={`/closed-roles/candidate-details?role_id=${props.role_id}&candidate_id=${props.account_id}${props.clientIDLink ? "&" + props.clientIDLink : ""}`}>
                <Typography variant="h2">
                  {props.Title}
                </Typography>
              </Link>

              <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.dates}>
                <Typography variant="body1" className={classes.userdate_head}>
                  Date Opened: {formatDate(props.Date_Open)}
                </Typography>
                <Typography variant="body1" className={classes.userdate_head}>
                  Closed: {formatDate(props.Date_Close)}
                </Typography>
              </Box>

            </Box>
          </Box>
          <Box display="flex" pt={1.5} pb={1.5} borderBottom="1px solid #ddd">
            <Box display="flex" flex={1} alignItems="center" >
              <img src={require('./assets/initial_score.png')} style={props.windowWidth < 350 ? { width: 15, height: 15 } : { width: 25, height: 25 }} />

              <Typography variant="caption" className={`${classes.final_score_head} ${props.windowWidth < 350 ? classes.final_score_head_space_mobile : classes.final_score_head_space}`}>
                AI Score:
                <Typography variant="body1" className={`${classes.final_score_data} ${props.windowWidth < 350 ? classes.final_score_data_space_mobile : classes.final_score_data_space}`}>
                  {props.Initial_stage || 0}
                </Typography>
              </Typography>
            </Box>
            <Box display="flex" flex={1} alignItems="center" style={{ justifyContent: "flex-end" }}>
              <img src={require('./assets/final_score.png')} style={props.windowWidth < 350 ? { width: 15, height: 15 } : { width: 25, height: 25 }} />
              <Typography variant="caption" className={`${classes.final_score_head} ${props.windowWidth < 350 ? classes.final_score_head_space_mobile : classes.final_score_head_space}`}>
                Finalist:
                <Typography variant="body1" className={`${classes.final_score_data} ${props.windowWidth < 350 ? classes.final_score_data_space_mobile : classes.final_score_data_space}`}>
                  {props.status == "accepted" ? "Yes" : "No"}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.assessmenttalentmain}>
            <Box className={classes.talentmain}>
              <Typography variant="h3" className={classes.talentheading}>
                Feedback:
              </Typography>
              <Tooltip title={props.Feedback || "No feedback"} arrow>
                <button className={classes.talentbutton}>
                  <Typography variant="h3">
                    {props.Feedback ? trimFeedback(props.Feedback) : "-"}
                  </Typography>
                </button>
              </Tooltip>
            </Box>
          </Box>
          <Box className={classes.iconboxmain}>
            <Tooltip title="View Resume" arrow>
              <a href={props.resume} target="_blank" style={{ textDecoration: "none" }} download>
                <Box className={classes.iconbox1}>
                  <DescriptionIcon fontSize="large" className={classes.iconall} />
                </Box>
              </a>
            </Tooltip>
            <Tooltip title="Send a message in WhatsApp" arrow>
              <Box className={classes.iconbox2} onClick={toggleModal}>
                <WhatsAppIcon fontSize="large" className={classes.iconall} />
              </Box>
            </Tooltip>
            <Tooltip title="Start Chatting" arrow>
              <Box className={classes.iconbox3} onClick={() => props.createCoversation(props.email)}>
                <MailOutlineIcon fontSize="large" className={classes.iconall} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Card>
    </ThemeProvider >
  );
}