Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Scoring";
exports.labelBodyText = "Scoring Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiMethodTypeAddDetail = "POST";
exports.putMethodTypeAddDetail = "PUT";
exports.getCandidateAssesmentListAPiEndPoint = "bx_block_roles_permissions/candidate_accessment_list";
exports.getCandidateAssesmentDetailAPiEndPoint = "bx_block_roles_permissions/candidate_accessment";
exports.getTestDomeDataListAPiEndPoint = "bx_block_test_dome/test_domes";
exports.getFeedbackParameterListAPiEndPoint = "bx_block_feedback/feedback_parameter_lists";
exports.sendCandidateFeedbackAPIEndPoint = "bx_block_feedback/interview_feedbacks";
exports.showCandidateFeedbackAPIEndPoint = "bx_block_feedback/interview_feedbacks";
exports.showCandidateFeedbackListAPiEndPoint = "bx_block_feedback/interview_feedbacks/";
exports.getFeedbackOptionListAPiEndPoint = "bx_block_scheduling/feedbacks_drop";
exports.sendCandidateAssesmentFeedbackAPIEndPoint = "bx_block_scheduling/schedule_interviews/";
// Customizable Area End