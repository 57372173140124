import React, { PureComponent } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import StorageProvider from "../../framework/src/StorageProvider";

export type Props = RouteProps & {
  role: string;
};
export interface S {
  token: any;
  loading: boolean;
  role: any;
}
export default class ProtedtedRoute extends PureComponent<Props, S> {
  state = {
    token: null,
    loading: true,
    role: null,
  };
  async componentDidMount() {
    let token = await sessionStorage.getItem("Token");
    let user_role = await sessionStorage.getItem("user_role");

    if (token) {
      this.setState({
        token: token,
        loading: false,
        role: user_role,
      });
    } else {
      this.setState({ loading: false })
    }
  }
  render() {
    return !this.state.loading ? (
      (this.props.role == "candidateClient" || this.state.role == this.props.role || this.state.role == 'admin') && this.state.token ? (
        <Route {...this.props} />
      ) : (
        <Redirect to="/" />
      )
    ) : null;
  }
}