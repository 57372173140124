import React from "react";
import { Box, Typography,Grid,Card } from "@material-ui/core";
import {  ThemeProvider, makeStyles, createTheme } from "@material-ui/core/styles";


const useStyles = makeStyles({
  iconall: {
    color: "white",
    fontSize: "30px"
  },

  iconboxmain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 10
  },

  iconbox1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#24325F",
    borderRadius: 8
  },
  iconbox2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#25D366",
    borderRadius: 8
  },
  iconbox3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#24325F",
    borderRadius: 8
  },
  iconbox4: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#3DD598",
    borderRadius: 8
  },
  iconbox5: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#FC5A5A",
    borderRadius: 8
  },
  icondotgreen: {
    padding: 0,
    color: "#3dd598",
    fontSize: "30px",
    marginRight: "0px"
  },
  icondotgrey: {
    padding: 0,
    color: "#DDDDDD",
    fontSize: "30px",
    marginRight: "0px"
  },
  talentbutton: {
    width: 130,
    borderRadius: "8px",
    fontSize: "18px",
    paddingTop: "4px",
    paddingBottom: "4px",
    outline: "none",
    border: "none",
    background: "#25d3666e",
    color: "#3DD598",
    fontWeight: 500
  },
  talentheading: {
    color: "#555",
    paddingBottom: 6,
  },
  talentmain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },
  assessmentdata: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  assessmentmain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },
  assessmenttalentmain: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 2,
    paddingBottom: 2,
    borderBottom: "1px solid #ddd"
  },
  final_score_head: {
    color: "#555",
    paddingLeft: "10px"
  },
  final_score_data: {
    fontSize: 18,
    color: "#3DD598",
    paddingLeft:5,
    fontWeight: "bold",
    display: "inline-block"
  },
  username: {
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: "10px"
  },
  userdate_head: {
    fontSize: 15,
    fontWeight: "bold",
    color: "grey",
  
  },
  userdate_data: {
    fontSize: 15,
    fontWeight: "bold",
    display: "inline-block",
    color: "grey"
  },

  titlemain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
   
  },
  icondhead: {
    width: 65,
    height: 65,
    borderRadius: "5px",
    border: "1px solid grey"
  },
  imgprofile:{
    borderRadius:10,
  },

  counthead: {
    color: "#A3A3AC"
  }

});

const theme = createTheme({
  typography: {
    h1: {
      fontSize: 20,
      fontWeight: 700
    },
    h2: {
      fontWeight:600,
      fontSize: 28,
      fontFamily: 'Poppins-SemiBold'

    },h5: {
        
        fontSize: 14,
        fontFamily: 'Roboto',
    },
    h6: {
        
        fontSize: 16,
        fontFamily: 'Roboto',
    }



  }
});

export default function OpenRolesCards(props: any) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
     <Box p={3}>
         
         <Grid spacing={2} container >
             <Grid item lg={6}>
                 <Box display="flex" justifyContent="space-between">
                     <Box display="flex" flexDirection="column" justifyContent="center" >
                     <Box><Typography variant="h5">Roles Name</Typography></Box>
                     <Box fontWeight="600"><Typography variant="h2">IOS Developer</Typography></Box>
                    </Box>
                     <Box display="flex" flexDirection="column" justifyContent="center">
                         <Box><Typography variant="h5">Open Positions</Typography></Box>
                         <Box fontWeight="600"><Typography variant="h2">15</Typography></Box>
                     </Box>
                     <Box display="flex" flexDirection="column" justifyContent="center">
                         <Box><Typography variant="h5">Candidates Applied</Typography></Box>
                         <Box > <Typography variant="h2">87</Typography></Box>
                     </Box>
                 </Box>
             </Grid>
             <Grid item lg={6} >
                 <Box display="flex" flexDirection="column" textAlign="right">
                     <Box my={1}><Typography variant="h6">Edit Job Description</Typography></Box>
                     <Box my={1} ><Typography variant="h6">Delete Role</Typography></Box>
                 </Box>
             </Grid>
         </Grid>
     </Box>
    </ThemeProvider>
  );
}