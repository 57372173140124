import { Box, Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import RoleMangementController, { Props } from "./RoleMangementController.web";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
interface FormikInitialValues {
    allSkills: any;
}
class StepIVJD extends RoleMangementController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        this.handleSelectedData(this.props.jobDescriptionData?.itSkills, this.props.fieldData.selectedITSkill);
        const initialValues: FormikInitialValues = { allSkills: this.props.fieldData?.allSkills?.length > 0 ? this.props.fieldData?.allSkills : [] };
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape(
                    this.state.buyerSchemaArray[this.props.currentStep]
                )}
                onSubmit={(values) => {
                    this.props.setJDValues(values);
                    this.props.submitFormData("", this.props.fieldData);

                }}
                enableReinitialize
            >
                {(formikProps) => (

                    <form onSubmit={formikProps.handleSubmit} className="job-desc-form">
                        <div className="form-data-wrap">
                            <div className="job-descriptio-step">
                                <h2 className="job-description-title">
                                    {this.props.jdHeadingValue} Job Description - Skill Level
                                </h2>

                                {/* Rows */}
                                {this.props.jobDescriptionData?.itSkills?.map((item: any) => item?.skilles?.length > 0 && (
                                    <TableContainer className="job-category-tabel" key={item?.category_id}>
                                        <Table size="small" >
                                            <TableHead className="job-category-tabel-head">
                                                <TableRow className="job-category-tabel-hrI">
                                                    <TableCell style={{ width: "20%" }} colSpan={1}>
                                                        {item?.category_name}
                                                    </TableCell>
                                                    <TableCell style={{ width: "80%" }} colSpan={this.props.jobDescriptionData?.skill_levels?.length || 0}>
                                                        SKILL LEVEL
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className="job-category-tabel-hrII">
                                                    <TableCell style={{ width: "20%" }} />
                                                    {this.props.jobDescriptionData?.skill_levels?.map((item: any) => (
                                                        <TableCell key={item.id} style={{ width: "16%" }}>
                                                            {item.level}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="job-category-tabel-body">
                                                {item?.skilles?.map((skill: any, index: number) => (
                                                    !skill?.isDisabled &&
                                                    <TableRow key={skill?.id} className={`job-category-tabel-br ${index % 2 ? "dark-r" : "light-r"}`}>
                                                        <TableCell style={{ width: "20%" }} className="job-category-tabel-bcell">{skill?.name}</TableCell>
                                                        {this.props.jobDescriptionData?.skill_levels?.map((cellData: any) => (
                                                            <TableCell
                                                                key={cellData.id}
                                                                style={{ width: "16%" }}
                                                                className="job-category-tabel-bcell">
                                                                <Checkbox
                                                                    disabled={skill?.isDisabled}
                                                                    checked={formikProps.values?.allSkills?.length > 0 ? formikProps.values?.allSkills?.filter((e: any) => e?.preferred_skill_level_ids?.includes(cellData?.id) && e?.skill_id == skill.id)?.length > 0 : false}
                                                                    onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                                                    onChange={(event) => {
                                                                        let dataId: any = [];
                                                                        let dataAll: any = [];
                                                                        if (formikProps.values.allSkills?.length > 0) {
                                                                            let index = formikProps.values.allSkills?.findIndex((e: any) => e.skill_id == skill?.id);
                                                                            dataAll = [...formikProps.values.allSkills];
                                                                            if (index != -1) {
                                                                                let cellDataIndex = formikProps.values.allSkills[index].preferred_skill_level_ids?.findIndex((e: any) => e == cellData.id);
                                                                                if (cellDataIndex != -1) {
                                                                                    dataId = [...formikProps.values.allSkills[index].preferred_skill_level_ids];
                                                                                    dataId?.splice(cellDataIndex, 1);
                                                                                } else {
                                                                                    dataId = [...formikProps.values.allSkills[index].preferred_skill_level_ids, cellData.id];
                                                                                }
                                                                                dataAll[index] = { "skill_id": skill?.id, "preferred_skill_level_ids": dataId };
                                                                                formikProps.setFieldValue("allSkills", [...dataAll]);
                                                                            } else {
                                                                                formikProps.setFieldValue("allSkills", [...dataAll, { "skill_id": skill?.id, "preferred_skill_level_ids": [cellData.id] }]);
                                                                            }
                                                                        } else {
                                                                            formikProps.setFieldValue("allSkills", [{ "skill_id": skill?.id, "preferred_skill_level_ids": [cellData.id] }]);
                                                                        }
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {item?.totalSelected == 0 && <p className="no-data-found-jd">{`No ${item?.category_name} Selected`}</p>}
                                    </TableContainer>
                                ))}
                                {/* Buttons */}

                                <Box justifyContent="center" display="flex">
                                    <Button
                                        type="button" className="fullBtnCancel" disableRipple variant="contained" color="secondary" onClick={() => this.props.previousStep(formikProps.values)}>
                                        <Typography variant="body2">
                                            Previous
                                        </Typography>
                                    </Button>
                                    <Button
                                        type="submit" className="fullBtnSubmit" disableRipple variant="contained" color="primary">
                                        <Typography variant="body2">
                                            {this.props.jdHeadingValue} JD
                                        </Typography>
                                    </Button>
                                </Box>
                            </div>
                        </div>
                    </form>)
                }
            </Formik>);
    }
}

export default StepIVJD;