import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  // Customizable Area End
  EmailSchema: any;
  emailSchema: any;
  forgetFlag: any;
  verifyFlag: any;
  createPwdFlag: any;
  updatedFlag: any;
  snackBar: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error" | undefined;
  };
  loader: any;
  userType: string;
  accountEmail: string;
  isVerifyAccount: boolean;
  signInStep: string;
  loading: boolean;
}

interface SS {
  // Customizable Area Start
  id: any;
  classes: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";


  emailReg: RegExp;
  pwdReg: RegExp;

  labelTitle: string = "";
  sendOTPtoEmailURLApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.pwdReg = new RegExp(/^(?=.*[A-Z])(?=.*[#!@$&*?<>',\[\]}{=\-)(^%`~+.:;_])(?=.*[0-9])(?=.*[a-z]).{8,}$/)


    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    let EmailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.errorEmailRequired),
      password: Yup.string().required(configJSON.errorPasswordNotValid),
    };

    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };



    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      EmailSchema: EmailSchema,
      emailSchema: emailSchema,
      forgetFlag: false,
      verifyFlag: false,
      createPwdFlag: false,
      updatedFlag: false,
      snackBar: {
        show: false,
      },
      loader: false,
      userType: "",
      accountEmail: "",
      isVerifyAccount: false,
      signInStep: "signin",
      loading: false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("runEngine.debugLog", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !(responseJson?.errors || responseJson?.error)) {
        if (apiRequestCallId != null) {
          //Sigin API
          if (
            apiRequestCallId === this.apiEmailLoginCallId
          ) {
            this.setState({
              ...this.state,
              loader: false
            });
            if (this.state.userType == 'admin') {
              sessionStorage.setItem('Token', responseJson.meta.token);
              sessionStorage.setItem('user_role', 'admin');
              sessionStorage.setItem('email', responseJson.data?.attributes?.email);
              sessionStorage.setItem('first_name', "Admin");
            } else {
              this.openSnackBarHandler("success", configJSON.successLogin);
              sessionStorage.setItem('Token', responseJson.meta.token);
              sessionStorage.setItem('user_role', responseJson.data.attributes.user_role);
              sessionStorage.setItem('email', responseJson.data.attributes.email);
              sessionStorage.setItem('first_name', responseJson.data.attributes.first_name);
              sessionStorage.setItem('last_name', responseJson.data.attributes.last_name);
              sessionStorage.setItem("profile_image", responseJson.data.attributes.photo);
            }
            this.saveLoggedInUserData(responseJson);
            setTimeout(() => {
              if (this.state.userType == 'admin') {
                if (sessionStorage.getItem("redirect_url") !== null) {
                  // @ts-ignore
                  this.props?.history?.push(sessionStorage.getItem("redirect_url"));

                } else {
                  // @ts-ignore
                  // this.props?.history?.push("/clients-list");
                  this.setState({ signInStep: "otpVerify" })
                }
              } else if (responseJson.data.attributes.user_role === 'client') {
                if (sessionStorage.getItem("redirect_url") !== null) {
                  // @ts-ignore
                  this.props?.history?.push(sessionStorage.getItem("redirect_url"));
                } else {
                  // @ts-ignore
                  this.props?.history?.push("/dashboard");
                }
                //  this.props?.history?.push("dashboard-buyer")
              } else if (responseJson.data.attributes.user_role === 'candidate') {
                if (sessionStorage.getItem("redirect_url") !== null) {
                  // @ts-ignore
                  this.props?.history?.push(sessionStorage.getItem("redirect_url"));
                } else {
                  // @ts-ignore
                  this.props?.history?.push("/profile-setting");
                }
              }
            }, 2000);
          }
          //Resend OTP
          else if (apiRequestCallId === this.sendOTPtoEmailURLApiCallId) {
            this.setState({ loading: false, signInStep: "otpVerify" });
            if (responseJson !== undefined && !responseJson.errors) {
              this.openSnackBarHandler("success", responseJson?.message);
            } else {
              //Check Error Response
              // this.parseApiErrorResponse(responseJson);
              const error = responseJson?.error;
              const errors = responseJson?.errors;
              if (errors && errors[0]?.account) {
                this.openSnackBarHandler("error", errors[0]?.account);
              } else if (error && error?.message) {
                this.openSnackBarHandler("error", error?.message);
              } else {
                this.openSnackBarHandler("error", "Please wait. Our team is working on it");
              }
            }
          }
        }
      } else {
        this.setState({ loader: false });

        if (responseJson?.errors) {
          if (responseJson?.errors?.[0]?.failed_login) {
            if (responseJson?.errors?.[0]?.failed_login == "not_fully_verify") {
              sessionStorage.setItem("Token", responseJson?.meta?.token);
              this.setState({ isVerifyAccount: true, signInStep: "verifyEmail" });
              this.openSnackBarHandler('warning', "Please verify your email");
            } else {
              this.openSnackBarHandler('error', responseJson?.errors?.[0]?.failed_login);
            }
          } else if (responseJson?.errors?.[0]?.account) {
            this.openSnackBarHandler("error", responseJson.errors?.[0]?.account);
          } else if (responseJson?.errors?.['email or password']) {
            this.openSnackBarHandler("error", "Invalid email or password");
          } else {
            this.openSnackBarHandler("error", JSON.stringify(responseJson.errors));
          }
        } else if (responseJson?.error) {
          if (responseJson?.error?.message) {
            this.openSnackBarHandler("error", responseJson?.error?.message);
          } else {
            this.openSnackBarHandler("error", JSON.stringify(responseJson.error));
          }
        } else {
          this.openSnackBarHandler("error", "Please wait. Our team is working on it");
        }
      }
    }
  }


  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );
      msg.addData(
        getName(MessageEnum.SessionResponseData),
        responseJson.meta.full_name
      );

      this.send(msg);
    }
  }


  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  // For email login start
  doEmailLogIn = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = values;

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    let httpBody: any = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;

    let logInEndPoint;
    if (this.state.userType == "admin") {
      logInEndPoint = configJSON.adminLoginWithEmailAPIEndPOINT;
      httpBody = { ...values }
    } else if (this.state.userType == "client") {
      logInEndPoint = configJSON.clientLoginWithEmailAPIEndPOINT;
    } else {
      logInEndPoint = configJSON.candidateLoginWithEmailAPIEndPOINT;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      logInEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      ...this.state,
      loader: true,
      accountEmail: values.email
    })
    // this.setState({ loading: true, });
    return true;
  };
   // For email login start


  // If user is "Admin" redirect to "OTP Verification" screen else send OTP to email and then redirect 
  handleToOtpScreen = () => {
    if (this.state.userType == "admin") {
      this.setState({ signInStep: "otpVerify" });
    } else {
      this.sendOTPtoEmail();
    }
  }

  // To send OTP to the email
  sendOTPtoEmail(): boolean {
    const header = {
      "Content-Type": "application/json",
      "token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendOTPtoEmailURLApiCallId = requestMessage.messageId;

    let logInEndPoint;
    if (this.state.userType == "admin") {
      logInEndPoint = configJSON.sendAdminOTPtoEmailURLAPiEndPoint;
    } else {
      logInEndPoint = configJSON.sendOTPtoEmailURLAPiEndPoint;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      logInEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodTypeAddDetail
    );
    this.setState({ loading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}
