import React from "react";
// Customizable Area Start
import styles from "./Styles.module.scss";
import { Box, Button, IconButton, Modal, Typography } from "@material-ui/core";
import { ReactComponent as DefaultPic } from "../assets/no-profile-picture.svg";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Customizable Area End
interface ConfirmToCloseRoleInterface {
    showConfirmToCloseRole: boolean;
    onCloseshowConfirmToCloseRole: () => void;
    confirmType: string;
    profileData?: any;
    callAcceptRole?: (data: any, toCloseType: string) => void;
    roleID?: string | number;
    callDeleteJobRole?: (id: string | number) => void;
}

export default class ConfirmToCloseRole extends React.Component<ConfirmToCloseRoleInterface> {
    constructor(props: ConfirmToCloseRoleInterface) {
        super(props);
    }

    // Customizable Area Start
    async componentDidMount() { }
    // Customizable Area End


    render() {
        return (
            // Customizable Area Start
            <Modal
                open={this.props.showConfirmToCloseRole}
                onClose={this.props.onCloseshowConfirmToCloseRole}
                aria-labelledby="confirmToCloseRole-title"
                aria-describedby="confirmToCloseRole"
                className={styles.confirmToCloseRoleModal}
            >
                <Box className={`${styles.confirmToMainBody} ${(this.props.confirmType == "toDeleteJobRole" || this.props.confirmType == "toCloseTheJobRole") ? styles.confirmToCloseRoleBody : styles.confirmToNotCloseRoleBody}`}>
                    {/* <Box > */}
                    <IconButton onClick={this.props.onCloseshowConfirmToCloseRole} aria-label="Close" className={styles.confirmToCloseRoleClose}>
                        <CloseIcon />
                    </IconButton>
                    {/* </Box> */}
                    <Box className={styles.confirmToCloseRoleContentWrap}>
                        <Box className={styles.confirmToCloseRoleContent}>
                            {this.props.confirmType == "toCloseRole" &&
                                <Typography variant="h5" className={styles.confirmToCloseRolehead}> Last Open Position </Typography>}
                            {this.props.confirmType == "cantAccept" &&
                                <Typography variant="h5" className={styles.confirmToCloseRolehead}> No Open Position </Typography>}
                            {this.props.confirmType == "toDeleteJobRole" &&
                                <Typography variant="h5" className={styles.confirmToCloseRolehead}> Delete Role </Typography>}
                            {this.props.confirmType == "toCloseTheJobRole" &&
                                <Typography variant="h5" className={styles.confirmToCloseRolehead}> Close Role </Typography>}

                            {this.props.confirmType == "toCloseRole" && <Typography variant="body1">Do you want to close the role after accepting this candidate?</Typography>}
                            {this.props.confirmType == "cantAccept" && <Typography variant="body1">You cannot accept this candidate as there is no open position available. Do you want to close the role?</Typography>}
                            {this.props.confirmType == "toDeleteJobRole" && <Typography variant="body1">Are you sure you want to delete the role?</Typography>}
                            {this.props.confirmType == "toCloseTheJobRole" && <Typography variant="body1">Are you sure you want to close the role?</Typography>}

                            <Box className={styles.confirmToCloseRoleActions}>
                                <Button className={styles.noCloseRole} onClick={() => {
                                    if (this.props.confirmType == "toCloseRole") {
                                        this.props.callAcceptRole?.(this.props.profileData, "acceptDontClose");
                                    } else {
                                        this.props.onCloseshowConfirmToCloseRole();
                                    }
                                }}>No</Button>
                                <Button className={styles.yesCloseRole} onClick={() => {
                                    if ((this.props.confirmType == "toDeleteJobRole"
                                     || this.props.confirmType == "toCloseTheJobRole") 
                                     && this.props.roleID) {
                                        this.props.callDeleteJobRole?.(this.props.roleID!);
                                    } else if (this.props.confirmType == "toCloseRole") {
                                        this.props.callAcceptRole?.(this.props.profileData, "acceptAndClose");
                                    } else {
                                        this.props.callAcceptRole?.(this.props.profileData, "closeRole");
                                    }
                                }}>Yes</Button>
                            </Box>
                        </Box>

                    </Box>

                </Box >
            </Modal >
            // Customizable Area End
        );
    }
}
