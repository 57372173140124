// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import MultipleCurrencySupport from '../../blocks/MultipleCurrencySupport/src/MultipleCurrencySupport';
import ProjectTemplates from '../../blocks/ProjectTemplates/src/ProjectTemplates';
import LandingPage2 from '../../blocks/LandingPage2/src/LandingPage2';
import MerchantDashboard2 from '../../blocks/MerchantDashboard2/src/MerchantDashboard2.web';
import CreateRole from '../../blocks/MerchantDashboard2/src/CreateRole';

import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import TicketSystem from '../../blocks/TicketSystem/src/TicketSystem';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import Scoring from '../../blocks/Scoring/src/Scoring';
import PrivacySettings from '../../blocks/PrivacySettings/src/Privacypolicy.web';
import JobListing2 from '../../blocks/JobListing2/src/JobListing2';
//customizanle area start
import JobDescription from '../../blocks/JobListing2/src/JobDetails.web';
//customizable area ends
import RequestDemo from '../../blocks/RequestDemo/src/RequestDemo';
import CfLinkedin2 from '../../blocks/CfLinkedin2/src/CfLinkedin2';
import ShareCalendar from '../../blocks/ShareCalendar/src/ShareCalendar';
import RecommendationEngine4 from '../../blocks/RecommendationEngine4/src/RecommendationEngine4';
import LeadManagement from '../../blocks/LeadManagement/src/LeadManagement';
import SummaryCard from '../../blocks/SummaryCard/src/SummaryCard';
import CollectTransactionFees from '../../blocks/CollectTransactionFees/src/CollectTransactionFees';
import Favourites from '../../blocks/Favourites/src/Favourites';
import CfMonster2 from '../../blocks/CfMonster2/src/CfMonster2';
import FileAttachment from '../../blocks/FileAttachment/src/FileAttachment';
import AccountScoreranking from '../../blocks/AccountScoreranking/src/AccountScoreranking.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
//customizable area start
import EmailAccountRegistrationVerification from '../../blocks/email-account-registration/src/EmailAccountRegistrationVerify.web';
import EmailAccountRegistrationOTP from '../../blocks/email-account-registration/src/EmailAccountRegistrationOTP.web';
import DemoDialog from '../../components/src/demo.web';
//customizable area ends
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import OpenApi from '../../blocks/OpenApi/src/OpenApi';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import CfCvAutofill from '../../blocks/CfCvAutofill/src/CfCvAutofill';
import Payments from '../../blocks/Payments/src/Payments';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Feedback from '../../blocks/Feedback/src/Feedback';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import Scheduling from '../../blocks/Scheduling/src/Scheduling';
import CfCvScanner from '../../blocks/CfCvScanner/src/CfCvScanner';
import Location4 from '../../blocks/Location4/src/Location4';
import Analytics3 from '../../blocks/Analytics3/src/Analytics3.web';
import SendAMessage from '../../blocks/SendAMessage/src/SendAMessage';
import Sorting from '../../blocks/sorting/src/Sorting';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import AdHocReporting from '../../blocks/AdHocReporting/src/AdHocReporting';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import CfEfinancial2 from '../../blocks/CfEfinancial2/src/CfEfinancial2';
import GraphicalCharts from '../../blocks/GraphicalCharts/src/GraphicalCharts';
import Settings2 from '../../blocks/Settings2/src/Settings2';
import Notifications from '../../blocks/Notifications/src/Notifications';
import AssessmentTest from '../../blocks/AssessmentTest/src/AssessmentTest';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import PeopleManagement2 from '../../blocks/PeopleManagement2/src/PeopleManagement2';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import CfZoomIntegration from '../../blocks/CfZoomIntegration/src/CfZoomIntegration';
import ApiIntegration8 from '../../blocks/ApiIntegration8/src/ApiIntegration8';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import CvresumeCandidateManagement2 from '../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateManagement2';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import CfWhatsappSupport2 from '../../blocks/CfWhatsappSupport2/src/CfWhatsappSupport2';
import Calendar from '../../blocks/Calendar/src/Calendar';
import SubscriptionBilling from '../../blocks/SubscriptionBilling/src/SubscriptionBilling';
import AppointmentManagement from '../../blocks/AppointmentManagement/src/AppointmentManagement';
import QuestionBank from '../../blocks/QuestionBank/src/CandidatesLogin.web';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import CfIndeed3 from '../../blocks/CfIndeed3/src/CfIndeed3';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import ActivityLog from '../../blocks/ActivityLog/src/ActivityLog';
import LinkShare from '../../blocks/LinkShare/src/LinkShare';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import ViewallOpenroles from '../../blocks/Location4/src/Viewopenroles';
import Terms from '../../blocks/PrivacySettings/src/Terms.web';
import ContactUs from '../../blocks/LandingPage2/src/Contact.web';
import RouteConfig from './route.config';
import { CandidateProvider } from './Context/CandidateContext.web.tsx';
import { InterviewProvider } from './Context/InterviewContext.web.tsx';
import {
  LoaderConsumer,
  LoaderProvider
} from './Context/LoaderContext.web.tsx';

const routeMap = {
  MultipleCurrencySupport: {
    component: MultipleCurrencySupport,
    path: '/MultipleCurrencySupport'
  },
  ProjectTemplates: {
    component: ProjectTemplates,
    path: '/ProjectTemplates'
  },
  LandingPage2: {
    component: LandingPage2,
    path: '/',
    exact: true
  },
  MerchantDashboard2: {
    component: MerchantDashboard2,
    path: '/dashboard'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  ViewallOpenroles: {
    component: ViewallOpenroles,
    path: '/viewopenroles',
    exact: true
  },
  TicketSystem: {
    component: TicketSystem,
    path: '/TicketSystem'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/login',
    exact: true
  },
  Scoring: {
    component: Scoring,
    path: '/Scoring'
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: '/privacy-policy'
  },
  Terms: {
    component: Terms,
    path: '/terms-and-conditions'
  },
  JobListing2: {
    component: JobListing2,
    path: '/JobListing2'
  },
  //customizable area start
  JobDescription: {
    component: JobDescription,
    path: '/jobdescription'
  },
  //customizable area ends

  RequestDemo: {
    component: RequestDemo,
    path: '/RequestDemo'
  },
  CfLinkedin2: {
    component: CfLinkedin2,
    path: '/CfLinkedin2'
  },
  ShareCalendar: {
    component: ShareCalendar,
    path: '/ShareCalendar'
  },
  RecommendationEngine4: {
    component: RecommendationEngine4,
    path: '/RecommendationEngine4'
  },
  LeadManagement: {
    component: LeadManagement,
    path: '/LeadManagement'
  },
  SummaryCard: {
    component: SummaryCard,
    path: '/SummaryCard'
  },
  CollectTransactionFees: {
    component: CollectTransactionFees,
    path: '/CollectTransactionFees'
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  CfMonster2: {
    component: CfMonster2,
    path: '/CfMonster2'
  },
  FileAttachment: {
    component: FileAttachment,
    path: '/FileAttachment'
  },
  AccountScoreranking: {
    component: AccountScoreranking,
    path: '/verticals'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/signup'
  },
  //customizable area start
  EmailAccountRegistrationVerification: {
    component: EmailAccountRegistrationVerification,
    path: '/emailverification'
  },
  EmailAccountRegistratinOTP: {
    component: EmailAccountRegistrationOTP,
    path: '/OTP'
  },
  //customizable area end
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  OpenApi: {
    component: OpenApi,
    path: '/OpenApi'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },
  CfCvAutofill: {
    component: CfCvAutofill,
    path: '/CfCvAutofill'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Feedback: {
    component: Feedback,
    path: '/Feedback'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions'
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  CfCvScanner: {
    component: CfCvScanner,
    path: '/CfCvScanner'
  },
  Location4: {
    component: Location4,
    path: '/open-roles'
  },
  Analytics3: {
    component: Analytics3,
    path: '/blog'
  },
  SendAMessage: {
    component: SendAMessage,
    path: '/SendAMessage'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  AdHocReporting: {
    component: AdHocReporting,
    path: '/AdHocReporting'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  CfEfinancial2: {
    component: CfEfinancial2,
    path: '/CfEfinancial2'
  },
  GraphicalCharts: {
    component: GraphicalCharts,
    path: '/GraphicalCharts'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: '/PeopleManagement2'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  CfZoomIntegration: {
    component: CfZoomIntegration,
    path: '/CfZoomIntegration'
  },
  ApiIntegration8: {
    component: ApiIntegration8,
    path: '/ApiIntegration8'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  CvresumeCandidateManagement2: {
    component: CvresumeCandidateManagement2,
    path: '/CvresumeCandidateManagement2'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/forgot-password'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  CfWhatsappSupport2: {
    component: CfWhatsappSupport2,
    path: '/CfWhatsappSupport2'
  },
  Calendar: {
    component: Calendar,
    path: '/Calendar'
  },
  SubscriptionBilling: {
    component: SubscriptionBilling,
    path: '/SubscriptionBilling'
  },
  AppointmentManagement: {
    component: AppointmentManagement,
    path: '/AppointmentManagement'
  },
  QuestionBank: {
    component: QuestionBank,
    path: '/profile-setting'
  },
  CreateRole: {
    component: CreateRole,
    path: '/createRole'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  CfIndeed3: {
    component: CfIndeed3,
    path: '/CfIndeed3'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  ActivityLog: {
    component: ActivityLog,
    path: '/about-us'
  },
  LinkShare: {
    component: LinkShare,
    path: '/Closed-roles'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const appStyle = theme => ({
  appBar: {
    backgroundColor: '#FFF',
    zIndex: theme.zIndex.drawer + 1
  },
  contentContainer: {
    flex: 1,
    padding: theme.spacing(3),
    marginTop: '6rem'
  }
});

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import theme from '../../components/src/Theme';
import Footer from '../../components/src/Footer';

import { BrowserRouter as Router } from 'react-router-dom';
import { LinearProgress, Box } from '@material-ui/core';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserLoggedIn: true,
      openContactModal: false
    };
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <Router>
        <ThemeProvider theme={theme}>
          <View
            style={{ width: '100%', overflowX: 'hidden', minHeight: '100vh' }}
          >
            <LoaderProvider>
              <InterviewProvider>
                <CandidateProvider>
                  <TopNav />
                  <LoaderConsumer>
                    {props => {
                      return (
                        <Box style={{ minHeight: '4px' }}>
                          {props.isLoading && <LinearDeterminate />}
                        </Box>
                      );
                    }}
                  </LoaderConsumer>
                  <RouteConfig />
                  <Footer />
                </CandidateProvider>
              </InterviewProvider>
            </LoaderProvider>
          </View>
        </ThemeProvider>
      </Router>
    );
  }
}

const LinearDeterminate = () => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 20;
        }
        const diff = Math.random() * 30;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default withStyles(appStyle)(App);
