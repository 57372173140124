import React, { FC } from "react";
import Jobtopfilter from "../../../components/src/Jobtopfilter";
import Leftfilterdropdown from "../../../components/src/Leftfilterdropdown";
//Customize Area Start
import JobList from "./JobList";
import { Box, Grid, InputBase, Theme, withStyles } from "@material-ui/core";
import JobListing2Controller, { Props } from "./JobListing2Controller.web";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { SearchIcon } from "../../LeadManagement/src/assets";
import SearchModal from "./SearchModal.web";
import { withRouter } from "react-router-dom";
//Customize Area Ends

// component styles
const useStyles = (theme: Theme) => ({
    root: {
        margin: "3rem 0",

        [theme.breakpoints.down("sm")]: {
            margin: 0,
        },
    },

    innerContainer: {
        margin: "2.5rem 3.5rem",

        [theme.breakpoints.down("sm")]: {
            margin: "2rem 0",
        },

        "& .MuiGrid-grid-xs-8": {
            flexGrow: 1,
            maxWidth: '95%',

            [theme.breakpoints.down("md")]: {
                maxWidth: "93%",
            }
        },
    },

    jobList: {
        marginLeft: "2rem",

        [theme.breakpoints.down("xs")]: {
            margin: "0 1rem",

            "& span": {
                fontSize: "1rem",
            }
        },

        ['@media (min-width: 1220px) and (max-width: 1280px)']: {
            maxWidth: "100%",
        }
    },

    isMobile: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        }
    },

    mobileSearch: {
        display: "none",

        [theme.breakpoints.down("sm")]: {
            display: "block",
        }
    },

    searchContainer: {
        background: "#fff",
        margin: "1.2rem",
        padding: ".6rem 2rem .6rem 1rem",
        borderRadius: ".6rem",
        display: "flex",
        alignItems: "center",

        "& img": {
            marginRight: "1rem",
        },

        "& div": {
            width: "100%",

            "& input": {
                fontSize: "1.2rem",
                padding: "6px 0",

                "&::placeholder": {
                    fontSize: "1.2rem",
                }
            }
        }
    },

    topSearchBar: {
        [theme.breakpoints.down("md")]: {
            width: "89%",
        },

        ['@media (min-width: 1220px) and (max-width: 1280px)']: {
            width: "93%",
        }
    },

    leftSearch: {
        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
            flexBasis: "25%",
        },

        ['@media (min-width: 1220px) and (max-width: 1280px)']: {
            maxWidth: "100%",
            flexBasis: "22%",
        }
    }
});

// Props for Mobile Search
interface MSProps {
    handleClick: () => void;
    handleBack?: () => void;
    imgIcon: any;
    classes: any;
    autoFocus?: boolean;
    handleChange: (value: string) => void;
    value?: string;
    isModalOpen?: boolean;
}

// Search Component for Small Screen 
export const MobileSearch: FC<MSProps> = (
    { value, handleClick, handleBack, handleChange, imgIcon, classes, autoFocus = false, isModalOpen = false }
) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    return (
        <Box>
            <Box className={classes.searchContainer}>
                <img
                    src={imgIcon}
                    width={18} height={18}
                    onClick={handleBack}
                    style={{ cursor: "pointer" }}
                />
                <InputBase
                    placeholder="Search For Jobs"
                    inputProps={{ "aria-label": "search" }}
                    ref={inputRef}
                    onClick={handleClick}
                    value={value}
                    onChange={(e) => {
                        if (!isModalOpen) handleClick();
                        handleChange(e.target.value)
                    }}
                    autoFocus={autoFocus}
                />
            </Box>
        </Box>
    );
}

// Job Listing Component For Listing the Jobs
class JobListing extends JobListing2Controller {
    constructor(props: Props) {
        super(props);
    }

    // Customize Area Start
    async componentDidMount() {
        window.scrollTo(0, 0);
        // fetch the filters
        this.getFilters();
        // fetch the jobs
        this.getAllJobs();
    }

    extractValue = (value: string) => {
        this.setState({ searchText: value });
    }
    // Customize Area End

    render() {
        const { classes } = this.props;

        return (
          <>
            {/* SearchBar Modal to Mobile view */}
            <SearchModal
              {...this.props}
              isModalOpen={this.state.isSearchModalOpen}
              toggleSearchModal={this.toggleSearchModal}
              filters={this.state.jobFilters.filters}
              clearFilters={this.state.clearAllFilters}
              resetFilters={this.resetFilters}
              applyFilters={this.multipleSearchHandler}
              handleApply={this.handleMobileSearch}
              extractValue={this.extractValue}
              searchTextValue={this.state.searchText}
            />

            <Grid
              container
              justifyContent="center"
              className={classes.root}
            >
              <Grid
                container
                justifyContent="center"
                className={`${classes.isMobile} ${
                  classes.topSearchBar
                }`}
              >
                {/* Filters to Apply on the Jobs */}
                <Jobtopfilter
                  filterData={this.state.jobFilters.filters}
                  applyFilters={this.handleFilters}
                  searchJobs={this.searchJobs}
                  isFilterApplied={this.state.isFilterApplied}
                  resetFilters={this.resetFilters}
                  showAlert={this.openSnackBarHandler}
                />
              </Grid>

              {/* searchbar for mobile */}
              <Grid container className={classes.mobileSearch}>
                <MobileSearch
                  classes={classes}
                  handleClick={this.toggleSearchModal}
                  imgIcon={SearchIcon}
                  handleChange={this.extractValue}
                  value={this.state.searchText}
                />
              </Grid>

              <Grid container className={classes.innerContainer}>
                <Grid
                  xs={2}
                  item
                  className={`${classes.isMobile} ${
                    classes.leftSearch
                  }`}
                >
                  {/* sidebar dropdown for handle mutiple filtes */}
                  <Leftfilterdropdown
                    filters={this.state.jobFilters.filters}
                    handleFilters={this.multipleSearchHandler}
                    clearAllFilters={this.state.clearAllFilters}
                  />
                </Grid>

                <Grid item xs={8} className={classes.jobList}>
                  {/* Job lists that maches user CV */}
                  <JobList
                    heading="Latest jobs matching your CV"
                    noJobText="No CV based jobs are available"
                    perPage={6}
                    type="cv_based"
                    jobs={this.state.cvMatchingJobList}
                    isAppliedForJob={this.applyForJob}
                    handleFavorite={this.handleFavorite}
                    handlePagination={this.getAllListedJobs}
                    sendEmailToParent={this.createCoversation}
                  />
                  {/* recommended Jobs List */}
                  <JobList
                    heading="Other Results"
                    noJobText="No other jobs are available"
                    perPage={6}
                    type="others"
                    jobs={this.state.otherJobList}
                    isAppliedForJob={this.applyForJob}
                    handleFavorite={this.handleFavorite}
                    handlePagination={this.getAllListedJobs}
                    sendEmailToParent={this.createCoversation}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Snackbar for display success and failed messages. */}
            <Snackbar
              open={this.state.snackBar.show}
              autoHideDuration={3000}
              onClose={this.closeSnackBarHandler}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={this.closeSnackBarHandler}
                severity={this.state.snackBar.type}
              >
                {this.state.snackBar.message}
              </MuiAlert>
            </Snackbar>
          </>
        );
    }
}
// @ts-ignore
export default withStyles(useStyles)(withRouter(JobListing));
