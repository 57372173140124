export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const arrow = require("../assets/arrow.png");
export const OpenSearch = require("../assets/open_roles.png");
export const ClosedRoles = require('../assets/closed_roles.png')
export const CreatNewRole = require('../assets/create_new_role.png')
export const Candidates = require('../assets/candidatemessages.png')
export const Interview = require('../assets/upcoming_interview.png')
export const SpreadSheet = require('../assets/ic_Spreadsheets.png');
export const profileIcon = require('../assets/profileIcon.png');
export const chartIcon = require('../assets/chartIcon.png');


