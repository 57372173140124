import React from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import ClosedRoleList from '../../../components/src/ClosedRolesList';
import { Link } from "react-router-dom";
interface CloseItemInterface {
    classes: any;
    role: any;
    isExpand: boolean;
    history: any;
    handleViewAll: (id?: number) => void;
    hasMoreCandidateData?: boolean;
    infiniteCandidateLoading?: boolean;
    candidateListScroll?: (data: string) => void;
    startLoading: () => void;
    createConversation: (email: string) => void;
    clientIDLink?: any;
}

class CloseRoleListItem extends React.Component<CloseItemInterface> {
    constructor(props: CloseItemInterface) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        return (
            <Card>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    p={1}
                    px={2}
                    className={classes.BgCardHeader}
                >
                    <Box>
                        <Link to={`/job-detail/${this.props.role?.id}${this.props.clientIDLink ? "?" + this.props.clientIDLink : ""}`} style={{ textDecoration: "none", color: "#24325F" }}>

                            <Typography variant="body1"
                                style={{ textTransform: "capitalize" }}
                                component='h6'
                            >
                                {this.props.role?.attributes?.role_name}
                            </Typography>
                        </Link>
                    </Box>
                    <Box>
                        {/* <Typography style={{ cursor: "pointer" }}
                            onClick={(_) => {
                                this.props.history.push(`/job-detail/${this.props.role?.id}`);
                            }}>VIEW DETAIL</Typography> */}
                        {this.props.isExpand ?
                            <Typography
                                style={{ cursor: "pointer" }}
                                onClick={(_) => {
                                    this.props.startLoading();
                                    this.props.history.push({
                                        pathname: "/closed-roles",
                                        search: location.search
                                    });
                                    this.props.handleViewAll?.()
                                }}
                            >
                                VIEW LESS
                            </Typography> :
                            <Typography
                                style={{ cursor: "pointer" }}
                                onClick={(_) => {
                                    this.props.startLoading();
                                    this.props.history.push({
                                        pathname: `/closed-roles/role/${this.props.role?.id}`,
                                        search: location.search
                                    });
                                    this.props.handleViewAll?.(this.props.role?.id)
                                }}
                            >
                                VIEW ALL
                            </Typography>}
                    </Box>
                </Box>
                <Box p={2}>
                    {this.props.role?.attributes?.candidate &&
                        this.props.role?.attributes?.candidate.length > 0 ?
                        <Grid container spacing={2}>
                            <Grid item lg={12} className={classes.roleTableStyle}>
                                <ClosedRoleList
                                    classes={classes}
                                    role_id={this.props.role?.id}
                                    candidate={this.props.role?.attributes?.candidate}
                                    hasMoreCandidateData={this.props.hasMoreCandidateData}
                                    infiniteCandidateLoading={this.props.infiniteCandidateLoading}
                                    candidateListScroll={this.props.candidateListScroll}
                                    createConversation={this.props.createConversation}
                                    clientIDLink={this.props.clientIDLink}
                                />
                            </Grid>
                        </Grid> :
                        <Typography variant="body1">No candidates found.</Typography>}
                </Box>
            </Card>
        )
    }
}

export default CloseRoleListItem;