import React, { FC, useState } from "react";
import { Box, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import UserProfilePicture from "../../blocks/user-profile-basic/src/UserProfilePicture.web";
import { EmailOutlined } from "@material-ui/icons";
import { useStyles as useNotiStyles } from "./TopNav_Candidate";
import Notifications from './Notifications.web';

interface DropDownProps {
    history: any;
    isMobile?: boolean;
}

const useSyles = makeStyles({
    dropDown: {
        marginTop: "2.6rem",
        position: "absolute !important" as any,

        "& .MuiMenu-paper": {
            left: "unset!important",
            right: "1em"
        }
    },
    icon: {
        marginRight: ".5rem",
        padding: ".3rem .2rem"
    },
    iconStyle: {
        color: "#24325f",
        cursor: "pointer",
        fontSize: "2rem",
    },
    option: {
        color: "#2D3A66",
        fontWeight: 600,
    }
});

const DropDownMenu: FC<DropDownProps> = ({ history, isMobile }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userProfile, setUserProfile] = React.useState<boolean | HTMLElement>(false);
    const [clickOnItem, setClickOnItem] = React.useState(false);
    const [userType, setUserType] = React.useState(sessionStorage.getItem("user_role"));
    const open = Boolean(anchorEl);
    const classes = useSyles();
    const notifcationClasses = useNotiStyles();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        sessionStorage.clear();
        history.push('/');
    }

    const handleUserProfile = () => {
        setUserProfile(prev => !prev);
    }

    // is Mobile View
    const [width, setWidth] = useState<number>(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <>
            <Box onClick={handleClick} mr={width <= 960 ? "1em" : ""}>
                <img src={require("./image_Dropdown.png")} style={{ cursor: "pointer" }} />
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.dropDown}
                disableScrollLock={true}
            >
                {isMobile && (
                    <MenuItem onClick={() => history.push('/profile-setting/chats')}>
                        <EmailOutlined className={`${classes.icon} ${classes.iconStyle}`} />
                        <Typography className={classes.option}>
                            Messages
                        </Typography>
                    </MenuItem>
                )}
                {isMobile && (
                    <MenuItem>
                        <Notifications
                            classes={notifcationClasses}
                            history={history}
                            isMobile={isMobile}
                        >
                            <Typography className={classes.option}>
                                Notifications
                            </Typography>
                        </Notifications>
                    </MenuItem>
                )}
                {userType != 'admin' &&
                    <MenuItem onClick={handleUserProfile}>
                        <img src={require("./edit-profile.svg")} className={classes.icon} />
                        <Typography className={classes.option}>
                            Edit Profile Picture
                        </Typography>
                    </MenuItem>
                }
                <MenuItem onClick={logOut}>
                    <img src={require("./logout.svg")} className={classes.icon} />
                    <Typography style={{ color: "#FF5959", fontWeight: 600 }}>
                        Logout
                    </Typography>
                </MenuItem>
            </Menu>

            <UserProfilePicture showProfilePicture={userProfile} onCloseshowProfilePicture={handleUserProfile} />
        </>
    )
}

export default DropDownMenu;