import React from "react";
import { arrow, OpenSearch, ClosedRoles, CreatNewRole, Candidates, Interview, SpreadSheet, chartIcon, profileIcon } from './assets'
import { CardContent, Drawer } from '@material-ui/core';
// Customizable Area Start
import { withRouter } from "react-router";
import { Card, Container, Box, Typography, withStyles, Button, Grid, ListItemIcon, Toolbar, List, ListItem, ListItemText, AppBar } from "@material-ui/core";
import MerchantDashboard2Controller, {
  Props,
  configJSON
} from "./MerchantDashboard2Controller.web";
import { Link } from "react-router-dom";
import DashboardCounter from "../../../components/src/DashboardCardsCounter";
import AppSidebar from '../../../components/src/AppSidebar';
import Loader from "../../../components/src/Loader.web";
import moment from "moment";
// Customizable Area End
const drawerWidth = 240;
const muiStyles = (theme: any) => ({
  root: {
    display: 'flex',
  },
  textWhite: {
    color: "#ffffff"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#384786',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  listIconeWidth: {
    minWidth: '47px',
    color: 'White'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  BgHighlightBottom: {
    width: '100%',
    backgroundColor: '#24325f',
    height: '15px',
  },
  BgCardHeader: {
    backgroundColor: '#a8d0e7',
  },
  BtnCreateNewRole: {
    backgroundColor: '#24325f',
    color: "white"
  },
  BgHighlightInterviewsBottom: {
    backgroundColor: '#e19954',
    height: '15px',
    width: "100%"

  },
  InterveiwTextColor: {
    color: '#e19954'
  },
  CandidatesTextColor: {
    color: '#5f72b9'
  },
  BgHighlightcandidatesBottom: {
    backgroundColor: '#5f72b9',
    height: "15px",
    width: '100%'
  },
  BgHighlightClosedRolesBottom: {
    backgroundColor: '#ff4e6d',
    height: "15px",
    widht: '100%'
  },
  OpenRolesTextColor: {
    color: "#24325F"
  },
  MessageTextColor: {
    color: "#5F72B9",
  },
  ClosedRolesTextColor: {
    color: '#ff4e6d'
  },
  card: {
    boxShadow: 'none!important',
  },
  TilesItem: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    height: '100%',
  },
  TilesItemContent: {
    flex: '1'
  },
  TilesTextSyle: {
    fontSize: '0.875em',
    color: '#363636',
    cursor: "pointer"
  },
  RoleHeading: {
    color: '#24325F',
    fontFamily: 'Poppins-SemiBold',
  },
  RoleHeadingAll: {
    color: '#24325F',
    fontFamily: 'Poppins-SemiBold',
    cursor: "pointer"
  },
  RoleHeadingAllSmall: {
    fontSize: '0.75em',
  },
  WelcomeDate: {
    color: '#92929D'
  },
  topAppBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffff",
  },
  container: {
    marginTop: "5.4rem",
    width: "100%"
  },
  customMargin: {
    marginTop: "1.9rem",
    marginLeft: "1rem"
  },
  skeletonText: {
    animation: "$skeleton-loading 1s linear infinite alternate",
    opacity: ".7",
    borderRadius: ".7rem",
  },
  skeletonDiv: {
    animation: "$skeleton-loading 1s linear infinite alternate",
    opacity: ".7",
    width: "calc(100% + 24px)",
    height: "100%",
    borderRadius: "1rem",
    marginLeft: "-12px"
  },
  link: {
    textDecoration: "none",
  },
  "@keyframes skeleton-loading": {
    "0%": {
      backgroundColor: "hsl(200, 20%, 70%)",
    },

    "100%": {
      backgroundColor: "hsl(200, 20%, 95%)",
    },
  }
});

class MerchantDashboard2 extends MerchantDashboard2Controller {
  constructor(props: Props) {
    super(props);

  }
  componentDidMount(): Promise<void> {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.checkWindowResize);
    return super.componentDidMount();
  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowResize);
  }

  render() {
    const { classes } = this.props;

    return (
      <Container maxWidth="xl" >
        <Box display="flex">
          <AppSidebar />

          <Box color="primary" mt={4} className={classes.container}>
            {/* Dashboard User Details start */}
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Box mb={3}>
                <Box mb={1}><Typography variant="h4" >Welcome, {sessionStorage.getItem('first_name') ? sessionStorage.getItem('first_name') : '-'}&nbsp;{sessionStorage.getItem('user_role') != 'admin' && (sessionStorage.getItem('last_name') ? sessionStorage.getItem('last_name') : '-')}</Typography></Box>
                <Typography className={classes.WelcomeDate}>{moment().format('dddd')}, {moment().format('DD')}, {moment().format('MMM')}, {moment().format('YYYY')}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" flexDirection="Row">
                <Box m={1}><Button className={classes.BtnCreateNewRole} onClick={() => {
                  // @ts-ignore
                  this.props?.history?.push("/createRole" + this.clientIDLink);
                }}><Box pr={1} display="flex"><img src={CreatNewRole} width={20} /> </Box>Create New Role</Button></Box>
                <Box m={1}><Button className={classes.BtnCreateNewRole}><Box pr={1} display="flex"><img src={SpreadSheet} width={20} /></Box> Admin Page</Button></Box>
              </Box>
            </Box>
            {/* Dashboard User Details End */}

            {/* Cards Counter Start */}
            <Box mb={4}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <Link to={`/open-roles${this.clientIDLink}`} href={`/open-roles${this.clientIDLink}`} className={classes.link}>
                    <Card className={classes.TilesItem}>
                      <CardContent className={classes.TilesItemContent}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                          <Box className={classes.customMargin}>
                            <Box>
                              <Typography variant="h4" className={classes.OpenRolesTextColor}>{this.state.rolesData?.total_open_roles || 0}</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems='center'>
                              <Typography className={classes.TilesTextSyle}>Open Roles</Typography>
                              <img src={arrow} style={{ marginLeft: '10px', height: '0.75em', width: "1em" }} />
                            </Box>
                          </Box>
                          <Box><img src={OpenSearch} width={50} /></Box>
                        </Box>
                      </CardContent>
                      <Box className={classes.BgHighlightBottom}></Box>
                    </Card>
                  </Link>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <Link to={`/closed-roles${this.clientIDLink}`} href={`/closed-roles${this.clientIDLink}`} className={classes.link}>
                    <Card className={classes.TilesItem}>
                      <CardContent className={classes.TilesItemContent}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                          <Box className={classes.customMargin}>
                            <Box>
                              <Typography variant="h4" className={classes.ClosedRolesTextColor}>{this.state.rolesData?.total_close_roles || 0}</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems='center'>
                              <Typography className={classes.TilesTextSyle}>Closed Roles</Typography>
                              <img src={arrow} style={{ marginLeft: '10px', height: '0.75em', width: "1em" }} />
                            </Box>
                          </Box>
                          <Box><img src={ClosedRoles} width={50} /></Box>
                        </Box>
                      </CardContent>
                      <Box className={classes.BgHighlightClosedRolesBottom}></Box>
                    </Card>
                  </Link>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <Link to={`/email-notifications${this.clientIDLink}`} href={`/email-notifications${this.clientIDLink}`} className={classes.link}>
                    <Card className={classes.TilesItem}>
                      <CardContent className={classes.TilesItemContent}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                          <Box className={classes.customMargin}>
                            <Box>
                              <Typography variant="h4" className={classes.MessageTextColor}>0</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems='center'>
                              <Typography className={classes.TilesTextSyle}>Messages from candidates</Typography>
                              <img src={arrow} style={{ marginLeft: '10px', height: '0.75em', width: "1em" }} />
                            </Box>
                          </Box>
                          <Box><img src={Candidates} width={50} /></Box>
                        </Box>
                      </CardContent>
                      <Box className={classes.BgHighlightcandidatesBottom}></Box>
                    </Card>
                  </Link>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <Link to={`/candidate-scoring${this.clientIDLink}`} href={`/candidate-scoring${this.clientIDLink}`} className={classes.link}>
                    <Card className={classes.TilesItem}>
                      <CardContent className={classes.TilesItemContent}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                          <Box className={classes.customMargin}>
                            <Box>
                              <Typography variant="h4" className={classes.InterveiwTextColor}>{this.state.rolesData?.candidate_scoring || 0}</Typography>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems='center'>
                              <Typography className={classes.TilesTextSyle}>Candidate Scoring </Typography>
                              <img src={arrow} style={{ marginLeft: '10px', height: '0.75em', width: "1em" }} />
                            </Box>
                          </Box>
                          <Box><img src={Interview} width={50} /></Box>
                        </Box>
                      </CardContent>
                      <Box className={classes.BgHighlightInterviewsBottom}></Box>
                    </Card>
                  </Link>
                </Grid>
              </Grid>
            </Box>
            {/* Cards Counter End */}

            {/* Dashboard Open Roles Section Start */}
            <Box mb={4}>
              <Card className={classes.card}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems='center' p={1} px={2} className={classes.BgCardHeader}>
                  <Box>
                    <Typography className={classes.RoleHeading}>Open Roles</Typography>
                  </Box>
                  <Box onClick={() => {
                    //@ts-ignore
                    this.props.history.push(`/open-roles${this.clientIDLink}`)
                  }}>
                    <Typography
                      className={`${classes.RoleHeadingAll} ${this.state.windowWidth > 960 && classes.RoleHeadingAllSmall}`}
                    >VIEW ALL</Typography>
                  </Box>
                </Box>
                <Box p={2}>
                  <Grid container spacing={3}>
                    {this.state.rolesData?.open_roles?.data?.length > 0 ?
                      this.state.rolesData?.open_roles?.data?.slice(0, 6)?.map((item: any) => {
                        let date = new Date(item?.attributes?.data_opened);
                        let setDate = date.toLocaleDateString("en-AU");
                        return (
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={item?.id}>
                            <DashboardCounter
                              roleType={"openRole"}
                              jobId={item?.id}
                              title={item?.attributes?.name}
                              date={setDate}
                              countshortlist={item?.attributes?.shortlisted_candidates}
                              countunder={item?.attributes?.under_assessment}
                              candidatImg={profileIcon}
                              clientIDLink={this.clientIDLink}
                            />
                          </Grid>
                        )
                      }) : <Typography style={{ padding: "1rem" }}>No Roles Has Been Opened</Typography>}
                  </Grid>
                </Box>
              </Card>
            </Box>
            {/* Dashboard Open Roles Section End */}

            {/* Dashboard Closed Roles Section Start */}
            <Box mb={4}>
              <Card className={classes.card}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems='center' p={1} px={2} className={classes.BgCardHeader}>
                  <Box>
                    <Typography className={classes.RoleHeading}>Closed Roles</Typography>
                  </Box>
                  <Box onClick={() => {
                    //@ts-ignore
                    this.props.history.push(`/closed-roles${this.clientIDLink}`)
                  }}>
                    <Typography
                      className={`${classes.RoleHeadingAll} ${this.state.windowWidth > 960 && classes.RoleHeadingAllSmall}`}
                    >VIEW ALL</Typography>
                  </Box>
                </Box>
                <Box p={2}>
                  <Grid container spacing={3}>
                    {this.state.rolesData?.closed_roles?.data.length > 0 ? this.state.rolesData?.closed_roles?.data?.slice(0, 6)?.map((item: any) => {
                      let date = new Date(item?.attributes?.data_opened);
                      let setDate = date.toLocaleDateString("en-AU");
                      return (
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={item?.id}>
                          <DashboardCounter
                            roleType={"closeRole"}
                            jobId={item?.id}
                            title={item?.attributes?.name}
                            date={setDate}
                            countshortlist={item?.attributes?.shortlisted_candidates}
                            countunder={item?.attributes?.under_assessment}
                            candidatImg={profileIcon}
                            clientIDLink={this.clientIDLink} />
                        </Grid>
                      )
                    }) : <Typography style={{ padding: "1rem" }}>No Roles Has Been Closed</Typography>}
                  </Grid>
                </Box>
              </Card>
            </Box>
            {/* Dashboard Open Roles Section End */}
          </Box>
        </Box>

      </Container>
    );
  }
}

// Customizable Area Start
//@ts-ignore
export default withStyles(muiStyles)(withRouter(MerchantDashboard2));
// Customizable Area End
