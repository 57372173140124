import React, { FC } from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    skeleton: {
        animation: "$skeleton-loading 1s linear infinite alternate",
        opacity: ".6",
        borderRadius: ".5rem",
        marginRight: ".5rem"
    },

    "@keyframes skeleton-loading": {
        "0%": {
            backgroundColor: "hsl(200, 20%, 70%)",
        },

        "100%": {
            backgroundColor: "hsl(200, 20%, 95%)",
        },
    }
});

interface SProps {
    width?: string;
    height?: string;
    numberOfShimmer?: number;
}

const randomNum = (min: number = 10, max: number = 30) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const ShimmerLoading: FC<SProps> = ({ width, height, numberOfShimmer = 1 }) => {
    const styles = useStyles();

    if (numberOfShimmer > 1) {
        return (
            <Box display="flex">
                {[...Array(numberOfShimmer)].map((_, index) => (
                    <div
                        key={index}
                        className={styles.skeleton}
                        style={{ width: width || `${randomNum()}%`, height: height || "1.5rem" }}
                    />
                ))}
            </Box>
        )
    }

    return <div className={styles.skeleton} style={{ width: width || "100%", height: height || "1.5rem" }} />;
}

export default ShimmerLoading;