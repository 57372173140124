import React from "react";
// Customizable Area Start
import UserProfilePictureController, {
    Props
} from "./UserProfilePictureController.web";
import styles from "./Styles.module.scss";
import { Box, Button, IconButton, Modal } from "@material-ui/core";
import { ReactComponent as DefaultPic } from "../assets/no-profile-picture.svg";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Customizable Area End
export default class UserProfilePicture extends UserProfilePictureController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        let clientID = +window.location?.search?.split("clientId=")?.[1];
        this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID }, () => {
            if (this.state.userType == 'admin') {
                this.checkIfRefreshToken();
            } else {
                this.getUserProfile();
            }
        });

    }

    removeProfilePicture = async () => {
        this.removeProfilePhoto();
    }

    setImageData = async (event: any) => {
        let file = event?.target?.files[0];
        this.setState({ imageData: file });
        this.uploadImageWithParsingURl(file);
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Modal
                open={this.props.showProfilePicture}
                onClose={this.props.onCloseshowProfilePicture}
                aria-labelledby="user-profile-title"
                aria-describedby="user-profile"
                className={styles.userProfileModal}
            >
                <>
                    <Box className={styles.profileBody}>
                        <Box className={styles.profilehead}>
                            <p>Edit Profile Picture</p>
                            <IconButton onClick={this.props.onCloseshowProfilePicture} aria-label="Close" className={styles.profileClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box className={styles.profilePicture}>
                            <img src={`${this.context.profile_picture}`} />
                        </Box>
                        <Box className={styles.profileButton}>
                            <Box display="flex" justifyContent="center">
                                <label htmlFor="upload-profile-picture-button" >
                                    <Button
                                        disableRipple
                                        size="medium"
                                        component="span"
                                        className={styles.uploadButton}>
                                        Upload New Profile Picture
                                    </Button>
                                </label>
                                <input
                                    accept="image/*"
                                    className={styles.uploadInput}
                                    id="upload-profile-picture-button"
                                    onChange={(event) => {
                                        this.setImageData(event)
                                    }}
                                    type="file"
                                />

                            </Box>
                            {this.state.profilePicture &&
                                <Box display="flex" justifyContent="center">
                                    <Button
                                        size="medium"
                                        variant="outlined"
                                        className={styles.deleteButton}
                                        onClick={this.removeProfilePicture}
                                    >
                                        Remove Current Profile Picture
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box >

                    <Snackbar
                        open={this.state.snackBar.show}
                        autoHideDuration={3000}
                        onClose={this.closeSnackBarHandler}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={this.closeSnackBarHandler}
                            severity={this.state.snackBar.type}
                        >
                            {this.state.snackBar.message}
                        </MuiAlert>
                    </Snackbar>
                </>
            </Modal >
            // Customizable Area End
        );
    }
}
