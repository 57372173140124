import React from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import OpenRolesCards from '../../../components/src/OpenRolesCards'
import { Link } from "react-router-dom";

interface OpenItemInterface {
    classes: any;
    role: any;
    isExpand: boolean;
    match?: any;
    history: any;
    windowWidth: any;
    candidateListScroll?: (data: string) => void;
    handleViewAll: (id?: number | undefined) => void;
    handleSendStatus?: (data: { role_id: number, profile_id: number, isAccepted: boolean }, position: number | string, applied_candidates: number | string) => void;
    hasMoreCandidateData?: boolean;
    infiniteCandidateLoading?: boolean;
    startLoading: () => void;
    createCoversation: (candidate_email: string) => void;
    clientIDLink?: any;
}

class OpenRoleGridItem extends React.Component<OpenItemInterface> {
    candidateObserver: any;
    lastCandidateItem: (node: any) => void;
    userType: string;
    clientID: string | number;
    clientIDLink: string;
    constructor(props: OpenItemInterface) {
        super(props);

        // To set observer and handle infinite scroll start
        this.candidateObserver = React.createRef();
        this.lastCandidateItem = (node: any) => {
            if (this.candidateObserver.current) this.candidateObserver.current.disconnect();

            this.candidateObserver.current = new IntersectionObserver(entries => {
                if (this.props.infiniteCandidateLoading) return;
                if (entries[0].isIntersecting && this.props.hasMoreCandidateData && !this.props.match?.params?.id) {
                    this.props.candidateListScroll?.("candidateScrolled")
                }
            });
            if (node) this.candidateObserver.current.observe(node);
        // To set observer and handle infinite scroll end

        }
    }

    async componentDidMount() {
        this.clientID = +this.props.history?.location?.search?.split("clientId=")[1] || "";
        this.userType = sessionStorage.getItem("user_role") || "";
        this.clientIDLink = this.userType == 'admin' && this.clientID ? "clientId=" + this.clientID : ""
    }

    render() {
        const { classes } = this.props;
        let totalRejected = 0;
        return (
            <Box mb={3} key={this.props.role?.id} >
                <Card>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        p={1}
                        px={2}
                        className={classes.BgCardHeader}
                    >
                        <Box>
                            <Link to={`/job-detail/${this.props.role?.id}${this.props.clientIDLink ? "?" + this.props.clientIDLink : ""}`} style={{ textDecoration: "none", color: "#24325F" }}>
                                <Typography
                                    variant="body1"
                                    component='h6'
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {this.props.role?.attributes?.role_name}
                                </Typography>
                            </Link>
                        </Box>
                        <Box>
                            {this.props.isExpand ?
                                <Typography style={{ cursor: "pointer" }}
                                    onClick={(_) => {
                                        this.props.startLoading();
                                        this.props.history.push({
                                            pathname: "/open-roles",
                                            search: location?.search
                                        });
                                        this.props.handleViewAll?.()
                                    }}>VIEW LESS</Typography> :
                                <Typography style={{ cursor: "pointer" }}
                                    onClick={(_) => {
                                        this.props.startLoading();
                                        this.props.history.push({
                                            pathname: `/open-roles/role/${this.props.role?.id}`,
                                            search: location?.search
                                        });
                                        this.props.handleViewAll?.(this.props.role?.id)
                                    }}>VIEW ALL</Typography>
                            }
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Grid container spacing={2} className={`${this.props.windowWidth <= 959 && classes.formobileCardContainer}`}>
                            {(this.props.role?.attributes?.candidate?.length > 0 && !this.props.role?.attributes?.candidate?.includes(null)) ?
                                this.props.role?.attributes?.candidate?.map((candidate: any, index: number) => {
                                    // if (candidate?.status == "rejected") {
                                    //     totalRejected += 1;
                                    // }
                                    // if (totalRejected == this.props.role?.attributes?.candidate?.length) {
                                    if (this.props.role?.attributes?.candidate?.length == 0) {
                                        return (<Typography className={classes.fallBackText} key={index}>No candidates found.</Typography>)
                                    }
                                    return (
                                        // candidate?.status !== "rejected" && (
                                        this.props.role?.attributes?.candidate?.length == index + 1 ?
                                            <Grid item xl={3} lg={4} md={6} sm={12} key={index}
                                                className={`${this.props.windowWidth <= 959 && classes.formobileCardItem}`}
                                                ref={this.lastCandidateItem}
                                            >
                                                <OpenRolesCards
                                                    Title={candidate?.name}
                                                    Date={candidate?.date_opened?.split("T")[0]}
                                                    Initial_stage={candidate?.initial_score}
                                                    Final_score={candidate?.final_score}
                                                    Feedback={candidate?.final_feedback}
                                                    imageUrl={candidate?.photo ? candidate?.photo : `https://www.bootdey.com/img/Content/avatar/avatar${(index % 8) + 1}.png`}
                                                    assessment_stage={candidate?.assessment_stage}
                                                    role_id={this.props.role?.id}
                                                    candidate_id={candidate?.profile_id}
                                                    account_id={candidate?.account_id}
                                                    resume={candidate?.resume}
                                                    email={candidate?.email}
                                                    status={candidate?.status}
                                                    handleStatus={(data: any) => { this.props.handleSendStatus?.(data, this.props.role?.attributes?.position, this.props.role?.attributes?.accepted_candidate) }}
                                                    windowWidth={this.props.windowWidth}
                                                    createCoversation={this.props.createCoversation}
                                                    phone_number={candidate?.phone_number}
                                                    clientIDLink={this.props.clientIDLink}
                                                />
                                            </Grid> :
                                            <Grid item xl={3} lg={4} md={6} sm={12} key={index} className={`${this.props.windowWidth <= 959 && classes.formobileCardItem}`}>
                                                <OpenRolesCards
                                                    Title={candidate?.name}
                                                    Date={candidate?.date_opened?.split("T")[0]}
                                                    Initial_stage={candidate?.initial_score}
                                                    Final_score={candidate?.final_score}
                                                    Feedback={candidate?.final_feedback}
                                                    imageUrl={candidate?.photo ? candidate?.photo : `https://www.bootdey.com/img/Content/avatar/avatar${(index % 8) + 1}.png`}
                                                    assessment_stage={candidate?.assessment_stage}
                                                    role_id={this.props.role?.id}
                                                    candidate_id={candidate?.profile_id}
                                                    resume={candidate?.resume}
                                                    account_id={candidate?.account_id}
                                                    email={candidate?.email}
                                                    status={candidate?.status}
                                                    handleStatus={(data: any) => { this.props.handleSendStatus?.(data, this.props.role?.attributes?.position, this.props.role?.attributes?.accepted_candidate) }}
                                                    windowWidth={this.props.windowWidth}
                                                    createCoversation={this.props.createCoversation}
                                                    phone_number={candidate?.phone_number}
                                                    clientIDLink={this.props.clientIDLink}
                                                />
                                            </Grid>
                                        // )
                                    )
                                }) : <Typography className={classes.fallBackText}>No candidates found.</Typography>}
                        </Grid>
                    </Box>
                </Card>
            </Box>
        );
    }
}

export default OpenRoleGridItem;