import React, { FC, useState } from "react";
import { Box, makeStyles, Menu, MenuItem, Typography, Tooltip } from "@material-ui/core";
import {
    Bookmark, DeleteOutline,
    MoreVertOutlined, ReplyRounded
} from "@material-ui/icons";

interface DropDownProps {
    favouriteChats: any;
    selectedConversation: any;
    sendHandleFavorite: () => void;
}

const useSyles = makeStyles({
    dropDown: {
        marginTop: "2.6rem",
        position: "absolute !important" as any,

        "& .MuiMenu-paper": {
            left: "unset!important",
            right: "1em"
        }
    },
    icon: {
        marginRight: ".5rem",
        padding: ".3rem .2rem"
    },
    iconStyle: {
        color: "#24325f",
        cursor: "pointer",
        fontSize: "2rem",
    },
    option: {
        color: "#2D3A66",
        fontWeight: 600,
    }
});

const DropDownMenu: FC<DropDownProps> = ({ favouriteChats, selectedConversation, sendHandleFavorite }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userProfile, setUserProfile] = React.useState<boolean | HTMLElement>(false);

    const open = Boolean(anchorEl);
    const classes = useSyles();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUserProfile = () => {
        setUserProfile(prev => !prev);
    }

    // is Mobile View
    const [width, setWidth] = useState<number>(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <>
            <Box onClick={handleClick} mr={width <= 960 ? "1em" : ""}>
                <MoreVertOutlined />
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.dropDown}
                disableScrollLock={true}
            >
                <MenuItem onClick={sendHandleFavorite}>
                    {favouriteChats.find((chat: any) => chat.sid === selectedConversation) ? (
                        <>
                            <Bookmark style={{ color: "#f3b23a " }} />
                            <Typography className={classes.option}>
                                Remove from Favourite
                            </Typography>
                        </>

                    ) : (
                        <>
                            <Bookmark />
                            <Typography className={classes.option}>
                                Add to Favourite
                            </Typography>
                        </>
                    )}

                </MenuItem>
                <MenuItem>
                    <ReplyRounded style={{ transform: "scaleX(-1)" }} />
                    <Typography className={classes.option}>
                        Reply
                    </Typography>
                </MenuItem>
                <MenuItem>
                    {sessionStorage.getItem("user_role") === "client" && <DeleteOutline />}
                    <Typography className={classes.option}>
                        Delete Chat
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default DropDownMenu;