//@ts-nocheck
import React from "react";


// Customizable Area Start
import { Container, Box, Typography, withStyles, Button, Grid, Modal, TextField } from "@material-ui/core";
import { Card, CardContent } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { Link, withRouter } from "react-router-dom";
// Customizable Area End

// Customizable Area Start
import LandingPage2Controller, { Props } from "./LandingPage2Controller.web";
import RequestDemoSuccessModalWeb from "./RequestDemoSuccessModal.web";
import 'react-phone-number-input/style.css';
import RequestDemoModal from "./RequestDemoModal.web";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Carousel from 'react-elastic-carousel';
// Customizable Area End


const muiStyles = (theme: any) => ({
  bannerContainer: {
    "& p": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    }
  },
  textWhite: {
    color: "#ffffff",

    [theme.breakpoints.down("sm")]: {
      fontSize: "2.75rem",
    }
  },
  button: {
    color: "#ffffff",
    borderColor: "#ffffff",
    borderRadius: 7,
    padding: "1rem 2rem"
  },
  banner: {
    padding: "7rem 6rem",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      padding: "2rem 1.5rem 8rem",
    },

    "& h2": {
      width: "80%"
    },

    "& .description": {
      width: "90%"
    }
  },
  candidateArea: {
    [theme.breakpoints.down('xs')]: {
      marginTop: "3rem",
    },
  },
  loginBtn: {
    marginRight: "1rem"
  },
  featureIcons: {
    margin: "30px auto",
    display: "flex",
    height: "25%",

    [theme.breakpoints.down('sm')]: {
      height: "18.5%",
      marginTop: "20px",
    },
  },
  featureTxt: {
    lineHeight: "1.6rem",
    fontWeight: 400,
    marginBottom: "1.2rem",
    marginTop: ".5rem",
    height: "70%",

    [theme.breakpoints.down('sm')]: {
      height: "40%",
    },
  },
  testimonialsContainer: {
    backgroundColor: theme.palette.secondary.main + '30'
  },
  clientTxt: {
    color: "#000",
    lineHeight: "1.8rem"
  },
  clientDes: {
    marginTop: "1.1rem",
    fontStyle: "italic",
    fontSize: "0.8rem",
    fontWeight: 500
  },
  clientName: {
    fontSize: "1rem",
  },
  demo: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
    },
  },
  separatorBox: {
    justifyContent: "center",
    display: "flex"
  },
  separator: {
    height: "100%",
    width: 1,
    backgroundColor: theme.palette.secondary.main
  },
  playIcon: {
    width: 64,
    height: 64,
    cursor: 'pointer'
  },
  player: {
    borderRadius: "30px",
    backgroundColor: '#000000',
    "& video": {
      borderRadius: "30px",
    }
  },
  overlay: {
    border: "20px solid #ececec",
    borderRadius: "70px",
    top: "-21px",
    left: "-20px",
  },
  Bgrey: {
    backgroundColor: "#ececec"
  },
  link: {
    textDecoration: 'none'
  },
  phoneNumberContainer: {
    "& .PhoneInputInput": {
      border: 0,
      borderBottom: "1px solid #949494",
      fontFamily: "Poppins-Regular",
      fontSize: "18px",
      color: "rgb(125, 124, 132)",
      letterSpacing: "0.15008px"
    },
    "& .PhoneInputCountrySelectArrow": {
      display: "none",
    }
  },
  appBar: {
    backgroundColor: '#FFF',
    zIndex: theme.zIndex.drawer + 1
  },
  carousel: {
    "& button": {
      backgroundColor: '#FFF',
    },

    "& .rec-arrow": {
      [theme.breakpoints.down('sm')]: {
        display: "none"
      }
    },

    "& h5": {
      height: "15%",
      [theme.breakpoints.down('sm')]: {
        height: "auto",
      },
    }
  },

  cardWrapper: {
    marginRight: "2rem",

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },

  cardContent: {
    height: "30rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      height: "30rem",
    },
  },

  videos: {
    [theme.breakpoints.down('sm')]: {
      padding: "12px 34px !important",
    }
  },

  emptyDiv: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    }
  }
});

// convert HTML Raw to JSX
export function createMarkup(rawHtml: string) {
  return {
    __html: rawHtml
  };
};

const breakPoints = [
  { width: 600, itemsToShow: 1 },
  { width: 900, itemsToShow: 2 },
  { width: 1000, itemsToShow: 3 },
];

// Customizable Area End
class LandingPage2 extends LandingPage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.doPageContent("Home Page Intro Video");
    this.doPageContent("Challenges facing Employers and Professionals");
    this.getBannerContent()
  }

  // Customizable Area End

  render() {
    const { classes } = this.props;
    const bannerContent = this.state.bannerContent;

    return (
      <>
        <Box>
          <Box
            display="flex"
            className={classes.banner}
            style={{ backgroundImage: `url(${bannerContent.image})`, backgroundSize: "cover", backgroundPosition: "center" }}
          >
            <Box flex={1} className={classes.bannerContainer}>
              <Typography className={classes.textWhite} variant="h2">
                {bannerContent.title}
              </Typography>
              <Box mt={3}>
                <Typography className={`${classes.textWhite} description`} variant="subtitle1">
                  <div dangerouslySetInnerHTML={createMarkup(bannerContent.description)} />
                </Typography>
              </Box>
              <Box mt={8}>
                <Link className={classes.link} to="/about-us">
                  <Button variant="outlined" color="primary" className={classes.button}>
                    <Typography className={classes.btnTxt} variant="body2">
                      Learn More
                    </Typography>
                  </Button>
                </Link>
              </Box>
            </Box>
            <Box flex={1} className={classes.emptyDiv}></Box>
          </Box>

          {/* login area */}
          <Box py={7}>
            <Container>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <Box alignItems="center" display="flex" flexDirection="column">
                    <img width="72px" src={require('../assets/client_login.png')} alt="" />
                    <Box my={3}>
                      <Typography color="primary" variant="h5">Recruiter Login</Typography>
                    </Box>
                    <Link className={classes.link} to="/login?type=client">
                      <Button disableRipple variant="contained" color="primary">
                        <Typography className={classes.btnTxt} variant="body2">Login</Typography>
                      </Button>
                    </Link>
                  </Box>
                </Grid>
                <Grid item sm={2} className={classes.separatorBox}>
                  <Box className={classes.separator}></Box>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.candidateArea}>
                  <Box alignItems="center" display="flex" flexDirection="column">
                    <img width="72px" src={require('../assets/candidate_login.png')} alt="" />
                    <Box my={3}>
                      <Typography color="primary" variant="h5">Candidate Login</Typography>
                    </Box>
                    <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-around">
                      <Box mr={2}>
                        <Link className={classes.link} to="/login?type=candidate">
                          <Button disableRipple variant="contained" color="primary">
                            <Typography className={classes.btnTxt} variant="body2">Login</Typography>
                          </Button>
                        </Link>
                      </Box>
                      <Link className={classes.link} to="/signup">
                        <Button disableRipple variant="contained" color="secondary">
                          <Typography className={classes.btnTxt} variant="body2">
                            Sign Up
                          </Typography>
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/* request demo buttom container */}
          <Box py={7} className={classes.Bgrey}>
            <Container>
              <Box display="flex" justifyContent="right" className={classes.demo}>
                <Button
                  onClick={() => this.setState({ open: true })}
                  disableRipple
                  variant="contained"
                  color="secondary"
                >
                  <Typography variant="body2">Request for Demo</Typography>
                </Button>
              </Box>
            </Container>
          </Box>
          {this.state.open && (
            //@ts-ignore
            <RequestDemoModal showRequestDemoModal={this.state.open} handleCloseRequestDemoModal={this.handleClose} sendRequestForDemoData={this.setRequestForDemo} successDemoCall={this.handleOpenSuccessModel} />
          )}
          {this.state.openSuccesModel && (
            //@ts-ignore
            <RequestDemoSuccessModalWeb showRequestDemoSuccessModal={this.state.openSuccesModel} handleCloseRequestDemoSuccessModal={this.handleCloseSuccessModel} />
          )}

          {/* videos */}
          < Box py={7} className={classes.Bgrey} >
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} className={classes.videos}>
                  <Box style={{ position: "relative" }}>
                    <Box className={classes.overlay} width="100%" height="100%" style={{ position: "absolute", zIndex: 100 }} display={this.state.overlay1 ? "flex" : "none"} justifyContent="center" alignItems="center">
                      <Button onClick={() => { this.setState({ overlay1: false, showControls1: true }); this.playVideo(this.video1()) }}>
                        <img className={classes.playIcon} src={require('../assets/play.png')} alt="" />
                      </Button>
                    </Box>
                    <ReactPlayer className={classes.player} ref={this.video1()} controls={this.state.showControls1} width="100%" height="17em" url={this.state.videoContent[0] && this.state.videoContent[0]?.video_url ? this.state.videoContent[0].video_url : require('../assets/video1.mp4')} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.videos}>
                  <Box style={{ position: "relative" }}>
                    <Box className={classes.overlay} width="100%" height="100%" style={{ position: "absolute", zIndex: 100 }} display={this.state.overlay2 ? "flex" : "none"} justifyContent="center" alignItems="center">
                      <Button onClick={() => { this.setState({ overlay2: false, showControls2: true }); this.playVideo(this.video2()) }}>
                        <img className={classes.playIcon} src={require('../assets/play.png')} alt="" />
                      </Button>
                    </Box>
                    <ReactPlayer className={classes.player} ref={this.video2()} controls={this.state.showControls2} width="100%" height="17em" url={this.state.videoContent[1] && this.state.videoContent[1]?.video_url ? this.state.videoContent[1].video_url : require('../assets/video2.mp4')} />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box >

          {/* challenges */}
          < Box py={7} className={classes.Bgrey} >
            <Container maxWidth="lg">
              <Box my={5}>
                <Typography color="primary" align="center" variant="h4">Challenges Facing Employers and Professionals</Typography>
              </Box>
              <Carousel breakPoints={breakPoints} className={classes.carousel}>
                {
                  this.state.challengesContent?.map((data: any, index: number) => (
                    <Card variant="outlined" className={classes.cardWrapper}>
                      <CardContent className={classes.cardContent}>
                        <img className={classes.featureIcons} width="72px" src={this.state.challegeImages?.[index % 2]} />
                        <Typography align="center" color="primary" variant="h5">
                          {data?.headline}
                        </Typography>
                        <Typography align="center" className={classes.featureTxt} color="primary" variant="h6">
                          <div dangerouslySetInnerHTML={createMarkup(data?.synopsis)} />
                        </Typography>
                        <Box mb={1} display="flex" justifyContent="center">
                          <Button color="primary">
                            <Typography align="center" color="primary" variant="body2">
                              <Typography component={'span'} style={{ textDecoration: "none", color: "#24325f", fontWeight: "bold", }} onClick={() => {
                                //@ts-ignore
                                this.props.history.push({ pathname: "/blog", state: { contentData: data } })
                              }}>Read More</Typography>
                            </Typography>
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  ))
                }
              </Carousel>
            </Container>
          </Box >

          {/* testimonials */}
          < Box display="none" py={8} className={classes.testimonialsContainer} >
            <Container>
              <Box mb={5}>
                <Typography color="primary" align="center" variant="h4">Why Our Clients Love Xcelyst</Typography>
              </Box>
              <Box>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <Box p={3}>
                          <Typography variant="h6" color="textSecondary" className={classes.clientTxt}>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Soluta, officiis maxime! Nihil consequuntur praesentium quia ea! Molestiae totam cum laudantium ad mollitia vero inventore, cumque, accusantium incidunt asperiores aperiam iste.
                          </Typography>
                          <Box display="flex" justifyContent="space-between" mt={2}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                              <img width="64px" src={require('../assets/quote.png')} />
                              <Box ml={2}>
                                <Typography color="primary" variant="h4" className={classes.clientName}>Daniel Ricciardo</Typography>
                                <Typography color="primary" variant="body2" className={classes.clientDes}>CEO, Sportigo</Typography>
                              </Box>
                            </Box>
                            <Box>
                              <img width="64px" src={require('../assets/quote.png')} />
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <Box p={3}>
                          <Typography variant="h6" color="textSecondary" className={classes.clientTxt}>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Soluta, officiis maxime! Nihil consequuntur praesentium quia ea! Molestiae totam cum laudantium ad mollitia vero inventore, cumque, accusantium incidunt asperiores aperiam iste.
                          </Typography>
                          <Box display="flex" justifyContent="space-between" mt={2}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                              <img width="64px" src={require('../assets/quote.png')} />
                              <Box ml={2}>
                                <Typography color="primary" variant="h4" className={classes.clientName}>Daniel Ricciardo</Typography>
                                <Typography color="primary" variant="body2" className={classes.clientDes}>CEO, Sportigo</Typography>
                              </Box>
                            </Box>
                            <Box>
                              <img width="64px" src={require('../assets/quote.png')} />
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box >
        </Box >
        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

// Customizable Area Start
export default withStyles(muiStyles)(withRouter(LandingPage2));
// Customizable Area End
