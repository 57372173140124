import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Tooltip, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";

interface FormikInitialValues {
    selectedITSkill: any;
    others: string;
}
import RoleMangementController, { Props } from "./RoleMangementController.web";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class StepIIJD extends RoleMangementController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const initialValues: FormikInitialValues = { selectedITSkill: this.props.fieldData?.selectedITSkill || {}, others: this.props.fieldData?.others || "" };
        return (
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape(
                    this.state.buyerSchemaArray[this.props.currentStep]
                )}
                onSubmit={(values) => {
                    this.props.setJDValues(values);
                    this.props.nextStep();
                }}
                enableReinitialize
            >
                {(formikProps) => (

                    <form onSubmit={formikProps.handleSubmit} className="job-desc-form">
                        <div className="form-data-wrap">
                            <div className="job-descriptio-step">
                                <h2 className="job-description-title">
                                    {this.props.jdHeadingValue} Job Description - Skills
                                </h2>

                                {/* Rows */}
                                <Box sx={{ flexGrow: 1 }} mt={2}>
                                    <Grid container spacing={3}>
                                        {this.props.jobDescriptionData?.itSkills?.map((item: any) => item?.skilles?.length > 0 && (
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={item?.category_id}>
                                                <div className="jd-card">
                                                    <h5 className="jd-card-title">{item?.category_name}</h5>
                                                    <div className="jd-checkbox-wrap">
                                                        <FormGroup>
                                                            {item?.skilles?.map((skill: any) => (
                                                                <FormControlLabel key={skill?.id} control={
                                                                    <Checkbox
                                                                        name={`selectedITSkill[${item?.category_name}]`}
                                                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                                                        checked={formikProps.values?.selectedITSkill?.[item?.category_name]?.length > 0 ? formikProps.values?.selectedITSkill?.[item?.category_name]?.includes(skill.id) : false}
                                                                        onChange={(event) => {
                                                                            let data = [];
                                                                            if (formikProps.values?.selectedITSkill?.[item?.category_name]?.length > 0) {
                                                                                // data = [...formikProps.values?.selectedITSkill?.[item?.category_name], skill?.id];
                                                                                if (formikProps.values?.selectedITSkill?.[item?.category_name]?.includes(skill.id)) {
                                                                                    data = formikProps.values?.selectedITSkill?.[item?.category_name].filter((ele: any) => ele != skill.id);
                                                                                } else {
                                                                                    data = [...formikProps.values.selectedITSkill[item?.category_name], skill.id];
                                                                                }
                                                                            } else {
                                                                                data = [skill.id]
                                                                            }
                                                                            formikProps.setFieldValue(`selectedITSkill[${item?.category_name}]`, [...data]);
                                                                        }}
                                                                    />} label={skill?.name} />
                                                            ))}
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>

                                {/* Rows */}
                                <Box sx={{ flexGrow: 1 }} mt={2}>
                                    <Box className="jd-others-data">
                                        <FormLabel className="job-desc-textLabel">Others</FormLabel>
                                        <Tooltip title="Please enter the other skills as comma seperated value">
                                            <InfoOutlined /></Tooltip>
                                    </Box>
                                    <TextField
                                        style={{ width: "100%" }}
                                        className="job-desc-textField"
                                        id="others"
                                        name="others"
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        value={formikProps.values.others}
                                        error={formikProps.touched.others && Boolean(formikProps.errors.others)}
                                        helperText={formikProps.touched.others && formikProps.errors.others}
                                        placeholder="Enter Other Skills"
                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                    />
                                </Box>

                                {/* Buttons */}

                                <Box justifyContent="center" display="flex" mt={5}>
                                    <Button
                                        type="button" className="fullBtnCancel" disableRipple variant="contained" color="secondary" onClick={() => this.props.previousStep(formikProps.values)}>
                                        <Typography variant="body2">
                                            Previous
                                        </Typography>
                                    </Button>
                                    <Button
                                        type="submit" className="fullBtnSubmit" disableRipple variant="contained" color="primary">
                                        <Typography variant="body2">
                                            Continue
                                        </Typography>
                                    </Button>
                                </Box>
                            </div>
                        </div>
                    </form>)
                }
            </Formik>);
    }
}

export default StepIIJD;