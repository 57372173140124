Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ActivityLog";
exports.labelBodyText = "ActivityLog Body";

exports.btnExampleTitle = "CLICK ME";
exports.GetAboutContent = "bx_block_aboutpage/aboutpage";
exports.getAPIMethod = "GET";
exports.jsonApiContentType = "application/json";
exports.members = [
  {
    id: 1,
    name: "Ashutosh Gupta",
    position: "Co-founder & CEO",
    type: "management",
    bio: ["Prior to joining Xcelyst, Ashutosh Gupta worked at Evalueserve, a Swiss Research and Analytics firm with over 4,000 global employees, where he was co-CEO and global head of the Financial Services Business Unit. Mr. Gupta led the digital transformation of the company’s solutions portfolio by building several technology products and platforms. Mr. Gupta, who joined the company in 2006, initially set up Financial Services teams in China, Chile, Romania, and the U.S. and grew annual revenues from a very small base to more than 50% of Group revenues.", "Before joining Evalueserve, Mr. Gupta worked at Goldman Sachs for 6 years in New York and London in the equities division and the new products group. He has also worked at an engineering consulting firm in Pennsylvania. Mr. Gupta holds a bachelor’s degree in chemical engineering from the Indian Institute of Technology Delhi and an MBA from NYU Stern School of Business."]
  },
  {
    id: 2,
    name: "Ritesh Agarwal",
    position: "Co-founder & Chairman",
    type: "management",
    bio: ["Ritesh is a seasoned sales professional with 17+ years’ experience in financial services industry. Prior to Xcelyst, he has worked with CRISIL (S&P Global subsidiary) as Sales Director, Head of Derivatives Business and UK Centre Head. He was also instrumental in setting up the Risk & Analytics practice.", "Ritesh has worked with several investment & corporate banks to deliver cutting edge solutions in the areas of Risk Management, Front Office, Finance and Global Markets Technology.  He is well connected with CXOs and key decision makers in the financial services industry.", "Ritesh is a Certified Financial Analyst and MBA."]
  },
  {
    id: 3,
    name: "Namita Akhauri",
    position: "Co-founder & CHRO",
    type: "management",
    bio: ["Namita is a seasoned Human Resources & Recruitment professional with 10+ years of experience across industries. She has worked as an HR Partner with Morpheus Human Consulting, Recruitment Lead with Accenture Consulting, HR Lead with Recruitment SMART and HR Manager with Idea Cellular Limited (now Vodafone Idea Limited). During her stint with Accenture consulting, she was instrumental in setting up the offshore Financial Services team from scratch and held leadership responsibilities for recruiting 200+ specialist talent for Risk management, Capital Markets, Shared Services, Banking & Insurance. In her previous stint she has also helped develop & market an HR Analytics product using Artificial Intelligence & Machine Learning in recruitment.", "Namita has completed her Intermediate Diploma in Human Resource Management from the Chartered Institute of Personnel and Development (CIPD) UK. She has also completed an MBA in Human Resources & Marketing from IILM, New Delhi and a Political Science (Hons.) from Delhi University."]
  },
  {
    id: 4,
    name: "Tarun Lala",
    position: "Chief Technology Officer",
    type: "management",
    bio: ["Tarun is an Electrical Engineer from Northeastern University, Boston and a Stanford entrepreneur, Tarun Lala has been a serial entrepreneur from the get-go. On the sidelines of working with EMC as a Technology Consultant, he embarked on his first venture, Yes Mastr – a delivery services consumer app. Tarun was behind the world’s first airport networking lounge which was set up at Bengaluru International airport.", "He is also the Founder of Levo Labz - a company that builds Software across the IT stack for startups, SMEs, Enterprises, banks, healthcare, aviation and more.", "Tarun is a huge sports fan, particularly Basketball, he is also the proud coowner of Southside Warriors, a 7-member team in the Street Ball League – a first-of-its-kind 3on3 basketball tournament, as well as a Golf Team - Fanzart Eagles!"]
  },
  {
    id: 5,
    name: "Tito Singh",
    type: "advisory",
    bio: ["Tito Singh is a sales driven revenue intrapreneur and thought leader, who has achieved remarkable revenue growth in the financial services industry. He has dedicated more than 20 years of his career to technology and financial services where he held significant leadership positions overseeing high-performing sales organizations through critical transformation and new business channel development. Most recently, he was Global Head of Sales at CRISIL, a leading analytics provider to the financial services industry. Prior to that, he spent 25 years in several sales and leadership roles at Refinitiv, formerly Thomson Reuters. His last position was Head of Financial & Risk Solution Sales, leading a solutions sales team for the Americas with responsibility for its go-to-market sales strategy, market development, and operations functions. In this role, he built out new revenue channels and oversaw $150-plus million in gross new sales yearly.", "Currently the Chief Revenue Officer at InvestCloud Financial Supermarket (formerly Tegra118), Tito is known as an energetic P&L leader who delivers revenue growth, valuation growth, increased customer happiness and nurtures deep connection with global financial services. Tito has completed his BS Quantitative Analysis and MBA in Finance from St. John's University, The Peter J. Tobin College of Business, New York."]
  },
  {
    id: 6,
    name: "Mani Gangadharan Venkatchalam",
    type: "advisory",
    bio: ["Mani is a serial intrapreneur and scaleup expert with 30 years’ experience in building and growing B2B recurring revenues businesses in data, analytics, research, risk management and information services. He is passionate about productising IP and monetising data. He has worked as CEO of Moody’s Analytics Knowledge Services and President Global Research & Analytics at Standard & Poor’s subsidiary (CRISIL) and more recently, built a Regtech business ground up, serving operational risk standards to global banks and asset managers. Presently, Mani works as a board advisor, helping founders convert their ideas into scalable businesses and helping firms accelerate their revenue growth. Mani is a ranked Chartered Accountant and holds a first-class Bachelor’s degree in Commerce from Shri Ram College of Commerce, New Delhi."]
  }
]
// Customizable Area End