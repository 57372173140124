import React from "react";
import { Box, Container, Dialog, DialogContent, Grid, IconButton, Typography, Theme } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import ActivityLogController from "./ActivityLogController";
import _ from "lodash";
import clsx from 'clsx';
import { createMarkup } from "../../LandingPage2/src/LandingPage2.web";
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: 24,
            fontWeight: 700,
            fontFamily: "Poppins-Regular"
        },
        h2: {
            fontWeight: 700,
            fontSize: 25,
            color: "black",
            fontFamily: "Poppins-Medium"
        },
        h3: {
            fontWeight: 400,
            fontSize: 18,
            color: "#44434a",
            fontFamily: "Roboto-Regular",
        },
        subtitle1: {
            fontFamily: "Poppins-Regular"
        }
    }
});

const muiStyles = (theme: Theme) => ({
    contentBox: {
        maxWidth: "90%",

        ['@media (min-width: 600px)']: {
            padding: "0 .7rem",
        }
    },

    content: {
        textIndent: "4rem",
        lineHeight: "2rem",
        fontSize: 15,
    },

    contentGrid: {
        "flex-direction": "row",
        [theme.breakpoints.down('sm')]: {
            "flex-direction": "column-reverse"
        }
    },

    memberCard: {
        backgroundColor: theme.palette.primary.main,
        height: 110,
        borderRadius: 20,
        cursor: "pointer",
        wordBreak: "break-all",
        [theme.breakpoints.down('sm')]: {
            width: 307,
        },

        "& .details": {
            [theme.breakpoints.down('sm')]: {
                padding: "1rem"
            },

            "& h5": {
                wordBreak: "break-word",
                color: "#fff",
            },

            "& span": {
                color: "#fff",
            }
        }
    },

    memberImage: {
        border: `5px solid ${theme.palette.secondary.main}`,
        top: -60,
        right: -50,
        borderRadius: 20,
        [theme.breakpoints.down('lg')]: {
            width: 130,
            top: -30,
            right: -30
        },
        [theme.breakpoints.down('sm')]: {
            width: 90,
            right: -21
        },
    },

    dialogImage: {
        borderRadius: 10,
        backgroundSize: "cover",
        backgroundPosition: "center",
        margin: "0 auto",
        [theme.breakpoints.down('lg')]: {
            width: "100px",
            height: "100px",
            top: "-30px",
        },
    },

    memberName: {
        padding: 2,
        [theme.breakpoints.down('lg')]: {
            fontSize: "17px",
        },
    },

    memberPosition: {
        padding: 2,
        [theme.breakpoints.down('lg')]: {
            fontSize: "0.7rem",
        },
    },

    memberGrid: {
        marginTop: "5rem !important",
        [theme.breakpoints.down('lg')]: {
            marginTop: "10px !important",
        },

        "& > div": {
            [theme.breakpoints.down('sm')]: {
                flexDirection: "column",

                "& > div": {
                    marginLeft: "1.5rem",
                }
            },
        }
    },

    advisroryMemberGrid: {
        marginTop: 80,
        [theme.breakpoints.down('lg')]: {
            marginTop: 10,
        },
        marginBottom: 145,

        "& > div": {
            [theme.breakpoints.down('sm')]: {
                flexDirection: "column",

                "& > div": {
                    marginLeft: "1.5rem",
                }
            },
        }
    },

    memberDialog: {
        padding: "40px!important",

        '& button': {
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            }
        }
    },

    details: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },

        "& h2": {
            [theme.breakpoints.down('sm')]: {
                marginTop: "1.5rem",
                fontSize: "1.5rem",
            },
        },

        "& h3": {
            [theme.breakpoints.down('sm')]: {
                fontSize: "1rem",
            },
        },

        "& .links": {
            [theme.breakpoints.down('sm')]: {
                marginTop: "1rem",
            },

            "& img": {
                [theme.breakpoints.down('sm')]: {
                    width: "25px",
                }
            }
        }
    },

    memberDetails: {
        [theme.breakpoints.down('sm')]: {
            marginTop: "2rem",
        },
    },

    mobileBoardView: {
        [theme.breakpoints.down('md')]: {
            maxWidth: "50%",
            flexBasis: "50%",
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "0 !important",
        }
    }
});

class Teams extends ActivityLogController {

    // everytime starts the page, from top
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getAboutContent();
    }

    // Customize Area Start
    getMembers = () => {
        const { classes } = this.props;
        let members: any = [];
        _.forEach(_.filter(this.state.aboutContent?.management_team), (member: any, index: number) => {
            members.push(
                <Grid item xs={12} sm={6} md={4} key={index} className={classes.mobileBoardView}>
                    <Box
                        onClick={() => this.setState({ openMemberId: member.id })}
                        mt={5} mb={5}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className={classes.memberCard}
                    >
                        <Box flex="1">
                            <Box>
                                <img style={{ position: 'relative' }} className={classes.memberImage} width={150} src={member.image} alt="" />
                            </Box>
                        </Box>
                        <Box flex="1" className="details">
                            <Box display="flex" flexDirection="column" justifyContent="center">
                                <Typography variant="h5" className={clsx(classes.textWhite, classes.memberName)}>
                                    {member.name}
                                </Typography>
                                <Typography variant="overline" className={clsx(classes.textWhite, classes.memberPosition)}>
                                    {member.position}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {this.memberDialog(member)}
                </Grid>
            )
        })
        return members;
    }

    getAdvisryMembers = () => {
        const { classes } = this.props;
        let members: any = [];
        _.forEach(_.filter(this.state.aboutContent?.advisory_board), (member: any, index: number) => {
            members.push(
                <Grid item xs={12} sm={6} md={4} key={index} className={classes.mobileBoardView}>
                    <Box
                        onClick={() => this.setState({ openMemberId: member.id })}
                        mt={5} mb={5}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        className={classes.memberCard}>
                        <Box flex="1">
                            <Box>
                                <img style={{ position: 'relative' }} className={classes.memberImage} width={150} src={member.image} alt="" />
                            </Box>
                        </Box>
                        <Box flex="1" className="details">
                            <Box display="flex" flexDirection="column" justifyContent="center">
                                <Typography variant="h5" className={clsx(classes.textWhite, classes.memberName)}>
                                    {member.name}
                                </Typography>
                                <Typography variant="overline" className={clsx(classes.textWhite, classes.memberPosition)}>
                                    {member.position}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {this.adVisoryMemberDialog(member)}
                </Grid>
            )
        })
        return members;
    }


    // get opened member id(from state)'s bio

    getMemberBio(id: any) {
        const { classes } = this.props;
        const bio: any = _.filter(this.state.aboutContent?.management_team, (member: any) => { if (member.id === id) return true })[0];

        let memberBio: any = [];
        _.forEach(bio.bio, (bio: any, index: number) => {
            memberBio.push(
                <Box mt={3} key={index}>
                    <Typography align="justify" className={classes.bio} color="textPrimary" variant="subtitle1">{bio}</Typography>
                </Box>
            )
        });
        return memberBio;
    }

    getAdvisoryMemberBio(id: any) {
        const { classes } = this.props;
        const bio: any = _.filter(this.state.aboutContent?.advisory_board, (member: any) => { if (member.id === id) return true })[0];

        let memberBio: any = [];
        _.forEach(bio.bio, (bio: any, index: number) => {
            memberBio.push(
                <Box mt={3} key={index}>
                    <Typography align="justify" className={classes.bio} color="textPrimary" variant="subtitle1">{bio}</Typography>
                </Box>
            )
        });
        return memberBio;
    }


    // function to return member dialog
    memberDialog = (member: any) => {
        const { classes } = this.props;
        const bio = _.filter(this.state.aboutContent?.management_team, (member: any) => { if (member.id == this.state.openMemberId) return true });
        return (
            <ThemeProvider theme={theme}>
                <Dialog
                    maxWidth="md"
                    open={member.id == this.state.openMemberId ? true : false}
                    BackdropProps={{ style: { opacity: "0.9" } }}
                >
                    <DialogContent className={classes.memberDialog}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                            <Box display="flex" flexDirection="row" className={classes.details}>
                                <Box display="flex" flexDirection="column" mr={4}>
                                    <img width={120} src={member.image} alt={member.name} />
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="space-between">
                                    <Box>
                                        <Typography variant="h2" >
                                            {member.name}
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography variant="h3" >
                                                {member.position}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" mt={4} className="links">
                                            {member.facebook_link &&
                                                <a href={`https://${member.facebook_link}`} target="_blank">
                                                    <img width="32px" src={require('../assets/fb.png')} alt="" />
                                                </a>
                                            }
                                            {member.linkedin_link &&
                                                <a href={`https://${member.linkedin_link}`} target="_blank">
                                                    <img width="32px" src={require('../assets/in.png')} alt="" />
                                                </a>
                                            }
                                            {member.twitter_link &&
                                                <a href={`https://${member.twitter_link}`} target="_blank">
                                                    <img width="32px" src={require('../assets/twitter.png')} alt="" />
                                                </a>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <IconButton onClick={() => this.setState({ openMemberId: 0 })} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box mt={8} className={classes.memberDetails}>
                            <Typography variant="h3" >
                                <div dangerouslySetInnerHTML={createMarkup(member.description)} />
                            </Typography>
                        </Box>
                        <Box mt={5}>
                            {this.getMemberBio(member.id)}
                        </Box>
                    </DialogContent>
                </Dialog>
            </ThemeProvider>
        )
    }

    // function to return Advisory member dialog
    adVisoryMemberDialog = (member: any) => {
        const { classes } = this.props;
        const bio = _.filter(this.state.aboutContent?.advisory_board, (member: any) => { if (member.id == this.state.openMemberId) return true });
        return (
            <ThemeProvider theme={theme}>
                <Dialog
                    maxWidth="md"
                    open={member.id == this.state.openMemberId ? true : false}
                    BackdropProps={{ style: { opacity: "0.9" } }}
                >
                    <DialogContent className={classes.memberDialog}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
                            <Box display="flex" flexDirection="row" className={classes.details}>
                                <Box display="flex" flexDirection="column" mr={4}>
                                    <img width={120} src={member.image} alt={member.name} />
                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="space-between">
                                    <Box>
                                        <Typography variant="h2" >
                                            {member.name}
                                        </Typography>
                                        <Box mt={1}>
                                            <Typography variant="h3" >
                                                {member.position}
                                            </Typography>
                                            <Box display="flex" mt={4} className="links">
                                                {member.facebook_link !== "" &&
                                                    <a href={`https://${member.facebook_link}`} target="_blank">
                                                        <img width="32px" src={require('../assets/fb.png')} alt="" />
                                                    </a>}
                                                {member.linkedin_link &&
                                                    <a href={`https://${member.linkedin_link}`} target="_blank">
                                                        <img width="32px" src={require('../assets/in.png')} alt="" />
                                                    </a>
                                                }
                                                {member.twitter_link &&
                                                    <a href={`https://${member.twitter_link}`} target="_blank">
                                                        <img width="32px" src={require('../assets/twitter.png')} alt="" />
                                                    </a>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <IconButton onClick={() => this.setState({ openMemberId: 0 })} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box mt={8} className={classes.memberDetails}>
                            <Typography variant="h3" >
                                <div dangerouslySetInnerHTML={createMarkup(member.description)} />
                            </Typography>
                        </Box>
                        <Box mt={5}>
                            {this.getAdvisoryMemberBio(member.id)}
                        </Box>
                    </DialogContent>
                </Dialog>
            </ThemeProvider>
        )
    }
    // Customize Area End

    render() {
        const { classes } = this.props;

        return (
            <Box>
                {/* management */}
                <Container className={classes.contentBox} >
                    <Box mt={8}>
                        <Typography align="center" color="primary" variant="h4" style={{ paddingBottom: "30px" }}>Management Team</Typography>
                    </Box>
                    <Box className={classes.memberGrid}>
                        <Grid container spacing={5}>
                            {this.getMembers()}
                        </Grid>
                    </Box>
                </Container >

                {/* advisory */}
                <Container className={classes.contentBox} >
                    <Box mt={8}>
                        <Typography align="center" color="primary" variant="h4" style={{ paddingBottom: "30px" }}>Advisory Board</Typography>
                    </Box>
                    <Box className={classes.memberGrid} mt={16}>
                        <Grid container spacing={5}>
                            {this.getAdvisryMembers()}
                        </Grid>
                    </Box>
                </Container>
            </Box>
        );
    }
}

// @ts-ignore
export default withStyles(muiStyles)(Teams);