//@ts-nocheck
import React, { useState } from "react";
import { Box, Menu, MenuItem, Typography, makeStyles, } from "@material-ui/core";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import VisitorMenu from "./TopNav_Visitor";
import RecruiterMenu from "./TopNav_Recruiter";
import CandidateMenu from "./TopNav_Candidate";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Contact from "../../blocks/LandingPage2/src/Contact.web";

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: 2, background: "#fff",
    padding: "0 24px",

    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileLinks: {
    textDecoration: "none",
    color: "#24325f",
  },
  boxShadow: {
    boxShadow: "0 2px 2px #ccc",
    background: "#fff"
  },
  stickHeader: {
    position: "fixed",
    width: "97%",
    zIndex: 1
  },
  menuIcon: {
    cursor: "pointer",
  },
  mobileActiveLink: {
    fontWeight: "bold",
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      width: "7rem",
      margin: ".5rem 0",
    }
  }
}));

type UserType = "visitor" | "recruiter" | "candidate";
export const clientRoutes = ["notifications", "dashboard", "createRole", "open-roles", "closed-roles", "candidate-scoring", "assessment-detail", "email-notifications", "candidate-details", "job-detail", "editRole", "clients-list"]
export const candidateRoutes = ['profile-setting', 'job-listing', "job-detail"]

export const NavData = [
  { route: "Home", path: "" },
  { route: "About Us", path: "/about-us" },
  { route: "Verticals", path: "/verticals" },
  { route: "Database", path: "/database" },
  { route: "Team", path: "/team" },
]

const TopNav = ({ history }: RouteComponentProps) => {
  const location = history.location.pathname.split("/")[1];
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [userType, setUsertype] = useState<UserType>("visitor");
  const userRole = sessionStorage.getItem("user_role");
  const [isModalOpen, setModal] = useState(false);

  const toggleModal = () => {
    setModal(prev => !prev);
  }

  // is Mobile View
  const [width, setWidth] = useState<number>(window.innerWidth);
  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    window.location.search.includes("?action=contact-us") && (
      setModal(true)
    );

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 960;

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    if (location == 'job-detail') {
      if (userRole == "candidate") {
        setUsertype("candidate");
      } else if (userRole == "client") {
        setUsertype("recruiter");
      }
    } else if (candidateRoutes.includes(location)) {
      setUsertype("candidate");
    } else if (clientRoutes.includes(location)) {
      setUsertype("recruiter");
    } else {
      setUsertype("visitor");
    }
  }, [location]);

  // dynamiclly change the link based on the user type
  const linkTo = location === "whatsapp" ? "" :
    location === "" || location === 'login' ? "/" : userRole === "admin" ? "/clients-list" : userType === "recruiter" ? "/dashboard" : "/profile-setting";

  const RenderMobileMenu = (
    <Menu
      onClick={handleMobileMenuClose}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {NavData.map((item) => (
        <Link key={item.path} to={item.path} className={classes.mobileLinks}>
          <MenuItem>
            <Typography className={item.path == location ? classes.mobileActiveLink : null}>{item.route}</Typography>
          </MenuItem>
        </Link>
      ))}

      <Link to="#" onClick={toggleModal} className={classes.mobileLinks}>
        <MenuItem>
          <Typography>Contact Us</Typography>
        </MenuItem>
      </Link>
    </Menu>
  );

  return (
    <>
      {/* contact Modal */}
      <Contact
        open={isModalOpen}
        onClose={toggleModal}
        sendRequestForDemoData={() => { }}
      />

      <Box
        display="flex" flexDirection="row" justifyContent="space-between"
        alignItems="center"
        className={`${classes.header} 
          ${userType !== "visitor" ? classes.boxShadow : ""} 
          ${userType === "recruiter" ? classes.stickHeader : ""}`
        }
      >
        {RenderMobileMenu}
        <Box>
          <Link to={linkTo}>
            <img className={classes.logo} width="130px" src={require("./xcelyst_logo.png")} />
          </Link>
        </Box>
        {userType === "visitor" && <VisitorMenu pathName={location} toggleModal={toggleModal} />}
        {userType === "recruiter" && <RecruiterMenu history={history} />}
        {userType === "candidate" && <CandidateMenu history={history} isMobile={isMobile} />}
        {userType === "visitor" && isMobile && <MoreVertIcon className={classes.menuIcon} onClick={handleMobileMenuOpen} />}
      </Box>
    </>
  );
};

export default withRouter(TopNav) as TopNav;
