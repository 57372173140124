import React from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import CloseRoleController, { Props } from './CloseRoleController.web';
import ClosedRoleList from '../../../components/src/ClosedRolesList';
import { Link } from "react-router-dom";
import CloseRoleListItem from './CloseRoleListItem.web';

class CloseRoleListView extends CloseRoleController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                {this.props.jobRoles &&
                    this.props?.jobRoles?.map((role: any, index: number) => {
                        return (
                            this.props.jobRoles?.length === index + 1 ?
                                <div style={{ marginBottom: "1em" }} key={index} ref={this.lastCloseRoleItem}>
                                    <CloseRoleListItem
                                        role={role}
                                        classes={classes}
                                        isExpand={this.props?.isExpand}
                                        history={this.props.history}
                                        handleViewAll={(id: number | undefined) => this.props.handleViewAll(id)}
                                        hasMoreCandidateData={this.props.hasMoreCandidateData}
                                        infiniteCandidateLoading={this.props.infiniteCandidateLoading}
                                        candidateListScroll={this.props.candidateListScroll}
                                        createConversation={this.createCoversation}
                                        startLoading={this.props.startLoading}
                                        clientIDLink={this.props.clientIDLink}
                                    />
                                </div> :
                                <div style={{ marginBottom: "1em" }} key={index}>
                                    <CloseRoleListItem
                                        role={role}
                                        classes={classes}
                                        isExpand={this.props?.isExpand}
                                        history={this.props.history}
                                        handleViewAll={(id: number | undefined) => this.props.handleViewAll(id)}
                                        hasMoreCandidateData={this.props.hasMoreCandidateData}
                                        infiniteCandidateLoading={this.props.infiniteCandidateLoading}
                                        createConversation={this.createCoversation}
                                        candidateListScroll={this.props.candidateListScroll}
                                        startLoading={this.props.startLoading}
                                        clientIDLink={this.props.clientIDLink}
                                    />
                                </div>
                        )
                    })}
            </>
        )
    }
}

export default CloseRoleListView;