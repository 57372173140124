import React from "react";

import {
    StyleSheet,
    Platform,
    // Customizable Area Start
    // Customizable Area End
} from "react-native";
// Customizable Area Start

import { Container, Box, Typography, withStyles, Button, Grid, Modal, TextField } from "@material-ui/core";
import { banner, successImage } from './assets';
import { Formik } from "formik";
import LandingPage2Controller, {
    Props,
} from "./LandingPage2Controller.web";
import 'react-phone-number-input/style.css'

const muiStyles = (theme: any) => ({
    textWhite: {
        color: "#ffffff"
    },
    button: {
        color: "#ffffff",
        borderColor: "#ffffff",
        borderRadius: 7,
        padding: "1rem 2rem"
    },
    banner: {
        padding: "1rem 2rem",
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [theme.breakpoints.up('sm')]: {
            padding: "10rem 8rem",
        },
    },
    candidateArea: {
        [theme.breakpoints.down('xs')]: {
            marginTop: "3rem",
        },
    },
    loginBtn: {
        marginRight: "1rem"
    },
    featureIcons: {
        margin: "30px auto",
        display: "flex"
    },
    featureTxt: {
        lineHeight: "1.6rem",
        fontWeight: 400,
        marginTop: "1.2rem",
        marginBottom: "1.2rem"
    },
    testimonialsContainer: {
        backgroundColor: theme.palette.secondary.main + '30'
    },
    clientTxt: {
        color: "#000",
        lineHeight: "1.8rem"
    },
    clientDes: {
        marginTop: "1.1rem",
        fontStyle: "italic",
        fontSize: "0.8rem",
        fontWeight: 500
    },
    clientName: {
        fontSize: "1rem",
    },
    demo: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
        },
    },
    separatorBox: {
        justifyContent: "center",
        display: "flex"
    },
    separator: {
        height: "100%",
        width: 1,
        backgroundColor: theme.palette.secondary.main
    },
    playIcon: {
        width: 64,
        height: 64,
        cursor: 'pointer'
    },
    player: {
        borderRadius: "30px"
    },
    overlay: {
        border: "20px solid #ececec",
        borderRadius: "70px",
        top: "-21px",
        left: "-20px",
    },
    Bgrey: {
        backgroundColor: "#ececec"
    },
    link: {
        textDecoration: 'none'
    },
    phoneNumberContainer: {
        "& .PhoneInputInput": {
            border: 0,
            borderBottom: "1px solid #949494",
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "rgb(125, 124, 132)",
            letterSpacing: "0.15008px"
        }
    },
    successImg: {
        height: "auto",
        width: 100
    },
    successModelContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    SuccessModelmarign: {
        marginTop: "2.2rem",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
    },
    SuccessModelText: {
        color: "#24325f",
        textTransform: "capitalize",
        width: "62%",
        fontWeight: 600
    },
    successModal: {
        top: "50%",
        left: "50%",
        width: "25rem",
        padding: "32px",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        background: "#fff",
        borderRadius: "10px",
    }
});

// Customizable Area End
class RequestDemoSuccessModal extends LandingPage2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    openSuccessModel = () => {
        this.setState({ openSuccesModel: true })
    }

    render() {
        const { classes } = this.props;
        return (
            <>

                <Modal
                    open={(this.props.showRequestDemoSuccessModal)}
                    onClose={this.props.handleCloseRequestDemoSuccessModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className={classes.successModal}>
                        <Container maxWidth="sm">
                            <Grid item xs={12} className={classes.successModelContent}>
                                <img src={successImage} className={classes.successImg} />
                                <div className={classes.SuccessModelmarign}>
                                    <Typography className={classes.SuccessModelText}>
                                        A member of the Team will be in Contact with you
                                    </Typography>
                                </div>
                                <Box flex="1">
                                    <Button
                                        onClick={this.props.handleCloseRequestDemoSuccessModal}
                                        disableRipple
                                        variant="contained"
                                        color="secondary"
                                        className={classes.SuccessModelmarign}
                                    >
                                        <Typography variant="body2">Close</Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </Container>
                    </Box>
                </Modal >
            </>
        );
    }
}

// Customizable Area Start
//@ts-ignore
export default withStyles(muiStyles)(RequestDemoSuccessModal)
// Customizable Area End
