import React, { Fragment } from "react";
import AppSidebar from '../../../components/src/AppSidebar';
import {
  Container, Box, Typography, withStyles, Grid, Theme, TextField, FormControl,
  Select, MenuItem, Divider, Button, InputBase, Snackbar, Tooltip, Modal
} from '@material-ui/core';
import WebChatController, { Props } from "./EmailNotificationsController.web";
// @ts-ignore
import ReplayIcon from '@material-ui/icons/Replay';
import {
  AttachmentRounded, Bookmark, DeleteOutline, EmojiEmotions,
  MoreHorizOutlined, PhotoSharp, ReplyRounded
} from "@material-ui/icons";
import { SearchIcon } from "./assets";
import Messages from "./Messages";
import Picker from 'emoji-picker-react';
import MuiAlert from "@material-ui/lab/Alert";
import { withRouter } from "react-router-dom";
import UploadModal from "./components/UploadModal.web";
import styles from "./Styles.module.scss";
import { arrow } from "../../forgot-password/src/assets";
import DropDownMenu from "./mobile_DropDown";

const dropDownOptions = ["All", "Favourite"];
let count = 0;

class WebChat extends WebChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { classes } = this.props;
    const isClient = sessionStorage.getItem("user_role") === "client" || sessionStorage.getItem("user_role") === "admin";

    return (
      // Customizable Area Start
      <Box className={styles.rootContainer}>

        <Box height='100%'>
          {/* side app-bar */}
          {isClient && <AppSidebar />}

          {/* main container */}
          <div className={isClient ? styles.mainCont : styles.canDidateMainCont}>

            {/* Header start */}
            <Grid className={styles.header} container>
              <Grid item xl={6} lg={6} md={7} sm={6} xs={12}>
                <Typography variant="h5" className={styles.headerT}>Messages</Typography>
              </Grid>
              {isClient && this.state.status === "success" &&
                <Grid item xl={6} lg={6} md={5} sm={6} xs={12} className={styles.searchContainer}>
                  <Box className={styles.search}>
                    <SearchIcon />
                    <TextField
                      variant="filled"
                      placeholder="Search here..."
                      className={styles.inputTextField}
                      InputProps={{ disableUnderline: true }}
                      value={this.state.searchKeyword}
                      onChange={(e) => {
                        count = 0;
                        this.setState({ searchKeyword: e.target.value })
                      }}
                    />
                  </Box>
                </Grid>
              }
            </Grid>
            {/* Header end */}

            {/* Content start || Inner Container */}
            <Grid className={styles.innerContainer}>
              {/* Left Container */}
              {this.state.showMessageList &&
                <Grid className={styles.leftContainer}>
                  {/* header */}
                  <Grid
                    className={styles.leftHeader}
                    style={{ background: isClient ? "#24325f" : "#fff" }}
                    container
                  >
                    <Grid item style={{ flex: .95 }}>
                      <FormControl fullWidth style={{ margin: ".6rem 0" }}>
                        <Select
                          variant="outlined"
                          value={this.state.filters}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          onChange={(e) => {
                            count = 0;
                            this.setState({ filters: e.target.value as "All" | "Favourite" })
                          }}
                          className={`${styles.dropDown} ${!isClient ? "candidate_header" : null}`}
                        >
                          {dropDownOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>{option}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      {this.state.status === "success" && (
                        <Tooltip title="Reset Filters">
                          <ReplayIcon
                            style={{ color: isClient ? "#fff" : "#24325f" }}
                            className={styles.replyIcon}
                            onClick={() => {
                              count = 0;
                              this.resetFilters();
                            }}
                          />
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  {/* Candidates List */}
                  <Grid className={styles.candidateList} >
                    {this.state.conversations && this.state.conversations.map((room: any, index: number) => {
                      const channelName = room.channelState.friendlyName.split("__");
                      const userName = isClient ? channelName[1] : channelName[0];
                      const userEmail = isClient ? room.channelState.uniqueName.split("_")[1] :
                        room.channelState.uniqueName.split("_")[0];

                      if (this.state.searchKeyword.trim().length > 0) {
                        if (!userName.toLowerCase().includes(this.state.searchKeyword.toLowerCase())) {
                          count += 1;
                          return null;
                        }
                      }

                      if ((this.state.filters === "Favourite") &&
                        (!this.state.favouriteChats.find((fav: any) => fav.sid === room.sid))) {
                        count += 1;
                        return null;
                      }

                      const lastMsg = this.state.lastMessages && this.state.lastMessages.find((message) => message.sid === room.sid);

                      return (
                        (
                          <Fragment key={room.sid}>
                            <Grid
                              container
                              className={styles.candidate}
                              style={{ background: this.state.selectedConversation?.sid === room.sid ? "#A8D0E7" : "inherit" }}
                              onClick={() => this.handleSelectConversation(room, index)}
                            >
                              <Grid item>
                                <img
                                  src={this.state.usersData.find((each: any) => each.email === userEmail)?.photo || `https://www.bootdey.com/img/Content/avatar/avatar${(index % 8) + 1}.png`}
                                  alt="avatar" width={45} height={45}
                                />
                              </Grid>
                              <Grid item style={{ flex: 1 }}>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                  <Typography variant="h5">{userName}</Typography>
                                  <Typography variant="subtitle1">{lastMsg?.time}</Typography>
                                </Box>
                                {lastMsg && (
                                  <Typography
                                    // style={{ color: candidate.unread ? "#007AFF" : "#363636", fontWeight: candidate.unread ? "bold" : "normal" }}
                                    variant="body1"
                                  >
                                    {lastMsg?.message?.length > 20 ? `${lastMsg?.message.substring(0, 20)}...` : lastMsg?.message}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                            {
                              index !== this.state.conversations.length - 1 &&
                              <Divider />
                            }
                          </Fragment>
                        )
                      )
                    })}
                    {this.state.status === 'success' &&
                      count === this.state.conversations.length && (
                        <Grid style={{ margin: "2rem 0", textAlign: "center" }}>
                          <Typography variant="h5">No User Found...</Typography>
                        </Grid>
                      )}
                  </Grid>
                </Grid>}

              {/* Right Container */}
              {this.state.showMessageView &&
                <Grid className={styles.rightContainer} onClick={() => this.state.isEmojiOpen && this.setState({ isEmojiOpen: false })}>
                  {/* header */}
                  {this.state.selectedConversation && this.state.selectedConversation.sid && (
                    <>
                      <Box className={styles.rightHeader} display="flex">
                        <Grid item>
                          <Box display="flex" alignItems="center">
                            {this.state.windowWidth < 750 &&
                              <Box onClick={() =>
                                this.setState({ showMessageView: false, showMessageList: true })
                              }>
                                <img src={arrow} alt="arrow" />
                              </Box>
                            }
                            <img src={this.state.chattingWith &&
                              this.state.usersData.find((each: any) => each.email === this.state.chattingWith)?.photo ||
                              `https://www.bootdey.com/img/Content/avatar/avatar${(this.state.selectedChatIndex as number % 8) + 1}.png`
                            } alt="avatar" width={50} height={50} />
                          </Box>
                        </Grid>

                        <Grid item>
                          <Box>
                            <Box display="flex" alignItems="center">
                              <Typography variant="h5">
                                {isClient ?
                                  this.state.selectedConversation.channelState.friendlyName.split("__")[1] :
                                  this.state.selectedConversation.channelState.friendlyName.split("__")[0]
                                }
                              </Typography>
                              <div style={{ background: "#42CD22", width: ".7rem", height: ".7rem" }} />
                            </Box>
                            {this.state.chattingWith && <Typography variant="h6">{this.state.chattingWith}</Typography>}
                          </Box>
                        </Grid>
                        <Grid item className={styles.options}>
                          {this.state.windowWidth >= 900 ?
                            <Box>
                              {this.state.favouriteChats.find((chat) => chat.sid === this.state.selectedConversation.sid) ? (
                                <Tooltip title="Remove from Favourite">
                                  <Bookmark style={{ color: "#f3b23a " }} onClick={this.handleFavorite} />
                                </Tooltip>

                              ) : (
                                <Tooltip title="Add to Favourite">
                                  <Bookmark onClick={this.handleFavorite} />
                                </Tooltip>
                              )}
                              {/* todo replace all of these with figma SVG */}
                              <ReplyRounded style={{ transform: "scaleX(-1)" }} />
                              {isClient && <DeleteOutline onClick={this.deleteConversation} />}
                              <MoreHorizOutlined />
                            </Box>
                            :
                            <DropDownMenu favouriteChats={this.state.favouriteChats} selectedConversation={this.state.selectedConversation.sid} sendHandleFavorite={this.handleFavorite} />}
                        </Grid>
                      </Box>

                      <Divider />

                      {/* chats screen start */}
                      <Messages messages={this.state.messages} isLoading={this.state.isMsgLoading} />
                      {/* chats screen end */}

                      < Divider />

                      <Box style={{
                        position: "absolute", right: "5rem",
                        bottom: "0", zIndex: 1
                      }}>
                        {this.state.isEmojiOpen && <Picker onEmojiClick={this.onEmojiClick} native />}
                      </Box>

                      {/* Footer  */}
                      <Box
                        className={styles.rightFooter}
                        display="flex"
                        component="form"
                        onSubmit={this.handleMsgSend}
                      >
                        <Box style={{ marginRight: "1rem", flex: 1 }}>
                          <Box className={styles.textField} >
                            <InputBase
                              placeholder="Type Here..."
                              inputProps={{ 'aria-label': 'Type Here...' }}
                              value={this.state.newMessage}
                              onChange={(e) => { this.setState({ newMessage: e.target.value }) }}
                            // multiline
                            // autoFocus
                            />
                            <Box className={styles.footerOptions}>
                              <AttachmentRounded onClick={this.toggleUploadModal} />
                              <EmojiEmotions
                                onClick={this.toggleEmojiPicker}
                                style={{ color: this.state.isEmojiOpen ? "#000" : "#92929d" }}
                              />
                              {/* <PhotoSharp /> */}
                            </Box>
                          </Box>
                        </Box>

                        <Box>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={this.state.newMessage.trim().length < 1}
                            type="submit"
                          >
                            Send Message
                          </Button>
                        </Box>
                      </Box>

                    </>
                  )}

                  {!this.state.selectedConversation && (
                    <Box padding='1rem' height='100%'>
                      <Box display='flex' justifyContent='center' alignItems="center" height="100%" >
                        {this.state.status !== "connected" ? <Typography variant="h5">{this.state.statusString}</Typography> :
                          this.state.conversations.length === 0 ? (
                            <Typography variant="h5">You are connected, But No conversations found.</Typography>
                          ) : <Typography variant="h5">{this.state.statusString}</Typography>
                        }
                      </Box>
                    </Box>
                  )}
                </Grid>
              }
              {/* Content end */}
            </Grid>
          </div>
        </Box>

        {/* Snackbar */}
        {this.state.snackBar.show && (
          <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.toggleSnackBar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.toggleSnackBar}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar>
        )}
        {/* upload files modal */}
        <UploadModal
          open={this.state.isUploadModalOpen}
          onClose={this.toggleUploadModal}
          uploadImage={this.uploadImage}
        />
      </Box >
      // Customizable Area End
    );
  }
}

// @ts-ignore
export default withRouter(WebChat);

// Customizable Area End
