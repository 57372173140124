import React from "react";
import { Card, InputBase, Container, FormControl, FormHelperText, CardContent, Box, Typography, withStyles, Button, Grid, ListItemIcon, Toolbar, List, ListItem, ListItemText } from "@material-ui/core";
import { SearchIcon, FilterBtn } from './assets';
import TextField from '@material-ui/core/TextField';
import OpenRolesCards from '../../../components/src/OpenRolesCards'
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Rolesdata from '../../../components/src/ViewAllRoles';

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import Location4Controller, {
  Props,
  configJSON
} from "./Location4Controller";
import { createStyles, ThemeProvider, makeStyles, createTheme } from "@material-ui/core/styles";

const OpenrolesStyles = (theme: any) => ({
  root: {
    display: 'flex',
    backgroundColor: 'grey',
  },
  textWhite: {
    color: "#ffffff"
  },

  drawerContainer: {
    overflow: 'auto',
  },
  listIconeWidth: {
    minWidth: '47',
    color: 'White'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  BgHighlightBottom: {
    width: '100%',
    backgroundColor: '#24325f',
    height: '15px',
  },
  BgCardHeader: {
    backgroundColor: '#a8d0e7',
  },
  BtnCreateNewRole: {
    backgroundColor: '#24325f',
    color: "white"
  },
  BgHighlightInterviewsBottom: {
    backgroundColor: '#e19954',
    height: '15',
    width: "100%"

  },
  InterveiwTextColor: {
    color: '#e19954'
  },
  CandidatesTextColor: {
    color: '#5f72b9'
  },
  BgHighlightcandidatesBottom: {
    backgroundColor: '#5f72b9',
    height: "15",
    width: '100%'
  },
  BgHighlightClosedRolesBottom: {
    backgroundColor: '#ff4e6d',
    height: "15",
    widht: '100%'
  },
  ClosedRolesTextColor: {
    color: '#ff4e6d'
  },
  inputTextField: {
    fontSize: "10px"
  },
  SelectFilterWidth: {
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "50px"
    },

  }

});
const theme = createTheme({
  typography: {
    h1: {
      fontSize: 28,
      fontWeight: 700,
      fontFamily: "Poppins-SemiBold"
    },
    h2: {
      fontWeight: 600,
      fontSize: 24,
      fontFamily: "Poppins-SemiBold"
    },
    h3: {
      fontWeight: 500,
      fontSize: 16,
      fontFamily: "Roboto-Regular"
    },
    h4: {
      fontWeight: 400,
      fontSize: 14,
      fontFamily: "Roboto-Regular"
    },

  }
});
class Location4 extends Location4Controller {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Box color="primary" >
            <main className={classes.content}>
              {/* Dashboard User Details start */}
              <Box mb={3}>
                <Grid container >
                  <Grid item lg={5} sm={12}>
                    <Box mb={3}>
                      <Box mb={1} fontFamily="Poppins-SemiBold"><Typography variant="h2" >Open Roles</Typography></Box>
                    </Box>
                  </Grid>
                  <Grid item lg={7} sm={6}>
                    <Box display="flex" justifyContent="space-evenly" flexWrap="wrap" alignItems="center" alignContent="center">
                      <Box>
                        <div className={classes.margin}>
                          <Grid container spacing={1} alignItems="center" className={classes.Textfieldsborder}>
                            <Grid item>
                              <img src={SearchIcon} width="20px" />
                            </Grid>
                            <Grid item>
                              <TextField color="secondary" variant="outlined" id="input-with-icon-grid" placeholder="Search here" size="small" className={classes.inputTextField} />
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box>

                        <Select
                          labelId="product-subcategory"
                          className={classes.SelectFilterWidth}
                          id="demo-simple-select"
                          displayEmpty>
                          <MenuItem onClick={(e) => {
                            this.setState({
                              ListView: false,
                            });
                          }}>
                            <Typography variant="h4">
                              View: Grid
                            </Typography>
                          </MenuItem>
                          <MenuItem value="4" onClick={(e) => {
                            this.setState({
                              ListView: true,
                            });
                          }}>
                            <Typography variant="h4">
                              View: List
                            </Typography>
                          </MenuItem>

                        </Select>
                      </Box>
                      <Box>
                        <Select
                          className={classes.SelectFilterWidth}
                          labelId="product-subcategory"
                          id="demo-simple-select"
                          displayEmpty>
                          <MenuItem >
                            <Typography >
                              Sort By: Default
                            </Typography>

                          </MenuItem>
                          <MenuItem value="4">
                            <Typography >
                              Sort By: A to Z
                            </Typography>
                          </MenuItem>
                        </Select>
                      </Box>
                      <Box>
                        <img src={FilterBtn} width="20px" />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* Dashboard User Details End */}


              <Box mb={3}>
                <Rolesdata />
              </Box>

              {/* Dashboard open roles start */}
              <Box mb={3} style={this.state.ListView ? { "display": "block" } : { "display": "none" }}>
                <Card>

                  <Box p={2}>
                    <Grid container spacing={2}>
                      <Grid lg={12}>
                        <Box>
                          {/* <OpenRoleList name="test user" date="25/11/2021" initial="98" final="95" feedback="Great" /> */}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Box>
              {/* Dashboard open roles start */}

              {/* Dashboard open roles start */}
              <Box mb={3} style={!(this.state.ListView) ? { "display": "block" } : { "display": "none" }}>
                <Card>
                  <Box p={2}>
                    <Grid container spacing={2}>
                      <Grid item lg={4}>
                        <OpenRolesCards Title="Test User"
                          Date="29/09/2021"
                          Initial_stage="79"
                          Final_score="59"
                          Feedback="Great Talent" />
                      </Grid>
                      <Grid item lg={4}>
                        <OpenRolesCards Title="Test User"
                          Date="29/09/2021"
                          Initial_stage="79"
                          Final_score="59"
                          Feedback="Great Talent" />
                      </Grid>
                      <Grid item lg={4}>
                        <OpenRolesCards Title="Test User"
                          Date="29/09/2021"
                          Initial_stage="79"
                          Final_score="59"
                          Feedback="Great Talent" />
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Box>
              {/* Dashboard open roles start */}
              {/* Dashboard open roles start */}
              <Box mb={3} style={!(this.state.ListView) ? { "display": "block" } : { "display": "none" }}>
                <Card>

                  <Box p={2}>
                    <Grid container spacing={2}>
                      <Grid item lg={4}>
                        <OpenRolesCards Title="Test User"
                          Date="29/09/2021"
                          Initial_stage="79"
                          Final_score="59"
                          Feedback="Great Talent" />
                      </Grid>
                      <Grid item lg={4}>
                        <OpenRolesCards Title="Test User"
                          Date="29/09/2021"
                          Initial_stage="79"
                          Final_score="59"
                          Feedback="Great Talent" />
                      </Grid>
                      <Grid item lg={4}>
                        <OpenRolesCards Title="Test User"
                          Date="29/09/2021"
                          Initial_stage="79"
                          Final_score="59"
                          Feedback="Great Talent" />
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Box>
              {/* Dashboard open roles start */}

            </main>
          </Box >
        </Box >
      </ThemeProvider>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
export default withStyles(OpenrolesStyles)(Location4)

