import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { Theme, Tooltip, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { trimFeedback } from "./OpenRolesCards";

const useStyles = (theme: Theme) => ({
  tableheadmain: {
    backgroundColor: "#DCECF5",
    color: "#4D4E58",
    borderRadius: "15px"
  },
  tableheadtitle: {
    textTransform: "uppercase",
    fontSize: "12px",
    color: "#575963",
    fontWeight: 600
  },

  tableheadtitle1: {
    textTransform: "uppercase",
    fontSize: "12px",
    color: "#575963",
    fontWeight: 600,
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px"
  },

  tableheadtitlelast: {
    textTransform: "uppercase",
    fontSize: "12px",
    color: "#575963",
    fontWeight: 600,
    borderTopRightRadius: "15px",
    borderBottomRightRadius: "15px"
  },

  tabledata: {
    textTransform: "capitalize",
    fontSize: "13px",
    fontWeight: 600
  },

  cvbutton: {
    borderRadius: "25px",
    border: "none",
    padding: "8px 14px",
    textTransform: "capitalize",
    color: "#575963",
    fontWeight: 700,
    cursor: "pointer"
  },
  icondotgreen: {
    padding: 0,
    color: "#3dd598",
    fontSize: "24px",
    marginRight: "0px"
  },
  icondotgrey: {
    padding: 0,
    color: "#DDDDDD",
    fontSize: "24px",
    marginRight: "0px"
  },
  talentbutton: {
    width: 110,
    borderRadius: "8px",
    fontSize: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    textTransform: "capitalize",
    outline: "none",
    border: "none",
    background: "#E2F5F1",
    color: "#5BDBA8",
    fontWeight: 700
  },
  statusButton: {
    width: 110,
    borderRadius: "8px",
    fontSize: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    textTransform: "capitalize",
    outline: "none",
    border: "none",
    fontWeight: 700
  },
  acceptedStatus: {
    background: "#E2F5F1",
    color: "#5BDBA8"
  },
  rejectedStatus: {
    background: "#fc5a6524",
    color: "#FC5A5A"
  },
  iconwhat: {
    color: "#36D773",
    fontSize: "28px",
    padding: "0px 5px",
    cursor: "pointer"
  },
  iconmessage: {
    color: "grey",
    fontSize: "28px",
    padding: "0px 5px",
    cursor: "pointer"
  },

  icondone: {
    padding: "4px",
    fontSize: "23px",
    background: "#3DD598",
    borderRadius: "7px",
    color: "white",
    margin: "0px 5px"
  },

  iconclose: {
    padding: "4px",
    fontSize: "23px",
    background: "#FC5A5A",
    borderRadius: "7px",
    color: "white",
    margin: "0px 3px"
  },
  date: {
    color: "#A3A3AC",
    fontSize: "14px",
    paddingLeft: "5px"
  },
  counthead: {
    color: "#A3A3AC"
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    textTransform: "capitalize",
    cursor: "pointer"
  }
});
interface OpenItemInterface {
  classes: any;
  candidate: any;
  role_id: number;
  hasMoreCandidateData?: boolean;
  infiniteCandidateLoading?: boolean;
  candidateListScroll?: (data: string) => void;
  match?: any;
  createConversation: (email: string) => void;
  clientIDLink?: any;
}
class Rollist extends React.Component<OpenItemInterface> {
  candidateObserver: any;
  lastCandidateItem: (node: any) => void;
  constructor(props: OpenItemInterface) {
    super(props);
    this.candidateObserver = React.createRef();
    this.lastCandidateItem = (node: any) => {
      if (this.candidateObserver.current) this.candidateObserver.current.disconnect();

      this.candidateObserver.current = new IntersectionObserver(entries => {
        if (this.props.infiniteCandidateLoading) return;
        if (entries[0].isIntersecting && this.props.hasMoreCandidateData && !this.props.match?.params?.id) {
          this.props.candidateListScroll?.("candidateScrolled")
        }
      });
      if (node) this.candidateObserver.current.observe(node);
    }
  }
  render() {
    console.log("#############################################", this.props);
    const { classes } = this.props;
    let totalRejected = 0;
    const checkRejectCandidate = () => {
      let isRejectedSameCount = false;
      this.props.candidate.map((candidate: any, index: number) => {
        if (candidate?.status == "rejected") {
          totalRejected += 1;
        }
        if (totalRejected == this.props?.candidate?.length) {
          isRejectedSameCount = true;
        }
      });
      return isRejectedSameCount;
    }
    return (
      // checkRejectCandidate() ? <Typography variant="body1">No candidates found.</Typography> :
      <TableContainer>
        <Table aria-label="simple table">
          {(this.props.candidate.length > 0 && !this.props.candidate.includes(null)) &&
            <TableHead className={classes.tableheadmain}>
              <TableRow>
                <TableCell className={classes.tableheadtitle1}>no</TableCell>
                <TableCell className={classes.tableheadtitle} align="center">
                  Candidate Name
                </TableCell>
                <TableCell className={classes.tableheadtitle} align="center">
                  Date Opened
                </TableCell>
                <TableCell className={classes.tableheadtitle} align="center">
                  Date Closed
                </TableCell>
                <TableCell className={classes.tableheadtitle} align="center">
                  CV
                </TableCell>
                <TableCell className={classes.tableheadtitle} align="center">
                  AI Score
                </TableCell>
                <TableCell className={classes.tableheadtitle} align="center">
                  Feedback
                </TableCell>
                <TableCell className={classes.tableheadtitle} align="center">
                  Finalist
                </TableCell>
                <TableCell className={classes.tableheadtitle} align="center">
                  Message
                </TableCell>
              </TableRow>
            </TableHead>}
          <TableBody>
            {(this.props.candidate.length > 0 && !this.props.candidate.includes(null)) ?
              this.props.candidate.map((candidate: any, index: number) => {
                return (
                  // candidate.status !== "rejected" && (
                  this.props.candidate.length == index + 1 ?
                    <TableRow key={index} ref={this.lastCandidateItem}>
                      <RoleTableList
                        index={index}
                        classes={classes}
                        candidate={candidate}
                        role_id={this.props.role_id}
                        createConversation={this.props.createConversation}
                        clientIDLink={this.props.clientIDLink}
                      />
                    </TableRow> :
                    <TableRow key={index}>
                      <RoleTableList
                        index={index}
                        classes={classes}
                        candidate={candidate}
                        role_id={this.props.role_id}
                        createConversation={this.props.createConversation}
                        clientIDLink={this.props.clientIDLink}
                      />
                    </TableRow>
                )
                // )
              }) :
              <TableRow>
                <TableCell style={{ borderBottom: 0 }}>
                  <Typography>No Candidate Apply Yet.</Typography>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

//@ts-ignore
export default withStyles(useStyles)(Rollist);

const RoleTableList = (props: any) => {
  return (
    <>
      <TableCell component="th" scope="row">
        {props.index + 1}
      </TableCell>
      <TableCell className={props.classes.tabledata} style={{ cursor: "pointer" }} align="center">
        <Link to={`/open-roles/candidate-details/?role_id=${props.role_id}&candidate_id=${props.candidate.account_id}${props.clientIDLink ? "&" + props.clientIDLink : ""}`} className={props.classes.link}>
          {props.candidate.name}
        </Link>
      </TableCell>
      <TableCell className={props.classes.tabledata} align="center">
        {props.candidate.date_opened?.split("T")[0] || "-"}
      </TableCell>
      <TableCell className={props.classes.tabledata} align="center">
        {props.candidate.date_closed?.split("T")[0] || "-"}
      </TableCell>
      <TableCell align="center">
        {props.candidate.resume ?
          <a href={props.candidate.resume} target="_blank" style={{ textDecoration: "none" }} download>
            <button className={props.classes.cvbutton}> VIEW CV</button>
          </a> : <button className={props.classes.cvbutton}> No CV</button>}
      </TableCell>
      <TableCell className={props.classes.tabledata} align="center">
        {props.candidate.initial_score || 0}
      </TableCell>
      <TableCell align="center">
        <Tooltip title={props.candidate?.final_feedback || "No feedback"} arrow>
          <button className={props.classes.talentbutton}>
            {props.candidate?.final_feedback ? trimFeedback(props.candidate?.final_feedback, 11, 3) : "-"}
          </button>
        </Tooltip>
      </TableCell>
      <TableCell className={props.classes.tabledata} align="center">
        {props.candidate.status == "accepted" ? "Yes" : "No"}
      </TableCell>

      <TableCell align="center">
        <Tooltip title="Whatsapp">
          <WhatsAppIcon fontSize="large" className={props.classes.iconwhat} />
        </Tooltip>
        <Tooltip title="Start Chatiing">
          <MailOutlineIcon
            fontSize="large"
            className={props.classes.iconmessage}
            onClick={() => props.createConversation(props.candidate?.email)}
          />
        </Tooltip>
      </TableCell>
    </>
  )
}