// Customizable Area Start
import * as React from 'react';
import { usePagination } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import PaginationStyle from './Styles.module.scss';
const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
});

export default function Pagination(props: any) {
    const { items } = usePagination({
        count: props.totalPages,
    });

    return (
        <nav className={PaginationStyle.pagination}>
            <List>
                {items.map(({ page, type, selected, ...item }, index) => {
                    let children = null;

                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = '…';
                    } else if (type === 'page') {
                        let pageVal = page < 10 ? "0" + page : page;
                        children = (
                            <button
                                type="button"
                                style={{
                                    fontWeight: selected ? 'bold' : undefined,
                                    color: selected ? 'inherit' : '#96969f',
                                    cursor: "pointer"
                                }}
                                {...item}
                            >

                                {pageVal}
                            </button>
                        );
                    } else {
                        if (type == 'next') {
                            children = (
                                <button type="button" {...item}
                                    style={{ cursor: "pointer" }}
                                >
                                    Next
                                </button>
                            );
                        }
                    }

                    return <li className={PaginationStyle.listItem} key={index} onClick={() => props.onPageNumSelect(page)}>{children}</li>;
                })}
            </List>
        </nav>
    );
}
// Customizable Area End