//@ts-nocheck
import React from "react";
import ScoringController, {
  Props,
  configJSON
} from "./ScoringController.web";
// Customizable Area Start
import styles from "./Styles.module.scss";
import { AppBar, Box, Container, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import AppSidebar from "../../../components/src/AppSidebar";
import TopHeader from "../../../components/src/TopHeader";
import { ReactComponent as FilterBtn } from "../assets/image_Filter.svg";
import { ReactComponent as SearchIcon } from "../assets/ic_Search.svg";
import Pagination from '@material-ui/lab/Pagination';
import { upArrow, downArrow } from "./assets";
import { withRouter } from "react-router-dom";
// Customizable Area End


class Scoring extends ScoringController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.initialConfig();
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.checkWindowResize);
    // this.getTestDomeDataList();
  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowResize);
  }
  // handle candidate search 
  handleSearch = (keyword: string) => {
    this.setState({ searchString: keyword.target.value, pageNumber: 1 }, () => this.getCandidateAssesmentList({ page: 1, per_page: 10 }));
  }

  // handle filter by name/role/initial score
  handleFilter = (event: any) => {
    const { value } = event.target;
    if (value === "default") {
      this.setState({ sortRoleType: "", pageNumber: 1 }, () => this.getCandidateAssesmentList({ page: 1, per_page: 10 }));
    } else {
      this.setState({ sortRoleType: value, pageNumber: 1 }, () => this.getCandidateAssesmentList({ page: 1, per_page: 10 }));
    }
  }

  // Customizable Area End
  // Customizable Area Start
  render() {

    let pages = 0;
    if (this.state.scoreList?.total_candidaate < this.state.pageLengthToShow) {
      pages = 1;
    } else if (this.state.scoreList?.total_candidaate % this.state.pageLengthToShow != 0) {
      pages = ~~(this.state.scoreList?.total_candidaate / this.state.pageLengthToShow) + 1;
    } else {
      pages = ~~(this.state.scoreList?.total_candidaate / this.state.pageLengthToShow);
    }
    return (
      <>
        <div className={styles.pageWrap}>
          <div className={styles.pageSection}>
            <div className={styles.pageContentWrap}>
              <AppSidebar />

              <main className={styles.pageContent}>
                <div className={styles.headContainer} >
                  <div className={styles.leftItem}>
                    <h1 className={styles.pageHead}>Candidate Assessment</h1>
                  </div>
                  <div className={styles.rightItem}>
                    <div className={`${styles.searchWrap} ${styles.subItem}`}>
                      <SearchIcon />
                      <input className={styles.searchItem} onChange={(e) => this.handleSearch(e)} placeholder="Search here..." data-focusvisible-polyfill="false" />
                    </div>
                    <div className={`${styles.viewTypeWrap} ${styles.subItem}`}>
                      <InputLabel variant="standard" htmlFor="scoringSort">
                        Sort by:
                      </InputLabel>
                      <Select
                        labelId="product-subcategory"
                        className={styles.scoringSortWrap}
                        id="scoringSort"
                        defaultValue="default"
                        onChange={this.handleFilter}
                      >
                        <MenuItem value="default">Default</MenuItem>
                        <MenuItem value="name">Name</MenuItem>
                        <MenuItem value="role_name">Role</MenuItem>
                        <MenuItem value="score">Score</MenuItem>
                      </Select>
                    </div>
                    <div className={`${styles.filterWrap} ${styles.subItem}`}>
                      <FilterBtn />
                    </div>
                  </div>
                </div>
                <div className={styles.bodyContainer}>
                  <div className={`${styles.bodyCard} ${styles.bodyCardMain}`}>
                    <div className={styles.tablePagWrap}>
                      <Table className={styles.scoreTable}>
                        <TableHead>
                          <TableRow className={styles.shRow}>
                            <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "5%" }}>NO.</TableCell>
                            <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "25%" }}>CANDIDATE NAME</TableCell>
                            <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "60%" }}>ROLE</TableCell>
                            <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "10%" }}>AI SCORE</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.scoreList?.candidate?.data?.map((item: any, index: number) => (
                            <TableRow className={`${styles.sRRow} ${index % 2 !== 0 ? styles.evenRow : ""}`} key={item.id} onClick={() => {
                              //@ts-ignore
                              this.props.history.push(`assessment-detail/${item?.attributes?.applied_id}${this.clientIDLink ? "?" + this.clientIDLink : ""}`);
                            }}>
                              <TableCell className={`${styles.tableNoBorder} ${styles.sRCell}`}>{this.state.pageLengthToShow * (this.state.pageNumber - 1) + 1 + index}</TableCell>
                              <TableCell className={`${styles.tableNoBorder} ${styles.sRCell}`}>{item?.attributes?.candidate_name}</TableCell>
                              <TableCell className={`${styles.tableNoBorder} ${styles.sRCell}`}>{item?.attributes?.role}</TableCell>
                              <TableCell className={`${styles.tableNoBorder} ${styles.sRCell}`}>{item?.attributes?.initial_score || 0}</TableCell>
                            </TableRow>))}
                        </TableBody>
                      </Table>
                      {this.state.scoreList?.total_candidaate === 0 && <Typography style={{ padding: "1rem 2rem" }}>No Candidate Found.</Typography>}
                      <div className={styles.paginationWrap}>
                        <div className={styles.mobilePagination}>
                          <p>
                            {this.state.pageLengthToShow * (this.state.pageNumber - 1) + 1}-{(this.state.pageNumber * this.state.pageLengthToShow) > this.state.scoreList?.total_candidaate ? this.state.scoreList?.total_candidaate : (this.state.pageNumber * this.state.pageLengthToShow)} of {this.state.scoreList?.total_candidaate} items
                          </p>
                          {this.state.windowWidth < 621 ?
                            <div className={styles.rowCountWrap}>
                              <p>{this.state.pageLengthToShow}</p>
                              <div className={styles.countHandler}>
                                <div className={styles.countUpArrowImgWrap} onClick={() => {
                                  if (this.state.pageLengthToShow < 40) {
                                    this.setState((prev: any) => ({ pageNumber: 1, pageLengthToShow: prev.pageLengthToShow * 2 }), () => this.getCandidateAssesmentList({ page: this.state.pageNumber, per_page: this.state.pageLengthToShow }))
                                  }
                                }}>
                                  <div>
                                    <img src={upArrow} alt="arrow" />
                                  </div>
                                </div>
                                <div className={styles.countDownArrowImgWrap} onClick={() => {
                                  if (this.state.pageLengthToShow > 10) {
                                    this.setState((prev: any) => ({ pageNumber: 1, pageLengthToShow: prev.pageLengthToShow / 2 }), () => this.getCandidateAssesmentList({ page: this.state.pageNumber, per_page: this.state.pageLengthToShow }))
                                  }
                                }}>
                                  <div>
                                    <img src={downArrow} alt="arrow" />
                                  </div>
                                </div>
                              </div>
                            </div> : ""}
                        </div>
                        <div className={styles.paginationCountWrap}>
                          <div className={styles.jobPagination}>
                            <Pagination
                              count={pages}
                              page={this.state.pageNumber}
                              variant="outlined"
                              shape="rounded"
                              onChange={(event: any, page: number) => {
                                this.setState({ pageNumber: page });
                                this.getCandidateAssesmentList({ page: page, per_page: this.state.pageLengthToShow })
                              }} />
                          </div>
                          {this.state.windowWidth > 620 ?
                            <div className={styles.rowCountWrap}>
                              <p>{this.state.pageLengthToShow}</p>
                              <div className={styles.countHandler}>
                                <div className={styles.countUpArrowImgWrap} onClick={() => {
                                  if (this.state.pageLengthToShow < 40) {
                                    this.setState((prev: any) => ({ pageNumber: 1, pageLengthToShow: prev.pageLengthToShow * 2 }), () => this.getCandidateAssesmentList({ page: this.state.pageNumber, per_page: this.state.pageLengthToShow }))
                                  }
                                }}>
                                  <div>
                                    <img src={upArrow} alt="arrow" />
                                  </div>
                                </div>
                                <div className={styles.countDownArrowImgWrap} onClick={() => {
                                  if (this.state.pageLengthToShow > 10) {
                                    this.setState((prev: any) => ({ pageNumber: 1, pageLengthToShow: prev.pageLengthToShow / 2 }), () => this.getCandidateAssesmentList({ page: this.state.pageNumber, per_page: this.state.pageLengthToShow }))
                                  }
                                }}>
                                  <div>
                                    <img src={downArrow} alt="arrow" />
                                  </div>
                                </div>
                              </div>
                            </div> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>

      </>
      // Customizable Area End
    );
  }
}
export default withRouter(Scoring) as Scoring;
