import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import DescriptionIcon from "@material-ui/icons/Description";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { ThemeProvider, makeStyles, createTheme } from "@material-ui/core/styles";
// @ts-ignore
import WhatsAppModal from "../../web/src/common/components/WhatsAppModal.web";

const useStyles = makeStyles({
  iconall: {
    color: "white",
    fontSize: "30px"
  },

  iconboxmain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 10,
    gap: "1rem",

    "& div": {
      cursor: "pointer",
    }
  },

  iconbox1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#24325F",
    borderRadius: 8
  },
  iconbox2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#25D366",
    borderRadius: 8
  },
  iconbox3: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#24325F",
    borderRadius: 8
  },
  iconbox4: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#3DD598",
    borderRadius: 8
  },
  iconbox5: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 50,
    height: 50,
    backgroundColor: "#FC5A5A",
    borderRadius: 8
  },

  icondotgreen: {
    padding: 0,
    color: "#3dd598",
    fontSize: "1.2rem",
    marginRight: "0px"
  },

  icondotgrey: {
    padding: 0,
    color: "#DDDDDD",
    fontSize: "1.2rem",
    marginRight: "0px"
  },
  talentbutton: {
    borderRadius: "8px",
    fontSize: "18px",
    paddingTop: "4px",
    paddingBottom: "4px",
    outline: "none",
    border: "none",
    background: "#edfbf6",
    color: "#3DD598",
    fontWeight: 500
  },

  talentheading: {
    color: "#555",
    paddingBottom: 6,

  },

  talentmain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },

  assessmentdata: {
    display: "flex",

    alignItems: "center",
    justifyContent: "center"
  },

  assessmentmain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1
  },

  assessmenttalentmain: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem 0",
    borderBottom: "1px solid #ddd"
  },

  final_score_head: {
    color: "#555",
    paddingLeft: "0.2rem",
    display: "flex",
    alignItems: "center",
    fontSize: "0.87rem"
  },
  final_score_data: {
    fontSize: 18,
    color: "#3DD598",
    paddingLeft: 5,
    fontWeight: "bold",
    display: "inline-block"
  },

  username: {
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: "10px"
  },
  userdate_head: {
    fontSize: "0.87rem!important",
    color: "grey",

  },
  userdate_data: {
    fontSize: 15,
    fontWeight: "bold",
    display: "inline-block",
    color: "grey"
  },

  titlemain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    "& a": {
      textDecoration: "none",
      color: "inherit",
      textTransform: "capitalize",
      cursor: "pointer"
    }
  },
  icondhead: {
    width: 65,
    height: 65,
    borderRadius: "5px",
    border: "1px solid grey"
  },
  imgprofile: {
    borderRadius: 10,
    width: "45px",
    height: "45px",
    objectFit: "cover"
  },

  counthead: {
    color: "#A3A3AC"
  },
  acceptedStatus: {
    background: "#E2F5F1",
    color: "#5BDBA8"
  },
  rejectedStatus: {
    background: "#fc5a6524",
    color: "#FC5A5A"
  },
  showStatusData: {
    display: "block"
  },
  onHideData: {
    display: "none"
  },
  roleCardWrapper: {
    height: "100%",
    "&:hover": {
      "& $onHoverShowData": {
        display: "flex"
      },
      "& $statusButton": {
        display: "none"
      }
    },
  },
  onHoverShowData: () => ({
    display: "none"
  }),
  statusButton: () => ({
    width: 110,
    borderRadius: "8px",
    fontSize: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    textTransform: "capitalize",
    outline: "none",
    border: "none",
    fontWeight: 700
  }),
});

const theme = createTheme({
  typography: {
    h1: {
      fontSize: 20,
      fontWeight: 700
    },
    h2: {
      fontWeight: 600,
      fontSize: 16,
      fontFamily: "Poppins-SemiBold"
    },
    h3: {
      fontWeight: 500,
      fontSize: 14,
      fontFamily: "Roboto-Regular"
    },
  }
});

export const trimFeedback = (feedback: string, max: number = 14, sendLess: number = 0) => {
  if (feedback.length > max) {
    return feedback.substring(0, (max - sendLess)) + "..."
  }
  return feedback;
}

export default function OpenRolesCards(props: any) {
  const classes = useStyles();
  const [isModalOpen, setModal] = useState(false);

  const handleChat = () => {
    props.createCoversation(props.email);
  }

  const toogleModal = () => {
    setModal(!isModalOpen);
  }
  const stagesList = ['Shortlist', 'Test', 'Interview (HR / Video / HR Assessment)', 'Final Score', 'Accept / Reject']
  const stagesKeys = ['shortlisted', 'test', 'interview', 'final_score', 'status'];
  return (
    <ThemeProvider theme={theme}>
      {isModalOpen && <WhatsAppModal isOpen={isModalOpen} toggleModal={toogleModal} candidate={props} />}
      <Card className={classes.roleCardWrapper}>
        <Box display="flex" p={2} flexDirection="column" pt={0} pr="1rem" pb="1rem">
          <Box m={2} pb={2} borderBottom="1px solid #ddd" display="flex" style={{ margin: '1rem 0 0' }}>
            <Box><img src={props.imageUrl} alt={props.Title} className={classes.imgprofile} /></Box>
            <Box className={classes.titlemain} pl={2}>
              <Link to={`/open-roles/candidate-details?role_id=${props.role_id}&candidate_id=${props.account_id}${props.clientIDLink ? "&" + props.clientIDLink : ""}`}>
                <Typography variant="h2">
                  {props.Title}
                </Typography>
              </Link>

              <Typography variant="body1" className={classes.userdate_head}>
                Date Opened : {props.Date}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" pt={1.5} pb={1.5} borderBottom="1px solid #ddd">
            <Box display="flex" flex={1} alignItems="center" >
              <img src={require('./assets/initial_score.png')} style={{ width: 25, height: 25 }} />

              <Typography variant="caption" className={classes.final_score_head}>
                AI Score:
                <Typography variant="body1" className={classes.final_score_data}>
                  {props.Initial_stage || 0}
                </Typography>
              </Typography>
            </Box>
            <Box display="flex" flex={1} alignItems="center" style={{ justifyContent: "flex-end" }}>
              <img src={require('./assets/final_score.png')} style={{ width: 25, height: 25 }} />
              <Typography variant="caption" className={classes.final_score_head}>
                Final Score:
                <Typography variant="body1" className={classes.final_score_data}>
                  {props.Final_score || "-"}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.assessmenttalentmain}>
            <Box className={classes.assessmentmain}>
              <Typography variant="h3" className={classes.talentheading}>
                Assessment Stage:
              </Typography>
              <Box className={classes.assessmentdata}>
                {/* {assessment_stage} */}
                {Array(5).fill(0).map((_, index: number) => (
                  <Tooltip title={stagesList[index]} key={index}>
                    <FiberManualRecordIcon
                      fontSize="large"
                      className={props.assessment_stage?.[stagesKeys[index]] ? classes.icondotgreen : classes.icondotgrey}
                      key={index}
                    /></Tooltip>
                ))}
              </Box>
            </Box>
            <Box className={classes.talentmain}>
              <Typography variant="h3" className={classes.talentheading}>
                Feedback:
              </Typography>
              <Tooltip title={props.Feedback || "No feedback"} arrow>
                <button className={classes.talentbutton}>
                  <Typography variant="h3">
                    {props.Feedback ? trimFeedback(props.Feedback) : "-"}
                  </Typography>
                </button>
              </Tooltip>
            </Box>
          </Box>
          <Box className={classes.iconboxmain}>
            <Tooltip title="View Resume" arrow>
              <a href={props.resume} target="_blank" style={{ textDecoration: "none" }} download>
                <Box className={classes.iconbox1}>
                  <DescriptionIcon fontSize="large" className={classes.iconall} />
                </Box>
              </a>
            </Tooltip>
            <Tooltip title="Send a message in WhatsApp" arrow>
              <Box className={classes.iconbox2} onClick={toogleModal}>
                <WhatsAppIcon fontSize="large" className={classes.iconall} />
              </Box>
            </Tooltip>
            <Tooltip title="Start Chatting" arrow>
              <Box className={classes.iconbox3} onClick={handleChat}>
                <MailOutlineIcon fontSize="large" className={classes.iconall} />
              </Box>
            </Tooltip>
            {props.status != "pending" ?
              <>
                <button
                  className={`${classes.statusButton} ${props?.status == "accepted" ? classes.acceptedStatus : classes.rejectedStatus}`}>{props?.status}</button>
                <Tooltip title="Accept the Candidate" arrow>
                  <Box className={`${classes.iconbox4} ${classes.onHideData} ${props?.status == 'rejected' ? classes.onHoverShowData : ""}`}
                    onClick={() => {
                      let data = { role_id: +props.role_id, profile_id: props.candidate_id, isAccepted: true };
                      props.handleStatus(data);
                    }}>
                    <DoneIcon fontSize="large" className={classes.iconall} />
                  </Box>
                </Tooltip>
                <Tooltip title="Reject the Candidate" arrow>
                  <Box className={`${classes.iconbox5} ${classes.onHideData} ${props?.status == 'accepted' ? classes.onHoverShowData : ""}`} onClick={() => {
                    let data = { role_id: +props.role_id, profile_id: props.candidate_id, isAccepted: false };
                    props.handleStatus(data);
                  }}>
                    <CloseIcon fontSize="large" className={classes.iconall} />
                  </Box>
                </Tooltip>
              </>
              :
              <>
                <Tooltip title="Accept the Candidate" arrow>
                  <Box className={classes.iconbox4} onClick={() => {
                    let data = { role_id: +props.role_id, profile_id: props.candidate_id, isAccepted: true };
                    props.handleStatus(data);
                  }}>
                    <DoneIcon fontSize="large" className={classes.iconall} />
                  </Box>
                </Tooltip>
                <Tooltip title="Reject the Candidate" arrow>
                  <Box className={classes.iconbox5} onClick={() => {
                    let data = { role_id: +props.role_id, profile_id: props.candidate_id, isAccepted: false };
                    props.handleStatus(data);
                  }}>
                    <CloseIcon fontSize="large" className={classes.iconall} />
                  </Box>
                </Tooltip>
              </>}
          </Box>
        </Box>
      </Card>
    </ThemeProvider>
  );
}