import React, { useEffect, useState } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import DropDownMenu from "./TopNav_DropDown";
import CandidateContext from "../../web/src/Context/CandidateContext.web";
import { Link } from "react-router-dom";
import { AdminProfile } from './assets';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
export const useStyles = makeStyles({
    profileImage: {
        width: "2.5rem",
        height: "2.5rem",
        marginRight: ".8rem",
        borderRadius: "50%",
    },
    profileName: {
        color: "#171725",
        textTransform: "capitalize",
        fontSize: "1.1rem",
        fontWeight: "bold",
    },
    designationText: {
        color: "#92929D",
        fontSize: ".9rem",
        marginLeft: ".2rem"
    },
});

const RecruiterMenu = ({ history }: any) => {
    const classes = useStyles();
    const ctx = React.useContext(CandidateContext);
    const [userType, setUserType] = React.useState(sessionStorage.getItem("user_role"));
    useEffect(() => {
        ctx.setCandidateProfilePicture(sessionStorage.getItem("profile_image") || "https://thebenclark.files.wordpress.com/2014/03/facebook-default-no-profile-pic.jpg");
    }, []);

    // is Mobile View
    const [width, setWidth] = useState<number>(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth);

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    return (
        <Grid container justifyContent="flex-end" alignItems="center">

            {sessionStorage.getItem("user_role") === "admin" && (
                <Link to='whatsapp/admin' style={{ textDecoration: "none", color: width < 450 ? '#25D366' : 'inherit' }}>
                    <Box mr={"2.5rem"}>
                        {width >= 450 ?
                            <Button color="primary" variant="outlined">Open WhatsApp</Button> :
                            <WhatsAppIcon fontSize="large" />}
                    </Box>
                </Link>
            )}

            {/* profile picture */}
            {userType == 'admin' ?
                <img src={AdminProfile} className={classes.profileImage} /> :

                <img src={ctx.profile_picture} className={classes.profileImage} />
            }

            {/* user details */}
            <Box style={{ marginRight: "1.5rem" }}>
                <Typography variant="body1" className={classes.profileName}>
                    {sessionStorage.getItem("first_name")} {sessionStorage.getItem("last_name")}
                </Typography>
                <Typography variant="body1" className={classes.designationText}>
                    {sessionStorage.getItem("user_role")}
                </Typography>
            </Box>

            {/* drop-down menu */}
            <DropDownMenu history={history} />
        </Grid>
    );
}

export default RecruiterMenu;