// Customizable Area Start
import { Box, Button, Grid, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import { Rating } from "@material-ui/lab";
import { upArrow, downArrow } from "./assets";
import ScoringController, { Props } from "./ScoringController.web";
import styles from "./Styles.module.scss";
import { withStyles } from "@material-ui/styles";
import { Formik } from "formik";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { withRouter } from "react-router-dom";

const useStyles = (theme: Theme) => ({
    root: {
        color: "#24325f",
        [theme.breakpoints.down('xs')]: {
            fontSize: "3rem"
        }
    }
});
class InterviewFeedBack extends ScoringController {
    constructor(props: Props) {
        super(props);
    }
    componentDidMount = async () => {
        let clientID = +window.location?.search?.split("clientId=")[1];
        this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: this.props.clientID || "" }, () => {
            if (this.state.userType == 'client' || (this.state.userType == 'admin' && clientID)) {
                if (this.props.candidateData.attributes.status != 'pending') {
                    this.showCandidateFeedback(this.props.candidateData?.id);
                } else {
                    this.getFeedbackParameterList();
                }
            }
            this.clientIDLink = this.state.userType == "admin" && this.state.clientID ? "clientId=" + this.state.clientID : "";
        });

    }
    render() {
        const { classes } = this.props;
        return (
            <Modal
                open={this.props.showInterviewFeedback}
                onClose={this.props.onCloseShowInterviewFeedback}
                className={styles.interviewModal}
            >
                <>
                    <Box className={styles.interviewWrapper}>
                        <Box className={styles.interviewHead} display="flex" justifyContent="space-between" alignItems="center">
                            <Box flex="1">
                                <Typography color="primary" align="center" variant="h5">
                                    Interview Feedback
                                </Typography>
                            </Box>
                            <IconButton onClick={this.props.onCloseShowInterviewFeedback} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box className={styles.interviewBody}>

                            <div className={styles.bodyCard}>
                                <Grid container className={styles.canDetail}>
                                    <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className={styles.canImg}>
                                        <img
                                            src={this.props.candidateData?.attributes?.photo ?
                                                this.props.candidateData?.attributes?.photo :
                                                require("../../user-profile-basic/assets/no-profile-picture.svg")}
                                        />
                                    </Grid>
                                    <Grid item xl={11} lg={11} md={11} sm={12} xs={12} className={styles.canData}>
                                        <Grid container >
                                            <Grid item xs={6} className={styles.canItem}>
                                                <p className={styles.canTitle}>Candidate Name</p>
                                                <h3 className={styles.canValue}>{this.props.candidateData?.attributes?.candidate_name}</h3>
                                            </Grid>
                                            <Grid item xs={6} className={styles.canItem}>
                                                <p className={styles.canTitle}>Role</p>
                                                <h3 className={styles.canValue}>{this.props.candidateData?.attributes?.role}</h3>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>

                            <Formik
                                initialValues={{
                                    feedbackData: [],
                                    accepted: ""
                                }}
                                //   validationSchema={this.state.createRoleValidationSchema}
                                onSubmit={(values) => {

                                    if (values.feedbackData?.length < this.state.feedbackParameters?.length) {
                                        this.setState({ ratingRBError: true });
                                    } else {
                                        let checkRating = false;
                                        let feedback: any = values.feedbackData;
                                        for (let i = 0; i < feedback?.length; i++) {
                                            if (!feedback[i]?.rating) {
                                                checkRating = true;
                                                break;
                                            }
                                        }
                                        if (checkRating) {
                                            this.setState({ ratingRBError: true });
                                        } else {
                                            this.setState({ ratingRBError: false });
                                            this.sendCandidateFeedback(values, this.props.candidateData?.attributes?.profile_id, this.props.candidateData?.attributes?.role_id);
                                        }
                                    }
                                }}>
                                {(formikProps: any) => (
                                    <form onSubmit={formikProps.handleSubmit} className={styles.giveFeedBack}>

                                        <div className={styles.bodyCard}>
                                            <Table className={styles.scoreTable}>
                                                <TableHead>
                                                    <TableRow className={styles.shRow}>
                                                        <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "25%" }}>PARAMETERS</TableCell>
                                                        <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "25%" }}>RATING</TableCell>
                                                        <TableCell className={`${styles.tableNoBorder} ${styles.sHCell}`} style={{ width: "50%" }}>COMMENTS</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(this.props.candidateData.attributes.status != 'pending' && this.props.candidateData?.attributes?.feedback == false) && <TableRow style={{ fontFamily: 'Poppins-Regular' }}>No feedback available</TableRow>}
                                                    {this.state.feedbackParameters?.map((item: any, index: number) => (
                                                        <TableRow className={`${styles.sRRow} ${index % 2 !== 0 ? styles.evenRow : ""}`} key={item?.id}>
                                                            <TableCell className={`${styles.tableNoBorder} ${styles.sRCell}`}>{item?.name}</TableCell>
                                                            <TableCell className={`${styles.tableNoBorder} ${styles.sRCell} ${styles.rating}`}>
                                                                <Rating
                                                                    name={`rating${item.id}`}
                                                                    value={this.props.candidateData?.attributes?.feedback ? item?.rating : formikProps?.values.feedbackData[index]?.rating || 0}
                                                                    className={classes.root}
                                                                    precision={0.5}
                                                                    readOnly={this.props.candidateData.attributes.status != 'pending'}
                                                                    onChange={(event, newValue) => {
                                                                        let feedbackData: any = [...formikProps.values.feedbackData];
                                                                        const index = feedbackData?.findIndex((feedb: any) => feedb.feedback_parameter_lists_id == item.id)
                                                                        let data: any;
                                                                        if (index != -1) {
                                                                            feedbackData[index]["rating"] = newValue;
                                                                        } else {
                                                                            feedbackData.push({
                                                                                feedback_parameter_lists_id: item?.id,
                                                                                rating: newValue
                                                                            });

                                                                        }
                                                                        formikProps.setFieldValue("feedbackData", feedbackData);

                                                                    }}
                                                                /></TableCell>
                                                            <TableCell className={`${styles.tableNoBorder} ${styles.sRCell}`}>
                                                                {this.props.candidateData.attributes.status != 'pending' ?
                                                                    <span>{item?.comments}</span>
                                                                    :
                                                                    <TextField
                                                                        className={styles.tableInputF}
                                                                        placeholder="Enter Comments Here…"
                                                                        size="small" variant="outlined"
                                                                        onChange={(event: any) => {
                                                                            let newValue = event.target.value;
                                                                            let feedbackData: any = [...formikProps.values.feedbackData];
                                                                            const index = feedbackData?.findIndex((feedb: any) => feedb.feedback_parameter_lists_id == item.id)
                                                                            let data: any;
                                                                            if (index != -1) {
                                                                                feedbackData[index]["comments"] = newValue;
                                                                            } else {
                                                                                feedbackData.push({
                                                                                    feedback_parameter_lists_id: item?.id,
                                                                                    comments: newValue
                                                                                });

                                                                            }
                                                                            formikProps.setFieldValue("feedbackData", feedbackData);
                                                                        }} />}
                                                            </TableCell>
                                                        </TableRow>))}
                                                </TableBody>
                                            </Table>


                                        </div>
                                        {this.props.candidateData.attributes.status == 'pending' ?
                                            <>
                                                {this.state.ratingRBError && (
                                                    <p className={styles.ratingBoxError}>Please give all the ratings</p>
                                                )}
                                                <Box justifyContent="center" className={styles.WrapAction}>
                                                    <Button
                                                        type="submit" className={styles.fullBtnReject} disableRipple variant="contained" onClick={() => formikProps.setFieldValue("accepted", false)}>
                                                        <Typography variant="body2">
                                                            Reject Candidate
                                                        </Typography>
                                                    </Button>
                                                    <Button
                                                        type="submit" className={styles.fullBtnAccept} disableRipple variant="contained" onClick={() => formikProps.setFieldValue("accepted", true)}>
                                                        <Typography variant="body2">
                                                            Accept Candidate
                                                        </Typography>
                                                    </Button>
                                                </Box></> : ""}
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                    {/* Snackbar for display success and failed messages. */}
                    <Snackbar
                        open={this.state.snackBar.show}
                        autoHideDuration={3000}
                        onClose={this.closeSnackBarHandler}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={this.closeSnackBarHandler}
                            severity={this.state.snackBar.type}
                        >
                            {this.state.snackBar.message}
                        </MuiAlert>
                    </Snackbar>
                </>
            </Modal>



        );
    }
}

export default withStyles(useStyles)(InterviewFeedBack);
// Customizable Area End