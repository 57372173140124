import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Client as ConversationsClient } from "@twilio/conversations";
import React from "react";
import { formatTime } from "./Messages";
// Customizable Area End

const config = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    email: string,
    token: null | string,
    statusString: null | string,
    status: "default" | "success" | "warning" | "error" | "connected",
    conversationsReady: boolean,
    conversations: any,
    selectedConversation: any,
    messages: any,
    newMessage: string,
    boundConversations: Set<string>,
    chattingWith: null | string,
    lastMessages: { sid: string, message: string, time: string }[],
    chosenEmoji: any,
    isEmojiOpen: boolean,
    totalMsgLoaded: number,
    totalMessages: number,
    candidate_email: null | string;
    snackBar: { show: boolean, message: string, type: "success" | "warning" | "error" },
    favouriteChats: any[],
    filters: "All" | "Favourite",
    isUploadModalOpen: boolean,
    isMsgLoading: boolean,
    searchKeyword: string,
    windowWidth: any,
    showMessageView: boolean,
    showMessageList: boolean,
    chatEmails: string[],
    usersData: any,
    selectedChatIndex: null | number,
    selectUserEmail: null | string,
    userType: string,
    clientID: number | string,
    // Customizable Area Ends
}

let isFound = false;
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WebChatController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getTokenApiId: string;
    conversationsClient: any;
    handleFavouriteApiId: string;
    fetchFavouriteApiId: string;
    deleteConversationApiId: string;
    profileImageApiId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            email: "",
            token: null,
            statusString: "Searching in Space....",
            status: "default",
            conversationsReady: false,
            conversations: [],
            selectedConversation: null,
            messages: "",
            newMessage: "",
            boundConversations: new Set(),
            chattingWith: null,
            lastMessages: [],
            chosenEmoji: null,
            isEmojiOpen: false,
            totalMsgLoaded: 7,
            totalMessages: 0,
            candidate_email: null,
            snackBar: { show: false, message: "", type: "success" },
            favouriteChats: [],
            filters: "All",
            isUploadModalOpen: false,
            isMsgLoading: false,
            searchKeyword: "",
            windowWidth: window.innerWidth,
            showMessageView: window.innerWidth >= 750,
            showMessageList: true,
            chatEmails: [],
            usersData: [],
            selectedChatIndex: null,
            selectUserEmail: null,
            userType: sessionStorage.getItem("user_role") || "",
            clientID: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId != null) {
                // twilo token
                if (apiRequestCallId === this.getTokenApiId) {
                    if (responseJson) {
                        this.setState({ token: responseJson.token }, this.initConversations);
                    }
                }
                // favourite
                else if (apiRequestCallId === this.handleFavouriteApiId) {
                    if (responseJson) {
                        if (responseJson?.message) {
                            this.setState({ snackBar: { show: true, message: "Chat Remove from Favourite...", type: "success" } },
                                () => {
                                    if (this.state.filters !== "All") {
                                        this.setState({ selectedConversation: null })
                                    }
                                });
                        } else {
                            this.setState({ snackBar: { show: true, message: "Chat Added to Favourite...", type: "success" } });
                        }
                        this.fetchFavorites();
                    }
                }
                else if (apiRequestCallId === this.fetchFavouriteApiId) {
                    if (responseJson) {
                        this.setState({ favouriteChats: responseJson.favoruite });
                    }
                }
                // delete conversation
                else if (apiRequestCallId === this.deleteConversationApiId) {
                    if (responseJson?.message) {
                        this.setState({
                            snackBar: { show: true, message: "Chat Deleted...", type: "success", }
                        });
                    } else {
                        this.setState({ snackBar: { show: true, message: "Something went wrong.", type: "error", } });
                    }
                    this.setState({ selectedConversation: null });
                }
                // profile image
                else if (apiRequestCallId === this.profileImageApiId) {
                    if (responseJson) {
                        this.setState({ usersData: responseJson });

                        // , () => {
                        //     const newUserData = this.state.usersData.map((each: any, index: number) => {
                        //         return { ...each, email: this.state.chatEmails[index] }
                        //     })
                        //     this.setState({ usersData: newUserData });
                        // }
                    }
                }
            }
            else {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    toggleSnackBar = () => {
        this.setState({ snackBar: { show: false, message: "", type: "success" } });
    }

    toggleUploadModal = () => {
        this.setState({ isUploadModalOpen: !this.state.isUploadModalOpen });
    }

    uploadImage = (file: File) => {
        this.state.selectedConversation.sendMessage({ contentType: file.type, media: file });
        this.setState({ isUploadModalOpen: false });
    }

    getToken = () => {
        const header = {
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("Token")
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTokenApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            config.getTokenEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )

        if (this.state.userType == "admin" && this.state.clientID) {
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify({ client_id: this.state.clientID })
            );
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            config.getTokenMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleFavorite = () => {
        const token = sessionStorage.getItem("Token");

        if (this.state.selectedConversation && token) {
            const header = {
                "Content-Type": "application/json",
                "token": token
            }

            let httpBody: any = { conversation_sid: this.state.selectedConversation.sid };
            if (this.state.userType == 'admin' && this.state.clientID) {
                httpBody["client_id"] = this.state.clientID;
            }
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.handleFavouriteApiId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                config.favouriteEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            )

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                config.favouriteMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    fetchFavorites = () => {
        const token = sessionStorage.getItem("Token");

        if (token) {
            const header = {
                "Content-Type": "application/json",
                "token": token
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.fetchFavouriteApiId = requestMessage.messageId;

            let endPoint = config.favouriteEndPoint;

            if (this.state.userType == 'admin' && this.state.clientID) {
                endPoint = endPoint + "?client_id=" + this.state.clientID;
            }
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                endPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                config.fetchFavouriteMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    resetFilters = () => {
        this.setState({ searchKeyword: "" });
        if (this.state.filters === "All") {
            this.setState({ snackBar: { show: true, message: "Filters Already Reseted.", type: "warning" } });
        } else {
            this.setState({
                snackBar: { show: true, message: "Filters Reseted.", type: "success" },
                filters: "All"
            });
        }
    }

    onEmojiClick = (event: any, emojiObject: any) => {
        this.setState({ chosenEmoji: emojiObject }, () => {
            this.setState({ newMessage: this.state.newMessage + this.state.chosenEmoji.emoji + ' ' });
        });
    };

    toggleEmojiPicker = () => {
        this.setState((prev: any) => ({ isEmojiOpen: !prev.isEmojiOpen }));
    }

    loopTheMap = (myMap: any) => { }

    initConversations = async () => {
        (window as any).conversationsClient = ConversationsClient;
        this.conversationsClient = await ConversationsClient.create(
            this.state.token as string
        );
        this.setState({ statusString: "Connecting to Twilio…" });

        this.loopTheMap(this.conversationsClient.conversations.conversations._c)

        this.conversationsClient.on("connectionStateChanged", (state: string) => {
            if (state === "connecting")
                this.setState({
                    statusString: "Connecting to Twilio…",
                    status: "default",
                });
            if (state === "connected") {
                this.setState({
                    statusString: "You are connected with twilio, Fetching Chats...",
                    status: "connected",
                });
            }
            if (state === "disconnecting")
                this.setState({
                    statusString: "Disconnecting from Twilio…",
                    conversationsReady: false,
                    status: "default",
                });
            if (state === "disconnected")
                this.setState({
                    statusString: "Disconnected.",
                    conversationsReady: false,
                    status: "warning",
                });
            if (state === "denied")
                this.setState({
                    statusString: "Failed to connect.",
                    conversationsReady: false,
                    status: "error",
                });
        });


        this.conversationsClient.on("conversationJoined", async (conversation: any) => {
            if (!isFound) {
                const participants = await conversation.getParticipants();
                participants.every((participant: any, index: number) => {
                    if (participant.state.identity === this.state.candidate_email) {
                        isFound = true;
                        this.handleSelectConversation(conversation, index);
                        return false;
                    }
                    return true;
                });
            }

            this.setState({
                conversations: [...this.state.conversations, conversation],
            }, () => {
                if (this.state.conversations.length > 0) {
                    this.retriveLastMessages();
                    this.setState({
                        statusString: "You are connected, select a chat to start chatting.",
                        status: "success",
                    });
                } else {
                    this.setState({
                        statusString: "You are connected, But No conversations found.",
                        status: "error",
                    });
                }
            });
        });

        this.conversationsClient.on("conversationLeft", (thisConversation: any) => {
            this.setState({
                conversations: [
                    ...this.state.conversations.filter(
                        (it: any) => it !== thisConversation
                    ),
                ],
            });
        });
    };

    logOut = (event: any) => {
        if (event) {
            event.preventDefault();
        }

        this.setState({
            token: null,
            statusString: null,
            conversationsReady: false,
            conversations: [],
            selectedConversation: null,
            messages: "",
        });

        this.conversationsClient.shutdown();
    };

    getParticipants = async (conversation: any) => {
        const participants = await conversation.getParticipants();
        if (participants) {
            for (let i = 0; i < participants.length; i++) {
                if (participants[i].state.identity !== sessionStorage.getItem("email")) {
                    return participants[i].state.identity;
                }
            }
        }
    }

    retriveLastMessages = () => {
        this.state.conversations.forEach(async (conversation: any) => {
            const participant = await this.getParticipants(conversation);
            if (participant && !this.state.chatEmails.includes(participant)) {
                this.setState({
                    chatEmails: [...this.state.chatEmails, participant]
                });
            }

            conversation.getMessages(1).then((messagePaginator: any) => {

                if (!this.state.lastMessages.find((it) => it.sid === conversation.sid)
                    && messagePaginator.items.length > 0) {
                    this.setState(prev => (
                        {
                            ...prev, lastMessages: [...prev.lastMessages,
                            {
                                sid: conversation.sid,
                                message: messagePaginator.items[0].state.body ? messagePaginator.items[0].state.body : "Image File",
                                time: formatTime(new Date(messagePaginator.items[0].state.timestamp))
                            }
                            ]
                        }
                    ));
                }
            });
        });
    }

    updateLastMsg = (message: string) => {
        const newLastMsgArr = this.state.lastMessages.map(msg => {
            if (msg.sid === this.state.selectedConversation.sid && msg.message !== message) {
                return { ...msg, message: message, time: formatTime(new Date()) };
            }
            return msg;
        });

        this.setState(prev => ({ ...prev, lastMessages: newLastMsgArr }))
    }

    extractChattingWith = async () => {
        const participant = await this.getParticipants(this.state.selectedConversation);
        if (participant) {
            this.setState({ chattingWith: participant });
        }
    };

    loadMessagesFor = () => {
        this.setState({ isMsgLoading: true });
        this.state.selectedConversation.getMessagesCount().then((count: number) => {
            // TODO: Will use this count, to reload the old messages from the server
            this.setState({ totalMessages: count }, () => {
                // if (this.state.totalMessages > this.state.totalMessages) {
                this.state.selectedConversation.getMessages(7)
                    .then((messagePaginator: any) => {
                        this.setState(
                            {
                                messages: messagePaginator.items,
                                totalMsgLoaded: this.state.totalMsgLoaded + messagePaginator.items.length
                            }
                        );

                        this.updateLastMsg(
                            messagePaginator?.items[6]?.type === "media" ? "Image File" : messagePaginator.items[6].state.body
                        );
                    })
                    .catch((err: any) => {
                        console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                    })
                    .finally(() => {
                        this.setState({ isMsgLoading: false });
                    });
                // }
            });
        });

        // this.state.selectedConversation.getParticipants().then((participants: any) => {
        //     console.log("participants -->", participants[1]);
        // });
    };

    messageAdded = (message: any, targetConversation: any) => {
        if (targetConversation === this.state.selectedConversation) {
            this.updateLastMsg(message.state.body);
            this.setState((prevState, props) => ({
                messages: [...(prevState as any).messages, message]
            }));
        }
    };

    handleSelectConversation = (conversation: any, index: number) => {
        this.setState({ selectedChatIndex: index });
        // Establishing Socket
        if (!this.state.boundConversations.has(conversation)) {
            let newConversation = conversation;
            newConversation.on('messageAdded', (m: any) => this.messageAdded(m, newConversation));
            this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
        }
        this.setState({ selectedConversation: conversation }, () => {
            this.extractChattingWith(); this.loadMessagesFor();
        });

        if (this.state.isEmojiOpen) {
            this.setState({ isEmojiOpen: false });
        }

        if (this.state.windowWidth < 750) {
            this.setState({ showMessageView: true, showMessageList: false })
        }
    };

    handleMsgSend = (event: React.FormEvent) => {
        event.preventDefault();
        this.state.selectedConversation.sendMessage(this.state.newMessage);
        this.updateLastMsg(this.state.newMessage);
        this.setState({ newMessage: "" });
    }

    deleteConversation = () => {
        if (this.state.selectedConversation) {
            const token = sessionStorage.getItem("Token");

            const header = {
                "Content-Type": "application/json",
                "token": token
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.deleteConversationApiId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                config.deleteConversationEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            const deleteBody: any = { conversation_sid: this.state.selectedConversation.sid };
            if (sessionStorage.getItem("user_role") === 'admin')
                deleteBody.client_id = window.location.href.split("?clientId=")[1];;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(deleteBody)
            )

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                config.deleteConversationMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    fetchProfle = () => {
        const token = sessionStorage.getItem("Token");

        const header = {
            "Content-Type": "application/json",
            "token": token
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.profileImageApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            config.profileImageEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ emails: this.state.chatEmails })
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            config.profileImageMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area Start
    async componentDidMount() {
        isFound = false;
        window.scrollTo(0, 0);
        let clientID = +window.location?.search?.split("clientId=")[1];
        let userType = sessionStorage.getItem("user_role");
        this.setState({
            userType: userType || "",
            clientID: clientID,
            email: sessionStorage.getItem("email") || ""
        }, () => {
            // fetch favorites 
            this.fetchFavorites();
            this.getToken();
        });

        if (window.location.href.includes("?email=")) {
            const isClient = userType === "client" || userType == 'admin';
            let email = window.location.href.split("?email=")[1];
            if (email.includes("&")) {
                email = email.split("&")[0];
            }
            this.setState({ candidate_email: email });
            if (isClient) {
                let url = "email-notifications";
                if (userType == 'admin') {
                    url += "?clientId=" + clientID;
                }
                this.props.history.replace(url);
            } else {
                this.props.history.replace(`chats`);
            }
        }

    }

    async componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.chatEmails !== this.state.chatEmails) {
            if (this.state.conversations.length === this.state.chatEmails.length) {
                this.fetchProfle();
            }
        }
    }
    // Customizable Area End
}
