import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import React, { LegacyRef } from "react";
import ReactPlayer from "react-player";
import { challange1, challange2, challange3 } from './assets';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  showRequestDemoModal?: any;
  showRequestDemoSuccessModal?: any;
  showContactSuccessModal?: any;
  handleCloseRequestDemoModal?: () => void;
  handleCloseRequestDemoSuccessModal?: () => void;
  handleCloseContactSuccessModal?: () => void;
  sendRequestForDemoData: (data: any) => void;
  successDemoCall?: () => void;
  open: any;
  onClose: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  showControls1: boolean;
  showControls2: boolean;
  overlay1: boolean;
  overlay2: boolean;
  open: boolean;
  phone: any;
  phoneError: boolean;
  errorMsg: String;
  snackBar: {
    show: boolean;
    message: string;
    type: any
  };
  demoRequestValidationSchema: any;
  videoContent: any;
  challengesContent: any;
  challegeImages: any;
  bannerContent: any;
  openContactModal: any;
  openSuccesModel: boolean;
  successMessage: any;
  openContactSuccessModal: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  classes: any;
  // Customizable Area End
}

export default class LandingPage2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  classes: any = this.props;
  requestDemoCallId: string;
  videoContentCallId: string;
  challengesContentCallId: string;
  bannerContentCallId: string;
  contactUsCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    const validationSchema = Yup.object({
      first_name: Yup.string()
        .trim()
        .min(3, "First name should be of minimum 8 characters length")
        .max(10, "First name should be of maximum 10 characters length")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .required("First name is a required field"),
      last_name: Yup.string()
        .trim()
        .min(3, "Last name should be of minimum 8 characters length")
        .max(10, "Last name should be of maximum 10 characters length")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .required("Last name is a required field"),
      email: Yup.string()
        .trim()
        .email("Email is invalid")
        .max(250, "Email should be of maximum 250 characters length")
        .required("Email is a required field"),
      company_name: Yup.string()
        .trim()
        .matches(/^[a-zA-Z0-9_.-]*$/, "Only alphabets and numbers are allowed for this field ")
        .required("Company name is a required field"),
      phone: Yup.string()
        .trim()
        .required("Phone Number is a required field")
    });
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      showControls1: false,
      showControls2: false,
      overlay1: true,
      overlay2: true,
      open: false,
      phone: null,
      phoneError: false,
      errorMsg: '',
      snackBar: {
        show: false,
        message: "",
        type: "",
      },
      demoRequestValidationSchema: validationSchema,
      videoContent: [],
      challengesContent: [],
      challegeImages: [challange1, challange2, challange3],
      bannerContent: [],
      openContactModal: false,
      openSuccesModel: false,
      successMessage: "",
      openContactSuccessModal: false

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidUpdate(prevProps: any, prevState: any, snapshot: any) { }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("runEngine.debugLog", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        //Demo Request API
        if (
          apiRequestCallId === this.requestDemoCallId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.handleClose();
            this.props.handleCloseRequestDemoModal && this.props.handleCloseRequestDemoModal();
            this.props.successDemoCall && this.props.successDemoCall();
            this.handleOpenSuccessModel();
            this.openSnackBarHandler("success", responseJson?.message);

          } else {
            this.parseApiCatchErrorResponse(errorReponse);
            const errors = responseJson.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors.join(", "));
            }
          }
        }

        // Contact us API
        else if (
          apiRequestCallId === this.contactUsCallId
        ) {
          if (responseJson !== undefined && responseJson.data) {
            this.props.onClose();
            this.handleOpenSuccessContactModel();
            // this.openSnackBarHandler("success", "Your response has been successfully submitted.");

          } else {
            this.parseApiCatchErrorResponse(errorReponse);
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors.join(", "));
            }
          }
        }
        //Video Content API
        else if (
          apiRequestCallId === this.videoContentCallId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.setState({ videoContent: responseJson?.data });
          } else {
            this.parseApiCatchErrorResponse(errorReponse);
            const errors = responseJson?.errors;
            if (errors) {
              this.openSnackBarHandler('error', errors.join(", "));
            }
          }
        }
        //Chanllenges Content API
        else if (
          apiRequestCallId === this.challengesContentCallId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.setState({ challengesContent: responseJson?.data }, () => {
            });
          } else {
            this.parseApiCatchErrorResponse(errorReponse);
            const errors = responseJson?.errors;
            if (errors) {
              this.openSnackBarHandler('error', errors.join(", "));
            }
          }
        }
        // Get banner content
        else if (
          apiRequestCallId === this.bannerContentCallId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.setState({ bannerContent: responseJson?.data[0] })

          } else {
            this.parseApiCatchErrorResponse(errorReponse);
            const errors = responseJson?.errors;
          }
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start


  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  //Request Demo
  setRequestForDemo = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      company_name: values.company_name,
      phone_no: values.phone
    };

    const httpBody = {
      request_demo: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestDemoCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.demorequestAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //Get Video
  doPageContent = (type: string): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (type == 'Home Page Intro Video') {
      this.videoContentCallId = requestMessage.messageId;
    } else {
      this.challengesContentCallId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchContentAPIEndPoint + type
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Get banner 

  getBannerContent = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bannerContentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchBannerContent
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Contact us 
  contactUs = (values: any): boolean => {

    const header = {
      "Content-Type": configJSON.jsonApiContentType
    };

    const data = {
      name: values.first_name,
      email: values.email,
      phone_number: values.phone,
      description: values.message,
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  video1 = () => {
    return React.createRef() as LegacyRef<ReactPlayer>
  }

  video2 = () => {
    // @ts-ignore
    return React.createRef() as LegacyRef<ReactPlayer>
  }

  playVideo = (videoRef: any) => {
    console.log(videoRef.current)
  }

  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false })
  handleOpenSuccessModel = () => this.setState({ openSuccesModel: true });
  handleCloseSuccessModel = () => this.setState({ openSuccesModel: false });
  handleOpenSuccessContactModel = () => this.setState({ openContactSuccessModal: true });
  handleCloseSuccessContactModal = () => this.setState({ openContactSuccessModal: false });

  // Customizable Area End
}
