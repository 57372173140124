import React from "react";
// Customizable Area Start

import { Container, Box, Typography, withStyles, Button, Modal, TextField, Chip, Theme } from "@material-ui/core";
import { Formik } from "formik";
import QuestionBankController, {
    Props,
} from "./QuestionBankController.web";
import 'react-phone-number-input/style.css'
// Customizable Area End

const muiStyles = (theme: Theme) => ({
    textWhite: {
        color: "#ffffff"
    },
    button: {
        color: "#ffffff",
        borderColor: "#ffffff",
        borderRadius: 7,
        padding: "1rem 2rem"
    },
    candidateArea: {
        [theme.breakpoints.down('xs')]: {
            marginTop: "3rem",
        },
    },
    loginBtn: {
        marginRight: "1rem"
    },
    featureIcons: {
        margin: "30px auto",
        display: "flex"
    },
    featureTxt: {
        lineHeight: "1.6rem",
        fontWeight: 400,
        marginTop: "1.2rem",
        marginBottom: "1.2rem"
    },
    testimonialsContainer: {
        backgroundColor: theme.palette.secondary.main + '30'
    },
    clientTxt: {
        color: "#000",
        lineHeight: "1.8rem"
    },
    clientDes: {
        marginTop: "1.1rem",
        fontStyle: "italic",
        fontSize: "0.8rem",
        fontWeight: 500
    },
    clientName: {
        fontSize: "1rem",
    },
    demo: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
        },
    },
    separatorBox: {
        justifyContent: "center",
        display: "flex"
    },
    separator: {
        height: "100%",
        width: 1,
        backgroundColor: theme.palette.secondary.main
    },
    playIcon: {
        width: 64,
        height: 64,
        cursor: 'pointer'
    },
    player: {
        borderRadius: "30px"
    },
    overlay: {
        border: "20px solid #ececec",
        borderRadius: "70px",
        top: "-21px",
        left: "-20px",
    },
    Bgrey: {
        backgroundColor: "#ececec"
    },
    link: {
        textDecoration: 'none'
    },
    phoneNumberContainer: {
        "& .PhoneInputInput": {
            border: 0,
            borderBottom: "1px solid #949494",
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "rgb(125, 124, 132)",
            letterSpacing: "0.15008px"
        }
    },
    fullBtnCancel: {
        backgroundColor: "#EBEBEB",
        marginRight: "0.5em"
    },
    fullBtnSubmit: {
        marginLeft: "0.5em"
    },
    modalContainer: {
        top: "50%",
        left: "50%",
        width: "37.125em",
        padding: "32px",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        background: "#fff",

        [theme.breakpoints.down('sm')]: {
            width: "75%",
        },
        [theme.breakpoints.down('xs')]: {
            width: "95%",
            padding: "32px 0px"
        }
    },
    chip: {
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.2rem"
        }
    }
});

// Customizable Area End
class EditRolePreferenceModal extends QuestionBankController {
    roleArray: any[];
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.setState({ prefferedRolesArray: this.props.profileData?.preferred_role_ids || [] });
    }

    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            <Modal
                open={this.props.showEditRoleModal}
                onClose={this.props.handleCloseEditRoleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modalContainer}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex="1">
                            <Typography color="primary" align="center" variant="h5">
                                Edit or Add Preferred Roles
                            </Typography>
                        </Box>
                        {/* <IconButton onClick={this.props.handleCloseEditRoleModal} aria-label="Close">
                            <CloseIcon />
                        </IconButton> */}
                    </Box>
                    <Container maxWidth="sm">
                        <Box>
                            <Formik
                                initialValues={{
                                    current_compensation: this.props.profileData?.other_details?.current_compensation,
                                    expected_compensation: this.props.profileData?.other_details?.expected_compensation,
                                    months: this.props.profileData?.other_details?.notice_period?.month,
                                    location_preference: this.props.profileData?.other_details?.location_preference,
                                    days: this.props.profileData?.other_details?.notice_period?.data,
                                    preferred_role_ids: this.state.prefferedRolesArray || [],
                                    initial_preferred_role_ids: [],
                                    deleted_role: [],
                                    preferedRole: "",
                                }}
                                // validationSchema={this.state.updateRoleValidationSchema}
                                onSubmit={(values) => {
                                    if (values.initial_preferred_role_ids.length == 0 && values.deleted_role.length == 0) {
                                        this.props.handleCloseEditRoleModal();
                                    } else {
                                        this.props.sendRequestForUpdateRole(values, "roles");
                                    }
                                }}>
                                {(formikProps) => (
                                    <form onSubmit={formikProps.handleSubmit} onKeyDown={this.onKeyDownCheckForRole}>
                                        <Box my={5}>
                                            <TextField
                                                className="preferredRoleChip"
                                                style={{ width: "100%" }}
                                                id="preferedRole"
                                                name="preferedRole"
                                                onChange={(e) => {
                                                    if (!/[^a-zA-Z ]/.test(e.target.value)) {
                                                        formikProps.handleChange(e);
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (formikProps.values.preferedRole) {
                                                        if (e.keyCode == 13) {
                                                            let roleArray = [...this.state.prefferedRolesArray];
                                                            roleArray.push(formikProps.values.preferedRole);
                                                            this.setState({ prefferedRolesArray: [...roleArray] })
                                                            formikProps.setFieldValue("preferred_role_ids", roleArray);
                                                            let data: any = [...formikProps.values.initial_preferred_role_ids];
                                                            data.push(formikProps.values.preferedRole);
                                                            formikProps.setFieldValue("initial_preferred_role_ids", data);
                                                            formikProps.setFieldValue("preferedRole", "");
                                                        }
                                                    }
                                                }}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.preferedRole}
                                                error={formikProps.touched.preferedRole && Boolean(formikProps.errors.preferedRole)}
                                                helperText={formikProps.touched.preferedRole && formikProps.errors.preferedRole}
                                                placeholder="Type Preferred Role here and Press Enter to Save" />
                                        </Box>
                                        <Box my={5}>
                                            {this.state.prefferedRolesArray.map((chip: any, index: any) => (
                                                <Chip
                                                    label={chip} key={index}
                                                    onDelete={this.handleDeleteChip(chip, formikProps.setFieldValue, formikProps.values.initial_preferred_role_ids, formikProps.values.deleted_role)}
                                                    style={{ marginRight: "0.6em", borderRadius: 3, marginBottom: "0.6em" }}
                                                    className={classes.chip}
                                                />
                                            ))}
                                        </Box>

                                        <Box justifyContent="center" display="flex" style={{ marginTop: "6em" }}>
                                            <Button
                                                type="button" className={classes.fullBtnCancel} disableRipple variant="contained" color="secondary" onClick={this.props.handleCloseEditRoleModal}>
                                                <Typography variant="body2">
                                                    Cancel
                                                </Typography>
                                            </Button>
                                            <Button
                                                type="submit" className={classes.fullBtnSubmit} disableRipple variant="contained" color="primary">
                                                <Typography variant="body2">
                                                    Save Changes
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Container>
                </Box>
            </Modal >
        );
    }
}

// Customizable Area Start
// @ts-ignore
export default withStyles(muiStyles)(EditRolePreferenceModal)
// Customizable Area End
