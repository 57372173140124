import React, { FC, ReactNode, Ref, useCallback, useEffect, useRef, useState } from "react";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { Box, Divider, makeStyles, Theme, Tooltip, Typography, withStyles } from "@material-ui/core";
import { NotificationsOutlined } from "@material-ui/icons";
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import moment from "moment";
import SelectTimeSlotModal from "../../blocks/QuestionBank/src/selectTimeSlot.web";
import { withRouter } from "react-router-dom";

// Customizable Area Start
// Customizable Area End

const dummyNoti = [{
    id: 1,
    title: "Selected for interview",
    message: "You have been selected for an interview, You have been selected for an interview.",
}, {
    id: 2,
    title: "Interview scheduled",
    message: "Your interview has been scheduled",
}, {
    id: 3,
    title: "Interview cancelled",
    message: "Your interview has been cancelled, Your interview has been cancelled, Your interview has been cancelled",
}, {
    id: 4,
    title: "Interview completed",
    message: "Your interview has been completed",
}, {
    id: 5,
    title: "Interview failed",
    message: "Your interview has been failed.Your interview has been failed.Your interview has been failed",
}, {
    id: 6,
    title: "Shortlisted",
    message: "You have been shortlisted",
},
]

const useStyles = makeStyles(theme => ({
    singleNotification: {
        padding: ".4rem .8rem .6rem .5rem",
        cursor: "pointer",
        transition: "all .3s ease-in-out",

        "&:hover": {
            background: "#f0f0f0",
            "&:last-child": {
                borderBottomLeftRadius: "1rem",
                borderBottomRightRadius: "1rem"
            }
        },

        "&:last-child": {
            paddingBottom: "1rem"
        },

        "& h5": {
            color: theme.palette.primary.main,
        },

        "& p": {
            color: "#000",
            fontSize: ".8rem",
            fontFamily: "sans-serif",
        },

        "& svg": {
            color: theme.palette.primary.main,
            background: "#d3e7f3",
            padding: ".5rem",
            borderRadius: ".5rem",
            margin: "0 1rem"
        }
    },
    notiDivide: {
        background: "#e6e6e6"
    }
}));

interface NotificationModalProps {
    notifications: any;
    noNotifications: boolean;
    currentPage: number;
    totalPage: number;
    isLoading: boolean;
    fetchNotifications: () => void;
    setInterviewData: (data: any) => void;
    history: any;
    toggleNotificationModal: () => void;
    isMobile: boolean;
}

interface RefObject {
    disconnect: () => void;
    observe: (node: any) => void;
}

const NotificationModal: FC<NotificationModalProps> = (
    { notifications, noNotifications, currentPage, totalPage, isLoading, isMobile,
        fetchNotifications, setInterviewData, history, toggleNotificationModal }
) => {
    const styles = useStyles();

    const observer = useRef<RefObject>();
    const lastNotiRef = useCallback(
        (node: any) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) fetchNotifications();
            });
            if (node) observer.current.observe(node);
        }, []);

    // disconnect the observer when we reaches the end of the page
    if (currentPage >= totalPage) {
        if (observer.current) observer.current.disconnect();
    }

    const handleClick = (notification: any) => {
        if (notification.attributes.notification_type === "accepted_feedback_summary_now_avalible") {
            history.push(`?tab=1&role_id=${notification.attributes.notificable.applied_job_id}`);
        } else if (notification.attributes.notification_type === "rejected_feedback_summary_now_avalible") {
            history.push(`?tab=2&role_id=${notification.attributes.notificable.applied_job_id}`);
        } else if (notification.attributes.notificable?.id) {
            setInterviewData(notification.attributes.notificable);
        } else if (notification.attributes.notificable?.role_id) {
            toggleNotificationModal();
            history.push('/job-detail/' + notification.attributes.notificable?.role_id);
        }
    }

    return (
        <Box>
            <Box style={{ textAlign: "center" }} mt={2.5} mb={2.5}>
                <Typography variant="h4" style={{ color: "#000", fontSize: "1.4rem" }}>Notifications</Typography>
            </Box>
            <Divider className={styles.notiDivide} />
            <Box style={{ overflow: "auto", maxHeight: !isMobile ? "23rem" : "30rem" }}>
                {notifications.map((noti: any, index: number) => {
                    return (
                        <div key={index} onClick={() => handleClick(noti)}
                            ref={currentPage <= totalPage && index === notifications.length - 1 ? lastNotiRef : undefined}>
                            <Box className={styles.singleNotification}>
                                <Box style={{ textAlign: "right" }}>
                                    <Typography variant="body1"
                                        style={{
                                            fontStyle: "italic", color: "#a7a7ad", fontWeight: 600,
                                            opacity: noNotifications ? 0 : 1
                                        }}>
                                        {moment(noti.attributes.created_at).fromNow()}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mt={-.5}>
                                    {noNotifications || !noti.attributes?.notificable ?
                                        <NotificationsActiveOutlinedIcon /> : <DateRangeOutlinedIcon />
                                    }
                                    <Box>
                                        <Typography variant="h5"
                                            style={{ fontSize: ".85rem", fontWeight: "bold" }}>
                                            {noti.attributes.headings}
                                        </Typography>
                                        {!noNotifications && noti.attributes?.notificable ?
                                            <Box display="flex" mt={.5}>
                                                <Typography style={{ marginRight: "2rem" }}
                                                    variant="body2">Role: {noti.attributes?.notificable.role}</Typography>
                                                <Typography
                                                    variant="body2">Company: {noti.attributes?.notificable.client}</Typography>
                                            </Box> :
                                            <Box mt={.5}>
                                                <Typography>
                                                    {noti.attributes?.contents}
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                            {index !== notifications.length - 1 && <Divider className={styles.notiDivide} />}
                        </div>
                    )
                })}
                {isLoading && currentPage < totalPage && <Typography variant="body1" style={{ color: "#a7a7ad" }} align="center">
                    Fetching notifications ...
                </Typography>}
            </Box>
        </Box>
    )
}

export interface Props {
    // Customizable Area Start
    classes: any;
    history: any;
    isMobile?: boolean;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showNotification: boolean;
    meta: {
        totalPage: number;
        currentPage: number;
        unReadMessages: number;
    };
    notifications: any;
    noNotifications: boolean;
    isNotificationLoading: boolean;
    selectSlotModalOpen: boolean;
    slotDetails: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

const NotificationToolTip = withStyles((theme: Theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            background: "transparent"
        }
    },
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED",
        },
        color: theme.palette.common.white,
        fontSize: 25,
        left: "185px !important",
    },
    tooltip: {
        minWidth: "27rem",
        background: theme.palette.common.white,
        boxShadow: "0px .3rem .8rem rgba(0, 0, 0, 0.30)",
        borderRadius: ".8rem",
        padding: ".2rem 0 0",
        overflow: "hidden",
    },
}))(Tooltip);

export default class Notifications extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    notificationApiCallId: string;
    readNotificationApiCallId: string;
    notificationApiInterval: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            showNotification: false,
            meta: {
                totalPage: 1,
                currentPage: 0,
                unReadMessages: 0,
            },
            notifications: [],
            noNotifications: false,
            isNotificationLoading: false,
            selectSlotModalOpen: false,
            slotDetails: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.notificationApiCallId) {
                    this.setState({ isNotificationLoading: false });
                    if (responseJson?.errors) {
                        if (this.state.notifications.length === 0) {
                            const newNotifications = {
                                attributes: {
                                    id: 1,
                                    headings: responseJson.errors[0].message,
                                    updated_at: new Date(),
                                }
                            };
                            this.setState({ notifications: [newNotifications], noNotifications: true });
                        } else return;
                    } else {
                        // set meta
                        this.setState({
                            meta: {
                                currentPage: responseJson.meta.page,
                                totalPage: responseJson.meta.totalPage,
                                unReadMessages: responseJson.meta.unread,
                            }
                        });
                        // set notifications
                        if (this.state.meta.currentPage === 1) {
                            this.setState({ notifications: responseJson.data, noNotifications: false });
                        } else {
                            this.setState(
                                {
                                    notifications: [...this.state.notifications, ...responseJson.data],
                                    noNotifications: false
                                });
                        }
                    }
                }
                // handle candidate details
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.handleFetchNotifications();
        this.notificationApiInterval = setInterval(() => {
            if (!this.state.showNotification) {
                this.setState({ meta: { ...this.state.meta, currentPage: 1 } }, () => {
                    this.fetchNotifications();
                });
            }
        }, 5000);
    }

    async componentWillUnmount() {
        clearTimeout(this.notificationApiInterval);
    }

    toggleNotification = () => {
        if (this.state.showNotification === false) {
            this.readNotification();
        }

        this.setState({
            showNotification: !this.state.showNotification,
        });

        if (this.state.meta.unReadMessages > 0) {
            this.setState({ meta: { ...this.state.meta, unReadMessages: 0 } });
        }
    }

    toggleSlotModal = () => {
        this.setState({ selectSlotModalOpen: !this.state.selectSlotModalOpen });
    }

    setInterviewData = (slotDetails: any) => {
        const newSlotDetails = {
            ...slotDetails,
            company: slotDetails.client
        }

        this.setState({ slotDetails: newSlotDetails, showNotification: false }, () => {
            this.toggleSlotModal();
        });
    }
    // Customizable Area End

    // Customizable Area Start

    handleFetchNotifications = () => {
        this.setState({
            isNotificationLoading: true,
            meta: { ...this.state.meta, currentPage: this.state.meta.currentPage + 1 }
        }, () => {
            if (this.state.meta.currentPage <= this.state.meta.totalPage) {
                this.fetchNotifications();
            }
        });
    }

    fetchNotifications = () => {
        const header = {
            "Content-Type": "application/json",
            'token': sessionStorage.getItem('Token')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.notificationApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_notifications/notifications?page=${this.state.meta.currentPage}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // be default it'll read all the notifications
    readNotification = () => {
        const header = {
            "Content-Type": "application/json",
            'token': sessionStorage.getItem('Token')
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.readNotificationApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_notifications/notifications'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

    render(): ReactNode {

        const { notifications, showNotification, noNotifications } = this.state;
        const { classes } = this.props;

        return (
            <>
                {this.state.selectSlotModalOpen && <SelectTimeSlotModal
                    navigation=""
                    id="selectTimeSlotModal"
                    toggleModal={this.toggleSlotModal}
                    handleCloseModal={this.toggleSlotModal}
                    isOpen={this.state.selectSlotModalOpen}
                    showEditProfileModal={false}
                    handleCloseEditProfileModal={() => { }}
                    sendRequestForUpdateProfile={(data: any) => { }}
                    showEditOtherDetailModal={false}
                    handleCloseEditOtherDetailModal={() => { }}
                    sendRequestForUpdateOtherDetail={(data: any, type: string) => { }}
                    showEditRoleModal={false}
                    handleCloseEditRoleModal={() => { }}
                    sendRequestForUpdateRole={(data: any, type: string) => { }}
                    profileData=""
                    slotDetails={this.state.slotDetails}
                />}

                <NotificationToolTip
                    title={
                        <NotificationModal
                            notifications={notifications}
                            noNotifications={noNotifications}
                            currentPage={this.state.meta.currentPage}
                            totalPage={this.state.meta.totalPage}
                            isLoading={this.state.isNotificationLoading}
                            fetchNotifications={this.handleFetchNotifications}
                            setInterviewData={this.setInterviewData}
                            history={this.props.history}
                            toggleNotificationModal={this.toggleNotification}
                            isMobile={(this.props.isMobile as boolean)}
                        />}
                    placement="bottom"
                    open={showNotification}
                    onClose={this.toggleNotification}
                    interactive
                    arrow
                    disableFocusListener={this.props.isMobile}
                    disableHoverListener={this.props.isMobile}
                    disableTouchListener={this.props.isMobile}
                >
                    <Box style={{ position: "relative" }} width={"100%"}>
                        {this.state.meta.unReadMessages > 0 && (
                            <Box className={classes.notiCount}>
                                <Typography variant="h6" className={classes.notification}
                                    style={{ marginLeft: this.state.meta.unReadMessages < 10 ? "0.5rem" : "0.2rem", }}>
                                    {this.state.meta.unReadMessages < 10 ? this.state.meta.unReadMessages : "9+"}
                                </Typography>
                            </Box>
                        )}
                        <Box display='flex' onClick={this.toggleNotification}>
                            {!showNotification && <NotificationsOutlined className={classes.iconStyle} />}
                            {showNotification && <NotificationsIcon className={classes.iconStyle} style={{ fill: "#24325f" }} />}
                            {this.props.children && this.props.children}
                        </Box>
                    </Box>
                </NotificationToolTip>
            </>
        );
    }
}