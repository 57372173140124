import React from 'react';
import { Box } from '@material-ui/core';
import OpenRolesController, { Props } from "./OpenRolesController.web";
import OpenRoleGridItem from './OpenRoleGridItem.web';
class OpenRolesGridView extends OpenRolesController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                {this.props?.jobRoles && this.props?.jobRoles?.map((role: any, index: number) => {
                    return (
                        <Box mb={3} key={role?.id} >
                            <div style={{ marginBottom: "1em" }} key={index}
                                ref={this.props.jobRoles?.length === index + 1 ? this.lastRoleItem : null}>
                                <OpenRoleGridItem
                                    role={role}
                                    classes={classes}
                                    isExpand={this.props?.isExpand}
                                    history={this.props.history}
                                    windowWidth={this.props.windowWidth}
                                    candidateListScroll={this.props.candidateListScroll}
                                    handleViewAll={(id: number | undefined) => this.props.handleViewAll(id)}
                                    handleSendStatus={(data: any) => { this.props.handleSendStatus?.(data, role?.attributes?.position, role?.attributes?.accepted_candidate); }}
                                    hasMoreCandidateData={this.props.hasMoreCandidateData}
                                    infiniteCandidateLoading={this.props.infiniteCandidateLoading}
                                    startLoading={this.props.startLoading}
                                    createCoversation={this.props.createCoversation}
                                    clientIDLink={this.props.clientIDLink}
                                />
                            </div>
                        </Box>
                    );
                })}
            </>
        );
    }
}

export default OpenRolesGridView;