import React, { Fragment } from "react";

import { Container, Box, Typography, withStyles, Button, Grid, AppBar, Paper, makeStyles } from "@material-ui/core";
import { banner } from './assets';

import AccountScorerankingController, { Props } from "./AccountScorerankingController";

class AccountScoreranking extends AccountScorerankingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getVerticals();
  }
  // Customizable Area End

  render() {
    const { classes } = this.props;

    return (
      <Box style={{ background: "#ececec" }}>
        <Box className={classes.Bgrey}>
          <Fragment>
            <Container className={classes.banner}>
              <Box flex={1}>
                <Typography className={classes.textWhite} variant="h2">
                  Verticals
                </Typography>
              </Box>
            </Container>

            <Container maxWidth="lg" style={{ marginTop: "60px", marginBottom: "60px" }}>
              <Typography align="center" color="primary" variant="h4">
                Business Functions
              </Typography>
            </Container>

            {/* tables */}

            <Container maxWidth="lg">
              {this.state.data.length > 0 &&
                this.state.data.map((item: any) => {
                  const lastRows = this.getRowNumbers(item.attributes.category.length);
                  return (
                    <Box key={item.id} className={classes.container}>
                      <Typography align="center" color="primary" variant="h5">
                        {item.attributes.name}
                      </Typography>


                      {item.attributes.category.length > 0 ?
                        <Box display="flex" className={classes.wrapper}>
                          {item.attributes.category.map((category: any, index: number) => {
                            const isOdd = this.isOddRow(item.attributes.category.length, index);

                            return category.sub_categories.length > 0 ?
                              (
                                <Box
                                  key={index}
                                  className={`content ${classes.addBorderRadius} mobileMargin`}
                                >
                                  <Typography align="center" color="primary" variant="h5">{category.name}</Typography>
                                  <Box>
                                    {category.sub_categories.map((subCategory: any, index: number) => {
                                      return (
                                        <Typography
                                          align="center"
                                          color="primary"
                                          variant="h6" key={index}
                                          style={{
                                            background: index % 2 === 0 ? "#eff7fa" : "",
                                            padding: index % 2 === 0 ? "1rem 0" : "auto"
                                          }}
                                        >
                                          {subCategory.name}
                                        </Typography>
                                      )
                                    })}
                                  </Box>
                                </Box>
                              ) :
                              (
                                <Box key={index}
                                  className={`content ${lastRows.includes(index) ? classes.addBorderRadius : ""}`}
                                  style={{
                                    padding: isOdd ? "0 1rem" : "auto",
                                    paddingBottom: lastRows.includes(index) ? "1rem" : "auto"
                                  }}>
                                  {isOdd ?
                                    <Box style={{ background: "#eff7fa" }}>
                                      <Typography align="center" color="primary" variant="h6" style={{ padding: "1rem 0" }}>
                                        {category.name}
                                      </Typography>
                                    </Box> :
                                    <Typography align="center" color="primary" variant="h6">
                                      {category.name}
                                    </Typography>
                                  }
                                </Box>
                              )
                          })}
                        </Box>
                        : <Box className={`content ${classes.addBorderRadius}`}>
                          <Typography align="center" color="primary" variant="body1">No Records Found</Typography>
                        </Box>
                      }
                    </Box>
                  )
                })}
            </Container>
          </Fragment>
        </Box>
      </Box>
    );
  }
}




// Customizable Area Start
const muiStyles = (theme: any) => ({
  banner: {
    padding: "1rem 2rem",
    maxWidth: "100%",
    backgroundImage: `url(${banner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('sm')]: {
      padding: "8rem 5rem",
    },
  },
  textWhite: {
    color: "#ffffff"
  },
  container: {
    margin: "3rem 0",

    "& > div": {
      flex: 1,
      flexWrap: "wrap",
    },

    "& h5": {
      marginBottom: "1rem",
    },

    "& .mobileMargin": {
      [theme.breakpoints.down('sm')]: {
        marginBottom: "1rem",
      }
    },

    "& .content": {
      padding: "1rem",
      background: "#fff",
      marginRight: "1rem",
      minWidth: "29%",
      minHeight: "auto",
      paddingLeft: "1rem",

      [theme.breakpoints.down('md')]: {
        minWidth: "28%",
      },

      "& h5": {
        fontSize: "1.2rem",
      },

      "& h6:not(:last-child)": {
        marginBottom: "1.3rem",
      },

      "&:first-child": {
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",

        [theme.breakpoints.down('sm')]: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }
      },

      "&:nth-child(2)": {
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",

        [theme.breakpoints.down('sm')]: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }
      },

      "&:nth-child(3)": {
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",

        [theme.breakpoints.down('sm')]: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }
      },
    }
  },

  addBorderRadius: {
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",

    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },

  wrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
});

// @ts-ignore
export default withStyles(muiStyles)(AccountScoreranking);
// Customizable Area End
