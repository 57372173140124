import React from "react";
import ScoringController, {
    Props,
    configJSON
} from "./ScoringController.web";
// Customizable Area Start
import styles from "./Styles.module.scss";
import { Button, Grid, Link, ListItemText, MenuItem, OutlinedInput, Select, Typography, Tooltip } from "@material-ui/core";
import AppSidebar from "../../../components/src/AppSidebar";
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import InterviewFeedBack from "./InterviewFeedBack.web";
import { Rating } from "@material-ui/lab";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
// Customizable Area End


class AssessmentDetail extends ScoringController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        window.addEventListener('resize', this.checkWindowResize);
        window.scrollTo(0, 0);

        let clientID = +this.props.location?.search?.split("clientId=")[1];
        this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID }, () => {
            if (this.state.userType == 'client' || (this.state.userType == 'admin' && clientID)) {
                this.getFeedbackOptionList();
                //@ts-ignore
                this.getCandidateAssesmentDetail(this.props.match.params?.id);
            } else {
                this.props.history.push(`/clients-list`);
            }

            this.clientIDLink = this.state.userType == "admin" && this.state.clientID ? "clientId=" + this.state.clientID : "";
        });
    }
    async componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowResize);
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <div className={styles.pageWrap}>
                    {/* <AppBar position="fixed" className={styles.topAppBar}>
                        <TopHeader />
                    </AppBar> */}
                    <div className={styles.pageSection}>
                        <div className={styles.pageContentWrap}>
                            <AppSidebar />

                            <main className={styles.pageContent}>
                                <div className={styles.headContainer} >
                                    <div className={styles.leftItem}>
                                        <h1 className={styles.pageHead}>Candidate Assessment</h1>
                                        <p className={styles.pageHeadBreadCrumb}>
                                            <Link href={`/candidate-scoring${this.clientIDLink ? "?" + this.clientIDLink : ""}`}>Candidate Scoring</Link> {`> ${this.state.assessmentDetail?.attributes?.candidate_name}`}
                                        </p>
                                    </div>
                                    <div className={styles.rightItem}>
                                        {!this.state.assessmentDetail?.attributes?.is_closed &&
                                            <Button
                                                type="button" className={styles.fullBtnAccept} disableRipple variant="contained" onClick={this.handleFeedBackModal} color="primary">
                                                <Typography variant="body2">
                                                    Feedback
                                                </Typography>
                                            </Button>
                                        }
                                        {/* <Button variant="contained" >Feedback</Button> */}
                                    </div>

                                </div>
                                <div className={styles.bodyContainer}>
                                    <div className={styles.bodyCard}>
                                        <Grid container className={styles.canDetail}>
                                            <Grid item xl={1} lg={1} md={1} sm={12} xs={12} className={styles.canImg}>
                                                <img
                                                    src={this.state.assessmentDetail?.attributes?.photo ?
                                                        this.state.assessmentDetail?.attributes?.photo :
                                                        require("../../user-profile-basic/assets/no-profile-picture.svg")}
                                                />
                                            </Grid>
                                            <Grid item xl={11} lg={11} md={11} sm={12} xs={12} className={styles.canData}>
                                                <Grid container >

                                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={6} className={styles.canItem}>
                                                        <p className={styles.canTitle}>Candidate Name</p>
                                                        <h3 className={styles.canValue}>{this.state.assessmentDetail?.attributes?.candidate_name}</h3>
                                                    </Grid>
                                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={6} className={styles.canItem}>
                                                        <p className={styles.canTitle}>Role</p>
                                                        <h3 className={styles.canValue}>{this.state.assessmentDetail?.attributes?.role}</h3>
                                                    </Grid>
                                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={6} className={styles.canItem}>
                                                        <p className={styles.canTitle}>AI Score</p>
                                                        <h3 className={styles.canValue}>{this.state.assessmentDetail?.attributes?.initial_score}</h3>
                                                    </Grid>
                                                    <Grid item xl={3} lg={3} md={3} sm={3} xs={6} className={styles.canItem}>
                                                        <p className={styles.canTitle}>Final Score</p>
                                                        <h3 className={styles.canValue}>{this.state.assessmentDetail?.attributes?.final_score || 0}</h3>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <Grid container spacing={2}>
                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={`${styles.bodyCard} ${styles.assessmentWrap}`}>
                                                <div className={styles.asseHead}>
                                                    Assessment Test Scores
                                                </div>
                                                {this.state.assessmentDetail?.attributes?.assessment_test_scores && this.state.assessmentDetail?.attributes?.assessment_test_scores?.length > 0 ? this.state.assessmentDetail?.attributes?.assessment_test_scores?.map((data: any, index: number) => (
                                                    <div className={styles.asseContent} key={index}>
                                                        <div className={styles.asseLeftContent}>
                                                            <p className={styles.asseLeftData}>{data?.name}</p>
                                                        </div>
                                                        <div className={styles.asseRightContent}>
                                                            <div className={styles.asseRightContentItemI}>
                                                                <p className={styles.asseRDataHead}>Score</p>
                                                                <p className={styles.asseRDataValue}>{data?.score || 0}</p>
                                                            </div>
                                                            <div className={styles.asseRightContentItemII}>
                                                                <p className={styles.asseRDataHead}>Pass</p>
                                                                <p className={styles.asseRDataValue}>{data?.status || "-"}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )) : <p className={styles.noDatAv}>No test scores assessment data</p>}
                                            </div>
                                        </Grid>

                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={`${styles.bodyCard} ${styles.assessmentWrap}`}>
                                                <div className={styles.asseHead}>
                                                    HR Assessment
                                                </div>
                                                {this.state.assessmentDetail?.attributes?.hr_assessment ? this.state.assessmentDetail?.attributes?.hr_assessment?.map((data: any, index: number) => (
                                                    <div className={styles.asseContent} key={index}>
                                                        <div className={styles.asseLeftContent}>
                                                            <p className={styles.asseLeftData}>{`Interview ${index + 1}`}</p>
                                                        </div>
                                                        <div className={styles.asseRightContent}>
                                                            <div className={styles.asseRightContentItemI}>
                                                                <p className={styles.asseRDataHead}>Interviewer</p>
                                                                <p className={styles.asseRDataValueA}>{data?.interviewer_name || "-"}</p>
                                                            </div>
                                                            <div className={styles.asseRightContentItemII}>
                                                                <p className={styles.asseRDataHead}>Feedback</p>
                                                                {data.feedback ?
                                                                    <>
                                                                        <Rating
                                                                            name="ratingdata"
                                                                            value={data?.rating || 0}
                                                                            className={styles.ratinCl}
                                                                            precision={0.5}
                                                                            readOnly
                                                                        />
                                                                        <Tooltip title={data.feedback} arrow>
                                                                            <div className={styles.ratingTooltip}></div>
                                                                        </Tooltip>
                                                                    </> : <p className={styles.asseRDataValueA}>-</p>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                )) : <p className={styles.noDatAv}>No HR assessment data</p>}
                                            </div>
                                        </Grid>

                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={`${styles.bodyCard} ${styles.assessmentWrap}`}>
                                                <div className={styles.asseHead}>
                                                    Video Interview
                                                </div>
                                                {this.state.assessmentDetail?.attributes?.video_interview ? this.state.assessmentDetail?.attributes?.video_interview?.map((data: any, index: number) => (
                                                    <div className={styles.asseContent} key={index}>
                                                        <div className={styles.asseLeftContent}>
                                                            <p className={styles.asseLeftData}>{`Interview ${index + 1}`}</p>
                                                        </div>
                                                        <div className={styles.asseRightContent}>
                                                            <div className={styles.asseRightContentItemI}>
                                                                <p className={styles.asseRDataHead}>Interviewer</p>
                                                                <p className={styles.asseRDataValueA}>{data?.interviewer_name || "-"}</p>
                                                            </div>
                                                            <div className={styles.asseRightContentItemII}>
                                                                <p className={styles.asseRDataHead}>Feedback</p>
                                                                {data?.feedback ?
                                                                    (<>
                                                                        <Rating
                                                                            name="ratingdata"
                                                                            value={data?.rating || 0}
                                                                            className={styles.ratinCl}
                                                                            precision={0.5}
                                                                            readOnly
                                                                        />
                                                                        <Tooltip title={data.feedback} arrow>
                                                                            <div className={styles.ratingTooltip}></div>
                                                                        </Tooltip>
                                                                    </>)
                                                                    : <p className={styles.asseRDataValueA}>-</p>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                )) : <p className={styles.noDatAv}>No video interview assessment data</p>}
                                            </div>
                                        </Grid>

                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className={`${styles.bodyCard} ${styles.assessmentWrap}`}>
                                                <div className={styles.asseHead}>
                                                    Hiring Manger Assessment
                                                </div>
                                                {this.state.assessmentDetail?.attributes?.hiring_manger_assessment ? this.state.assessmentDetail?.attributes?.hiring_manger_assessment?.map((data: any, index: number) => (
                                                    <div className={styles.asseContent} key={index}>
                                                        <div className={styles.asseLeftContent}>
                                                            <p className={styles.asseLeftData}>{`Interview ${index + 1}`}</p>
                                                        </div>
                                                        <div className={styles.asseRightContent}>
                                                            <div className={styles.asseRightContentItemI}>
                                                                <p className={styles.asseRDataHead}>Interviewer</p>
                                                                <p className={styles.asseRDataValueA}>{data?.interviewer_name || "-"}</p>
                                                            </div>
                                                            <div className={styles.asseRightContentItemII}>
                                                                <p className={styles.asseRDataHead}>Feedback</p>
                                                                {data?.rating ?
                                                                    (<>
                                                                        <Rating
                                                                            name="ratingdata"
                                                                            value={data?.rating || 0}
                                                                            className={styles.ratinCl}
                                                                            precision={0.5}
                                                                            readOnly
                                                                        />
                                                                        <Tooltip title={data.feedback} arrow>
                                                                            <div className={styles.ratingTooltip}></div>
                                                                        </Tooltip>
                                                                    </>)
                                                                    : <p className={styles.asseRDataValueA}>-</p>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                )) : <p className={styles.noDatAv}>No hiring manger assessment data</p>}
                                            </div>
                                        </Grid>

                                    </Grid>

                                </div>
                            </main>
                        </div>
                    </div>
                </div>

                {/* Snackbar for display success and failed messages. */}
                <Snackbar
                    open={this.state.snackBar.show}
                    autoHideDuration={3000}
                    onClose={this.closeSnackBarHandler}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.closeSnackBarHandler}
                        severity={this.state.snackBar.type}
                    >
                        {this.state.snackBar.message}
                    </MuiAlert>
                </Snackbar>

                {this.state.showInterviewFeedbackModal ?
                    <InterviewFeedBack
                        showInterviewFeedback={this.state.showInterviewFeedbackModal}
                        onCloseShowInterviewFeedback={this.handleFeedBackModal}
                        candidateData={this.state.assessmentDetail}
                        navigation={""} id={""} history={this.props.history}
                        clientID={this.state.clientID}
                    />
                    : ""}
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
//@ts-ignore
export default withRouter(AssessmentDetail) as AssessmentDetail;
 // Customizable Area End