Object.defineProperty(exports, "__esModule", {
    value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MerchantDashboard2";
exports.labelBodyText = "MerchantDashboard2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.getAPIMethod = "GET";
exports.jsonApiContentType = "application/json";
exports.createNewRoleAPIEndPoint = "bx_block_roles_permissions/roles";
exports.createNewRoleWithFileJDAPIEndPoint = "bx_block_sovren/create_jd";
exports.getAllRolesAPIEndPoint = "bx_block_roles_permissions/get_roles";
exports.getJDTemaplateDataAPIEndPoint = "bx_block_job_description/show";
exports.getRoleAllDataAPIEndPoint =
    "bx_block_suggestion/job_suggestions/?name=";
exports.getJobDetailsAPiEndPoint = "bx_block_roles_permissions/roles?id=";
exports.updateAutomatedJDEndPoint = "bx_block_sovren/update_jd/";
// Customizable Area End
