Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LinkShare";
exports.labelBodyText = "LinkShare Body";

exports.btnExampleTitle = "CLICK ME";

exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.getAPIMethod = "GET";
exports.deleteAPIMethod = "DELETE";
exports.jsonApiContentType = "application/json";
exports.jsonApiBody = { is_closed: false }
exports.getAllRolesAPIEndPoint = "bx_block_roles_permissions/apply_candidate_list";
exports.searchRoleAPIEndPoint = "bx_block_roles_permissions/search_role";
exports.acceptRejectRoleAPIEndPoint = "bx_block_roles_permissions/applied_jobs/proceed";
exports.candidateDetailsApiEndpoint = "bx_block_scheduling/profile";
exports.schedulingApiEndpoint = "bx_block_scheduling/schedule_interviews";
exports.managerNamesApiEndpoint = "bx_block_scheduling/interviewers";
exports.getAllCandidates = "bx_block_roles_permissions/apply_candidate_list";
exports.closeJobRoleApiEndPoint = "bx_block_roles_permissions/close_role";
exports.deleteJobRoleApiEndPoint = "bx_block_roles_permissions/roles?id=";

// Customizable Area End