// Customizable Area Start
import React, { Component } from "react";
import { Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, InputAdornment, InputBase, MenuItem, Select, TextField, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { SearchIcon } from "../../EmailNotifications/src/assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PersonIcon from '@material-ui/icons/Person';
import { JobIcon } from "../../JobListing2/src/assets";
import CatalogueStyles from "./Styles.module.scss";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { ReactComponent as WorkOutlineOutlined } from "../../JobListing2/assets/job_type.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Customizable Area End

// Customizable Area Start
const dummyKms = [
    { value: 15, label: "15km" },
    { value: 40, label: "40km" },
    { value: 55, label: "55km" },
    { value: 80, label: "80km" },
    { value: 120, label: "120km" },
    { value: 160, label: "160km" },
]

const dummyStatus = ['Show "Watched"', 'Exclude "Watched"'];
const dummyCP = ['Current and Past', 'Current', 'Past'];
// Customizable Area End

// Customizable Area Start
export interface TPState {
    location: string;
    title: string;
    keyword_OR_name: string;
    km: {
        value: number;
        label: string;
    };
    status: string | 'Show "Watched"' | 'Exclude "Watched"';
    cp: string | 'Current and Past' | 'Current' | 'Past';
    title_boolean_search: boolean;
    keyword: boolean;
    years_of_exp_from: number | "";
    years_of_exp_to: number | "";
    full_name: boolean;
    company: string;
    showText: string;
    showkeyword_OR_nameText: string;
    showLocation: any;
    showTitle: any;
    showCompany: any;
}

interface Props {
    // classes: any;
    onSearch: (state: TPState) => void;
    showSaveSearch?: boolean;
    locationList?: any[];
    companyList?: any[];
    titleList?: any[];
    keywordsList?: any[];
    onSuggessionSearch: (type: string, searchText: string) => void;
    onReset: () => void;

}
// Customizable Area End
class Filter extends Component<Props, TPState> {
    // Customizable Area Start
    initialState: TPState = {
        location: '',
        title: "",
        keyword_OR_name: "",
        km: dummyKms[0],
        status: '',
        cp: dummyCP[0],
        title_boolean_search: false,
        keyword: true,
        full_name: false,
        company: "",
        showText: "",
        years_of_exp_from: "",
        years_of_exp_to: "",
        showkeyword_OR_nameText: "",
        showLocation: [],
        showTitle: [],
        showCompany: []
    };
    state: TPState = { ...this.initialState };
    // Customizable Area End
    // Customizable Area Start
    onSearch = () => {
        this.props.onSearch(this.state);
    }
    onReset = () => {
        this.setState({ ...this.initialState });
        this.props.onReset();
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        let timeoutVar: any;
        // const { classes } = this.props;
        return (
            <Box className={`${CatalogueStyles.card} ${CatalogueStyles.filterWrap}`}>
                <Box className={CatalogueStyles.filterCard}>
                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Location</Typography>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box display="flex" width='100%' justifyContent='center' alignItems='center'>
                                <Box className={CatalogueStyles.filterIcon}>
                                    <SearchIcon />
                                </Box>
                                <Autocomplete
                                    className={CatalogueStyles.search}
                                    classes={{ root: CatalogueStyles.inputRoot }}
                                    multiple
                                    disableClearable
                                    value={this.state.showLocation}
                                    options={this.props.locationList?.map((option: any) => option) || []}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timeoutVar);
                                        timeoutVar = setTimeout(() => {
                                            this.props.onSuggessionSearch("location", newInputValue);
                                        }, 700)
                                    }}
                                    onChange={(event, value) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        this.setState({ location: value?.join(" OR "), showLocation: [...value] });

                                    }}
                                    renderTags={(value, getTagProps) => {
                                        return value?.map((option: string, index: number) => (
                                            <>
                                                <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                {index < value.length - 1 &&
                                                    <span className={CatalogueStyles.boolKeys}> OR </span>}
                                            </>
                                        ))
                                    }
                                    }
                                    renderInput={(params) => <TextField {...params}
                                        multiline
                                        maxRows={3}
                                        minRows={1}
                                        placeholder="Enter location"
                                        inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (event) => {
                                                if (event.key === 'Enter') {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }
                                            },
                                        }}
                                    />}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box flex={1} className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Title</Typography>
                        {this.state.title_boolean_search ?
                            <Box display="flex" position='relative' alignItems='center'>
                                <Box className={CatalogueStyles.filterIcon} height='1rem'>
                                    <JobIcon /></Box>
                                <Box className={CatalogueStyles.searchBiBox}>
                                    <Box className={CatalogueStyles.showSeachText} dangerouslySetInnerHTML={{ __html: this.state.showText }}></Box>
                                    <TextField
                                        className={CatalogueStyles.searchHide}
                                        multiline
                                        placeholder="Enter title"
                                        onChange={(event) => {
                                            let receivedText: any = event.target.value;
                                            let boolArray = ['AND', 'OR', 'NOT', 'and', 'or', 'not'];
                                            let forT = receivedText?.trim()?.split(" ")?.map((item: any) => {
                                                if (boolArray.includes(item)) {
                                                    return `<span style='color: #24325f;font-weight:600'>${item}</span>`
                                                } else return item
                                            });
                                            this.setState({ showText: forT?.join(" ") || "", title: receivedText });
                                        }}
                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={this.state.title}
                                    />
                                </Box>
                            </Box> :
                            <Box display="flex" width='100%' justifyContent='center' alignItems='center'>
                                <Box className={CatalogueStyles.filterIcon}>
                                    <JobIcon /></Box>
                                <Autocomplete
                                    className={CatalogueStyles.search}
                                    classes={{ root: CatalogueStyles.inputRoot }}
                                    multiple
                                    disableClearable
                                    options={this.props.titleList?.map((option: any) => option) || []}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timeoutVar);
                                        timeoutVar = setTimeout(() => {
                                            this.props.onSuggessionSearch("title", newInputValue);
                                        }, 700)
                                    }}
                                    onChange={(event, value) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        this.setState({ title: value?.join(" OR "), showTitle: [...value] });

                                    }}
                                    renderTags={(value, getTagProps) => {
                                        return value?.map((option: string, index: number) => (
                                            <>
                                                <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                {index < value.length - 1 &&
                                                    <span className={CatalogueStyles.boolKeys}> OR </span>}
                                            </>
                                        ))
                                    }
                                    }
                                    renderInput={(params) => <TextField {...params}
                                        multiline
                                        maxRows={3}
                                        minRows={1}
                                        placeholder="Enter title"
                                        inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (event) => {
                                                if (event.key === 'Enter') {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }
                                            },
                                        }}
                                    />}
                                    value={this.state.showTitle}
                                />
                            </Box>
                        }


                        <Box display="flex" alignItems="center">
                            <FormControlLabel
                                className={CatalogueStyles.filterSubTitle}
                                control={
                                    <Checkbox
                                        checked={this.state.title_boolean_search}
                                        onChange={() => {
                                            this.setState((prev) => ({ title_boolean_search: !prev.title_boolean_search }))
                                        }}
                                        name="title"
                                        color="primary"
                                    />
                                }
                                label="Title boolean search"
                            />
                            <Divider
                                orientation="vertical"
                                style={{ height: "1.6em", width: ".14em", backgroundColor: "#d6d6e5", marginRight: "1em" }}
                            />
                            <FormControl>
                                <Select
                                    className={CatalogueStyles.filterDropDown}
                                    IconComponent={() => <KeyboardArrowDownIcon />}
                                    value={this.state.cp}
                                    onChange={(e) => this.setState({ cp: e.target.value as string })}
                                >
                                    {dummyCP.map((cp, index) => (
                                        <MenuItem key={index} value={cp}>{cp}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box className={CatalogueStyles.filterCardBox} >
                        <Box display="flex">
                            <FormControlLabel
                                className={CatalogueStyles.filterSubTitle}
                                control={
                                    <Checkbox
                                        checked={this.state.keyword}
                                        onChange={() => {
                                            this.setState({
                                                keyword: !this.state.keyword, full_name: !this.state.full_name
                                            })
                                        }}
                                        name="keyword"
                                        color="primary"
                                    />
                                }
                                label="Keyword"
                            />
                            <FormControlLabel
                                className={CatalogueStyles.filterSubTitle}
                                control={
                                    <Checkbox
                                        checked={this.state.full_name}
                                        onChange={() => {
                                            this.setState({
                                                full_name: !this.state.full_name, keyword: !this.state.keyword
                                            })
                                        }}
                                        name="full_name"
                                        color="primary"
                                    />
                                }
                                label="Full Name"
                            />
                        </Box>
                        {this.state.keyword ?
                            <Box display="flex" position='relative' alignItems='center'>
                                <Box className={CatalogueStyles.filterIcon} height='1rem'>
                                    <JobIcon /></Box>
                                <Box className={CatalogueStyles.searchBiBox}>
                                    <Box className={CatalogueStyles.showSeachText} dangerouslySetInnerHTML={{ __html: this.state.showkeyword_OR_nameText }}></Box>
                                    <TextField
                                        className={CatalogueStyles.searchHide}
                                        multiline
                                        maxRows={3}
                                        minRows={1}
                                        placeholder="Enter Name"
                                        onChange={(event) => {
                                            let receivedText = event.target.value;
                                            let boolArray = ['AND', 'OR', 'NOT', 'and', 'or', 'not'];
                                            let forT = receivedText?.trim()?.split(" ")?.map((item) => {
                                                if (boolArray.includes(item)) {
                                                    return `<span style='color: #24325f;font-weight:600'>${item}</span>`
                                                } else return item
                                            });
                                            this.setState({ showkeyword_OR_nameText: forT?.join(" ") || "", keyword_OR_name: receivedText })
                                        }}
                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={this.state.keyword_OR_name}
                                    />
                                </Box>
                            </Box> :
                            <Box display="flex" className={`${CatalogueStyles.titleSearch}`}>
                                <InputBase
                                    placeholder="Enter keyword name"
                                    classes={{ root: CatalogueStyles.inputRoot }}
                                    inputProps={{ "aria-label": "search" }}
                                    startAdornment={
                                        <InputAdornment position="start" className={CatalogueStyles.filterIcon}>
                                            <JobIcon style={{ marginBottom: '.3rem' }} />
                                        </InputAdornment>
                                    }
                                    value={this.state.keyword_OR_name}
                                    onChange={(e) => this.setState({ keyword_OR_name: e.target.value })}
                                />


                            </Box>
                        }
                    </Box>
                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Manage Profiles</Typography>
                        <Box className={CatalogueStyles.filterIcon} display='flex' alignItems='center'>
                            <PermIdentityIcon />
                            <FormControl>
                                <Select
                                    className={CatalogueStyles.filterDropDown}
                                    IconComponent={() => <KeyboardArrowDownIcon />}
                                    displayEmpty
                                    renderValue={this.state.status !== null ?
                                        undefined : () => "Choose Status"}
                                    value={this.state.status !== null ? this.state.status : dummyStatus[0]}
                                    onChange={(e) => this.setState({ status: e.target.value as string })}
                                >
                                    <MenuItem value={""}>Choose Status</MenuItem>
                                    {dummyStatus.map((status, index) => (
                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Company</Typography>
                        <Box display="flex" width='100%' justifyContent='center' alignItems='center'>
                            <Box className={CatalogueStyles.filterIcon}>
                                <WorkOutlineOutlined /></Box>
                            <Autocomplete
                                className={CatalogueStyles.search}
                                classes={{ root: CatalogueStyles.inputRoot }}
                                multiple
                                disableClearable
                                options={this.props.companyList?.map((option: any) => option) || []}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timeoutVar);
                                    timeoutVar = setTimeout(() => {
                                        this.props.onSuggessionSearch("company", newInputValue);
                                    }, 700)
                                }}
                                onChange={(event, value) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    this.setState({ company: value?.join(" OR "), showCompany: [...value] });

                                }}
                                renderTags={(value, getTagProps) => {
                                    return value?.map((option: string, index: number) => (
                                        <>
                                            <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                            {index < value.length - 1 &&
                                                <span className={CatalogueStyles.boolKeys}> OR </span>}
                                        </>
                                    ))
                                }
                                }
                                renderInput={(params) => <TextField {...params}
                                    multiline
                                    maxRows={3}
                                    minRows={1}
                                    placeholder="Enter company name"
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (event) => {
                                            if (event.key === 'Enter') {
                                                event.stopPropagation();
                                                event.preventDefault();
                                            }
                                        },
                                    }}
                                />}
                                value={this.state.showCompany}
                            />
                        </Box>
                    </Box>
                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Years Of Experience</Typography>
                        <Box display="flex" width='100%' justifyContent='center' alignItems='center'>
                            <TextField
                                value={this.state.years_of_exp_from}
                                onChange={(e) => {
                                    if (!e.target.value.trim().length) {
                                        this.setState({ years_of_exp_from: '' })
                                    } else {
                                        this.setState({ years_of_exp_from: Number(e.target.value) })
                                    }
                                }}
                                type="number"
                                placeholder="From" fullWidth
                                className={CatalogueStyles.yearsExp}
                            />
                            <TextField
                                value={this.state.years_of_exp_to}
                                onChange={(e) => {
                                    if (!e.target.value.trim().length) {
                                        this.setState({ years_of_exp_to: '' })
                                    } else {
                                        this.setState({ years_of_exp_to: Number(e.target.value) })
                                    }
                                }}
                                type="number"
                                placeholder="To" fullWidth
                                className={CatalogueStyles.yearsExp}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box
                    className={CatalogueStyles.filterReset}
                    display="flex"
                    alignItems='center'
                    justifyContent="center"
                    onClick={this.onReset}
                >
                    <Typography>
                        Reset
                    </Typography>
                </Box>
                <Box
                    className={CatalogueStyles.filterSearch}
                    display="flex"
                    flexDirection="column"
                    justifyContent={this.props.showSaveSearch ? "flex-end" : "center"}
                    onClick={this.onSearch}
                >
                    {this.props.showSaveSearch && (
                        <Box>
                            <Typography>
                                Save Search
                            </Typography>
                        </Box>
                    )}
                    <Box
                        className="search"
                        style={{
                            flex: this.props.showSaveSearch ? .5 : 1,
                            borderTopRightRadius: !this.props.showSaveSearch ? "1rem" : 0,
                        }}

                    >
                        <Typography>
                            Search
                        </Typography>
                    </Box>
                </Box>
            </Box >
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default Filter;
// Customizable Area End