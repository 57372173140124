import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Platform,
} from "react-native";
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Container,
  Typography,
  Card,
  withStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { BrowserRouter as Router, Link, withRouter, useHistory } from "react-router-dom";
import { banner } from "./assets";
// Customizable Area End
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  paddingLeft: "20px",
  paddingRight: "20px",
  overflow: 'scroll',
  height: '80%',
  display: 'block'
};
const blockStyle = (theme: any) => ({
  banner: {
    //customizable area start
    backgroundColor: "whitesmoke",
    //customizable area ends
    padding: "1rem 2rem",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPositionY: "-15rem",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
    },
  },
  textWhite: {
    color: "#ffffff",
  },
  loginCard: {
    paddingTop: "2em",
    padding: "1.3em",
    border: "2px solid #eaeaea",
    //customizable area start
    marginTop: "80px",
    marginBottom: "80px"
    //customizable area ends
  },

  //customizable area start
  image: {
    display: "block",
    marginRight: "auto",
    marginLeft: "auto"
  },
  //customizable area ends
  fullBtn: {
    width: "100%",
  },
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#ffffff",
  },
  input: {
    display: "none",
  },
});

class EmailAccountRegistrationVerify extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.banner}>
        {/* //customizable area ends */}
        <Container maxWidth="sm">
          <Card className={classes.loginCard}>
            <Box alignContent="center"></Box>
            <img src={require("../assets/mail_verification.png")} alt="" width="200" height="180" className={classes.image} />
            <br />
            <Typography align="center" style={{ fontSize: 24 }} color="primary">
              Verify Your Email Address
            </Typography>
            <br />
            {!this.props.alreadyExistsMsg ?
              (<Typography align="center" style={{ color: "darkslategray", lineHeight: 1.5, marginBottom: "1em" }}>
                You have entered <b>{this.props.accountEmail}</b> as your email address for your account.
                Please verify this<br /> email address by clicking the button below
              </Typography>) :
              (<Typography align="center" style={{ color: "darkslategray", lineHeight: 1.5, marginBottom: "1em" }}>
                Please verify <b>{this.props.accountEmail}</b> as your email address for your account, by clicking the button below
              </Typography>)}
            <Button
              type="submit" className={classes.fullBtn}
              disableRipple
              variant="contained"
              color="primary"
              onClick={() => this.props.verifyIt()}
              disabled={this.props.showLoader}
            >
              <Typography variant="body2">
                <div style={{ textDecoration: "none", color: "lavender" }}>Verify Your Email</div>
              </Typography>
              {this.props.showLoader && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#24325f",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Button>
          </Card>
        </Container>

      </Box>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    this.getValidations();
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold",
  },
});
// Customizable Area End
// @ts-ignore
export default withStyles(blockStyle)(withRouter(EmailAccountRegistrationVerify));
