import React from 'react';

import { Theme } from '@material-ui/core/styles';
import { Box, Button, InputBase, Typography, withStyles } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';

// Customize Area Start
import JobListing2Controller from './JobListing2Controller.web';
import { MobileSearch } from './JobListing2.web';
import { ArrowBack } from './assets';
import Leftfilterdropdown from '../../../components/src/Leftfilterdropdown';
// Customize Area Ends

// Customize Area Start
const useStyles = (theme: Theme) => ({
    modal: {
        marginTop: "5.3rem", background: "#f2f2f2",

        [theme.breakpoints.up("md")]: {
            display: "none",
        },

        "& > div:first-child": {
            marginTop: "5rem",
            backgroundColor: "#f2f2f2 !important",
        }
    },
    content: {
        marginTop: "-.7rem",
        display: "flex",
        height: "102%",

        "& .buttonGroup": {
            zIndex: 1,
            marginTop: "auto",
            display: "flex",

            "& div": {
                padding: "1.3rem 3rem",
                flex: 1,
                textAlign: "center",
                cursor: "pointer",
                transition: "all .3s ease-in-out",

                "& h6": {
                    fontSize: "1.3rem",
                    letterSpacing: ".1rem",
                },

                "&:first-child": {
                    backgroundColor: "#ccc",
                    color: "#000",
                },
                "&:last-child": {
                    backgroundColor: "#24325F",
                    color: "#fff",
                },

                "&:active": {
                    backgroundColor: "#a8d0e7",
                    color: "#000"
                }
            }
        }
    },
    filters: {
        margin: "1rem 1rem 0",
        fontSize: "1.3rem",
    }
});
// Customize Area Ends

class SearchModal extends JobListing2Controller {

    // Customize Area Start
    // Customize Area Ends

    render(): React.ReactNode {
        // Customize Area Start
        const { classes } = this.props;
        // Customize Area Ends

        // Customize Area Start
        return (
            <Modal
                open={this.props.isModalOpen}
                className={classes.modal}
            >
                <Box className={classes.content} flexDirection="column">
                    <MobileSearch
                        handleBack={this.props.toggleSearchModal}
                        classes={classes}
                        imgIcon={ArrowBack}
                        autoFocus={true}
                        handleChange={this.props.extractValue}
                        value={this.props.searchTextValue}
                        handleClick={() => { }}
                        isModalOpen={this.props.isModalOpen}
                    />

                    <Box className={classes.filters}
                        style={{ maxHeight: "100vh", overflowY: "scroll", overflowX: "hidden" }}
                    >
                        <Leftfilterdropdown
                            filters={this.props.filters}
                            handleFilters={this.props.applyFilters}
                            clearAllFilters={this.props.clearFilters}
                        />
                    </Box>

                    <Box className='buttonGroup'>
                        <Box
                            onClick={this.props.resetFilters}>
                            <Typography variant="h6">
                                Reset
                            </Typography>
                        </Box>
                        <Box onClick={() => this.props.handleApply(this.props.searchTextValue)}>
                            <Typography variant="h6">
                                Apply
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
        // Customize Area Ends
    }
}

export default withStyles(useStyles)(SearchModal);