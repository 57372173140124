import React, { FC, useCallback, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Button, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '1rem',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),

            '& h6': {
                margin: theme.spacing(1, 0, 2),
            },

            "& .upload-btn": {
                textAlign: "center",
                margin: theme.spacing(1, 0, 2),
            },

            "& label": {
                padding: ".5rem 1rem"
            },

            "& .drop-zone": {
                borderRadius: "1rem",
                margin: "2rem 0",
                width: "100%",
                height: "10rem",
                background: "#e3e3e3",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            },

            "& .drop-zone-active": {
                background: "#a8d1e7 !important",
            },

            "& img": {
                maxWidth: "60rem",
                maxHeight: "40rem",
                borderRadius: ".3rem",
                margin: "1rem 0 2rem",
            }
        },
    }),
);

interface UploadModalProps {
    open: boolean;
    onClose: () => void;
    uploadImage: (file: File) => void;
}

const UploadModal: FC<UploadModalProps> = ({ open, onClose, uploadImage }) => {
    const classes = useStyles();
    const [file, setFile] = useState<File | null>(null);

    const onDrop = useCallback(acceptedFiles => {
        const file: File = acceptedFiles[0];

        if (file.type !== "image/jpeg" &&
            file.type !== "image/png" && file.type !== "image/gif"
            && file.type !== "image/jpg" && file.type !== "image/webp"
        ) {
            alert("For now, only Images files are supported...");
            return;
        }
        if (file.size / (1024 * 1024) > 3) {
            alert("File size should be less than 3MB");
            return;
        } else {
            setFile(file);
        }
    }, []);

    const handleUpload = () => {
        uploadImage(file as File);
        setFile(null);
    }

    const { getRootProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            <Modal
                className={classes.modal}
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.paper}>
                        {!file && (
                            <div {...getRootProps()} className={`drop-zone ${isDragActive ? "drop-zone-active" : ""}`}>
                                {!isDragActive && <Box className='upload-btn'>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color='primary'
                                    >
                                        Select File
                                        <input
                                            type="file"
                                            hidden
                                            onChange={(e) => onDrop(e.target.files)}
                                        />
                                    </Button>
                                </Box>}
                                {
                                    isDragActive ?
                                        <Typography>Drop the files here ...</Typography> :
                                        <Typography>Or, Drag 'n' drop some files here.</Typography>
                                }
                            </div>
                        )}
                        <Box style={{ textAlign: "center" }}>
                            {file && <Typography align='center' color='primary' component='h6'>{file.name}</Typography>}
                            {!file && <Typography variant='body1'> You can upload photos, pdfs, documents upto 1 mb.</Typography>}
                            {file && <img src={URL.createObjectURL(file)} alt="Choosen_file" />}
                            {file && (
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Button onClick={() => setFile(null)} variant="outlined" style={{ color: "red" }} component="label">
                                        Remove
                                    </Button>
                                    <Button onClick={handleUpload} variant="contained" color='primary' component="label">
                                        Upload
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}

export default UploadModal;