import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// @ts-ignore
import CandidateContext from "../../../web/src/Context/CandidateContext.web";
import _ from "lodash";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    showProfilePicture: any;
    onCloseshowProfilePicture: () => void;
    location?: any;
    history?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    imageData: any;
    parsingUrl: any;
    snackBar: {
        show: boolean;
        message: string;
        type: any
    };
    profilePicture: any;
    binaryFileData: any;
    userType: string;
    clientID: number | string;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    userProfileGetApiCallId: any;
    imageUploadApiCallId: any;
    uploadImagOnBucketInformation: any;
    uploadImagOnBackendInformation: any;
    showProfilePictureCallId: string;
    userProfileUploadApiID: string;
    removeProfilePhotoInformation: string;
    refreshTokenApiCallId: string;
    intervalCatch: any;
    logOutAdminApiCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.SessionSaveMessage),
        ];

        this.state = {
            imageData: {},
            parsingUrl: [],
            snackBar: {
                show: false,
                message: "",
                type: "",
            },
            profilePicture: "",
            binaryFileData: null,
            userType: sessionStorage.getItem("user_role") || "",
            clientID: ""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: String, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.userProfileGetApiCallId) {
                    if (
                        responseJson &&
                        !responseJson.errors
                    ) {
                        //On User Profile Success
                        this.setState({ profilePicture: responseJson?.data?.attributes?.photo }, () => {
                            this.context.setCandidateProfilePicture(this.state.profilePicture);
                        });
                    } else {
                        //Check Error Response
                        if (
                            responseJson.errors &&
                            responseJson.errors.length > 0 &&
                            responseJson.errors[0].token
                        ) {
                            this.showAlert("Session Expired", "Please Log in again.");
                            this.setState({ snackBar: { show: true, message: "Session Expried", type: "error" } });
                        } else {
                            // this.parseApiErrorResponse(responseJson);
                        }
                    }

                    this.parseApiCatchErrorResponse(errorReponse);
                }

                // Remove Profile Picture
                if (apiRequestCallId === this.removeProfilePhotoInformation) {
                    if (
                        responseJson &&
                        !responseJson.errors
                    ) {
                        //On User Profile Success
                        this.setState({ profilePicture: "" }, () => {
                            this.context.removeCandidateProfilePicture();
                        });
                        this.setState({ snackBar: { show: true, message: "Profile Picture Sucessfully Removed.", type: "success" } });
                    } else {
                        //Check Error Response
                        if (
                            responseJson.errors &&
                            responseJson.errors.length > 0 &&
                            responseJson.errors[0].token
                        ) {
                            this.showAlert("Session Expired", "Please Log in again.");
                            this.setState({ snackBar: { show: true, message: "Session Expried", type: "error" } });
                        } else {
                            // this.parseApiErrorResponse(responseJson);
                        }
                    }
                }

                if (apiRequestCallId === this.imageUploadApiCallId) {
                    if (responseJson && !responseJson.errors
                    ) {
                        this.setState({ parsingUrl: responseJson.data[0] });
                        this.uploadImageOnBucket(responseJson.data[0]?.presigned_url, this.state?.imageData);
                        this.updatePublicUrlToBackend(responseJson.data[0]?.public_url);

                    } else {
                        //Check Error Response
                        if (
                            responseJson.errors &&
                            responseJson.errors.length > 0 &&
                            responseJson.errors[0].token
                        ) {
                            this.showAlert("something went wrong", "Please try again");
                        } else {

                        }
                    }

                    // this.parseApiCatchErrorResponse(errorReponse);
                }
                if (apiRequestCallId === this.uploadImagOnBucketInformation) {
                    if (responseJson && !responseJson.errors
                    ) { } else {
                        //Check Error Response
                        if (
                            responseJson.errors &&
                            responseJson.errors.length > 0 &&
                            responseJson.errors[0].token
                        ) {
                            this.showAlert("something went wrong", "Please try again");
                        } else {

                        }
                    }
                }
                if (apiRequestCallId === this.uploadImagOnBackendInformation) {
                    if (responseJson && !responseJson.errors
                    ) {
                        this.openSnackBarHandler("success", responseJson.message);
                        setTimeout(() => {
                            this.props.onCloseshowProfilePicture();
                            this.getUserProfile();
                            this.closeSnackBarHandler();
                        }, 1000)
                    } else {
                        //Check Error Response
                        if (
                            responseJson.errors &&
                            responseJson.errors.length > 0 &&
                            responseJson.errors[0].token
                        ) {
                            this.showAlert("something went wrong", "Please try again");
                        } else {

                        }
                    }
                }
                if (apiRequestCallId === this.userProfileUploadApiID) {
                    if (responseJson && !responseJson.errors
                    ) {
                        this.getUserProfile();
                        this.setState({ snackBar: { show: true, message: "Profile Picture Updated Successfully", type: "success" } });
                    } else {
                        //Check Error Response
                        if (
                            responseJson.errors &&
                            responseJson.errors.length > 0 &&
                            responseJson.errors[0].token
                        ) {
                            this.showAlert("something went wrong", "Please try again");
                        } else {

                        }
                    }
                }

                if (apiRequestCallId === this.refreshTokenApiCallId) {
                    if (responseJson && !responseJson.errors
                    ) {
                        sessionStorage.setItem('Token', responseJson?.meta?.refresh_token);
                        if (!_.isEqual(responseJson?.meta?.refresh_token, sessionStorage.getItem("Token"))) {
                            sessionStorage.setItem("setTime", new Date().toJSON());
                        }
                    } else {
                        //Check Error Response
                        if (
                            responseJson.errors &&
                            responseJson.errors.length > 0 &&
                            responseJson.errors[0].token
                        ) {
                            this.showAlert("something went wrong", "Please try again");
                        } else {

                        }
                    }
                }

                if (apiRequestCallId === this.logOutAdminApiCallId) {
                    if (responseJson && !responseJson.errors && !responseJson.error
                    ) {
                        sessionStorage.clear();
                        window.location.href = "/login/?type=admin";
                    } else {
                        //Check Error Response
                        this.openSnackBarHandler("error", "Logout Unsuccessful")
                    }
                }
            }
        }

        // Customizable Area End
    }



    // Customizable Area Start
    getUserProfile() {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userProfileGetApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.endPointApiGetUserProfile
        );

        const header = {
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
            "Token": sessionStorage.getItem("Token")
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        if (this.state.userType == 'admin') {
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify({ client_id: this.state.clientID })
            );
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    uploadImageWithParsingURl(file: any) {
        const header = {
            'token': sessionStorage.getItem('Token')
        };

        const formData = new FormData();
        formData.append("photo", file);

        if (this.state.userType == 'admin' && this.state.clientID) {
            formData.append("client_id", this.state.clientID as string);
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userProfileUploadApiID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.uploadCandidatePhoto
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleFileData = (file: any) => {
        return new Promise((resolve: any, reject: any) => {
            const reader: any = new FileReader();
            // reader.onload = this.handleFileData.bind(this);
            reader.readAsBinaryString(file);
            reader.onload = () => {
                resolve(reader.result);
                this.setState({ binaryFileData: btoa(reader.result) });
            };
            reader.onerror = (error: any) => {
                reject(error)
            }
        });

        // let binaryString: any = readerEvent?.target?.result;
        // this.setState({ binaryFileData: btoa(binaryString) }, () => console.log(this.state.binaryFileData));
    }
    uploadImageOnBucket = async (url: string, file: any) => {
        var reader = new FileReader();
        reader.onload = function () { };
        // reader.readAsBinaryString(file);
        reader.readAsDataURL(file);

        // var reader = new FileReader();
        // reader.onload = this.handleFileData.bind(this);
        // reader.readAsBinaryString(file);
        // const reader = await this.handleFileData(file);

        const header = {
            "Content-Type": "application/json"
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );
        this.uploadImagOnBucketInformation = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            reader.result
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiUpdateUserType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    updatePublicUrlToBackend = async (url: string) => {

        const header = {
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("Token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const data = {
            photo: url
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.editProfileAPiEndPoint
        );

        this.uploadImagOnBackendInformation = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    removeProfilePhoto = () => {
        const header = {
            "Content-Type": "application/json",
            "token": sessionStorage.getItem("Token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeProfilePhoto
        );

        this.removeProfilePhotoInformation = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Open View Details Modal
    openSnackBarHandler = (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ): void => {
        this.setState({
            snackBar: {
                show: true,
                message: message,
                type,
            },
        });
    };

    // Close View Details Modal
    closeSnackBarHandler = () => {
        this.setState({
            snackBar: {
                show: false,
                message: this.state.snackBar.message,
                type: this.state.snackBar.type,
            },
        });
    };

    refreshToken() {
        const header = {
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
            "Token": sessionStorage.getItem("Token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.refreshTokenApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.endPointApiRefreshToken
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiUpdateUserType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    logOutAdmin() {
        const header = {
            "Content-Type": configJSON.contentTypeApiGetUserProfile,
            "Token": sessionStorage.getItem("Token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.logOutAdminApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.endPointApiLogOutAdmin
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    checkIfRefreshToken = () => {
        let getlogInTime: any = sessionStorage.getItem("setTime") as string;
        let currentTime: any = new Date().toJSON();
        let diff: any = Date.parse(currentTime) - Date.parse(getlogInTime);
        // console.log("*********************************************", getlogInTime, currentTime, diff);

        let totalTime = 29 * 60000;
        let remainingTime = totalTime - diff;
        if (diff <= totalTime) {
            this.intervalCatch = setInterval(() => {
                this.refreshToken();
            }, remainingTime);
        } else {
            clearInterval(this.intervalCatch);
            this.logOutAdmin();
        }
    }
    // Customizable Area End
}

UserProfileBasicController.contextType = CandidateContext;