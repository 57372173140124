import React, { FC, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { NavData } from "./TopNav";

const useStyles = makeStyles((theme) => ({
    sectionDesktop: {
        flex: "0.4 1 0%",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
        [theme.breakpoints.down("md")]: {
            flex: "none",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    links: {
        textDecoration: "none",
        paddingTop: "2rem",
        paddingBottom: "2rem",
        [theme.breakpoints.down("md")]: {
            "&:not(:last-child)": {
                marginRight: "1.5rem",
            }
        },
    },
    activeLink: {
        borderBottomWidth: 3,
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.primary.main,
    },
    activeLinkText: {
        color: theme.palette.primary.main,
        fontFamily: "Poppins-Semibold",
    },
    linkText: {
        color: theme.palette.text.primary,
        fontFamily: "Poppins-Regular",
        cursor: "pointer"
    },
}));

interface VisitorProps {
    pathName: string;
    toggleModal: () => void;
}

const VisitorMenu: FC<VisitorProps> = ({ pathName, toggleModal }) => {
    const classes = useStyles();

    return (
        <>
            {/* navigation menu */}
            <Box
                className={classes.sectionDesktop}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                flex="0.3"
                alignItems="center">
                {NavData.map((each) => (
                    <Link
                        to={each.path}
                        href={each.path}
                        key={each.path}
                        className={clsx([
                            classes.links,
                            pathName == each.path ? classes.activeLink : "",
                        ])}>
                        <Typography
                            variant="body2"
                            className={
                                pathName == each.path ? classes.activeLinkText : classes.linkText
                            }>
                            {each.route}
                        </Typography>
                    </Link>
                ))}
                <Typography
                    variant="body2"
                    onClick={toggleModal}
                    className={classes.linkText}>
                    Contact Us
                </Typography>
            </Box>
        </>
    );
}

export default VisitorMenu;