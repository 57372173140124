import React from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import OpenRoleList from "../../../components/src/Openroleslist";
import { Link } from "react-router-dom";
interface OpenItemInterface {
    classes: any;
    role: any;
    isExpand: boolean;
    history: any;
    candidateListScroll?: (data: string) => void;
    handleViewAll: (id?: number) => void;
    handleSendStatus?: (data: { role_id: number, profile_id: number, isAccepted: boolean }, position: number | string, applied_candidates: number | string) => void;
    hasMoreCandidateData?: boolean;
    infiniteCandidateLoading?: boolean;
    startLoading: () => void;
    createCoversation: (email: string) => void;
    clientIDLink?: any;
}

class OpenRoleListItem extends React.Component<OpenItemInterface> {
    constructor(props: OpenItemInterface) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        return (
            <Card>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    p={1}
                    px={2}
                    className={classes.BgCardHeader}
                >
                    <Box>
                        <Link to={`/job-detail/${this.props.role?.id}${this.props.clientIDLink ? "?" + this.props.clientIDLink : ""}`} style={{ textDecoration: "none", color: "#24325F" }}>

                            <Typography variant="body1"
                                style={{ textTransform: "capitalize" }}
                                component='h6'
                            >
                                {this.props.role?.attributes?.role_name}
                            </Typography>
                        </Link>
                    </Box>
                    <Box>
                        {this.props.isExpand ?
                            <Typography
                                style={{ cursor: "pointer" }}
                                onClick={(_) => {
                                    this.props.startLoading();
                                    this.props.history.push({
                                        pathname: "/open-roles",
                                        search: location.search
                                    });
                                    this.props.handleViewAll?.()
                                }}
                            >
                                VIEW LESS
                            </Typography> :
                            <Typography
                                style={{ cursor: "pointer" }}
                                onClick={(_) => {
                                    this.props.startLoading();
                                    this.props.history.push({
                                        pathname: `/open-roles/role/${this.props.role?.id}`,
                                        search: location.search
                                    });
                                    this.props.handleViewAll?.(this.props.role?.id)
                                }}
                            >
                                VIEW ALL
                            </Typography>}
                    </Box>
                </Box>
                <Box p={2}>
                    {this.props.role?.attributes?.candidate &&
                        this.props.role?.attributes?.candidate.length > 0 ?
                        <Grid container spacing={2}>
                            <Grid item lg={12} className={classes.roleTableStyle}>
                                <OpenRoleList
                                    classes={classes}
                                    role_id={this.props.role?.id}
                                    candidate={this.props.role?.attributes?.candidate}
                                    handleStatus={(data: any) => { this.props.handleSendStatus?.(data, this.props.role?.attributes?.position, this.props.role?.attributes?.accepted_candidate) }}
                                    hasMoreCandidateData={this.props.hasMoreCandidateData}
                                    infiniteCandidateLoading={this.props.infiniteCandidateLoading}
                                    candidateListScroll={this.props.candidateListScroll}
                                    createCoversation={this.props.createCoversation}
                                    clientIDLink={this.props.clientIDLink}
                                />
                            </Grid>
                        </Grid> :
                        <Typography variant="body1">No candidates found.</Typography>}
                </Box>
            </Card>
        )
    }
}

export default OpenRoleListItem;