import React, { FC, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box, Button, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            textAlign: "center",
            borderRadius: '1rem',
            boxShadow: theme.shadows[5],
            height: 250,

            "& .closeIcon": {
                alignSelf: "flex-end",
                margin: theme.spacing(2, 2, 0, 0),
                cursor: "pointer",
                transition: "all .2s ease-in-out",

                "&:hover": {
                    color: theme.palette.primary.main,
                    transform: "scale(1.2)",
                }
            },

            "& > div": {
                padding: theme.spacing(0, 10, 4),
            }
        },
        shareContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

            "& h6": {
                backgroundColor: "#e3e3e3",
                margin: theme.spacing(5, 0, 3),
                padding: theme.spacing(1, 10),
                borderRadius: ".4rem",
            },

            "& > div > div": {
                "& a": {
                    textDecoration: "none",
                    width: "100%",
                },

                "& button": {
                    marginBottom: "1rem",
                    width: "18rem",
                    fontWeight: "bold",

                    "&:last-child": {
                        backgroundColor: "#50cc5c",
                        color: "#fff",
                    }
                }
            }
        },
        info: {
            marginTop: theme.spacing(2),

            '& svg': {
                color: theme.palette.primary.main,
                marginRight: theme.spacing(1),
            },

            '& h6': {
                color: theme.palette.primary.main,
            }
        }
    }),
);

interface ShareModalProps {
    open: boolean;
    onClose: () => void;
}

const ShareModal: FC<ShareModalProps> = ({ open, onClose }) => {
    const classes = useStyles();
    const [copied, setCopied] = React.useState({
        copied: false,
        message: "",
    });

    const copyToClipboard = (message: string) => {
        navigator.clipboard.writeText(window.location.href);
        setCopied({ copied: true, message });
    }

    useEffect(() => {
        return () => {
            setCopied({ copied: false, message: '' });
        }
    }, []);

    return (
        <div>
            <Modal
                className={classes.modal}
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.paper} style={{ paddingBottom: copied.copied ? "7.5rem" : "5rem" }}>
                        <CloseIcon onClick={onClose} className="closeIcon" />

                        <Box>
                            <Typography variant="h5">Share Job Listing on Other Platforms</Typography>

                            <Box className={classes.shareContainer}>
                                <Box>
                                    <Typography variant='h6'>{window.location.href}</Typography>

                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<FileCopyIcon />}
                                            onClick={() => copyToClipboard("URL Copied to Clipboard")}
                                        >
                                            Copy the URL
                                        </Button>
                                        <a
                                            href={`https://wa.me?text=Hello, I found this awesome job on Xcelyst. Check it out here - ${window.location.href}.`}
                                            data-action="share/whatsapp/share"
                                            target='_blank'
                                            onClick={() => copyToClipboard("Open in WhatsApp.")}
                                        >
                                            <Button
                                                variant="contained"
                                                startIcon={<ShareIcon />}
                                            >
                                                Share On WhatsApp
                                            </Button>
                                        </a>
                                    </Box>
                                </Box>
                            </Box>

                            {copied.copied && (
                                <Box className={classes.info} display="flex" alignItems="center" justifyContent="center">
                                    <CheckCircleIcon />
                                    <Typography variant="h6">
                                        {copied.message}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}

export default ShareModal;