import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { ExpandMore } from '@material-ui/icons';
import { Whatsapp, Messages, ShowMore, PdfIcon, DownloadIcon, DeleteIcon, UploadIcon, EditIcon } from "./assets";
import JobsCards from "../../../components/src/JobsCards";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  Grid, AppBar, Tabs, Tab, Typography, Box, Button, Divider, Table, TableRow, TableCell, TableHead,
  TableContainer, TableBody, Chip, Accordion, AccordionSummary, AccordionDetails, Tooltip
} from "@material-ui/core";
import _ from "lodash";
import "./style.scss";
import ShimmerLoading from "../../../components/src/ShimmerLoading";
// @ts-ignore
import InterviewContext, { InType } from "../../../web/src/Context/InterviewContext.web";
import { Link } from "react-router-dom";
import { formatAMPM } from "../../Location4/src/TimeSlotModal.web";
import { v4 as uuidv4 } from 'uuid';
import { withRouter } from "react-router-dom";


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Poppins-SemiBold",
      fontSize: 20,
    },
    h5: {
      fontSize: 15,
      fontFamily: "Poppins-SemiBold",
    },
  },
});

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  ButtonSearchJobs: {
    textTransform: "capitalize",
    marginTop: 20,
    backgroundColor: "#24325F",
    color: "white",
    width: "100%",
    fontWeight: 500,
    borderRadius: "10px",
    "& a": {
      color: "white",
    },
    [Theme.breakpoints.down("sm")]: {
      "& span": {
        fontSize: "1.3rem",
      }
    }
  },
  InterviewButton: {
    backgroundColor: "#24325F",
    color: "white",
  },

  appBar: {
    boxShadow: "none",
  },
  candidatesTabs: {
    justifyContent: "center",
    alignItems: "center",
    "&  MuiTab-textColorInherit-322 Mui-selected": {
      backgroundColor: "#A8D0E7",
    },
  },
  UploadCVButton: {
    backgroundColor: "#EBEBEB",
    color: "#575761",
    fontSize: 15,
    "& img": {
      marginRight: 5,
    },
  },
  TableRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  LeftItem: {
    flex: 0.35,
    letterSpacing: 1,

    [Theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  RightItem: {
    flex: 1,
    [Theme.breakpoints.down("sm")]: {
      flex: "none",
      marginLeft: "auto",
    }
  },
  heading: {
    "& th:first-child": {
      borderRadius: "10px 0 0 10px",
      [Theme.breakpoints.down("sm")]: {
        borderRadius: "5px 0 0 5px",
      }
    },
    "& th:last-child": {
      borderRadius: "0 10px 10px 0",
      [Theme.breakpoints.down("sm")]: {
        borderRadius: "0 5px 5px 0",
      }
    },
  },
  head: {
    backgroundColor: "#dcecf5",
  },
  AssessmentRow: {
    "&:nth-of-type(even)": {
      backgroundColor: "#A8D0E71A",
    },
    "& td:first-child": {
      borderRadius: "10px 0 0 10px",
    },
    "& td:last-child": {
      borderRadius: "0 10px 10px 0",
    },

    [Theme.breakpoints.down("sm")]: {
      '& img': {
        width: "20px"
      },

      '& button span': {
        fontSize: ".7rem"
      }
    },
    ['@media (min-width: 280px) and (max-width: 400px)']: {
      '& button span': {
        fontSize: ".6rem"
      },

      '& p': {
        fontSize: ".8rem",
      },

      '& img': {
        width: "18px"
      },

      '& h5': {
        fontSize: "1.1rem",
      }
    }
  },
  ShowMoreJobs: {
    marginTop: 20,
    color: "#263460",
  },
  Boldfont: {
    color: "#24325F",
    fontWeight: 600,
    textTransform: "none"
  },
  marginRight: {
    marginRight: ".3rem",
  },
  fontBoldWithSpacing: {
    fontWeight: 600,
    letterSpacing: 0.8,
    wordSpacing: ".2rem",
    fontSize: "90%",
    color: "#44444F"
  },
  fontBold: {
    fontWeight: 600,
  },
  moreJobs: {
    alignItems: "center",
    [Theme.breakpoints.down("xs")]: {
      flexDirection: "column",

      "& img": {
        width: '3rem'
      }
    },

    "& > div": {
      width: "38%",
      [Theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [Theme.breakpoints.down("xs")]: {
        width: "100%",
        marginBottom: "1rem"
      },
    },

    "& span": {
      fontSize: ".9rem",
      [Theme.breakpoints.down("md")]: {
        // fontSize: ".7rem",
      }
    },
  },
  container: {
    "& > div": {
      "& > div": {
        paddingRight: 0,

        [Theme.breakpoints.down("sm")]: {
          padding: 0,
        },
      }
    }
  },
  uploadCV: {
    [Theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "0",
    },

    "& > div": {
      "&:last-child": {
        marginLeft: "auto",
        marginTop: ".5rem",
      }
    }
  },
  profileBox: {
    "& svg": {
      marginRight: 10
    },

    [Theme.breakpoints.down("sm")]: {
      "& svg": {
        width: "15px",
      },
      "& button": {
        "&:first-child": {
          width: "100%",
          marginBottom: ".5rem"
        },
      },
      "& label": {
        width: "100%",
        textAlign: "center"
      }
    }
  },
  editIcon: {
    width: "20px",
    [Theme.breakpoints.down("sm")]: {
      width: "1.2rem",
    }
  },
  textSecondary: {
    [Theme.breakpoints.down("sm")]: {
      fontSize: ".9rem",
    }
  },
  showMoreContainer: {
    [Theme.breakpoints.down("md")]: {
      flex: "100% !important",
      marginTop: "1rem",
    }
  },
  showMoreBox: {
    [Theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",

      "& h4": {
        marginTop: 0,
        marginRight: "1rem",
        fontSize: "1.3rem",
      }
    },
  },
  chips: {
    [Theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem"
    }
  },
  textPrimary: {
    [Theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    }
  },
  saveJobs: {
    "& span": {
      fontSize: "1rem"
    },

    "& .jobCard": {
      ['@media (min-width: 760px) and (max-width: 1330px)']: {
        flexBasis: "50%",
      }
    }
  },
  companyDetails: {
    [Theme.breakpoints.down("sm")]: {
      "& h4": {
        fontSize: "1.2rem",
      }
    }
  },
  feedBack: {
    [Theme.breakpoints.down("sm")]: {
      "& p": {
        marginLeft: "2rem",
      }
    }
  }
});

export const formatDate = (string_date: string, separator = "/", format: "basic" | "advance" = "basic") => {
  if (!string_date || string_date === "NA") return "N/A";
  const month_names = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const _date = string_date.split("T")[0].split("-");
  const date = _date[2];
  let month = _date[1];
  const year = _date[0];

  if (format === "basic")
    return `${date}${separator}${month}${separator}${year}`;

  month = month_names[Number(month.toString()) - 1];
  return `${date}${separator}${month}${separator}${year}`;
}

function SimpleTabs(props: any) {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const handleWindowSizeChange = () => setWindowWidth(window.innerWidth);
  const [hasRoleId, setRoleId] = useState<number | null>(null);
  const cardRef = useRef<HTMLElement>();

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  React.useEffect(() => {
    if (props.history.location.search) {
      const role_id = props.history.location.search.split("&")[1].split("=")[1];
      if (role_id) {
        setRoleId(Number(role_id));
        setTimeout(() => {
          if (cardRef) {
            cardRef!.current!.scrollIntoView({
              behavior: "smooth"
            });
          }
        }, 200);
      }
    }
  }, [props.history.location.search]);

  return (
    <ThemeProvider theme={Theme}>
      <Box className="profileSettingWrap">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3} md={3}>
            <Box mt={3} className="leftProfileBox">
              <AppBar
                position="static"
                color="transparent"
                className={classes.appBar}
              >
                <Tabs
                  className="candidatesTabs"
                  value={props.tabIndex}
                  onChange={props.handleTabChange}
                  aria-label="scrollable  simple tabs example"
                  orientation="vertical"
                  variant="scrollable"
                  scrollButtons="auto"
                  TabIndicatorProps={{ style: { display: "none" } }}
                >
                  <Tab label="My Profile" {...a11yProps(0)} />
                  <Tab label="Current Job Application(s)" {...a11yProps(1)} />
                  <Tab label="Past Job Applications" {...a11yProps(2)} />
                  <Tab label="Saved Jobs" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
            </Box>
            <Link href="/job-listing" to={"/job-listing"}>
              <Button
                size="medium"
                variant="contained"
                className={classes.ButtonSearchJobs}
              >
                Search for More Jobs
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} lg={9} md={9} className={classes.container}>
            <TabPanel value={props.tabIndex} index={0}>
              <Box mb={3} className={`profileBox ${classes.profileBox}`}>
                <Box display="flex" justifyContent="space-between" my={2} className={classes.uploadCV}>
                  <Box>
                    <Typography variant="h4">Edit or Upload CV</Typography>
                  </Box>
                  <Box>
                    {!props.profileData ? <ShimmerLoading width="15rem" /> :
                      props.profileData?.resume &&
                      <Typography
                        style={{ color: "#a6a6a6", fontStyle: "italic" }}
                        className={classes.textSecondary}
                      >
                        CV Last updated on {formatDate(props.profileData?.cv_update)}
                      </Typography>}
                  </Box>
                </Box>
                <Divider style={{ background: "#F1F1F5" }} variant="middle" />
                <Box
                  my={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {props.profileData?.resume &&
                    <Button className={classes.UploadCVButton}>
                      <PdfIcon />
                      {props.profileData?.resume.split("/").slice(-1)[0]}
                    </Button>
                  }

                  {props.profileData?.resume &&
                    <a href={props.profileData?.resume} download target="_blank">
                      <Button>
                        <DownloadIcon />
                        <Typography className={classes.Boldfont}>Download Resume</Typography>
                      </Button>
                    </a>
                  }

                  {props.profileData?.resume &&
                    <a onClick={props.removeResume}>
                      <Button>
                        <DeleteIcon />
                        <Typography style={{ color: "red" }} className={classes.Boldfont}>Delete Resume</Typography>
                      </Button>
                    </a>
                  }

                  <input
                    accept="application/*"
                    className="uploadInput"
                    id="uploadInput-button-file"
                    type="file"
                    onChange={props.uploadResume}
                  />
                  {!props.isLoading ? (
                    <label
                      htmlFor="uploadInput-button-file"
                      className="uploadInputLable"
                    >
                      <Button className="butten" disableRipple component="span">
                        <UploadIcon />
                        <Typography className={classes.Boldfont}>Upload or Replace Resume</Typography>
                      </Button>
                    </label>
                  ) : <Typography variant="body1">Uploading....</Typography>}
                </Box>
              </Box>
              <Box my={3} className="profileBox">
                <Box display="flex" justifyContent="space-between" my={2}>
                  <Box>
                    <Typography variant="h4">Preferred Roles</Typography>
                  </Box>
                  <Box
                    onClick={() => props.handleEditRoleModal()}
                    style={{ cursor: "pointer" }}
                  >
                    <EditIcon className={classes.editIcon} />
                  </Box>
                </Box>
                <Divider style={{ background: "#F1F1F5" }} variant="middle" />
                <br />
                <Box>
                  {/* <Chip label="chop" style={{ borderRadius: 3 }} /> */}
                  {!props.profileData && <ShimmerLoading numberOfShimmer={7} />}
                  {props.profileData?.preferred_role_ids.map(
                    (chip: any, index: any) => (
                      <Chip
                        label={chip}
                        key={index}
                        style={{ marginRight: "0.6em", borderRadius: 3, marginBottom: "0.6em" }}
                        className={classes.chips}
                      />
                    )
                  )}
                  {props.profileData?.preferred_role_ids.length === 0 &&
                    <Typography variant="body1" color="textSecondary">No Preferred Roles Added.</Typography>}
                </Box>
              </Box>

              <Box my={3} className="profileBox">
                <Box display="flex" justifyContent="space-between" my={2}>
                  <Box>
                    <Typography variant="h4">Other Details</Typography>
                  </Box>
                  <Box
                    onClick={() => props.handleEditOtherDetailModal()}
                    style={{ cursor: "pointer" }}
                  >
                    <EditIcon className={classes.editIcon} />
                  </Box>
                </Box>
                <Divider
                  style={{ background: "#F1F1F5", marginBottom: "1.4rem" }}
                  variant="middle"
                />
                <Box>
                  <Box className={classes.TableRow}>
                    <Typography className={classes.LeftItem}>
                      Current Compensation
                    </Typography>
                    <Typography className={classes.RightItem} variant="h5">
                      {props.profileData ? props.profileData.other_details?.current_compensation || "_" : <ShimmerLoading width="50%" />}
                    </Typography>
                  </Box>
                  <Box className={classes.TableRow}>
                    <Typography className={classes.LeftItem}>
                      Expected Compensation
                    </Typography>
                    <Typography variant="h5" className={classes.RightItem}>
                      {props.profileData ? props.profileData.other_details?.expected_compensation || "_" : <ShimmerLoading width="50%" />}
                    </Typography>
                  </Box>
                  <Box className={classes.TableRow}>
                    <Typography className={classes.LeftItem}>
                      Notice Period
                    </Typography>
                    <Typography variant="h5" className={classes.RightItem}>
                      {!props.profileData && <ShimmerLoading width="55%" />}
                      {_.isEmpty(
                        props.profileData?.other_details?.notice_period
                      )
                        ? ""
                        : `${props.profileData?.other_details?.notice_period
                          .month > "1"
                          ? props.profileData?.other_details?.notice_period
                            .month + " Months"
                          : props.profileData?.other_details?.notice_period
                            .month + " Month"
                        } ${props.profileData?.other_details?.notice_period
                          .data > "1"
                          ? props.profileData?.other_details?.notice_period
                            .data + " Days"
                          : props.profileData?.other_details?.notice_period
                            .data + " Day"
                        }`}
                    </Typography>
                  </Box>
                  <Box className={classes.TableRow}>
                    <Typography className={classes.LeftItem}>
                      Location Preference
                    </Typography>
                    <Typography variant="h5" className={classes.RightItem}>
                      {!props.profileData && <ShimmerLoading width="35%" />}
                      {props.profileData?.other_details?.location_preference?.join(
                        ", "
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box my={3} className="profileBox">
                <Box my={2}>
                  <Box>
                    <Typography variant="h4">
                      Available Jobs Matching Your Skills
                    </Typography>
                  </Box>
                </Box>
                <Divider style={{ background: "#F1F1F5" }} variant="middle" />
                <Box my={2}>
                  <Grid container spacing={1} className={classes.moreJobs} >
                    {props?.availableJobList?.slice(0, 2)?.map((job: any, index: number) => (
                      <Grid item key={index}>
                        <JobsCards
                          job={job}
                          showFavorite={false}
                          showSalary={false}
                          isAppliedForJob={props.handleApplyJobs}
                          handleFavorite={props.handleFavorite}
                          sendEmail={(email: string) => props.sendEmailToParent(email)}
                        />
                      </Grid>
                    ))}
                    {props?.availableJobList?.length > 0 ?
                      <Grid item style={{ flex: 1 }} className={classes.showMoreContainer}>
                        <Link href="/job-listing" to={'/job-listing'}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            className={classes.showMoreBox}
                          >
                            <img src={ShowMore} className="showMore" />
                            <Typography
                              variant="h4"
                              className={classes.ShowMoreJobs}
                            >
                              Show More Jobs
                            </Typography>
                          </Box>
                        </Link>
                      </Grid> :
                      <Typography variant="body1">No Similar Jobs Found.</Typography>
                    }
                  </Grid>
                </Box>
              </Box>
            </TabPanel>

            {/* Current Job Applications Start */}
            <TabPanel value={props.tabIndex} index={1}>
              {props?.appliedJobList?.length > 0 ? props?.appliedJobList?.map((job: any, index: number) => (
                <Accordion className="accordainProfileBox"
                  ref={hasRoleId && job.id == hasRoleId ? cardRef : undefined}
                  key={job.id} defaultExpanded={hasRoleId ? job.id == hasRoleId :
                    props?.appliedJobList?.length <= 2 && index == 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel${job.id}a-content`}
                    id={`panel${job.id}a-header`}
                  >
                    <Box
                      display={`${windowWidth > 959 ? "flex" : "block"}`}
                      justifyContent="flex-start"
                      flexWrap="wrap"
                      my={2}
                    >
                      <Box
                        mx={2}
                        mb={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.companyDetails}
                      >
                        <Typography variant="h6" className={classes.marginRight}>Role: </Typography>
                        <Typography variant="h4">{job?.attributes?.role || "-"}</Typography>
                      </Box>
                      <Box
                        mx={2}
                        mb={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.companyDetails}
                      >
                        <Typography variant="h6" className={classes.marginRight}>Company: </Typography>
                        <Typography variant="h4">{job?.attributes?.company || "-"}</Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails className="accordainProfileBox-detail" style={{ overflowX: "auto" }}>
                    <Box className="accordainProfileBox-detail-data">
                      <Divider style={{ background: "#F1F1F5" }} variant="middle" />
                      <Box my={4}>
                        <Typography variant="h4">Skill Assessment</Typography>
                        <Box my={2}>
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableHead className={classes.head}>
                                <TableRow className={classes.heading}>
                                  <TableCell
                                    style={{ borderColor: "transparent", width: "25%" }}
                                    align="left"
                                  >
                                    <Typography className={classes.fontBoldWithSpacing}>TEST NAME</Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ borderColor: "transparent", width: "35%" }}
                                    align="left"
                                  >
                                    <Typography className={classes.fontBoldWithSpacing}>TEST URL</Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ borderColor: "transparent", width: "25%" }}
                                    align="left"
                                  >
                                    <Typography className={classes.fontBoldWithSpacing}>COMPLETION DEADLINE</Typography>
                                  </TableCell>
                                  <TableCell
                                    style={{ borderColor: "transparent" }}
                                    align="left"
                                  >
                                    <Typography className={classes.fontBoldWithSpacing}>SCORE</Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {job?.attributes?.test?.map((row: any, index: number) => (
                                  <TableRow
                                    className={classes.AssessmentRow}
                                    key={index}
                                  >
                                    <TableCell
                                      style={{ borderColor: "transparent" }}
                                      align="left"
                                    >
                                      <Typography className={classes.fontBold}>{row?.name || "-"}</Typography>
                                    </TableCell>
                                    <TableCell
                                      style={{ borderColor: "transparent" }}
                                      align="left"
                                    >
                                      <a target="_blank" href={row?.test_url}>{row?.test_url || "-"}</a>
                                    </TableCell>
                                    <TableCell
                                      style={{ borderColor: "transparent" }}
                                      align="left"
                                    >
                                      {row?.invitation_end_date && `${row?.invitation_end_date?.split("T")[0]}, ${row?.invitation_end_date?.split("T")[1]?.split(".")[0]}` || "-"}
                                    </TableCell>
                                    <TableCell
                                      style={{ borderColor: "transparent" }}
                                      align="left"
                                    >
                                      {row?.score || "0"}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                      {/* Interview Scheduling | Select By Candidate */}

                      {job.attributes.hr_assessment &&
                        <ShowInterview
                          classes={classes}
                          interviews={job.attributes.hr_assessment.interviews}
                          job={job.attributes}
                          handleSelectSlot={props.toggleModal}
                          key={uuidv4()}  // create unique ID for each interview
                          title="hr_assessment"
                        />
                      }

                      {job.attributes.hiring_manger_assessment &&
                        <ShowInterview
                          classes={classes}
                          interviews={job.attributes.hiring_manger_assessment.interviews}
                          job={job.attributes}
                          handleSelectSlot={props.toggleModal}
                          key={uuidv4()}  // create unique ID for each interview
                          title="hiring_manger_assessment"
                        />
                      }

                      {job.attributes.video_interview &&
                        <ShowInterview
                          classes={classes}
                          interviews={job.attributes.video_interview.interviews}
                          job={job.attributes}
                          handleSelectSlot={props.toggleModal}
                          key={uuidv4()}  // create unique ID for each interview
                          title="video_interview"
                        />
                      }

                      {/* Interview End */}

                      <Box my={3} className={classes.feedBack}>
                        <Typography variant="h4" gutterBottom>
                          Feedback
                        </Typography>
                        <Typography variant="body2">
                          {job.attributes.feedback || "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )) : <Box className={`profileBox ${classes.companyDetails}`}>
                <Typography variant="h4">No Open Job Application</Typography>
              </Box>}
            </TabPanel>
            {/* Current Job Applications End */}

            {/* Past Job Applications */}
            <TabPanel value={props.tabIndex} index={2}>
              {props?.pastJobs?.length > 0 ? props?.pastJobs?.map((job: any, index: number) => (
                <Accordion className="accordainProfileBox" key={job.id} defaultExpanded={props?.pastJobs?.length <= 2 && index == 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel${job.id}a-content`}
                    id={`panel${job.id}a-header`}
                  >
                    <Box
                      display={`${windowWidth > 959 ? "flex" : "block"}`}
                      justifyContent="justify-center"
                      flexWrap="wrap"
                      my={2}
                      flex={1}
                    >
                      <Box
                        mx={2}
                        mb={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        flex={.3}
                      >
                        <Typography variant="h6" className={classes.marginRight}>Role: </Typography>
                        {windowWidth > 959 ? (
                          <Tooltip title={job.attributes.role}>
                            <Typography variant="h4" className={classes.textPrimary}>
                              {job.attributes.role.length > 15 ? `${job.attributes.role.slice(0, 15)}...` : job.attributes.role}
                            </Typography>
                          </Tooltip>) : (
                          <Typography variant="h4" className={classes.textPrimary}>
                            {job.attributes.role || "-"}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        mx={2}
                        mb={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        flex={.3}
                      >
                        <Typography variant="h6" className={classes.marginRight}>Company: </Typography>
                        {windowWidth > 959 ? (
                          <Tooltip title={job.attributes.company}>
                            <Typography variant="h4" className={classes.textPrimary}>
                              {job.attributes.company.length > 15 ? `${job.attributes.company.slice(0, 15)}...` : job.attributes.company}
                            </Typography>
                          </Tooltip>) : (
                          <Typography variant="h4" className={classes.textPrimary}>
                            {job.attributes.company || "-"}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        mx={2}
                        mb={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        flex={.3}
                      >
                        <Typography variant="h6" className={classes.marginRight} style={windowWidth > 959 ? { maxWidth: "unset" } : { maxWidth: "50%" }}>
                          Application closed on:
                        </Typography>
                        <Typography variant="h4" className={classes.textPrimary}>{formatDate(job.attributes.appliction_closed_on)}</Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails className="accordainProfileBox-detail">
                    <Box className="accordainProfileBox-detail-data-past">
                      <Divider style={{ background: "#F1F1F5" }} variant="middle" />
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexWrap="wrap"
                        my={2}
                      >
                        <Box mx={2} display="flex" flexDirection="column">
                          <Typography variant="h4">Rejected on</Typography>
                          <Typography variant="body2" className={classes.textPrimary} component="h6" style={{ margin: ".7rem 0 1rem" }}>
                            {formatDate(job.attributes.rejected_on)} </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexWrap="wrap"
                        my={2}
                      >
                        <Box mx={2} display="flex" flexDirection="column">
                          <Typography variant="h4" gutterBottom>Feedback</Typography>
                          <Typography>{job.attributes.feedback || "-"}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )) : <Box className="profileBox"><Typography variant="h4">No Past Job Application Found.</Typography></Box>}
            </TabPanel>
            {/* Pas Job Applications End */}

            {/* Saved Jobs Start */}
            <TabPanel value={props.tabIndex} index={3}>
              <Grid container justifyContent="flex-start" spacing={2} className={classes.saveJobs}>
                {props?.savedJobList?.length > 0 && props?.savedJobList?.map((job: any, index: number) => (
                  <Grid item lg={4} xs={12} key={index} className="jobCard">
                    <JobsCards
                      job={job}
                      isAppliedForJob={(data: any) => {
                        props.handleApplyJobs(data);
                      }}
                      showSalary={true}
                      handleFavorite={(data: any) => {
                        props.handleRealTimeFavorite(data);
                      }}
                      sendEmail={(email: string) => props.sendEmailToParent(email)}
                    />
                  </Grid>
                ))}
              </Grid>
              {!props.savedJobList &&
                <Box style={{ margin: "1rem 0 0" }} className="profileBox"><Typography variant="h4">No Saved Jobs Found.</Typography></Box>
              }
            </TabPanel>
            {/* Saved Jobs End */}
          </Grid>
        </Grid>


      </Box>
    </ThemeProvider >
  );
}

interface SIProps {
  classes: any;
  interviews: {
    interview_type: string;
    interviewer: any;
    first_slot: string;
    second_slot: string;
    third_slot: string;
    is_accepted_by_candidate: boolean;
    preferred_slot: string | null;
    id: number;
  }[];
  job: any,
  title: string;
  handleSelectSlot: (data: any) => void;
  sendEmailToParent?: (email: string) => void;
}

class ShowInterview extends React.Component<SIProps> {
  constructor(props: SIProps) {
    super(props);
  }

  render() {
    const { classes, interviews, job, title, handleSelectSlot } = this.props;
    return (
      <Box my={2}>
        <Typography
          variant="h4"
          style={{ textTransform: "capitalize" }}>
          {title.split("_").join(" ")}
        </Typography>

        <Box my={2}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead className={classes.head}>
                <TableRow className={classes.heading}>
                  <TableCell
                    style={{ borderColor: "transparent", width: "25%" }}
                    align="left"
                  >
                    <Typography className={classes.fontBoldWithSpacing}>INTERVIEWER</Typography>
                  </TableCell>
                  <TableCell
                    style={{ borderColor: "transparent", width: "35%" }}
                    align="left"
                  >
                    <Typography className={classes.fontBoldWithSpacing}>ZOOM LINK</Typography>
                  </TableCell>
                  <TableCell
                    style={{ borderColor: "transparent", width: "25%" }}
                    align="left"
                  >
                    <Typography className={classes.fontBoldWithSpacing}>DATE & TIME</Typography>
                  </TableCell>
                  <TableCell
                    style={{ borderColor: "transparent" }}
                    align="left"
                  >
                    <Typography className={classes.fontBoldWithSpacing}>MESSAGE</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {interviews.map((interview, index) => {
                return (
                  <TableBody key={index} style={{ background: index % 2 !== 0 ? "#f6fafc" : "transparent" }}>
                    <TableRow className={classes.AssessmentRow}>
                      <TableCell
                        style={{ borderColor: "transparent" }}
                        align="left"
                      >
                        <Typography variant="h5">{interview?.interviewer?.name || '-'}</Typography>
                      </TableCell>
                      <TableCell
                        style={{ borderColor: "transparent" }}
                        align="left"
                      >
                        {interview.is_accepted_by_candidate ? (
                          job[`${title}`]?.join_url ?
                            <a href={job[`${title}`]?.join_url} target='_blank'>{job[`${title}`]?.join_url}</a>
                            : job[`${title}`]?.start_url ?
                              <a href={job[`${title}`]?.start_url} target='_blank'>{job[`${title}`]?.start_url}</a>
                              : "-"
                        ) :
                          <Button
                            onClick={() => handleSelectSlot({ ...interview, ...{ role: job.role, company: job.company } })}
                            style={{ background: "#24325f", color: "#fff", fontSize: ".7rem" }}
                            variant="contained">
                            Choose Interview Slot
                          </Button>}
                      </TableCell>
                      <TableCell
                        style={{ borderColor: "transparent" }}
                        align="left"
                      >
                        <Typography>
                          {interview.preferred_slot ?
                            interview.preferred_slot === "first" ?
                              `${formatAMPM(interview.first_slot)}, ${formatDate(interview.first_slot, "-", "advance")}` :
                              interview.preferred_slot === "second" ?
                                `${formatAMPM(interview.second_slot)}, ${formatDate(interview.second_slot, "-", "advance")}` :
                                `${formatAMPM(interview.third_slot)}, ${formatDate(interview.third_slot, "-", "advance")}`
                            : this.context.searchInterview(interview.id) ?
                              DTComponent(this.context.searchInterview(interview.id)) :
                              isAnySlotAvaiable(interview) ? "Expried" : "Pending"
                          }
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{ borderColor: "transparent", justifyContent: "center", alignItems: "center", display: "flex" }}
                        align="left"
                      >
                        <img style={{ marginRight: "1rem" }} src={Whatsapp} width="25px" />
                        <img src={Messages} width="25px" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              })}

            </Table>
          </TableContainer>
        </Box>

      </Box>
    )
  }
}

const DTComponent = (interview: InType) => {
  const { slots } = interview;

  return slots.preffered_slot === "first" ?
    `${formatAMPM(slots.first_slot)}, ${formatDate(slots.first_slot, "-", "advance")}` :
    slots.preffered_slot === "second" ?
      `${formatAMPM(slots.second_slot)}, ${formatDate(slots.second_slot, "-", "advance")}` :
      `${formatAMPM(slots.third_slot)}, ${formatDate(slots.third_slot, "-", "advance")}`;
}

const isDatePassed = (slotDate: string) => new Date() >= new Date(slotDate.slice(0, slotDate.length - 1));
export const isAnySlotAvaiable = (interview: any) => {
  return isDatePassed(interview.first_slot) &&
    isDatePassed(interview.second_slot) && isDatePassed(interview.third_slot);
}

ShowInterview.contextType = InterviewContext;



export default withRouter(SimpleTabs);