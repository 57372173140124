import React, { useState } from 'react';

// Interview Type
export interface InType {
    interviewId: number;
    isSet: boolean;
    slots: {
        first_slot: string;
        second_slot: string;
        third_slot: string;
        preffered_slot: string;
    }
};

// Interview Context type
export interface InterviewContextType {
    interviews: InType[];
    candidate_name: string; // use candidate name here because we can't use multiple contexts in a simple class component
    setInterview: (interview: InType) => void;
    searchInterview: (id: number) => InType | undefined;
    setCandidateName: (name: string) => void;
}

// Create Interview Context using create Context
const InterviewContext = React.createContext<InterviewContextType>({
    interviews: [],
    candidate_name: '',
    setInterview: (interview: InType) => { },
    searchInterview: (id: number) => undefined,
    setCandidateName: (name: string) => { }
});

// Interview Consumer
export const InterviewConsumer = InterviewContext.Consumer;

// Interview Provider
export const InterviewProvider: React.FC = (props) => {
    const [interviews, setInterviews] = useState<InType[]>([]);
    const [candidate_name, setCandidateName] = useState<string>('');

    // Set Interview
    const handleSetInterview = (interview: InType) => {
        setInterviews(prev => prev.concat(interview));
    };

    // Return Interview Data
    const searchInterview = (id: number) => {
        const res = interviews.find(interview => interview.interviewId === id);
        return res;
    }

    // Set Candidate Name
    const handleSetCandidateName = (name: string) => {
        setCandidateName(name);
    }

    return (
        <InterviewContext.Provider value={{
            interviews: interviews,
            candidate_name: candidate_name,
            setInterview: handleSetInterview,
            searchInterview: searchInterview,
            setCandidateName: handleSetCandidateName
        }}>
            {props.children}
        </InterviewContext.Provider>
    );
}
export default InterviewContext;

