import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel, Grid, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React, { Fragment } from "react";
import * as Yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RoleMangementController, { Props } from "./RoleMangementController.web";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
interface FormikInitialValues {
    jobTitle: any;
    role: any;
    experience: any;
    showExperience: any;
    currency: any;
    minSalary: any;
    location: any;
    company_desc: any;
    job_responsibility: any;
    businessVerticleShow: any;
    businessFunctionShow: any;
    selectedDomainSkill: any;
    degree: any;
    fieldOfStudy: any;
}

const isContainsSpecialChar = (str: string) => {
    var format = /[!@#$%^&*_+\=\[\]{};':"\\|.<>\/?]+/;
    if (format.test(str)) {
        return true;
    } else {
        return false;
    }
};


class StepIJD extends RoleMangementController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const initialValues: FormikInitialValues = {
            jobTitle: this.props.fieldData?.jobTitle ? this.props.fieldData?.jobTitle : "",
            role: this.props.fieldData?.role ? this.props.fieldData?.role : "",
            experience: this.props.fieldData?.experience ? this.props.fieldData?.experience : "",
            showExperience: this.props.fieldData?.showExperience ? this.props.fieldData?.showExperience : "",
            currency: this.props.fieldData?.currency ? this.props.fieldData?.currency : "",
            minSalary: this.props.fieldData?.minSalary ? this.props.fieldData?.minSalary : "",
            location: this.props.fieldData?.location ? this.props.fieldData?.location : "",
            company_desc: this.props.fieldData?.company_desc ? this.props.fieldData?.company_desc : "",
            job_responsibility: this.props.fieldData?.job_responsibility ? this.props.fieldData?.job_responsibility : "",
            businessVerticleShow: this.props.fieldData?.businessVerticleShow ? this.props.fieldData?.businessVerticleShow : null,
            businessFunctionShow: this.props.fieldData?.businessFunctionShow ? this.props.fieldData?.businessFunctionShow : {},
            selectedDomainSkill: this.props.fieldData?.selectedDomainSkill ? this.props.fieldData?.selectedDomainSkill : {},
            degree: this.props.fieldData?.degree ? this.props.fieldData?.degree : "",
            fieldOfStudy: this.props.fieldData?.fieldOfStudy ? this.props.fieldData?.fieldOfStudy : ""
        };

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape(
                    this.state.buyerSchemaArray[this.props.currentStep]
                )}
                onSubmit={(values) => {
                    this.props.setJDValues(values);
                    this.props.nextStep();
                }}
                enableReinitialize
            >
                {(formikProps) => (

                    <form onSubmit={formikProps.handleSubmit} className="job-desc-form">
                        <div className="form-data-wrap">
                            <div className="job-descriptio-step">
                                <h2 className="job-description-title">
                                    {this.props.jdHeadingValue} Job Description
                                </h2>

                                {/* Row1 */}
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                            <FormLabel className="job-desc-textLabel">Job Title</FormLabel>
                                            <Autocomplete
                                                freeSolo
                                                disableClearable
                                                value={formikProps.values.jobTitle ? formikProps.values.jobTitle : null}
                                                options={this.state.jobTitleList.map((option: any) => option.suggestion)}
                                                onInputChange={(event, newInputValue) => {
                                                    if (!isContainsSpecialChar(newInputValue)) {
                                                        if (newInputValue && newInputValue.trim().length > 0) {
                                                            this.fetchInputData(newInputValue.trim(), formikProps.setFieldValue);
                                                            formikProps.setErrors({ jobTitle: (newInputValue.trim().length > 50 ? "Job title cannot be more than 50 characters" : "") })
                                                        } else {
                                                            formikProps.setErrors({ jobTitle: "Please enter job title" })
                                                        }
                                                    } else {
                                                        formikProps.setErrors({ jobTitle: "Special characters are not allowed" })
                                                    }
                                                }}
                                                onChange={(event, value) => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    formikProps.setFieldValue("jobTitle", value);
                                                }}
                                                renderInput={(params) => <TextField {...params}
                                                    placeholder="Please Select Job Title"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        onKeyDown: (event) => {
                                                            if (event.key === 'Enter') {
                                                                event.stopPropagation();
                                                                event.preventDefault();
                                                            }
                                                        },
                                                    }}
                                                />}
                                            />
                                            <Typography variant="caption" style={{ color: "#f44336", display: Boolean(formikProps.errors.jobTitle) ? 'flex' : 'none' }}>
                                                {formikProps.errors.jobTitle}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                            <FormLabel className="job-desc-textLabel">Job Role</FormLabel>
                                            <Select
                                                style={{ width: "100%" }}
                                                labelId="role-checkbox-label"
                                                id="role-checkbox"
                                                className="job-desc-textField"
                                                name="role"
                                                value={formikProps.values.role}
                                                error={formikProps.touched.role && Boolean(formikProps.errors.role)}
                                                onChange={(e) => {
                                                    formikProps.setFieldValue("role", e.target.value)
                                                }}
                                                input={<OutlinedInput label="Tag" />}
                                                MenuProps={MenuProps}
                                                displayEmpty
                                                renderValue={(value: any) => (value !== '' ? value : <span className="jd-placeholder">Please Select Job Role</span>)}
                                            >
                                                {this.props.jobDescriptionData?.roleList?.map((item: any) => (
                                                    <MenuItem key={item?.category_id} value={item?.category_name}>
                                                        <ListItemText primary={item?.category_name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                            <FormLabel htmlFor="experience-checkbox" className="job-desc-textLabel">Overall Experience</FormLabel>
                                            <Select
                                                style={{ width: "100%" }}
                                                labelId="experience-checkbox-label"
                                                id="experience-checkbox"
                                                className="job-desc-textField"
                                                name="showExperience"
                                                value={formikProps.values.showExperience}
                                                error={formikProps.touched.showExperience && Boolean(formikProps.errors.showExperience)}
                                                onChange={(e) => {
                                                    formikProps.setFieldValue("showExperience", e.target.value);
                                                }}
                                                input={<OutlinedInput label="experience-checkbox" />}

                                                MenuProps={MenuProps}
                                                displayEmpty
                                                renderValue={(value: any) => (value !== '' ? value : <span className="jd-placeholder">Please Select Overall Experience</span>)}
                                            >
                                                {this.props.jobDescriptionData?.overall_experiences?.map((item: any) => (
                                                    <MenuItem key={item.id} value={`${item.level}(${item.experiences_year} years)`}
                                                        onClick={(e: any) => {
                                                            formikProps.setFieldValue("experience", item.id);
                                                        }}>
                                                        <ListItemText primary={`${item.level}(${item.experiences_year} years)`} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                            <FormLabel className="job-desc-textLabel">Education/Degree</FormLabel>
                                            <Select
                                                style={{ width: "100%" }}
                                                labelId="degree-checkbox-label"
                                                id="degree-checkbox"
                                                className="job-desc-textField"
                                                name="degree"
                                                value={formikProps.values.degree}
                                                error={formikProps.touched.degree && Boolean(formikProps.errors.degree)}
                                                onChange={(e) => {
                                                    formikProps.setFieldValue("degree", e.target.value)
                                                }}
                                                input={<OutlinedInput label="Tag" />}
                                                MenuProps={MenuProps}
                                                displayEmpty
                                                renderValue={(value: any) => (value !== '' ? value : <span className="jd-placeholder">Please Select Experience/Degree</span>)}
                                            >
                                                {this.props.jobDescriptionData?.degreeList?.map((item: any) => (
                                                    <MenuItem key={item?.id} value={item?.name}>
                                                        <ListItemText primary={item?.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* Row2 */}
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                            <FormLabel className="job-desc-textLabel">Currency</FormLabel>
                                            <Select
                                                style={{ width: "100%" }}
                                                labelId="currency-checkbox-label"
                                                id="currency-checkbox"
                                                className="job-desc-textField"
                                                name="currency"
                                                value={formikProps.values.currency}
                                                error={formikProps.touched.currency && Boolean(formikProps.errors.currency)}
                                                onChange={(e) => {
                                                    formikProps.setFieldValue("currency", e.target.value)
                                                }}
                                                input={<OutlinedInput label="Tag" />}
                                                MenuProps={MenuProps}
                                                displayEmpty
                                                renderValue={(value: any) => (value !== '' ? value : <span className="jd-placeholder">Please Select Currency</span>)}

                                            >
                                                {Object.keys(this.props.jobDescriptionData?.currency)?.map((name: any) => (
                                                    <MenuItem key={name} value={name}>
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                            <FormLabel className="job-desc-textLabel">Minimum Salary</FormLabel>
                                            <TextField
                                                style={{ width: "100%" }}
                                                className="job-desc-textField"
                                                id="minSalary"
                                                name="minSalary"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.minSalary}
                                                error={formikProps.touched.minSalary && Boolean(formikProps.errors.minSalary)}
                                                helperText={formikProps.touched.minSalary && formikProps.errors.minSalary}
                                                placeholder="Enter Minimum Salary"
                                                onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                            <FormLabel className="job-desc-textLabel">Location</FormLabel>
                                            <TextField
                                                style={{ width: "100%" }}
                                                className="job-desc-textField"
                                                id="location"
                                                name="location"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.location}
                                                error={formikProps.touched.location && Boolean(formikProps.errors.location)}
                                                helperText={formikProps.touched.location && formikProps.errors.location}
                                                placeholder="Enter Job location"
                                                onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                            <FormLabel className="job-desc-textLabel">Field Of Study</FormLabel>
                                            <Select
                                                style={{ width: "100%" }}
                                                labelId="fieldOfStudy-checkbox-label"
                                                id="fieldOfStudy-checkbox"
                                                className="job-desc-textField"
                                                name="fieldOfStudy"
                                                value={formikProps.values.fieldOfStudy}
                                                error={formikProps.touched.fieldOfStudy && Boolean(formikProps.errors.fieldOfStudy)}
                                                onChange={(e) => {
                                                    formikProps.setFieldValue("fieldOfStudy", e.target.value)
                                                }}
                                                input={<OutlinedInput label="Tag" />}
                                                MenuProps={MenuProps}
                                                displayEmpty
                                                renderValue={(value: any) => (value !== '' ? value : <span className="jd-placeholder">Please Select Field Of Study</span>)}
                                            >
                                                {this.props.jobDescriptionData?.fieldOfStudyList?.map((item: any) => (
                                                    <MenuItem key={item?.id} value={item?.name}>
                                                        <ListItemText primary={item?.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* Row3 */}
                                <Box sx={{ flexGrow: 1 }} mt={2}>
                                    <FormLabel className="job-desc-textLabel">Company Description</FormLabel>
                                    <TextField
                                        style={{ width: "100%" }}
                                        className="job-desc-textField"
                                        id="company_desc"
                                        name="company_desc"
                                        multiline
                                        rows={2}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        value={formikProps.values.company_desc}
                                        error={formikProps.touched.company_desc && Boolean(formikProps.errors.company_desc)}
                                        helperText={formikProps.touched.company_desc && formikProps.errors.company_desc}
                                        placeholder="Enter Company Description" />
                                </Box>

                                {/* Row4 */}
                                <Box sx={{ flexGrow: 1 }} mt={2}>
                                    <FormLabel className="job-desc-textLabel">Job Responsibility</FormLabel>
                                    <TextField
                                        style={{ width: "100%" }}
                                        className="job-desc-textField"
                                        id="job_responsibility"
                                        name="job_responsibility"
                                        multiline
                                        rows={2}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        value={formikProps.values.job_responsibility}
                                        error={formikProps.touched.job_responsibility && Boolean(formikProps.errors.job_responsibility)}
                                        helperText={formikProps.touched.job_responsibility && formikProps.errors.job_responsibility}
                                        placeholder="Enter Job Responsibility" />
                                </Box>

                                {/* Row5 */}
                                <Box sx={{ flexGrow: 1 }} mt={2}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                            <div className="jd-card">
                                                <h5 className="jd-card-title">Business Function</h5>
                                                <div className="jd-radio-wrap">
                                                    <Grid container spacing={1}>
                                                        {this.props.jobDescriptionData?.domainList?.map((item: any) => {
                                                            if (item?.category_name == "Risk" || item.category_name == "Front Office" || item.category_name == "Finance / MO / Operations") {
                                                                return (<Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={item?.category_id}>
                                                                    <div className="jd-radio-box">
                                                                        <p className="jd-radio-label">{item.category_name}</p>
                                                                        <div className="jd-radio-box-wrap">
                                                                            <RadioGroup
                                                                                aria-labelledby={`${item?.category_id}-radio-buttons-group-label`}
                                                                                name={`businessFunctionShow[${item?.category_name}]`}
                                                                                row
                                                                                value={formikProps.values?.businessFunctionShow?.[item?.category_name] ? formikProps.values?.businessFunctionShow?.[item?.category_name] : null}
                                                                                onChange={(event: any) => {
                                                                                    formikProps.setFieldValue(`businessFunctionShow[${item?.category_name}]`, event.currentTarget.value)
                                                                                }}
                                                                                onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}

                                                                            >
                                                                                {item?.skilles?.map((skill: any) => (
                                                                                    <FormControlLabel
                                                                                        key={skill?.id}
                                                                                        value={skill?.name}
                                                                                        control={
                                                                                            <Radio
                                                                                                onChange={() => formikProps.setFieldValue(`selectedDomainSkill[${item?.category_name}]`, [skill?.id])}
                                                                                            />} label={skill?.name} />
                                                                                ))}
                                                                            </RadioGroup>
                                                                        </div>
                                                                    </div>
                                                                </Grid>)
                                                            }
                                                        })}
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <div className="jd-card">
                                                {this.props.jobDescriptionData?.domainList?.map((item: any) => item?.category_name == "Business Understanding" ? (<Fragment key={item?.category_id}>
                                                    <h5 className="jd-card-title">{item.category_name}</h5>
                                                    <div className="jd-main-radio-box-wrap">
                                                        <RadioGroup
                                                            aria-labelledby="businessVerticleShow-radio-buttons-group-label"
                                                            name="businessVerticleShow"
                                                            value={formikProps.values.businessVerticleShow ? formikProps.values.businessVerticleShow : ""}
                                                            onChange={(e) => formikProps.setFieldValue("businessVerticleShow", e.target.value)}
                                                            onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}

                                                        >
                                                            {item?.skilles?.map((skill: any) => (
                                                                <FormControlLabel
                                                                    key={skill?.id}
                                                                    value={skill?.name}
                                                                    control={
                                                                        <Radio
                                                                            onChange={() => formikProps.setFieldValue(`selectedDomainSkill[${item?.category_name}]`, [skill?.id])}
                                                                        />} label={skill?.name} />
                                                            ))}
                                                        </RadioGroup>
                                                    </div>
                                                </Fragment>) : ""
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* Row6 */}
                                <Box sx={{ flexGrow: 1 }} mt={2}>
                                    <Grid container spacing={3}>
                                        {this.props.jobDescriptionData?.domainList?.map((item: any) => item?.category_name == "Regulatory Programs" || item?.category_name == "Asset Class" ? (
                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={item?.category_id}>
                                                <div className="jd-card">
                                                    <h5 className="jd-card-title">{item?.category_name}</h5>
                                                    <div className="jd-checkbox-wrap">
                                                        <FormGroup>
                                                            {item?.skilles?.map((skill: any) => {
                                                                return (
                                                                    <FormControlLabel key={skill?.id} control={<Checkbox name={`selectedDomainSkill[${item?.category_name}]`}
                                                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                                                        checked={formikProps.values?.selectedDomainSkill?.[item?.category_name]?.length > 0 ? formikProps.values?.selectedDomainSkill?.[item?.category_name]?.includes(skill.id) : false}

                                                                        onChange={(event) => {
                                                                            let data: any = [];
                                                                            if (formikProps.values.selectedDomainSkill[item?.category_name]?.length > 0) {
                                                                                if (formikProps.values?.selectedDomainSkill?.[item?.category_name]?.includes(skill.id)) {
                                                                                    data = formikProps.values?.selectedDomainSkill?.[item?.category_name].filter((ele: any) => ele != skill.id);
                                                                                } else {
                                                                                    data = [...formikProps.values.selectedDomainSkill[item?.category_name], skill.id];
                                                                                }
                                                                            } else {
                                                                                data = [skill.id]
                                                                            }
                                                                            formikProps.setFieldValue(`selectedDomainSkill[${item?.category_name}]`, [...data]);
                                                                        }}
                                                                    />} label={skill?.name} />
                                                                )
                                                            })}
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </Grid>
                                        ) : "")}
                                    </Grid>
                                </Box>

                                {/* Buttons */}

                                <Box justifyContent="center" display="flex" mt={5}>
                                    <Button
                                        type="button" className="fullBtnCancel" disableRipple variant="contained" color="secondary" onClick={this.props.previousStep}>
                                        <Typography variant="body2">
                                            Cancel
                                        </Typography>
                                    </Button>
                                    <Button
                                        type="submit" className="fullBtnSubmit" disableRipple variant="contained" color="primary">
                                        <Typography variant="body2">
                                            Continue
                                        </Typography>
                                    </Button>
                                </Box>
                            </div>
                        </div>
                    </form>)
                }
            </Formik>);
    }
}

export default StepIJD;