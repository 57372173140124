import React from "react";

//Customizable Area Start
import { Formik } from "formik";
import * as Yup from "yup";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { Box, Typography, withStyles, Card, Container, Button, TextField, CircularProgress } from "@material-ui/core";
import { banner } from '../../email-account-login/src/assets';
import { arrow, otpImage } from "./assets";
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const blockStyle = (theme: any) => ({
  banner: {
    backgroundColor: "#f2f2f2",
    padding: "1rem 2rem",
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPositionY: "-15rem",
    [theme.breakpoints.up('sm')]: {
      padding: "2rem",
    },
    height: "100%",
  },
  textWhite: {
    color: "#ffffff",
  },
  loginCard: {
    paddingTop: "2em",
    padding: "1.3em",
  },
  fullBtn: {
    width: '100%'
  },
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  goBackArrow: { width: "5%", },
  forgetText: { width: "90%", }
});
//Customizable Area End

class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    //Customizable Area Start
    const { classes } = this.props;
    const { navigation } = this.props;

    return (
      <>
        <Box className={classes.container} mb={8}>
          <Box className={classes.banner}>
            <Box style={{ marginTop: "9em" }} display={this.state.accountStatus == 'EnterEmail' ? 'flex' : 'none'}>
              <Container maxWidth="sm">
                <Card className={classes.loginCard}>
                  <Box display='flex' alignItems="center">
                    <Box className={classes.goBackArrow} onClick={() => {
                      //@ts-ignore
                      this.props.history.goBack();
                    }}>
                      <img src={arrow} alt="arrow" /></Box>
                    <Box className={classes.forgetText} > <Typography color="primary" align="center" variant="h5">
                      Forgot Password?
                    </Typography></Box>
                  </Box>
                  <Box my={2}>
                    <Typography color="primary" align="center" variant="body1">
                      Please enter your Email ID to request a password change
                    </Typography>
                  </Box>
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={Yup.object().shape(this.state.emailSchema)}
                    onSubmit={(values: any) => {
                      this.setState({
                        emailValue: values.email ? values.email : ""
                      });
                      this.goToOtpAfterEmailValidation(values);
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      errors,
                      setFieldTouched,
                      touched,
                      values
                    }) => (
                      <Box flex="1" mt={4}>
                        <form >
                          <TextField
                            fullWidth
                            placeholder="Enter Your Email ID"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            InputProps={{
                              startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/email.png')} />,
                            }}
                          />
                          <Box style={{ marginTop: "6em" }}>
                            <Button onClick={() => handleSubmit()} className={classes.fullBtn} disableRipple variant="contained" color="primary" disabled={this.state.loader}>
                              <Typography variant="body2">
                                Request OTP
                              </Typography>
                              {this.state.loader && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    color: "#24325f",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                  }}
                                />
                              )}
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    )}
                  </Formik>
                </Card>
              </Container>
            </Box>
            {/* enter otp and password */}
            <Box style={{ marginTop: "9em" }} display={this.state.accountStatus == 'EnterOTP' ? 'flex' : 'none'}>
              <Container maxWidth="sm">
                <Card className={classes.loginCard}>
                  <Box display='flex' alignItems="center">
                    <Box className={classes.goBackArrow} onClick={() => {
                      //@ts-ignore
                      this.props.history.goBack();
                    }}>
                      <img src={arrow} alt="arrow" />
                    </Box>
                    <Box className={classes.forgetText} >
                      <Typography color="primary" align="center" variant="h5">
                        Forgot Password?
                      </Typography>
                    </Box>
                  </Box>
                  <Box my={4}>
                    <Typography color="primary" align="center" variant="body1">
                      OTP to reset your password has been sent to your email. Please enter the code and create new password
                    </Typography>
                  </Box>
                  <Formik
                    initialValues={{ otpCode: "", password: "", confirmPassword: "", email: this.state.emailValue }}
                    validationSchema={Yup.object().shape(this.state.passwordSchema)}
                    onSubmit={(values: any) => {
                      this.goToConfirmationAfterPasswordChange(values);
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      errors,
                      setFieldTouched,
                      touched,
                      values
                    }) => (
                      <Box flex="1">
                        <form >
                          <Box my={5}>
                            <TextField
                              fullWidth
                              placeholder="Enter OTP"
                              id="otpCode"
                              name="otpCode"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.otpCode}
                              error={touched.otpCode && Boolean(errors.otpCode)}
                              helperText={touched.otpCode && errors.otpCode}
                              InputProps={{
                                startAdornment: <img width="16px" style={{ marginRight: 15 }} src={otpImage} />,
                              }}
                            />
                          </Box>
                          <Box my={5}>
                            <TextField
                              fullWidth
                              type={
                                this.state.enablePasswordField
                                  ? "password"
                                  : "text"
                              }
                              placeholder="Enter Your Password"
                              id="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              error={touched.password && Boolean(errors.password)}
                              helperText={touched.password && errors.password}
                              InputProps={{
                                startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/password.png')} />,
                                endAdornment: (
                                  <img
                                    width="24px"
                                    onClick={() => {
                                      this.setState({
                                        enablePasswordField: !this.state
                                          .enablePasswordField,
                                      });
                                    }}
                                    style={{ marginRight: 15 }}
                                    src={
                                      this.state.enablePasswordField
                                        ? require("../assets/show_password.png")
                                        : require("../assets/hide_password.png")
                                    }
                                  />
                                ),
                              }}
                            />
                          </Box>
                          <Box my={5}>
                            <TextField
                              fullWidth
                              type={
                                this.state.enablePasswordField
                                  ? "password"
                                  : "text"
                              }
                              placeholder="Re-enter Password"
                              id="confirmPassword"
                              name="confirmPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                              helperText={touched.confirmPassword && errors.confirmPassword}
                              InputProps={{
                                startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/password.png')} />,
                                endAdornment: (
                                  <img
                                    width="24px"
                                    onClick={() => {
                                      this.setState({
                                        enablePasswordField: !this.state
                                          .enablePasswordField,
                                      });
                                    }}
                                    style={{ marginRight: 15 }}
                                    src={
                                      this.state.enablePasswordField
                                        ? require("../assets/show_password.png")
                                        : require("../assets/hide_password.png")
                                    }
                                  />
                                ),
                              }}
                            />
                          </Box>
                          <Box mt={8}>
                            <Button
                              onClick={() => handleSubmit()}
                              className={classes.fullBtn}
                              disableRipple variant="contained" color="primary"
                              disabled={this.state.loader}
                            >
                              <Typography variant="body2">
                                Reset Password
                              </Typography>
                              {this.state.loader && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    color: "#24325f",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                  }}
                                />
                              )}
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    )}
                  </Formik>
                </Card>
              </Container>
            </Box>
            <Box style={{ marginTop: "10em" }} display={this.state.accountStatus == 'Confirmation' ? 'flex' : 'none'}>
              <Container maxWidth="sm">
                <Card className={classes.loginCard}>
                  <Box mb={6} mt={6}>
                    <Typography color="primary" align="center" variant="h5">
                      Password Changed Successfully!
                    </Typography>
                  </Box>
                  <Box mb={6} onClick={() => {
                    //@ts-ignore
                    this.props.history.push(`/login/?type=${this.state.userType}`);
                  }}>
                    <Button className={classes.fullBtn} disableRipple variant="contained" color="primary">
                      <Typography variant="body2">
                        Go to Login
                      </Typography>
                    </Button>
                  </Box>
                </Card>
              </Container>
            </Box>
          </Box>
        </Box>
        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
    )
    //Customizable Area End
  }

}

// Customizable Area Start
// @ts-ignore
export default withStyles(blockStyle)(withRouter(ForgotPassword))