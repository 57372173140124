import React, { useRef } from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  Platform,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Container,
  Typography,
  Card,
  withStyles,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Modal,
  Checkbox,
  CircularProgress
} from "@material-ui/core";

import FormField from "../../../components/src/FormField";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import { BrowserRouter as Router, Link, withRouter } from "react-router-dom";
import { banner } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import axios from "axios";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  paddingLeft: "20px",
  paddingRight: "20px",
  overflow: 'scroll',
  height: '80%',
  display: 'block'
};

const blockStyle = (theme: any) => ({
  banner: {
    backgroundColor: "whitesmoke",
    padding: "1rem 2rem",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPositionY: "-15rem",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
    },
  },
  textWhite: {
    color: "#ffffff",
  },
  loginCard: {
    paddingTop: "2em",
    padding: "1.3em",
    border: "2px solid #eaeaea",
    marginTop: "80px",
    marginBottom: "80px"
  },
  image: {
    display: "block",
    marginRight: "auto",
    marginLeft: "auto"
  },
  fullBtn: {
    width: "100%",
  },
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#ffffff",
  },
  input: {
    display: "none",
  },
});

const validationSchema = Yup.object({
  first_name: Yup.string()
    .trim()
    .min(3, "First name should be of minimum 8 characters length")
    .max(10, "First name should be of maximum 10 characters length")
    .required("First name is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  last_name: Yup.string()
    .trim()
    .min(3, "Last name should be of minimum 8 characters length")
    .max(10, "Last name should be of maximum 10 characters length")
    .required("Last name is a required field")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  email: Yup.string()
    .trim()
    .email("Email is invalid")
    .required("Email is a required field")
    .max(250, "Email should be of maximum 250 characters length"),
  password: Yup.string()
    .trim()
    .required("Password is required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      "Pasword must contain minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  repeat_pass: Yup.string()
    .trim()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  city: Yup.string()
    .trim()
    .required("City is a required field")
    .max(200, "City name should be of maximum 200 characters length"),
  agree: Yup.boolean().required('You need to mark this as checked')
});

var otp: any = "";
// Customizable Area End
class EmailAccountRegistrationOTP extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Handle OTP value for each OTP input field start
  handleRegister = (values: any) => {
    otp = "";
    for (let i = 0; i < 6; i++) {
      if (i == 0) {
        otp = otp + values.first_input;
      }
      if (i == 1) {
        otp = otp + values.second_input;
      }
      if (i == 2) {
        otp = otp + values.third_input;
      }
      if (i == 3) {
        otp = otp + values.fourth_input;
      }
      if (i == 4) {
        otp = otp + values.fifth_input;
      }
      if (i == 5) {
        otp = otp + values.last_input;
      }
    }

    return otp;
  };
  // Handle OTP value for each OTP input field end

  // Customizable Area End
  render() {
    const { classes } = this.props;
    // Customizable Area Start
    return (
      <>
        <Box className={classes.banner}>
          <Container maxWidth="sm">
            <Card className={classes.loginCard}>
              <Box alignContent="center"></Box>

              <img src={require("../assets/mail_verification.png")} alt="" width="150" height="125" className={classes.image} />
              <br />
              <br />
              <Typography align="center" style={{ fontSize: 28, fontWeight: 1000 }} color="primary">
                Verify Your Email Address
              </Typography>
              <br />
              <Typography align="center" style={{ color: "darkslategray", fontSize: 18 }}>
                Enter the 6 digit OTP we sent to your email address
              </Typography>
              <br />
              <br />
              <Formik
                initialValues={{
                  first_input: "",
                  second_input: "",
                  third_input: "",
                  fourth_input: "",
                  fifth_input: "",
                  last_input: ""

                }}

                onSubmit={(values) => {
                  if (this.state.isOtpExpired) {
                    this.openSnackBarHandler("error", "OTP has expired, please click on retry");
                  } else {
                    let otp = values.first_input + values.second_input + values.third_input + values.fourth_input + values.fifth_input + values.last_input;
                    this.doOTPVerification(otp);
                  }
                }}
              >
                {(formikProps) => (
                  <form onSubmit={formikProps.handleSubmit}>
                    <Grid container spacing={1}>
                      {/* Each OTP charecters grid with input field start */}
                      <Grid item xs={2}>
                        <TextField required id="first_input"
                          name="first_input"
                          onChange={(e) => { formikProps.setFieldValue("first_input", e.target.value.substring(0, 1)) }}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.first_input}
                          inputProps={{ tabIndex: 1, min: 0, maxLength: 1, style: { textAlign: 'center', color: "black" } }}
                          onKeyUp={e => this.inputfocus(e)}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField required id="second_input"
                          name="second_input"
                          onChange={(e) => formikProps.setFieldValue("second_input", e.target.value.substring(0, 1))}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.second_input}
                          inputProps={{ tabIndex: 2, min: 0, maxLength: 1, style: { textAlign: 'center', color: "black" } }}
                          onKeyUp={e => this.inputfocus(e)}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField required id="third_input"
                          name="third_input"
                          onChange={(e) => formikProps.setFieldValue("third_input", e.target.value.substring(0, 1))}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.third_input}
                          inputProps={{ tabIndex: 3, min: 0, maxLength: 1, style: { textAlign: 'center', color: "black" } }}
                          onKeyUp={e => this.inputfocus(e)}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField required id="fourth_input"
                          name="fourth_input"
                          onChange={(e) => formikProps.setFieldValue("fourth_input", e.target.value.substring(0, 1))}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.fourth_input}
                          inputProps={{ tabIndex: 4, min: 0, maxLength: 1, style: { textAlign: 'center', color: "black" } }}
                          onKeyUp={e => this.inputfocus(e)}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField required id="fifth_input"
                          name="fifth_input"
                          onChange={(e) => formikProps.setFieldValue("fifth_input", e.target.value.substring(0, 1))}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.fifth_input}
                          inputProps={{ tabIndex: 5, min: 0, maxLength: 1, style: { textAlign: 'center', color: "black" } }}
                          onKeyUp={e => this.inputfocus(e)}
                          type="number"

                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField required id="last_input"
                          name="last_input"
                          onChange={(e) => formikProps.setFieldValue("last_input", e.target.value.substring(0, 1))}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.last_input}
                          inputProps={{ tabIndex: 6, min: 0, maxLength: 1, style: { textAlign: 'center', color: "black" } }}
                          onKeyUp={e => this.inputfocus(e)}
                          type="number"
                        />
                      </Grid>
                      {/* Each OTP charecters grid with input field end */}
                    </Grid>
                    <br />
                    <br />
                    <Button
                      type="submit"
                      className={classes.fullBtn}
                      disableRipple
                      variant="contained"
                      color="primary" onClick={this.handleRegister}
                      disabled={this.state.loading}
                    >
                      <Typography variant="body2">
                        Submit
                      </Typography>
                      {this.state.loading && (
                        <CircularProgress
                          size={24}
                          style={{
                            color: "#24325f",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </Button>

                  </form>)}
              </Formik>
              <Box mt='1em'>
                {this.state.minutes === 0 && this.state.seconds === 0
                  ? <Typography color="primary" variant="caption" style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (sessionStorage.getItem("user_role") == 'admin') {
                        this.props.goToLogIn();
                      }
                      else {
                        this.setState({ minutes: 5, seconds: 0 }, () => this.otpTimer());
                        this.props.verifyIt();
                      }
                    }}
                  >
                    {/* If user role is Admin show "Retry" else show "Resend OTP" */}
                    {sessionStorage.getItem("user_role") == 'admin' ? "Retry" : "Resend OTP"}
                  </Typography> :
                  <Typography color="primary" variant="caption">
                    OTP expiring in: <Typography
                      style={{ fontWeight: 700 }}
                      color="primary"
                      variant="body1"
                      component="span"
                    >
                      {this.state.minutes + ":" + (this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds)}
                    </Typography>
                  </Typography>}
              </Box>
            </Card>
          </Container>
        </Box>
        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
    );
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);

    this.getValidations();
    this.otpTimer();
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({

  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold",
  },
});
// Customizable Area End
// @ts-ignore
export default withStyles(blockStyle)(withRouter(EmailAccountRegistrationOTP));
