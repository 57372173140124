import React, { Component } from "react";
import { Box, Checkbox, Divider, FormControl, FormControlLabel, InputAdornment, InputBase, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { SearchIcon } from "../../../EmailNotifications/src/assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PersonIcon from '@material-ui/icons/Person';
import { JobIcon } from "../assets";

const muiStyles = (theme: Theme) => ({
    searchBar: {
        background: "#fff",
        borderRadius: "1rem",
        display: "flex",
        justifyContent: "space-between",

        "& h6": {
            fontSize: "1rem",
            fontWeight: "bold",
        },

        "& p": {
            color: "#7a7979",
            fontWeight: "bold",
        },

        "& > div:first-child": {
            flex: 2.5
        },

        "& > div:nth-child(2)": {
            flex: 4,
        },

        "& > div:last-child": {
            flex: .9,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",

            "& > div": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: .5,
            },

            "& .search": {
                background: theme.palette.primary.main,
                borderBottomRightRadius: "1rem",
                width: "100%",

                "& p": {
                    color: "#fff",
                },

                "&:active": {
                    background: theme.palette.primary.dark,
                },
            }
        },
    },
    search: {
        display: "flex",
        flex: 1,
        "& svg": {
            marginRight: ".5rem",
            marginLeft: "-.2rem"
        },
    },

    inputRoot: {
        color: "inherit",
        flex: 1,
    },


    formControl: {
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            border: "none"
        },

        '& > div': {
            marginLeft: ".6rem",

            "&::before": {
                border: "none",
            },

            "&::after": {
                border: "none",
            },

            "& .MuiSelect-select": {
                backgroundColor: "#fff",
                paddingRight: 0
            },

            "& .MuiInput-underline::hover": {
                borderColor: "#fff",
            },

            '& > div': {
                fontSize: ".9rem",
            },
        }
    },

    customDropDown: {
        '& > div': {
            color: theme.palette.primary.main,
        },

        "& svg": {
            color: theme.palette.primary.main,
        }
    },

    darkDropwDown: {
        '& > div': {
            color: "#000",
        },

        "& svg": {
            color: "#000",
        }
    },

    personSvg: {
        color: theme.palette.primary.main,
        marginBottom: "-.8rem",
    },

    customInputBorder: {
        borderBottom: "1px solid",
    },

    customTextInput: {
        width: "95%",
        "& input": {
            color: "inherit",
        }
    }
});


const dummyKms = [
    { value: 15, label: "15km" },
    { value: 40, label: "40km" },
    { value: 55, label: "55km" },
    { value: 80, label: "80km" },
    { value: 120, label: "120km" },
    { value: 160, label: "160km" },
]

const dummyStatus = ['Show "Watched"', 'Exclude "Watched"', 'Search in files', 'Search in comments'];
const dummyCP = ['Current and Past', 'Current', 'Past'];

export interface TPState {
    location: string;
    title: string;
    keyword_OR_name: string;
    km: {
        value: number;
        label: string;
    };
    status: null | 'Show "Watched"' | 'Exclude "Watched"' | 'Search in files' | 'Search in comments';
    cp: string | 'Current and Past' | 'Current' | 'Past';
    title_boolean_search: boolean;
    keyword: boolean;
    full_name: boolean;
    unique_profiles: boolean;
    ready_to_move: boolean;
}

interface Props {
    classes: any;
    onSearch: (state: TPState) => void;
    showSaveSearch?: boolean;
}

class TopSearchBar extends Component<Props, TPState> {
    state: TPState = {
        location: "",
        title: "",
        keyword_OR_name: "",
        km: dummyKms[0],
        status: null,
        cp: dummyCP[0],
        title_boolean_search: false,
        keyword: true,
        full_name: false,
        unique_profiles: false,
        ready_to_move: false,
    }

    onSearch = () => {
        this.props.onSearch(this.state);
    }

    render() {
        const { classes } = this.props;

        return (
            <Box>
                {/* Search Bar */}
                <Box className={classes.searchBar}>
                    {/* Location & Manage Profile */}
                    <Box>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-end" mt={1.5} px={2} mb={2.5}>
                            <Box flex={1}>
                                <Typography variant="h6" gutterBottom>Location</Typography>
                                <Box className={classes.search}>
                                    <InputBase
                                        placeholder="Enter city or zip code"
                                        classes={{ root: classes.inputRoot }}
                                        inputProps={{ "aria-label": "search" }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                        value={this.state.location}
                                        onChange={(e) => this.setState({ location: e.target.value })}
                                    />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box mt={.28}>
                                    <Typography>Radius: </Typography>
                                </Box>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        className={classes.customDropDown}
                                        IconComponent={() => <KeyboardArrowDownIcon />}
                                        value={this.state.km.value}
                                        onChange={(e) =>
                                            this.setState({
                                                km: dummyKms.find(
                                                    km => km.value === e.target.value as number
                                                ) as TPState["km"]
                                            })
                                        }
                                    >
                                        {dummyKms.map((km, index) => (
                                            <MenuItem key={index} value={km.value}>{km.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Divider />
                        <Box pt={2} pb={1} px={2}>
                            <Typography variant="h6" gutterBottom>Manage Profiles</Typography>
                            <Box>
                                <PersonIcon className={classes.personSvg} />
                                <FormControl className={classes.formControl}>
                                    <Select
                                        IconComponent={() => <KeyboardArrowDownIcon />}
                                        displayEmpty
                                        renderValue={this.state.status !== null ?
                                            undefined : () => "Choose Status"}
                                        value={this.state.status !== null ? this.state.status : dummyStatus[0]}
                                        onChange={(e) => this.setState({ status: e.target.value as TPState["status"] })}
                                    >
                                        {dummyStatus.map((status, index) => (
                                            <MenuItem key={index} value={status}>{status}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    {/* Titles and others */}
                    <Box display="flex">
                        <Divider orientation="vertical" />
                        <Box py={1.5} px={2} flex={1}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h6" gutterBottom>Title</Typography>
                                <Box display="flex">
                                    <InputBase
                                        placeholder="Enter a title"
                                        classes={{ root: classes.inputRoot }}
                                        inputProps={{ "aria-label": "search" }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <JobIcon style={{ marginBottom: '.3rem' }} />
                                            </InputAdornment>
                                        }
                                        value={this.state.title}
                                        onChange={(e) => this.setState({ title: e.target.value })}
                                    />
                                    <Box display="flex" alignItems="center">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.title_boolean_search}
                                                    onChange={() => {
                                                        this.setState({ title_boolean_search: !this.state.title_boolean_search })
                                                    }}
                                                    name="title"
                                                    color="primary"
                                                />
                                            }
                                            label="Title boolean search"
                                        />
                                        <Divider
                                            orientation="vertical"
                                            style={{ height: "60%", width: ".15rem" }}
                                        />
                                        <FormControl className={classes.formControl}>
                                            <Select
                                                className={classes.darkDropwDown}
                                                IconComponent={() => <KeyboardArrowDownIcon />}
                                                value={this.state.cp}
                                                onChange={(e) => this.setState({ cp: e.target.value as string })}
                                            >
                                                {dummyCP.map((cp, index) => (
                                                    <MenuItem key={index} value={cp}>{cp}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={1.5}>
                                <Box display="flex">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.keyword}
                                                onChange={() => {
                                                    this.setState({
                                                        keyword: !this.state.keyword, full_name: !this.state.full_name
                                                    })
                                                }}
                                                name="keyword"
                                                color="primary"
                                            />
                                        }
                                        label="Keyword"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.full_name}
                                                onChange={() => {
                                                    this.setState({
                                                        full_name: !this.state.full_name, keyword: !this.state.keyword
                                                    })
                                                }}
                                                name="full_name"
                                                color="primary"
                                            />
                                        }
                                        label="Full Name"
                                    />
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box display="flex" alignItems="center" flex={1}>
                                        <Box flex={1}>
                                            <InputBase
                                                placeholder={this.state.keyword ? "ex.: oracle OR Developer OR IBM" : ""}
                                                classes={{ root: classes.inputRoot }}
                                                inputProps={{ "aria-label": "search" }}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <JobIcon style={{ marginBottom: '.3rem' }} />
                                                    </InputAdornment>
                                                }
                                                value={this.state.keyword_OR_name}
                                                onChange={(e) => this.setState({ keyword_OR_name: e.target.value })}
                                                className={
                                                    `${!this.state.keyword ? classes.customInputBorder : ""} ${classes.customTextInput}`
                                                }
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.unique_profiles}
                                                    onChange={() => { this.setState({ unique_profiles: !this.state.unique_profiles }) }}
                                                    name="unique_profile"
                                                    color="primary"
                                                />
                                            }
                                            label="Unique Profiles"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.ready_to_move}
                                                    onChange={() => { this.setState({ ready_to_move: !this.state.ready_to_move }) }}
                                                    name="ready_to_move"
                                                    color="primary"
                                                />
                                            }
                                            label="Ready to Move"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Divider orientation="vertical" />
                    </Box>
                    {/* Search */}
                    <Box
                        className="noselect"
                        display="flex"
                        flexDirection="column"
                        justifyContent={this.props.showSaveSearch ? "flex-end" : "center"}
                    >
                        {this.props.showSaveSearch && (
                            <Box>
                                <Typography>
                                    Save Search
                                </Typography>
                            </Box>
                        )}
                        <Box
                            className="search"
                            style={{
                                flex: this.props.showSaveSearch ? .5 : 1,
                                borderTopRightRadius: !this.props.showSaveSearch ? "1rem" : 0,
                            }}
                            onClick={this.onSearch}
                        >
                            <Typography>
                                Search
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box >
        )
    }
}

export default withStyles(muiStyles)(TopSearchBar);