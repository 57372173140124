Object.defineProperty(exports, "__esModule", {
    value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "EmailNotifications";
exports.labelBodyText = "EmailNotifications Body";
exports.btnExampleTitle = "CLICK ME";

exports.getTokenEndPoint = "bx_block_twilio/chat_conversations";
exports.getTokenMethod = "POST";
exports.favouriteEndPoint = "bx_block_twilio/favourite_converstion";
exports.favouriteMethod = "POST";
exports.fetchFavouriteMethod = "GET";
exports.deleteConversationEndPoint = "bx_block_twilio/delete_conversation";
exports.deleteConversationMethod = "DELETE";
exports.profileImageEndPoint = "bx_block_twilio/user_detail";
exports.profileImageMethod = "POST";

// Customizable Area End
