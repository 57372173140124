import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import actionCable from 'actioncable';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    history: any;
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    email: string,
    password: string,
    snackBar: {
        show: boolean;
        message: string;
        type: any
    };
    isLoggedIn: boolean,
    loading: boolean;
    adminToken: string;
    users: any;
    selectedChat: any;
    chats: any;
    adminMessage: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WhatsappController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchUsers: string;
    fetchMessages: string;
    sendMessages: string;
    cable: actionCable.Cable;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            email: '',
            password: '',
            snackBar: {
                show: false,
                message: "",
                type: "",
            },
            isLoggedIn: false,
            loading: false,
            adminToken: '',
            users: [],
            selectedChat: null,
            chats: [],
            adminMessage: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.fetchUsers) {
                    this.setState({ loading: false });
                    if (responseJson?.errors) {
                        this.openSnackBarHandler("error", "Chats are failed to load.")
                    } else
                        this.setState({ users: responseJson.data });
                }
                if (apiRequestCallId === this.fetchMessages) {
                    this.setState({ loading: false });
                    this.setState({ chats: responseJson.data });
                }
            }
            else {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    openSnackBarHandler = (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ): void => {
        this.setState({
            snackBar: {
                show: true,
                message: message,
                type,
            },
        });
    };

    closeSnackBarHandler = () => {
        this.setState({ snackBar: { ...this.state.snackBar, show: false } });
    }
    // Customizable Area End

    // Customizable Area Start

    getChats = () => {
        if (!this.state.adminToken.trim().length) {
            this.openSnackBarHandler("error", "Token not found ...!");
            return;
        }

        this.setState({ loading: true });

        // subscribe to notification
        this.cable.subscriptions.create(
            {
                channel: 'WhatsappNotificationChannel',
            },
            {
                received: (notification) => {
                    const receive_chatId = notification.data.attributes.chat_id;
                    const user = this.state.users.filter((each: any) => each.attributes.chat_id === receive_chatId)[0];
                    if (!this.state.selectedChat || this.state.selectedChat.attributes.chat_id !== receive_chatId) {
                        this.openSnackBarHandler("success", `You got a new message from ${user.attributes.user_name}`)
                    }
                    const userIndex = this.state.users.findIndex((each: any) => each.attributes.chat_id === receive_chatId);
                    const newUsers = [...this.state.users];
                    newUsers.splice(userIndex, 1);
                    newUsers.unshift({ ...user, attributes: { ...user.attributes, has_new_msg: true } });
                    this.setState({ users: newUsers });
                },
            }
        );

        // subscribe to new chats !!
        this.cable.subscriptions.create(
            {
                channel: 'WhatsappNewChatChannel',
            },
            {
                received: (res_user) => {
                    const user = res_user.data;
                    this.setState({
                        users: [
                            { ...user, attributes: { ...user.attributes, has_new_msg: true } },
                            ...this.state.users
                        ]
                    });
                    this.openSnackBarHandler("success", `You have a new chat with ${user.attributes.user_name}`)
                },
            }
        );


        const header = {
            "Content-Type": configJSON.contentType,
            token: this.state.adminToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchUsers = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchUsersEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fetchUsersMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getMessages = () => {
        if (!this.state.selectedChat) {
            this.openSnackBarHandler("error", "Please select a chat first !!");
            return;
        }

        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.contentType,
            token: this.state.adminToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchMessages = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.fetchMessagesEndPoint}?chat_id=${this.state.selectedChat.attributes.chat_id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fetchMessagesMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSendMessage = (event: React.FormEvent) => {
        event.preventDefault();
        if (!this.state.adminMessage.trim().length) {
            this.openSnackBarHandler("error", "Please enter a valid message to send !!");
            return;
        }

        const header = {
            "Content-Type": configJSON.contentType,
            token: this.state.adminToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendMessages = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendMessagesEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.sendMessagesMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                chat_id: this.state.selectedChat.attributes.chat_id,
                message: this.state.adminMessage
            })
        );

        const chatMsg = {
            attributes: {
                message_id: Math.random(),
                type: "send",
                chat_id: this.state.selectedChat.attributes.chat_id,
                message: this.state.adminMessage,
                created_at: new Date()
            }
        }
        this.setState({ chats: [...this.state.chats, chatMsg] }, () => {
            this.setState({ adminMessage: "" });
        });

        const userIndex = this.state.users.findIndex((each: any) => each.attributes.chat_id === this.state.selectedChat.attributes.chat_id);
        const user = this.state.users[userIndex];

        const newUsers = [...this.state.users];
        newUsers.splice(userIndex, 1);
        newUsers.unshift(user);
        this.setState({ users: newUsers });

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    handleChatSelect = (user: any) => {
        if (this.state.selectedChat && this.state.selectedChat.attributes.chat_id === user.attributes.chat_id) {
            return;
        }

        if (this.state.selectedChat) {
            this.cable.disconnect();
        }

        const userIndex = this.state.users.findIndex((each: any) => each.attributes.chat_id === user.attributes.chat_id);
        const selecd_user = this.state.users[userIndex];

        const newUsers = [...this.state.users];
        newUsers[userIndex] = { ...selecd_user, attributes: { ...user.attributes, has_new_msg: false } }
        this.setState({ users: newUsers });

        this.setState({ selectedChat: user }, () => {
            this.cable.subscriptions.create(
                {
                    channel: 'WhatsappChatChannel',
                    chat_id: this.state.selectedChat.attributes.chat_id,
                },
                {
                    received: (message) => {
                        // console.log("Got message", message);
                        if (message.data.attributes.chat_id === this.state.selectedChat.attributes.chat_id)
                            this.setState({ chats: [...this.state.chats, message.data] });
                    },
                }
            );

            this.getMessages();
        });
    }

    async componentDidMount() {
        const adminToken = sessionStorage.getItem("final_OTP");
        if (!adminToken) {
            sessionStorage.setItem("wap_redirect_url", "whatsapp/admin")
            this.props.history.replace('/login?type=admin');
        } else {
            sessionStorage.removeItem("final_OTP");
            this.cable = actionCable.createConsumer(
                `${configJSON.socket_url}/cable`
            );
            this.setState({ isLoggedIn: true, adminToken }, () => {
                this.getChats();
            })
        }
    }
    // Customizable Area End
}

