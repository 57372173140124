import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Context } from "react";
// @ts-ignore
import LoaderContext, { LoaderontextType } from "../../../web/src/Context/LoaderContext.web";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    match?: { params: { id: string | number } };
    location?: any;
    history?: any;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    snackBar: {
        show: boolean;
        message: string;
        type: any
    };
    windowWidth: number;
    loader: boolean;
    feedbackSchema: any;
    pageToken: string | number;
    successText: boolean;
    ratingRBError: boolean;
    feedBackExpired: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AfterInterviewFeedbackController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    static contextType?: Context<LoaderontextType> = LoaderContext;
    getDatabaseListApiCallId: string;
    sendCandidateFeedbackCallId: string;
    checkInterviewDataApiCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        let feedbackValidationSchema: any = Yup.object({
            rating: Yup.string().required("Please give rating"),
            feedback: Yup.string().required("Please Enter Feedback")
        });

        const validationSchema = Yup.object({
            feedback: Yup.string()
                .trim()
                .required("Please Enter Feedback"),
            rating: Yup.number().nullable().required("Please Enter Rating")
        });

        this.state = {
            // Customizable Area Start
            snackBar: {
                show: false,
                message: "",
                type: "",
            },
            windowWidth: window.innerWidth,
            loader: false,
            feedbackSchema: validationSchema,
            pageToken: "",
            successText: false,
            ratingRBError: true,
            feedBackExpired: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        // runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId != null) {
                //Get Database List API
                if (
                    apiRequestCallId === this.getDatabaseListApiCallId
                ) {
                    if (responseJson !== undefined && !responseJson?.errors) {
                    } else {
                        const errors = responseJson?.errors;

                        if (errors) {
                            this.openSnackBarHandler('error', errors);
                        }
                    }
                }

                //Send Feedback API
                else if (
                    apiRequestCallId === this.sendCandidateFeedbackCallId
                ) {
                    this.setState({ loader: false });
                    if (responseJson !== undefined && !responseJson?.errors && !responseJson?.error) {
                        if (responseJson?.message?.includes('already given')) {
                            this.setState({ successText: false });
                            this.openSnackBarHandler('error', responseJson?.message);
                        } else {
                            this.openSnackBarHandler('success', "Feedback submitted successfully");
                            this.setState({ successText: true });
                        }
                    } else {
                        this.setState({ successText: false });
                        if (responseJson?.errors) {
                            this.openSnackBarHandler('error', responseJson?.errors);
                        } else if (responseJson?.error) {
                            this.openSnackBarHandler('error', responseJson?.error);
                        }
                    }
                }

                //Send Feedback API
                else if (
                    apiRequestCallId === this.checkInterviewDataApiCallId
                ) {
                    if (responseJson !== undefined && !responseJson?.errors && !responseJson?.error) {
                        if (responseJson?.closed_link) {
                            this.setState({ feedBackExpired: true });
                        }
                    } else {
                        if (responseJson?.errors) {
                            this.openSnackBarHandler('error', responseJson?.errors);
                        } else if (responseJson?.error) {
                            this.openSnackBarHandler('error', responseJson?.error);
                        }
                    }
                }

            }
            else {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start

    // Open View Details Modal
    openSnackBarHandler = (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ): void => {
        this.setState({
            snackBar: {
                show: true,
                message: message,
                type,
            },
        });
    };

    // Close View Details Modal
    closeSnackBarHandler = () => {
        this.setState({
            snackBar: {
                show: false,
                message: this.state.snackBar.message,
                type: this.state.snackBar.type,
            },
        });
    };

    checkWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    //Get Database results
    getDatabaseList = (page: number): boolean => {
        const header = {
            "Content-Type": "application/json",
            "Token": sessionStorage.getItem("Token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDatabaseListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDatabaseListAPiEndPoint + page
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    sendCandidateFeedback = (values: any): boolean => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            'Token': this.state.pageToken
        };

        let httpBody = {
            schedule: { ...values }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendCandidateFeedbackCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendCandidateFeedbackAPIEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPiMethod
        );
        this.setState({ loader: true });
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    checkInterviewData = (): boolean => {
        const header = {
            "Content-Type": "application/json",
            "Token": this.state.pageToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.checkInterviewDataApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.checkInterviewDataAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // Customizable Area End

}
