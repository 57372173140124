import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  classes?: any;
  match?: any;
  showInterviewFeedback: boolean;
  onCloseShowInterviewFeedback: () => void;
  candidateData: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  scoreList: any;
  snackBar: {
    show: boolean;
    message: string;
    type: any
  };
  pageLengthToShow: number;
  assessmentDetail: any;
  showInterviewFeedbackModal: boolean;
  getTestDomeDataType: string;
  getTestDomeDetail: any;
  feedbackParameters: any;
  feedbackData: any;
  feedbackValidationSchema: any;
  ratingRBError: boolean;
  pageNumber: number;
  feedbackList: any;
  selectedFedbackOption: any;
  sortRoleType: string;
  searchString: string;
  windowWidth: string | number;
  totalPages: string | number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  getFeedbackParameterListApiCallId: string;
  sendCandidateFeedbackCallId: string;
  showCandidateFeedbackListApiCallId: string;
  getFeedbackListApiCallId: string;
  getFeedbackOptionListApiCallId: string;
  sendCandidateAssesmentFeedbackCallId: string;
  getClientsListApiCallId: string;
  searchClientsApiCallId: string;

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    let feedbackValidationSchema: any = Yup.object({
      feedbackData: Yup.array().of(Yup.object({
        rating: Yup.string().required("Please give rating"),
        comments: Yup.string()
      }))
    });
    this.state = {
      // Customizable Area Start
      scoreList: null,
      snackBar: {
        show: false,
        message: "",
        type: "",
      },
      pageLengthToShow: 10,
      assessmentDetail: null,
      showInterviewFeedbackModal: false,
      getTestDomeDataType: "",
      getTestDomeDetail: null,
      feedbackParameters: [],
      feedbackData: null,
      feedbackValidationSchema: feedbackValidationSchema,
      ratingRBError: false,
      pageNumber: 1,
      feedbackList: [],
      selectedFedbackOption: "",
      sortRoleType: "",
      searchString: "",
      windowWidth: window.innerWidth,
      totalPages: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        //Get Clients List API
        if (
          apiRequestCallId === this.getClientsListApiCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors && !responseJson?.error) {
            this.setState({ scoreList: responseJson, pageLengthToShow: responseJson?.meta?.limit_value, totalPages: +responseJson?.meta?.total_pages });
          } else {
            if (responseJson?.errors) {
              this.openSnackBarHandler('error', responseJson?.errors);
            } else if (responseJson?.error) {
              this.openSnackBarHandler('error', responseJson?.error);
            }
          }
        }
        //Search Clients List API
        else if (
          apiRequestCallId === this.searchClientsApiCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors && !responseJson?.error) {
            if (responseJson?.message) {
              this.setState({ scoreList: { data: [] } });
            } else {
              this.setState({ scoreList: responseJson, pageLengthToShow: responseJson?.data?.length, pageNumber: 1, totalPages: 1 });
            }
          } else {
            if (responseJson?.errors) {
              this.openSnackBarHandler('error', responseJson?.errors);
            } else if (responseJson?.error) {
              this.openSnackBarHandler('error', responseJson?.error);
            }
          }
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start
  doButtonPressed() { }

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };
  getClientsList(values: { page: number }): boolean {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };
    let httpBody: any = values;
    if (this.state.sortRoleType) {
      httpBody['sort'] = this.state.sortRoleType;
    }
    if (this.state.searchString) {
      httpBody['name'] = this.state.searchString;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientsListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientsListAPiEndPoint + values.page
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  searchClients(searchText: any): boolean {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchClientsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchClientsAPiEndPoint + searchText
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  checkWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }
  // Customizable Area End

}
