import React from "react";
import ActivityLogController, { Props } from "./ActivityLogController";
import { Box, Typography, Grid, withStyles, Container, Theme } from "@material-ui/core";
import { banner } from './assets';
import { createMarkup } from "../../LandingPage2/src/LandingPage2.web";

const muiStyles = (theme: Theme) => ({
  textWhite: {
    color: "#ffffff",
  },
  cover: {
    width: 150,
    height: 150
  },
  banner: {
    backgroundColor: "whitesmoke",
    padding: "1rem 2rem",
    maxWidth: "100%",
    backgroundImage: `url(${banner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.up('sm')]: {
      padding: "8rem 5rem",
    },
  },

  paperWidthLg: {
    width: "100%!important",
    borderRadius: 15
  },
  appBar: {
    backgroundColor: '#FFF',
    zIndex: theme.zIndex.drawer + 1
  },

  locations: {
    margin: "0 8rem 3.5rem",

    [theme.breakpoints.down('sm')]: {
      margin: "0 1rem 3rem",
    },

    [theme.breakpoints.down('md')]: {
      margin: "0 1rem 3rem",
    },

    "& .container": {
      marginTop: "1.5rem",
      flexFlow: 'wrap',

      [theme.breakpoints.down('sm')]: {
        alignItems: "center",
        flexDirection: "column",
      },

      "& > div": {
        width: "30%",
        [theme.breakpoints.down('sm')]: {
          width: "90%",
        },

        "&:not(:last-child)": {
          marginBottom: "1rem",
        }
      }
    }
  }
});

class ActivityLog extends ActivityLogController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.checkWindowResize);

    this.getAboutContent();
  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowResize);
  }
  // Customizable Area End

  render() {
    const { classes } = this.props;
    var aboutData1Index, aboutData2Index, aboutData3Index;
    if (this.state?.aboutContent?.content) {
      aboutData1Index = this.state?.aboutContent?.content[0];
      aboutData2Index = this.state?.aboutContent?.content[1];
      aboutData3Index = this.state?.aboutContent?.content[2];
    }

    return (
      <Box id="top">
        <Container className={classes.banner}>
          <Box flex={1}>
            <Typography className={classes.textWhite} variant="h2">
              About Us
            </Typography>
          </Box>
        </Container>
        {/* who we are & what we are */}
        <Container className={classes.contentBox}>
          <Box py={5}>
            <Grid className={classes.contentGrid} container justify="flex-end" style={{ marginTop: "20px" }}>
              <Grid item xs={12} md={5} style={this.state.windowWidth > 960 ? { padding: "18px 18px 0 0" } : { paddingTop: "18px" }}>
                <Typography align="left" color="primary" variant="h4">{aboutData1Index?.title}</Typography>
                <Box mt={3} mb={3}>
                  <Typography align="justify" className={classes.content} style={{ color: "darkslategray" }} variant="subtitle1">
                    {'\t'}{'\t'} <div dangerouslySetInnerHTML={createMarkup(aboutData1Index?.description)} />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={7} style={{ paddingTop: "18px" }}>
                <img width="100%" style={{ paddingTop: "22px", height: "90%" }} src={aboutData1Index?.image} alt="" />
              </Grid>
            </Grid>
          </Box>
        </Container>

        {/* why us */}
        <Container className={classes.contentBox}>
          <Box py={5}>
            <Grid container justify="flex-end">
              <Grid item xs={12} md={7} style={this.state.windowWidth > 960 ? { padding: "18px 18px 0 0" } : { paddingTop: "18px" }}>
                <img width="100%" style={{ paddingTop: "10px", height: "92%" }} src={aboutData2Index?.image} alt="" />
              </Grid>
              <Grid item xs={12} md={5} style={{ paddingTop: "18px" }}>
                <Typography align="left" color="primary" variant="h4">{aboutData2Index?.title}</Typography>
                <Box mt={3}>
                  <Typography align="justify" className={classes.content} style={{ color: "darkslategray" }} variant="subtitle1">
                    {'\t'}{'\t'} <div dangerouslySetInnerHTML={createMarkup(aboutData2Index?.description)} />
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>

        {/* conclusion */}
        <Container className={classes.contentBox} style={{ marginTop: !aboutData3Index?.title ? "-2rem" : 0 }}>
          {aboutData3Index?.title !== "" && (
            <Box mt={4} mb={4}>
              <Typography align="left" color="primary" variant="h4">{aboutData3Index?.title}</Typography>
            </Box>
          )}
          <Box>
            <Typography align="justify" className={classes.content} style={{ color: "darkslategray" }} variant="subtitle1">
              {'\t'}{'\t'} <div dangerouslySetInnerHTML={createMarkup(aboutData3Index?.description)} />
            </Typography>
          </Box>
        </Container >

        {/* locations */}
        <Box className={classes.locations}>
          <Typography variant="h5" color="primary" gutterBottom>Our Locations</Typography>

          <Box display="flex" className="container">
            {this.state.aboutContent?.location_addresses && this.state.aboutContent?.location_addresses?.length > 0
              ? this.state.aboutContent?.location_addresses?.map((address: any) => (
                <Box key={address?.id} mr={3}>
                  <Typography>
                    <b>{address?.attributes?.country}</b>
                  </Typography>
                  <Typography>
                    {address?.attributes?.address}
                  </Typography>
                  <Typography>
                    {address?.attributes?.email}
                  </Typography>
                  <Typography>
                    {address?.attributes?.phone}
                  </Typography>
                </Box>)) : ""}
          </Box>
        </Box>
      </Box >
    );
  }
}

// Customizable Area Start
// @ts-ignore
export default withStyles(muiStyles)(ActivityLog);
// Customizable Area End
