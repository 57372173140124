const { baseURL } = require("../../../framework/src/config");

Object.defineProperty(exports, "__esModule", {
    value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SendAMessage";
exports.labelBodyText = "SendAMessage Body";

exports.btnExampleTitle = "CLICK ME";
exports.adminLoginEndPoint = "bx_block_admin/admin_login";
exports.adminLoginMethod = "POST";
exports.contentType = "application/json";
exports.fetchUsersMethod = "GET";
exports.fetchUsersEndPoint = "bx_block_admin/fetch_whatsapp_chats";
exports.fetchMessagesEndPoint = "bx_block_admin/fetch_whatsapp_messges";
exports.fetchMessagesMethod = "GET";
exports.socket_url = baseURL;
exports.sendMessagesEndPoint = "bx_block_admin/send_messages";
exports.sendMessagesMethod = "POST";
// Customizable Area End
