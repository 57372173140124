import React from "react";

import { Box, Typography, Container, withStyles } from "@material-ui/core";
import PrivacySettingsController, { Props, } from "./PrivacySettingsController";
import { createMarkup } from "../../LandingPage2/src/LandingPage2.web";
import { useStyles } from "./Terms.web";

class PrivacySettings extends PrivacySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getPrivacyPolicy();
  }
  // Customizable Area End

  render() {
    const { classes } = this.props;
    if (!this.state.policy) return null;

    return (
      <Container maxWidth="lg">
        <Box pt={8} pb={8} className={classes.root}>
          <Box my={2}>
            <Typography className={classes.heading} variant="h3">{this.state.policy.attributes.title}</Typography>
          </Box>

          <Box my={2}>
            <Typography
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={createMarkup(this.state.policy.attributes.description)}
            />
          </Box>

        </Box>
      </Container >
    );
  }
}

// Customizable Area Start
export default withStyles(useStyles)(PrivacySettings);
// Customizable Area End
