import React from "react";
import {
  Box,
  Typography,
  Grid,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import { BrowserRouter as Router, Link, withRouter, RouteComponentProps } from "react-router-dom";
import {
  DashboardIcon,
  OpenRolesIcon,
  ClosedRolesIcon,
  ScoreIcon,
  MessageIcon,
  CreateRoleIcon
} from "../src/assets";
import { withStyles } from "@material-ui/styles";
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth = 245;

const styles = (theme: any) => ({
  footerBox: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      margin: 0,
    },
  },
  links: {
    textDecoration: "none",
  },
  root: {
    display: "flex",
  },
  textWhite: {
    color: "#ffffff",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#384786!important",
    marginTop: "63px",
  },
  drawerPaperMobile: {
    width: drawerWidth,
    backgroundColor: "#384786!important",
    marginTop: 0,
  },
  drawerContainer: {
    marginTop: "1.12rem",
    overflow: "auto",
    // marginTop: 30
  },
  listIconeWidth: {
    minWidth: "47px",
  },
  listButtonSelected: {
    background: "#a8d0e7!important",

    "& span": {
      color: "#24325F",
      fontWeight: "bold",
    },
  },
  removeButtonUnderline: {
    textDecoration: "none",
  },
  menuIcon: {
    height: "2.6em",
    width: "2.6em",
    position: "fixed",
    left: "6.5em",
    top: "0",
    zIndex: 1
  }
} as any);

interface AppSidebarProps {
  classes: any;
  location: any;
}
class AppSidebar extends React.Component<AppSidebarProps & RouteComponentProps> {

  state = {
    selectedIndex: 0,
    windowWidth: window.innerWidth,
    showSideBar: false,
    clientIDLink: ""
  };

  checkWindowResize = () => {
    if (window.innerWidth <= 1000) {
      this.setState({ windowWidth: window.innerWidth, showSideBar: false });
    } else {
      this.setState({ windowWidth: window.innerWidth, showSideBar: true });
    }
  }

  handleSideBarToggle = () => {
    this.setState((prev: any) => ({ showSideBar: !prev.showSideBar }));
  }

  componentDidMount() {
    let clientID = +this.props.location?.search?.split("clientId=")[1];
    let userType = sessionStorage.getItem("user_role") || "";
    this.setState({
      clientIDLink: userType == "admin" && clientID ? "?clientId=" + clientID : ""
    })

    window.addEventListener('resize', this.checkWindowResize);
    const pathName = window.location.pathname;
    if (pathName === "/dashboard") this.setState({ selectedIndex: 0 });
    if (pathName === "/createRole") this.setState({ selectedIndex: 1 });
    if (pathName.includes("/open-roles") || pathName.includes("/editRole")) this.setState({ selectedIndex: 2 });
    if (pathName.includes("/closed-roles")) this.setState({ selectedIndex: 3 });
    if (pathName === "/candidate-scoring" || pathName.includes("/assessment-detail/")) this.setState({ selectedIndex: 4 });
    if (pathName === "/email-notifications") this.setState({ selectedIndex: 5 });
  }


  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.location?.pathname != prevProps.location?.pathname) {
      window.addEventListener('resize', this.checkWindowResize);
      const pathName = window.location.pathname;
      if (pathName === "/dashboard") this.setState({ selectedIndex: 0 });
      if (pathName === "/createRole") this.setState({ selectedIndex: 1 });
      if (pathName.includes("/open-roles") || pathName.includes("/editRole")) this.setState({ selectedIndex: 2 });
      if (pathName.includes("/closed-roles")) this.setState({ selectedIndex: 3 });
      if (pathName === "/candidate-scoring" || pathName.includes("/assessment-detail/")) this.setState({ selectedIndex: 4 });
      if (pathName === "/email-notifications") this.setState({ selectedIndex: 5 });
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowResize);
  }
  // const { navigate } = this.props;
  render() {
    const { classes }: any = this.props;
    console
    return (<>
      {(this.state.windowWidth <= 1000 || this.state.selectedIndex == 5) &&
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.menuIcon}
          onClick={this.handleSideBarToggle}
        >
          <MenuIcon />
        </IconButton>}
      <Drawer
        className={classes.drawer}
        variant={(this.state.windowWidth <= 1000 || this.state.selectedIndex == 5) ? "temporary" : "permanent"}
        open={this.state.showSideBar}
        classes={{
          paper: (this.state.windowWidth <= 1000 || this.state.selectedIndex == 5) ? classes.drawerPaperMobile : classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, onClose: this.handleSideBarToggle
        }}
      >
        <Box className={classes.drawerContainer}>
          <List>
            <Link
              to={`/dashboard${this.state.clientIDLink}`}
              className={classes.removeButtonUnderline}
            >
              <ListItem
                button
                selected={this.state.selectedIndex === 0}
                className={
                  this.state.selectedIndex === 0
                    ? classes.listButtonSelected
                    : ""
                }
              >
                <Box
                  display="flex"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <ListItemIcon className={classes.listIconeWidth}>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Dashboard"
                    className={classes.textWhite}
                  />
                </Box>
              </ListItem>
            </Link>
            <Link
              to={`/createRole${this.state.clientIDLink}`}
              className={classes.removeButtonUnderline}
            >
              <ListItem
                button
                selected={this.state.selectedIndex === 1}
                className={
                  this.state.selectedIndex === 1
                    ? classes.listButtonSelected
                    : ""
                }
              >
                <Box
                  display="flex"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <ListItemIcon className={classes.listIconeWidth}>
                    <CreateRoleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Create New Role"
                    className={classes.textWhite}
                  />
                </Box>
              </ListItem>
            </Link>
            <Link
              to={`/open-roles${this.state.clientIDLink}`}
              className={classes.removeButtonUnderline}
            >
              <ListItem
                button
                selected={this.state.selectedIndex === 2}
                className={
                  this.state.selectedIndex === 2
                    ? classes.listButtonSelected
                    : ""
                }
              >
                <Box
                  display="flex"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <ListItemIcon className={classes.listIconeWidth}>
                    <OpenRolesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Open Roles"
                    className={classes.textWhite}
                  />
                </Box>
              </ListItem>
            </Link>
            <Link
              to={`/closed-roles${this.state.clientIDLink}`}
              className={classes.removeButtonUnderline}
            >
              <ListItem
                button
                selected={this.state.selectedIndex === 3}
                className={
                  this.state.selectedIndex === 3
                    ? classes.listButtonSelected
                    : ""
                }
              >
                <Box
                  display="flex"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <ListItemIcon className={classes.listIconeWidth}>
                    <ClosedRolesIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Closed Roles"
                    className={classes.textWhite}
                  />
                </Box>
              </ListItem>
            </Link>
            <Link to={`/candidate-scoring${this.state.clientIDLink}`} className={classes.removeButtonUnderline}>
              <ListItem
                button
                selected={this.state.selectedIndex === 4}
                className={
                  this.state.selectedIndex === 4
                    ? classes.listButtonSelected
                    : ""
                }
              >
                <Box
                  display="flex"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <ListItemIcon className={classes.listIconeWidth}>
                    <ScoreIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Candidates Scoring"
                    className={classes.textWhite}
                  />
                </Box>
              </ListItem>
            </Link>
            <Link to={`/email-notifications${this.state.clientIDLink}`} className={classes.removeButtonUnderline}>
              <ListItem
                button
                selected={this.state.selectedIndex === 5}
                className={
                  this.state.selectedIndex === 5
                    ? classes.listButtonSelected
                    : ""
                }
              >
                <Box
                  display="flex"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <ListItemIcon className={classes.listIconeWidth} >
                    <MessageIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Messages"
                    className={classes.textWhite}
                  />
                </Box>
              </ListItem>
            </Link>
          </List>
        </Box>
      </Drawer >
    </>
    );
  }
}

export default withStyles(styles)(withRouter(AppSidebar));
