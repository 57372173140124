import React from "react";

import {
    Dialog, Grid, Snackbar, Theme, ThemeProvider, Typography, Button, Box, Tooltip,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { CloseOutlined } from "@material-ui/icons";

import QuestionBankController, { Props } from "./QuestionBankController.web";
import { theme } from "../../Location4/src/styles.web";
import { withStyles } from "@material-ui/styles";
import { formatDate } from "./TabComponent.web";
import { formatAMPM } from "../../Location4/src/TimeSlotModal.web";

const useStyles = (theme: Theme) => ({
    modal: {
        "& div div": {
            borderRadius: "1rem",
        },
    },

    container: {
        padding: "2rem 0",

        "& div": {
            justifyContent: "center",
            alignItems: "center",
        },
    },

    heading: {
        "& h5": {
            fontWeight: 600,

            [theme.breakpoints.down("sm")]: {
                fontSize: "1.2rem"
            }
        },

        "& svg": {
            position: "absolute",
            right: theme.spacing(4),
            cursor: "pointer",
            transition: "all .3s ease-in-out",

            "&:hover": {
                transform: "scale(1.2)",
            },

            [theme.breakpoints.down("sm")]: {
                right: "20px",
                top: "12px"
            }
        },
    },

    details: {
        margin: "2.5rem 0",

        "& p": {
            color: "#8a8a93",
            marginBottom: theme.spacing(1.5),
        },

        "& h5": {
            fontWeight: 600,
            textTransform: "capitalize",

            [theme.breakpoints.down("sm")]: {
                fontSize: "1.1rem"
            }
        },

        "& div": {
            "&:first-child": {
                marginRight: "7rem",
            },
        },
    },

    slots: {
        margin: "1rem 0",

        "& h6": {
            fontWeight: 600,
            fontSize: ".9rem",
        },

        "& div": {
            margin: "1rem 0",

            [theme.breakpoints.down("sm")]: {
                margin: "1rem 0 0",
            },

            "& button": {
                borderRadius: ".8rem",
                padding: ".7rem 2rem",
                border: "2px solid #24325f",

                "&:not(:last-child)": {
                    marginRight: "1rem",
                },
            },
        },
    },

    time: {
        "& p": {
            marginTop: theme.spacing(1.5),
            textAlign: "center",
            color: "#8a8a93",
            fontSize: ".8rem",
        },

        "& button:disabled": {
            backgroundColor: "#ccc !important",
            color: "#000 !important",
            borderColor: "#ccc",
        },

        [theme.breakpoints.down("sm")]: {
            "& button span": {
                fontSize: "1rem",
            }
        }
    },

    submitBtn: {
        marginTop: theme.spacing(2),
        backgroundColor: "#24325f",
        color: "#fff",
        padding: ".5rem 2rem",
        borderRadius: ".7rem",

        "&:hover": {
            backgroundColor: "#24325f",
        },

        [theme.breakpoints.down("sm")]: {
            "& span": {
                fontSize: "1.1rem",
            }
        }
    }
});

export const trimText = (text: string, length: number = 12) => {
    if (text.length > length) {
        return text.slice(0, length) + "...";
    }

    return text;
}

class SelectTimeSlot extends QuestionBankController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleTimeSlot = (index: number) => {
        this.setState({ slotSelected: index });
    }

    isDatePassed = (slotDate: string) => new Date() >= new Date(slotDate.slice(0, slotDate.length - 1));

    // Customizable Area End

    allocateFreeSlots = (isAnySlotAvaiable: any, slotDetails: any) => {
        if (this.state.slotSelected === 2 &&
            this.isDatePassed(slotDetails.second_slot) &&
            !isAnySlotAvaiable()
        ) {
            if (!this.isDatePassed(slotDetails.first_slot)) { }
            if (!this.isDatePassed(slotDetails.third_slot)) {
                this.setState({ slotSelected: 3 });
            }
        }
    }

    isAnySlotAvaiable = () => {
        const { slotDetails } = this.props;
        return this.isDatePassed(slotDetails.first_slot) &&
            this.isDatePassed(slotDetails.second_slot) && this.isDatePassed(slotDetails.third_slot);
    }

    async componentDidMount() {
        this.setState({ isInterviewSet: this.context.interviews });

        const { slotDetails } = this.props;

        if (this.state.slotSelected === 2 &&
            this.isDatePassed(slotDetails.second_slot) &&
            !this.isAnySlotAvaiable()
        ) {
            if (!this.isDatePassed(slotDetails.first_slot)) {
                this.setState({ slotSelected: 1 });
            }
            if (!this.isDatePassed(slotDetails.third_slot)) {
                this.setState({ slotSelected: 3 });
            }
        }
    }

    render() {
        const { classes, slotDetails } = this.props;

        if (!slotDetails) return null;

        return (
            <ThemeProvider theme={theme}>
                {/* modal */}
                <Dialog
                    open={this.props.isOpen || false}
                    fullWidth={true}
                    maxWidth="md"
                    className={classes.modal}
                >
                    {/* container */}
                    <Grid container className={classes.container}>
                        {/* heading */}
                        <Grid container className={classes.heading}>
                            <Typography variant="h5">
                                Choose Interview Slot
                            </Typography>
                            <CloseOutlined onClick={this.props.toggleModal} />
                        </Grid>
                        {/* job Details */}
                        <Grid container className={classes.details}>
                            <Grid item>
                                <Typography variant="body1">
                                    Role Name
                                </Typography>
                                <Tooltip title={slotDetails.role} arrow>
                                    <Typography variant="h5">
                                        {trimText(slotDetails.role)}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">Company</Typography>
                                <Tooltip title={slotDetails.company} arrow>
                                    <Typography variant="h5">
                                        {trimText(slotDetails.company)}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {/* choose slot */}
                        {(!slotDetails.is_accepted_by_candidate &&
                            (!this.state.isInterviewSet.find(
                                interview => interview.interviewId === slotDetails.id
                            )?.isSet || false)
                        ) &&
                            <Grid container className={classes.slots}>
                                <Typography variant="h6">
                                    Please Choose a Time Slot for Your Interview
                                </Typography>
                                <Grid container>
                                    <Grid className={classes.time}>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => this.handleTimeSlot(1)}
                                            style={{
                                                background: this.state.slotSelected === 1 ? "#24325f" : "#fff",
                                                color: this.state.slotSelected === 1 ? "#fff" : "#24325f"
                                            }}
                                            disabled={this.isDatePassed(slotDetails.first_slot)}
                                        >
                                            {formatDate(slotDetails.first_slot, "-")}
                                            <span style={{ margin: '0 0 0 1rem' }}>{formatAMPM(slotDetails.first_slot)}</span>
                                        </Button>
                                        <Typography
                                            variant="body1"
                                            style={{ opacity: this.state.slotSelected === 1 ? 1 : 0 }}
                                        >
                                            Selected Slot
                                        </Typography>
                                    </Grid>
                                    <Grid className={classes.time}>
                                        <Button variant="text" color="primary" onClick={() => this.handleTimeSlot(2)}
                                            style={{
                                                background: this.state.slotSelected === 2 ? "#24325f" : "#fff",
                                                color: this.state.slotSelected === 2 ? "#fff" : "#24325f"
                                            }}
                                            disabled={this.isDatePassed(slotDetails.second_slot)}
                                        >
                                            {formatDate(slotDetails.second_slot, "-")} <span style={{ margin: '0 0 0 1rem' }}>{formatAMPM(slotDetails.second_slot)}</span>
                                        </Button>
                                        <Typography
                                            variant="body1"
                                            style={{ opacity: this.state.slotSelected === 2 ? 1 : 0 }}
                                        >
                                            {this.isAnySlotAvaiable() ? "All slots are expired" : "Selected Slot"}
                                        </Typography>
                                    </Grid>
                                    <Grid className={classes.time}>
                                        <Button variant="text" color="primary" onClick={() => this.handleTimeSlot(3)}
                                            style={{
                                                background: this.state.slotSelected === 3 ? "#24325f" : "#fff",
                                                color: this.state.slotSelected === 3 ? "#fff" : "#24325f"
                                            }}
                                            disabled={this.isDatePassed(slotDetails.third_slot)}
                                        >
                                            {formatDate(slotDetails.third_slot, "-")} <span style={{ margin: '0 0 0 1rem' }}>{formatAMPM(slotDetails.third_slot)}</span>
                                        </Button>
                                        <Typography
                                            variant="body1"
                                            style={{ opacity: this.state.slotSelected === 3 ? 1 : 0 }}
                                        >
                                            Selected Slot
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Button onClick={() => {
                                    if (!this.isAnySlotAvaiable()) {
                                        this.handleSelectInterview(slotDetails);
                                    } else {
                                        // @ts-ignore
                                        this.props!.toggleModal();
                                    }
                                }} className={classes.submitBtn}>
                                    {this.isAnySlotAvaiable() ? "Okay" : "Submit"}
                                </Button>
                            </Grid>
                        }

                        {(slotDetails.is_accepted_by_candidate) &&
                            <Grid container style={{ padding: "0 0 4rem" }}>
                                <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
                                    You have already selected a slot, at
                                </Typography>
                                <Box style={{ padding: "1rem 0 0" }}>
                                    {slotDetails.preferred_slot === "first" &&
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                                            {formatDate(slotDetails.first_slot, "-")} {formatAMPM(slotDetails.first_slot)}
                                        </Typography>}
                                    {slotDetails.preferred_slot === "second" &&
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                                            {formatDate(slotDetails.second_slot, "-")} {formatAMPM(slotDetails.second_slot)}
                                        </Typography>}
                                    {slotDetails.preferred_slot === "third" &&
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                                            {formatDate(slotDetails.third_slot, "-")} {formatAMPM(slotDetails.third_slot)}
                                        </Typography>}
                                </Box>
                            </Grid>
                        }

                        {(!slotDetails.is_accepted_by_candidate &&
                            (this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)?.isSet || false)
                        ) &&
                            <Grid container style={{ padding: "0 0 4rem" }}>
                                <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
                                    You have already selected a slot, at
                                </Typography>
                                <Box style={{ padding: "1rem 0 0" }}>
                                    {this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)!.slots.preffered_slot === "first" &&
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                                            {formatDate(this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)!.slots.first_slot, "-")} {formatAMPM(this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)!.slots.first_slot)}
                                        </Typography>}
                                    {this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)!.slots.preffered_slot === "second" &&
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                                            {formatDate(this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)!.slots.second_slot, "-")} {formatAMPM(this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)!.slots.second_slot)}
                                        </Typography>}
                                    {this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)!.slots.preffered_slot === "third" &&
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>
                                            {formatDate(this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)!.slots.third_slot, "-")} {formatAMPM(this.state.isInterviewSet.find(interview => interview.interviewId === slotDetails.id)!.slots.third_slot)}
                                        </Typography>}
                                </Box>
                            </Grid>}
                    </Grid>
                    {/* Snackbar for display success and failed messages. */}
                    <Snackbar
                        open={this.state.snackBar.show}
                        autoHideDuration={3000}
                        onClose={this.closeSnackBarHandler}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={this.closeSnackBarHandler}
                            severity={this.state.snackBar.type}
                            style={{ color: "#fff" }}
                        >
                            {this.state.snackBar.message}
                        </MuiAlert>
                    </Snackbar>
                </Dialog>
            </ThemeProvider>
        );
    }
}

// Customizable Area Start
export default withStyles(useStyles)(SelectTimeSlot);
// Customizable Area End
