import React from "react";
import { AppBar, Checkbox, FormLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
// Customizable Area Start
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Card, Container, Box, Typography, withStyles, Button, Grid } from "@material-ui/core";
import RoleMangementController, { Props } from "./RoleMangementController.web";
import { Formik } from "formik";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ProfileImg, DraftImg, Messages } from "../../QuestionBank/src/assets";
import { DropzoneAreaBase } from "material-ui-dropzone";
import Description from "@material-ui/icons/Description";
import "./style.scss";
import JobDescriptionParent from "./JobDescriptionParent.web";
import AppSidebar from '../../../components/src/AppSidebar';
import { ReactComponent as JDCreateIcon } from "../assets/Filled.svg";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress"
// Customizable Area End

const drawerWidth = 240;
const muiStyles = (theme: any) => ({
  root: {
    display: 'flex',
  },
  textWhite: {
    color: "#ffffff"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#384786',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  listIconeWidth: {
    minWidth: '47px',
    color: 'White'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  BgHighlightBottom: {
    width: '100%',
    backgroundColor: '#24325f',
    height: '15px',
  },
  BgCardHeader: {
    backgroundColor: '#a8d0e7',
  },
  BtnCreateNewRole: {
    backgroundColor: '#24325f',
    color: "white"
  },
  BgHighlightInterviewsBottom: {
    backgroundColor: '#e19954',
    height: '15px',
    width: "100%"

  },
  InterveiwTextColor: {
    color: '#e19954'
  },
  CandidatesTextColor: {
    color: '#5f72b9'
  },
  BgHighlightcandidatesBottom: {
    backgroundColor: '#5f72b9',
    height: "15px",
    width: '100%'
  },
  BgHighlightClosedRolesBottom: {
    backgroundColor: '#ff4e6d',
    height: "15px",
    widht: '100%'
  },
  ClosedRolesTextColor: {
    color: '#ff4e6d'
  },
  card: {
    boxShadow: 'none!important',
  },
  TilesItem: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    height: '100%',
  },
  TilesItemContent: {
    flex: '1'
  },
  TilesTextSyle: {
    fontSize: '0.875em',
    color: '#363636',
  },
  RoleHeading: {
    color: '#24325F',
    fontFamily: 'Poppins-SemiBold',
  },
  RoleHeadingAll: {
    color: '#24325F',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '0.75em'
  },
  WelcomeDate: {
    color: '#92929D'
  },

  fullBtnCancel: {
    marginRight: "0.5em",
    border: "1px solid #E2E2EA",
    color: "#696974",
  },
  fullBtnSubmit: {
    marginLeft: "0.5em"
  },
  topAppBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffff"
  },
  ContentTopMargin: {
    marginTop: "5.4rem",
    width: "100%"
  },
  CreateBtn: {
    marginLeft: "0.5em"
  },
  progressBar: {
    marginRight: "0.5em"
  }
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface FormikInitialValues {
  role_name: string;
  positions: string | number;
  managers: any;
  mappedManagers: any;
  jdFile: any;
  isAutomated: boolean;
}
class CreateRole extends RoleMangementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    let clientID = +this.props.location?.search?.split("clientId=")[1];
    this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID }, () => {
      if (this.state.userType == "admin") {
        if (clientID) {
          this.getJDTemaplateData();
        } else {
          this.props.history.push(`/clients-list`);
        }
      } else if (this.state.userType == "client") {
        this.getJDTemaplateData();
      }
      this.clientIDLink = this.state.userType == 'admin' && this.state.clientID ? "client_id=" + this.state.clientID : "";
    });
    this.setState({ isInitiated: true });
    if (this.props?.location?.pathname?.includes("editRole")) {
      this.setState({ requestType: "editRole", jdHeading: "Edit" })
    } else {
      this.setState({ requestType: "createRole", jdHeading: "Create", jobDetailData: {}, jdFormData: {}, showJobDescription: false, fetchAllJDData: {} })
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: any) {
    if (this.props.location?.pathname != prevProps.location?.pathname) {
      if (this.props?.location?.pathname?.includes("editRole")) {
        this.setState({ requestType: "editRole", jdHeading: "Edit" }, () => this.getJobDetails(this.props?.match?.params?.id))
      } else {
        this.setState({ requestType: "createRole", jdHeading: "Create", jobDetailData: {}, jdFormData: {}, showJobDescription: false, fetchAllJDData: {} })
      }
    }
  }

  allDropdown = (n: number) => {
    let data = [];
    for (let i = 0; i <= n; i++) {
      data.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return data;
  }
  // Customizable Area End
  render() {
    const { classes } = this.props;
    const maxFile: number = 1;

    let mappedManger = this.state.jobDetailData?.attributes?.managers?.length > 0 &&
      typeof this.state.jobDetailData?.attributes?.managers != "string" ?
      this.state.jobDetailData?.attributes?.managers?.map((item: any) => (`${item.name},(${item.email})`)) : [];
    const initialValues: FormikInitialValues = {
      role_name: this.state.jobDetailData?.attributes?.name ? this.state.jobDetailData?.attributes?.name : "",
      positions: this.state.jobDetailData?.attributes?.position || "",
      managers: this.state.jobDetailData?.attributes?.managers?.length > 0 && typeof this.state.jobDetailData?.attributes?.managers != "string" ? this.state.jobDetailData?.attributes?.managers : [],
      mappedManagers: mappedManger,
      jdFile: [],
      isAutomated: this.state.jobDetailData?.attributes?.jd_automatic || false
    };
    if (this.state.isInitiated && this.state.jdLoader && this.state.requestType == "editRole") {
      this.setState({ isInitiated: false });
      this.getJobDetails(this.props?.match?.params?.id)
    }
    return (
      <>
        <Container maxWidth="xl">
          <Box display="flex">
            <AppSidebar />
            <Box color="primary" mt={4} mb={6} className={`${classes.ContentTopMargin}`}>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Box mb={1}><Typography variant="h5" >{this.state.requestType == "createRole" ? "Create New Role" : "Edit Role"}</Typography></Box>
              </Box>
              <Box mb={4}>
                <Card className={classes.card}>
                  <Box p={2}>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={this.state.createRoleValidationSchema}
                      onSubmit={(values, { resetForm }) => {
                        let id = "";
                        if (this.state.requestType == "editRole") {
                          id = this.state.jobDetailData?.id;
                        } if (values.isAutomated) {
                          id = this.state.jobDetailData?.attributes?.job_description?.job_description_id
                        }
                        this.createNewRole(values, id);
                        // resetForm();
                        // this.setState({ uploadFiles: [] })

                      }}
                      enableReinitialize
                    >
                      {(formikProps) => (
                        <form onSubmit={formikProps.handleSubmit} className="createRole">
                          <Box my={5}>
                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <FormLabel className="formLabel-role">Role Name</FormLabel>
                                <TextField
                                  style={{ width: "100%" }}
                                  id="role_name"
                                  name="role_name"
                                  onChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                  value={formikProps.values.role_name}
                                  error={formikProps.touched.role_name && Boolean(formikProps.errors.role_name)}
                                  helperText={formikProps.touched.role_name && formikProps.errors.role_name}
                                  placeholder="Enter Role Name"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormLabel className="formLabel-role">No. of Open Positions</FormLabel>
                                <TextField
                                  style={{ width: "100%" }}
                                  type="number"
                                  id="positions"
                                  name="positions"
                                  onChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                  value={formikProps.values.positions}
                                  error={formikProps.touched.positions && Boolean(formikProps.errors.positions)}
                                  helperText={formikProps.touched.positions && formikProps.errors.positions}
                                  placeholder="Enter No. Of Positions" />
                              </Grid>
                            </Grid>
                          </Box>
                          <Box my={5} className="hiringselect">
                            <FormLabel className="formLabel-role">Hiring Managers</FormLabel>
                            <Select
                              style={{ width: "100%" }}
                              labelId="mappedManagers-label"
                              id="mappedManagers"
                              multiple
                              name="mappedManagers"
                              onBlur={formikProps.handleBlur}
                              value={formikProps.values.mappedManagers}
                              error={formikProps.touched.mappedManagers && Boolean(formikProps.errors.mappedManagers)}
                              onChange={(e) => {
                                formikProps.setFieldValue("mappedManagers", e.target.value);
                              }}
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected: any) => selected.join(', ')}
                              MenuProps={MenuProps}
                            >
                              {this.state.managersList?.map((item: any) => (
                                <MenuItem key={item.id} value={`${item.name},(${item.email})`}
                                  selected={formikProps.values.managers?.filter((e: any) => e.email == item.email).length > 0}
                                  onClick={(e: any) => {
                                    let dataAll: any = [];
                                    if (formikProps.values.managers?.length > 0) {
                                      let index = formikProps.values.managers?.findIndex((e: any) => e.id == item?.id);
                                      dataAll = [...formikProps.values.managers];
                                      if (index != -1) {
                                        dataAll?.splice(index, 1);

                                        formikProps.setFieldValue("managers", [...dataAll]);
                                      } else {
                                        formikProps.setFieldValue("managers", [...dataAll, item]);
                                      }
                                    } else {
                                      formikProps.setFieldValue("managers", [item]);
                                    }
                                  }}
                                >
                                  {(
                                    //@ts-ignore
                                    <Checkbox checked={formikProps.values.managers?.filter((e: any) => e.email == item.email).length > 0} />
                                  )}<ListItemText primary={`${item.name},(${item.email})`} />
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography variant="caption" style={{ color: "#f44336", display: (Boolean(formikProps.errors.managers) && formikProps.touched.managers) ? 'flex' : 'none' }}>
                              Please select at least one manger
                            </Typography>
                          </Box>
                          {this.state.requestType == "createRole" || this.state.compulsoryJDUpload ?
                            <Box my={4}>
                              <DropzoneAreaBase
                                dropzoneClass="dropZoneJob"
                                fileObjects={this.state.uploadFiles}
                                dropzoneText={"DRAG & DROP YOUR JOB DESCRIPTION HERE"}
                                onAdd={newFileObjs => {
                                  this.setState({ uploadFiles: newFileObjs });
                                  formikProps.setFieldValue("jdFile", newFileObjs[0]);
                                  formikProps.setFieldValue("isAutomated", true);
                                  delete formikProps.errors.jdFile;
                                }}
                                onDelete={deleteFileObj => {
                                  this.setState({ uploadFiles: [] });
                                  formikProps.setFieldValue("jdFile", []);
                                  formikProps.setFieldValue("isAutomated", false);
                                }}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                useChipsForPreview
                                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                previewChipProps={{ classes: { root: classes.previewChip } }}
                                previewText="Selected files"
                                filesLimit={maxFile}
                              />
                            </Box> : ""}
                          {this.state.compulsoryJDUpload ?
                            <Typography variant="caption" style={{ color: "#f44336", display: formikProps.errors.jdFile ? 'flex' : 'none' }}>
                              {formikProps.errors.jdFile}
                            </Typography>
                            : ""}
                          {this.state.requestType == "createRole" &&
                            <Box my={2} justifyContent="center" display="flex" alignItems="center">
                              <Typography variant="h6" >OR</Typography>
                            </Box>}

                          {this.state.requestType == "createRole" || (this.state.requestType == "editRole" && !this.state.jobDetailData?.attributes?.jd_automatic) ?
                            <Box justifyContent="center" display="flex" mb={5} mt={2}>
                              <Button
                                disabled={this.state.uploadFiles?.length > 0}
                                type="button" className="jobDesc" disableRipple variant="contained" color="secondary"
                                onClick={(e: any) => this.handleJobDescription(e, "click")}
                              >
                                <JDCreateIcon />
                                <Typography variant="body2" className={classes.CreateBtn} >
                                  {this.state.jdHeading} Job Description
                                </Typography>
                              </Button>
                            </Box> : ""}

                          <Box justifyContent="center" display="flex">
                            <Button
                              type="button" className={classes.fullBtnCancel} disableRipple variant="outlined" color="default" onClick={() => {
                                if (this.state.requestType == "editRole") {
                                  this.props.history.goBack();
                                } else {

                                  formikProps.resetForm();
                                  this.props.history.goBack();
                                }
                              }}>
                              <Typography variant="body2">
                                Cancel
                              </Typography>
                            </Button>
                            <Button
                              type="submit" className={classes.fullBtnSubmit} disableRipple variant="contained" color="primary" onClick={() => {
                                if (this.state.compulsoryJDUpload && formikProps.values.jdFile?.length <= 0) {
                                  formikProps.errors.jdFile = "Please upload job description file";
                                }
                                this.setState({ resetFormikForm: formikProps.resetForm });

                              }}
                              disabled={this.state.successSubmit}
                            >
                              <Typography variant="body2">
                                <span className={classes.progressBar} >Submit</span>
                                {this.state.successSubmit && <CircularProgress color='primary' size="1rem" />}
                              </Typography>
                            </Button>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Box>
                </Card>
              </Box>
            </Box>
          </Box>
        </Container>
        {this.state.showJobDescription ? (
          //@ts-ignore
          <JobDescriptionParent jdHeadingValue={this.state.jdHeading} jobDescription={this.state.showJobDescription} onCloseJobDescription={this.handleJobDescription} handleSendJDData={this.handleFetchJDData} onEditJobDescriptionData={this.state.fetchAllJDData} clientID={this.state.clientID} />
        ) : ""}
        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }


}

// Customizable Area Start
//@ts-ignore
export default withStyles(muiStyles)(withRouter(CreateRole));
// Customizable Area End
