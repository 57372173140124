import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Checkbox,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
    boxleftall: {
        padding: "1.5rem 0.3rem",
        background: "#fff",
        borderRadius: ".8rem",
        textTransform: "capitalize",

        "& .MuiAccordion-root:before": {
            background: "none",
        },
    },

    accordallleft: {
        boxShadow: "none",
        background: "#fff",
        "& .MuiAccordionDetails-root": {
            display: "block",
            textTransform: "capitalize",
        },
        "&:not(:last-child)": {
            marginBottom: "2rem",
        },
    },

    acorddata: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    checkall: {
        color: "#3DD598 !important",
        flex: 0.1,
    },

    chechead: {
        fontSize: "0.8em",
        paddingTop: "5px",
        flex: 0.9,
        color: "#44444F",
    },

    checkcount: {
        textAlign: "center",
        background: "#F1F1F5",
        padding: "5px 7px",
        color: "grey",
        borderRadius: "7px",
        fontWeight: "bold",
        fontSize: "0.8em",
        width: "1rem",
        height: "1rem",
        [theme.breakpoints.down("sm")]: {
            // marginRight: "1.2rem",
        }
    },

    accordsumm: {
        padding: "0px 25px",
        maxHeight: "20px !IMPORTANT",
        minHeight: "20px !IMPORTANT",
        width: "87%",
        
        [theme.breakpoints.down("sm")]: {
            width: "95%",
        },

        [theme.breakpoints.down("xs")]: {
            width: "90%",
        },
    },

    filterText: {
        fontWeight: 600,
        textTransform: "capitalize",
        fontSize: "0.9em",
    },

    activeText: {
        color: "#44444F !important",
    },

    details: {
        padding: ".5rem 1rem 0",
    },
}));

export interface DropdownProps {
    filters: {
        filter: string;
        values: {
            name: string;
            vacancy: number;
        }[];
    }[];
    handleFilters: (checked: boolean, type: string, value: string) => void;
    clearAllFilters: boolean;
}

const Leftfilterdropdown: FC<DropdownProps> = ({
    filters,
    handleFilters,
    clearAllFilters,
}) => {
    const classes = useStyles();
    const [openFilters, setOpenFilters] = React.useState<number[]>([]);

    const handleChange = (checked: boolean, type: string, value: string) => {
        handleFilters(checked, type, value);
    };

    // when the accordion is open, font color changes
    const handleOpen = (menuNumber: number) => {
        setOpenFilters(
            openFilters.includes(menuNumber)
                ? openFilters.filter((item) => item !== menuNumber)
                : [...openFilters, menuNumber]
        );
    };

    if (filters === undefined) return null;

    return (
        <Box className={classes.boxleftall}>
            {filters &&
                filters.map((data, index) => {
                    return (
                        <Accordion
                            className={classes.accordallleft}
                            key={index}
                        >
                            <AccordionSummary
                                className={classes.accordsumm}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={(e) => {
                                    handleOpen(index);
                                }}
                            >
                                <Typography
                                    className={`${classes.filterText
                                        } ${openFilters.includes(index) &&
                                        classes.activeText}`}
                                >
                                    {data.filter}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails className={classes.details}>
                                {data.values.map((job) => {
                                    return (
                                        <Box
                                            className={classes.acorddata}
                                            key={job.name}
                                        >
                                            <CBComponent
                                                styles={classes.checkall}
                                                handleChange={handleChange}
                                                filter_name={data.filter}
                                                value={job.name}
                                                forcedRemoveChecked={
                                                    clearAllFilters
                                                }
                                            />
                                            <Typography
                                                className={classes.chechead}
                                                variant="body1"
                                            >
                                                {job.name}
                                            </Typography>
                                            <Typography
                                                className={classes.checkcount}
                                                variant="body2"
                                            >
                                                {job.vacancy}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
        </Box>
    );
};

interface CBProps {
    styles: any;
    handleChange: (checked: boolean, type: string, value: string) => void;
    filter_name: string;
    value: string;
    forcedRemoveChecked?: boolean;
}

const CBComponent: React.FC<CBProps> = ({ styles, handleChange, ...props }) => {
    const [isSet, setIsSet] = useState(false);

    const onHandleChange = () => {
        setIsSet((prev) => !prev);
        handleChange(!isSet, props.filter_name, props.value);
    };

    useEffect(() => {
        if (isSet) {
            setIsSet(false);
        }
    }, [props.forcedRemoveChecked]);

    return (
        <Checkbox
            className={styles}
            checked={isSet}
            onChange={() => onHandleChange()}
        />
    );
};

export default Leftfilterdropdown;
