import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  classes?: any;
  match?: any;
  showInterviewFeedback: boolean;
  onCloseShowInterviewFeedback: () => void;
  candidateData: any;
  location?: any;
  clientID?: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  scoreList: any;
  filterList: any;
  snackBar: {
    show: boolean;
    message: string;
    type: any
  };
  pageLengthToShow: number;
  assessmentDetail: any;
  showInterviewFeedbackModal: boolean;
  getTestDomeDataType: string;
  getTestDomeDetail: any;
  feedbackParameters: any;
  feedbackData: any;
  feedbackValidationSchema: any;
  ratingRBError: boolean;
  pageNumber: number;
  feedbackList: any;
  selectedFedbackOption: any;
  sortRoleType: string;
  searchString: string;
  windowWidth: string | number;
  userType: string;
  clientID: number | string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ScoringController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCandidateAssesmentListApiCallId: string;
  getCandidateAssesmentDetailApiCallId: string;
  getTestDomeDataListApiCallId: string;
  getFeedbackParameterListApiCallId: string;
  sendCandidateFeedbackCallId: string;
  showCandidateFeedbackListApiCallId: string;
  getFeedbackListApiCallId: string;
  getFeedbackOptionListApiCallId: string;
  sendCandidateAssesmentFeedbackCallId: string;
  clientIDLink: string;

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    let feedbackValidationSchema: any = Yup.object({
      feedbackData: Yup.array().of(Yup.object({
        rating: Yup.string().required("Please give rating"),
        comments: Yup.string()
      }))
    });
    this.state = {
      // Customizable Area Start
      scoreList: null,
      filterList: null,
      snackBar: {
        show: false,
        message: "",
        type: "",
      },
      pageLengthToShow: 10,
      assessmentDetail: null,
      showInterviewFeedbackModal: false,
      getTestDomeDataType: "",
      getTestDomeDetail: null,
      feedbackParameters: [],
      feedbackData: null,
      feedbackValidationSchema: feedbackValidationSchema,
      ratingRBError: false,
      pageNumber: 1,
      feedbackList: [],
      selectedFedbackOption: "",
      sortRoleType: "",
      searchString: "",
      windowWidth: window.innerWidth,
      userType: sessionStorage.getItem("user_role") || "",
      clientID: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        //Get Assessment List API
        if (
          apiRequestCallId === this.getCandidateAssesmentListApiCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.setState({ scoreList: responseJson, filterList: responseJson?.candidate?.data });
          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Get Assesment Detail API
        if (
          apiRequestCallId === this.getCandidateAssesmentDetailApiCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            let data = responseJson?.data;
            this.formatData(data);
          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }

        //Get Test Dome List API
        if (
          apiRequestCallId === this.getTestDomeDataListApiCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            let accounts = responseJson?.data?.accounts;
            let allTests: any = [];
            accounts?.map((account: any) => {
              if (account?.test_data?.length > 0) {
                account?.test_data?.map((test: any) => {
                  let data = { ...test, full_name: account?.full_name };
                  allTests.push(data);
                });
              }
            });
            this.setState({ scoreList: allTests, filterList: allTests });
            if (this.state.getTestDomeDataType == "detail") {
              let details = this.state.scoreList?.filter((item: any) => item.id == this.props.match.params?.id);
              this.setState({ getTestDomeDetail: details });
            }

          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }

        //Get Feedback Parameter List API
        if (
          apiRequestCallId === this.getFeedbackParameterListApiCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.setState({ feedbackParameters: responseJson?.parameters })

          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }

        //Send Feedback API
        if (
          apiRequestCallId === this.sendCandidateFeedbackCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.openSnackBarHandler('success', "Feedback submitted successfully");
            let clientIDLinkSet = this.clientIDLink;
            setTimeout(() => {
              this.props.onCloseShowInterviewFeedback();
              setTimeout(() => {
                this.props.history.push(`/candidate-scoring${clientIDLinkSet ? "?" + clientIDLinkSet : ""}`);
              }, 500);
            }, 2000);

          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }

        //Show Feedback API
        if (
          apiRequestCallId === this.showCandidateFeedbackListApiCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            let feedback = responseJson?.interview_feedback?.data?.map((feedback: any) => {
              return ({
                id: feedback?.attributes?.feedback_parameter_list?.id,
                name: feedback?.attributes?.feedback_parameter_list?.name,
                rating: feedback?.attributes?.rating,
                comments: feedback?.attributes?.comments
              })
            });
            this.setState({ feedbackParameters: feedback });

          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }

        //Get Feedback Option List API
        if (
          apiRequestCallId === this.getFeedbackOptionListApiCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.setState({ feedbackList: responseJson?.feedback });
          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Send Feedback for Assessment API
        if (
          apiRequestCallId === this.sendCandidateAssesmentFeedbackCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.openSnackBarHandler('success', "Feedback submitted successfully");
            //@ts-ignore
            this.getCandidateAssesmentDetail(this.props.match.params?.id);
          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }

      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start
  doButtonPressed() { }

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  // To get all candidate assessment list
  getCandidateAssesmentList(values: { page: number, per_page: number }): boolean {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };
    let httpBody: any = values;
    if (this.state.sortRoleType) {
      httpBody['sort'] = this.state.sortRoleType;
    }
    if (this.state.searchString) {
      httpBody['name'] = this.state.searchString;
    }
    if (this.state.userType == 'admin' && this.state.clientID) {
      httpBody['client_id'] = this.state.clientID
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCandidateAssesmentListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCandidateAssesmentListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To get candiadte assessment details based on ID
  getCandidateAssesmentDetail(id: any): boolean {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };
    const httpBody: any = {
      applied_id: id
    }
    if (this.state.userType == 'admin') {
      httpBody['client_id'] = this.state.clientID;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCandidateAssesmentDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCandidateAssesmentDetailAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To get feedback option list
  getFeedbackOptionList = (): boolean => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFeedbackOptionListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFeedbackOptionListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To send candidate assessment feedback
  sendCandidateAssesmentFeedback = (value: any, id: string | number): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      'Token': sessionStorage.getItem('Token')
    };

    let httpBody = {
      schedule: {
        feedback: value
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendCandidateAssesmentFeedbackCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendCandidateAssesmentFeedbackAPIEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To format received candidate assessment data
  formatData = (data: any) => {
    let assessment_test_scores = this.convertObjectToArray(data?.assessment_test_scores);
    let hiring_manger_assesment = this.convertObjectToArray(data?.hiring_manger_assesment);
    let hr_assessment = this.convertObjectToArray(data?.hr_assessment);
    let video_interview = this.convertObjectToArray(data?.video_interview);
    data = {
      ...data,
      "assessment_test_scores": assessment_test_scores,
      "hiring_manger_assesment": hiring_manger_assesment,
      "hr_assessment": hr_assessment, "video_interview": video_interview
    };
    this.setState({ assessmentDetail: data });
  }
  convertObjectToArray = (data?: any | undefined) => {
    if (data !== undefined && !data.errors) {
      let objArray: any[] = [];
      if (data) {
        let objArray: any = [];
        Object.keys(data).forEach(key => objArray.push({
          name: key,
          data: data[key]
        }));
        return objArray;
      } else {
        return [];
      }
    }
  }

  // To handle feedback modal to open it and to check if feedback is already provided or not
  // If provided call assessment details
  handleFeedBackModal = () => {
    this.setState((prev) => ({ showInterviewFeedbackModal: !prev.showInterviewFeedbackModal }), () => {
      if (this.state.showInterviewFeedbackModal == false) {
        this.getCandidateAssesmentDetail(this.props.match.params?.id);
      }
    });

  }

  // To get testDome assessment list
  getTestDomeDataList(type: string): boolean {
    const header = {
      "Content-Type": "application/json",
      // "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTestDomeDataListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTestDomeDataListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.setState({ getTestDomeDataType: type });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To get feedback parameter list
  getFeedbackParameterList(): boolean {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFeedbackParameterListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFeedbackParameterListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To send candidate feedback
  sendCandidateFeedback = (values: any, profileID: string | number, roleId: string | number): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      'Token': sessionStorage.getItem('Token')
    };

    let httpBody: any = {
      data: {
        profile_id: profileID,
        role_id: roleId,
        accepted: values?.accepted,
        attributes: values?.feedbackData
      }
    };

    if (this.state.userType == 'admin' && this.state.clientID) {
      httpBody['client_id'] = this.state.clientID;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendCandidateFeedbackCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendCandidateFeedbackAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To show candidate feedback
  showCandidateFeedback(id: number | string): boolean {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showCandidateFeedbackListApiCallId = requestMessage.messageId;

    let endpoint = configJSON.showCandidateFeedbackListAPiEndPoint + id;
    if (this.state.userType == "admin" && this.state.clientID) {
      endpoint = endpoint + `?client_id=${this.state.clientID}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // if (this.state.userType == 'admin') {
    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify({ client_id: this.state.clientID })
    // );
    // }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  checkWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  initialConfig = () => {
    let clientID = +this.props.location?.search?.split("clientId=")[1];
    this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID }, () => {
      if (this.state.userType == 'client' || (this.state.userType == 'admin' && clientID)) {
        this.getCandidateAssesmentList({ page: 1, per_page: 10 });
      } else {
        this.props.history.push(`/clients-list`);
      }

      this.clientIDLink = this.state.userType == "admin" && this.state.clientID ? "clientId=" + this.state.clientID : "";
    });
  }
  // Customizable Area End

}
