import React from 'react';
import { Box, Button, Container, Divider, Grid, Link, Snackbar, Tooltip, Typography } from '@material-ui/core';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import DescriptionIcon from "@material-ui/icons/Description";
import MuiAlert from "@material-ui/lab/Alert";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AppSidebar from '../../../components/src/AppSidebar';
import { withStyles } from '@material-ui/styles';
import { useCDStyles } from './styles.web';
import Location4Controller from './Location4Controller';
import TimeSlotModal from './TimeSlotModal.web';
import { withRouter } from "react-router-dom";
// @ts-ignore
import { NoProfilePictureURL } from '../../../web/src/Context/CandidateContext.web';
import ShimmerLoading from '../../../components/src/ShimmerLoading';
import { createMarkup } from '../../LandingPage2/src/LandingPage2.web';
// @ts-ignore
import WhatsAppModalWeb from '../../../web/src/common/components/WhatsAppModal.web';
import { ThemeProvider, makeStyles, createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    typography: {
        h1: {
            fontSize: 20,
            fontWeight: 700
        },
        h2: {
            fontWeight: 600,
            fontSize: 16,
            fontFamily: "Poppins-SemiBold"
        },
        h3: {
            fontWeight: 500,
            fontSize: 14,
            fontFamily: "Roboto-Regular"
        },
    }
});

class CandidateDetails extends Location4Controller {

    async componentDidMount() {
        // will the window from top
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.checkWindowResize);
        let clientID = +this.props.location?.search?.split("clientId=")[1];
        this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID }, () => {
            if (this.state.userType == 'client' || (this.state.userType == 'admin' && clientID)) {
                // @ts-ignore
                this.getCandidateDetails(this.props.location && this.getParameters(this.props.location.search));
                this.fetchManagerNames();
            } else {
                this.props.history.push(`/clients-list`);
            }

            this.clientIDLink = this.state.userType == "admin" && this.state.clientID ? "clientId=" + this.state.clientID : "";
        });
        // call the apis for data

    }
    async componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowResize);
    }

    render() {
        const isOpenRole = window.location.pathname.includes("open-roles");
        const { classes } = this.props;
        const currentRoute = this.state.candidate?.attributes &&
            `${isOpenRole ? "Open Roles" : "Closed Roles"} > ${this.state.candidate?.attributes?.role_name} > ${this.state.candidate?.attributes?.full_name}`

        const profileUrl = this.state.candidate?.attributes?.photo ?
            this.state.candidate?.attributes?.photo : NoProfilePictureURL;

        return (
            <Box display="flex">
                <ThemeProvider theme={theme}>
                    {this.state.isWapModalOpen &&
                        <WhatsAppModalWeb
                            isOpen={this.state.isWapModalOpen}
                            toggleModal={this.toggleWapModal}
                            candidate={{
                                Title: this.state.candidate?.attributes?.full_name,
                                phone_number: this.state.candidate?.attributes?.phone_number,
                            }}
                        />}
                </ThemeProvider>
                <AppSidebar />

                {/* main container */}
                <Container className={this.state.windowWidth > 960 ? classes.container : classes.mobileContainer}>

                    {/* header */}
                    <Grid container className={classes.header}>
                        {/* header left side */}
                        <Grid item>
                            <Typography variant='h5'>
                                {isOpenRole ? "Open Roles" : "Closed Roles"}
                            </Typography>
                            {!this.state.candidate?.attributes && <ShimmerLoading width='25rem' height="1rem" />}
                            <Typography className={classes.currentRoute} style={{ marginBottom: "1em" }}> {currentRoute} </Typography>
                        </Grid>
                        {/* header right side */}
                        <Grid item>
                            {/* icons */}
                            <Box display="flex">
                                <Tooltip title="View Resume" arrow>
                                    <a href={this.state.candidate?.attributes?.resume} target="_blank" style={{ textDecoration: "none" }} download >
                                        <DescriptionIcon className={classes.description} />
                                    </a>
                                </Tooltip>
                                <Tooltip title="Send message in WhatsApp" arrow>
                                    <WhatsAppIcon className={classes.whatsapp} onClick={this.toggleWapModal} />
                                </Tooltip>
                                <Tooltip title="Start Chatting" arrow>
                                    <MailOutlineIcon
                                        className={classes.mailout}
                                        onClick={() => this.createCoversation(this.state.candidate?.attributes.email)}
                                    />
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* candidate details */}
                    <Grid container className={`${classes.details} ${classes.innerContainer}`}>
                        <Grid item style={{ marginBottom: "1em" }}>
                            <img src={profileUrl} alt="profile_img" />
                        </Grid>
                        <Grid item className={classes.name} style={{ marginBottom: "1em" }}>
                            <Typography>Candidate Name</Typography>
                            {!this.state.candidate?.attributes && <ShimmerLoading width='14rem' />}
                            <Typography variant='h5'>{this.state.candidate?.attributes?.full_name}</Typography>
                        </Grid>

                        <Grid item>
                            <Box display="grid">
                                {
                                    isOpenRole && <Button onClick={this.handleModal}>
                                        Share Inteview Time Slots
                                    </Button>
                                }
                                <Button className={classes.assesmentBtn}>
                                    <Link
                                        href={`/assessment-detail/${this.state.candidate?.attributes?.applied_id}${this.clientIDLink ? "?" + this.clientIDLink : ""}`}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        Candidate Assesment Details
                                    </Link>
                                </Button>
                            </Box>
                        </Grid>

                    </Grid>

                    {/* work exprience */}
                    <Grid className={`${classes.innerContainer} ${classes.workExprience}`}>
                        <Grid item>
                            <Typography variant='h5'> Work Experience </Typography>
                        </Grid>

                        <Divider />

                        {/* past expriences */}
                        {this.state.candidate?.attributes && this.state.candidate?.attributes.work_experience !== " " ?
                            <Typography
                                variant='body1'
                                style={{ whiteSpace: "pre-line" }}
                                dangerouslySetInnerHTML={createMarkup(this.state.candidate?.attributes?.work_experience)}
                            /> :
                            <Typography variant='body1'> No Work Experience Found. </Typography>}
                    </Grid>

                    <Grid className={classes.innerContainer}>
                        <Grid item>
                            <Typography variant='h5'> Skills </Typography>
                        </Grid>

                        <Divider />

                        {/* skills */}
                        {this.state.candidate?.attributes ? this.state.candidate?.attributes.skills !== "No skills found." ?
                            <Grid container>
                                {this.state.candidate?.attributes.skills.map((skill) => (
                                    <Grid item key={skill.id} className={classes.skill}>
                                        <Typography variant='body1'> {skill.name} </Typography>
                                    </Grid>
                                ))}
                            </Grid> :
                            <Typography variant='body1' color='textPrimary'> No skills found. </Typography> :
                            <ShimmerLoading numberOfShimmer={7} />
                        }
                    </Grid>

                    {/* modal */}
                    <TimeSlotModal
                        navigation={this.props.navigation}
                        id={this.props.id}
                        toggleModal={this.handleModal}
                        isOpen={this.state.isModalOpen}
                        candidate={this.state.candidate}
                        // @ts-ignore
                        params={this.props.location.search}
                    />
                </Container>

                {/* Snackbar for display success and failed messages. */}
                {this.state.snackBar.show && (
                    <Snackbar
                        open={this.state.snackBar.show}
                        autoHideDuration={3000}
                        onClose={this.closeSnackBarHandler}
                    >
                        <MuiAlert
                            elevation={6}
                            variant="filled"
                            onClose={this.closeSnackBarHandler}
                            severity={this.state.snackBar.type}
                        >
                            {this.state.snackBar.message}
                        </MuiAlert>
                    </Snackbar>
                )}
            </Box >
        );
    }
}

// @ts-ignore
export default withStyles(useCDStyles)(withRouter(CandidateDetails));


// Work Exprience ( will use in future ) :
// dummyData.exprience.map((job, index) => (
//     <Box key={index}>
//         <Grid item>
//             <Typography variant='h6'> {job.role} </Typography>
//             <Typography variant='body1'> {job.company} - {job.startDate} to {job.endDate} </Typography>
//             <Typography variant='body1'>{job.description}</Typography>
//         </Grid>
//         {index !== dummyData.exprience.length - 1 && <Divider />}
//     </Box>
// ))