import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Context } from "react";
// @ts-ignore
import LoaderContext, { LoaderontextType } from "../../../web/src/Context/LoaderContext.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  history?: any;
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  match: { params: { id: string | number } };
  location: any;
  isModalOpen: boolean;
  toggleSearchModal?: () => void;
  filters: any;
  clearFilters: boolean;
  resetFilters: () => void;
  applyFilters: (checked: boolean, type: string, value: string) => void;
  handleApply: (value: string) => void;
  extractValue: (value: string) => void;
  searchTextValue: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  snackBar: {
    show: boolean;
    message: string;
    type: any
  };
  cvMatchingJobList: any;
  otherJobList: any;
  similarJobList: any;
  jobListType: string;
  jobDetailData: any;
  isApplied: boolean;
  jobFilters: any;
  filters: any;
  isFilterApplied: boolean;
  clearAllFilters: boolean;
  isSearchModalOpen: boolean;
  searchText: string;
  isShareModalOpen: boolean;
  windowWidth: string | number;
  userType?: string;
  clientID: number | string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobListing2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllListedJobsApiCallId: string;
  applyForJobsApiCallId: string;
  getJobDetailsApiCallId: string;
  favouriteJobApiCallId: string;

  // Customizable Area Start
  getFiltersApiCallId: string;
  searchJobsApiCallId: string;
  searchOthersJobsApiCallId: string;
  static contextType?: Context<LoaderontextType> = LoaderContext;
  createConversationAPIId: string;
  clientIDLink: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      snackBar: {
        show: false,
        message: "",
        type: "",
      },
      cvMatchingJobList: null,
      otherJobList: null,
      similarJobList: null,
      jobListType: "",
      jobDetailData: null,
      isApplied: false,
      jobFilters: [],
      filters: [],
      isFilterApplied: false,
      clearAllFilters: false,
      isSearchModalOpen: false,
      searchText: "",
      isShareModalOpen: false,
      windowWidth: window.innerWidth,
      userType: sessionStorage.getItem("user_role") || "",
      clientID: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    // runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        // Favouite API Handling
        if (apiRequestCallId == this.favouriteJobApiCallId) {
        }
        // Get Filters API Handling
        if (apiRequestCallId == this.getFiltersApiCallId) {
          this.setState({ jobFilters: responseJson });
        }
        // Get Jobs By Applying Filters API Handling
        if (apiRequestCallId == this.searchJobsApiCallId) {
          this.setState({
            cvMatchingJobList: responseJson,
          });
        }
        if (apiRequestCallId == this.searchOthersJobsApiCallId) {
          this.context.stopLoader();
          this.setState({
            otherJobList: responseJson,
          })
        }
        //Get Job List API
        if (
          apiRequestCallId === this.getAllListedJobsApiCallId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            if (this.state.jobListType == "cv_based") {
              this.setState({ cvMatchingJobList: responseJson });
            } else if (this.state.jobListType == "others") {
              this.setState({ otherJobList: responseJson }, () => {
                this.context.stopLoader();
              });
            } else {
              this.setState({ similarJobList: responseJson });
            }

          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Apply for Job List API
        if (
          apiRequestCallId === this.applyForJobsApiCallId
        ) {
          if (responseJson !== undefined && !responseJson.error) {
            this.setState({ isApplied: true })
            this.openSnackBarHandler('success', "You have successfully applied for the job");
          } else {
            this.openSnackBarHandler('error', "Job apply failed");
          }
        }
        //Get Job Details API
        if (
          apiRequestCallId === this.getJobDetailsApiCallId
        ) {
          if (responseJson !== undefined && !responseJson.error) {
            this.setState({ jobDetailData: responseJson?.data, isApplied: responseJson?.data?.attributes?.applied }, () => {
              if (this.state.jobDetailData?.attributes?.user_role !== "client") {
                this.getAllListedJobs({ type: "similiar_job", page: 1, perPage: 3, roleId: this.props.match?.params?.id });
              }
            });
          } else {
            this.openSnackBarHandler('error', "Job apply failed");
          }
        }
        // conversation creation
        if (apiRequestCallId === this.createConversationAPIId) {
          if (responseJson) {
            if (responseJson?.table?.errors.includes("already")) {
              this.openSnackBarHandler("success", "Redirecting to chatting.....");
            } else {
              this.openSnackBarHandler("success", "Conversation Created, Redirecing.....");
            }
            setTimeout(() => {
              this.props.history.push(
                "/profile-setting/chats?email=" + sessionStorage.getItem("candidate_email")
              );
            }, 1000);
          } else {
            this.openSnackBarHandler("error", "Someting went wrong ....!");
          }
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start

  // Fetch All Job List
  getAllJobs = () => {
    this.context.startLoader();
    this.getAllListedJobs({ type: "cv_based", page: 1, perPage: 6 });
    setTimeout(() => {
      this.getAllListedJobs({ type: "others", page: 1, perPage: 6 });
    }, 2000);
  };

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  toggleShareModal = () => {
    this.setState({ isShareModalOpen: !this.state.isShareModalOpen });
  }

  // Customizable Area End

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  // toggle search modal for mobile
  toggleSearchModal = () => {
    this.setState({
      isSearchModalOpen: !this.state.isSearchModalOpen
    });
  }

  // Handle Filters
  handleFilters = (
    type: "Search" | "Location" | "Work Level" | "Salary Range" | any,
    value: string
  ) => {
    let isFound = false;

    // Check if filter is already applied, then remove it when user change the filter
    if (this.state.isFilterApplied) {
      this.setState({ isFilterApplied: false });
    }

    // Loop through filters and assign the value to the filter
    this.state.filters.forEach((each: any) => {
      if (each.type === type) {
        type === "Search"
          ? (each.values = value)
          : each.values.push(value);
        isFound = true;
      }
    });

    // If filter is not applied, then add it to the filter list
    if (!isFound) {
      type === "Search"
        ? this.state.filters.push({ type, values: value })
        : this.state.filters.push({ type, values: [value] });
    }

    // Remove the filter if user remove the filter
    if (value === "") {
      const data = this.state.filters.filter(
        (each: any) => each.type !== type
      );
      this.setState({ filters: data }, () => {
        if (data.length === 0) this.getAllJobs();
      });
    }
  };

  // Left Bar Search Handler
  multipleSearchHandler = (checked: boolean, type: string, value: string) => {
    this.context.startLoader();
    if (checked) {
      let isFound = false;

      // Loop through filters and assign the value to the filter
      this.state.filters.forEach((each: any) => {
        if (each.type === type) {
          each.values.push(value);
          isFound = true;
        }
      });

      if (!isFound) {
        this.state.filters.push({ type, values: [value] });
      }
      this.setState({ isFilterApplied: true }, () => {
        this.getAllJobs();
      });
    } else {
      const data = this.state.filters.map(
        (each: any) => {
          each.type === type ? each.values.length > 0 ?
            each.values.splice(each.values.indexOf(value), 1) : []
            : null;
          return each
        }
      );
      const filterData = data.filter((each: any) => each.values.length > 0);
      this.setState({ filters: filterData, isFilterApplied: true }, () => {
        this.getAllJobs();
      });
    }
  }

  // Reset Filter
  resetFilters = () => {
    if (this.state.isFilterApplied) {
      this.setState({
        filters: [],
        isFilterApplied: false,
        clearAllFilters: !this.state.clearAllFilters,
        searchText: ""
      }, () => {
        this.getAllJobs();
      });
    }
  };

  // To get all listed jobs base on type("similar","cv","other")
  getAllListedJobs = (params: { type: string, page?: number, perPage?: number, roleId?: number | string }): boolean => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };
    const data: {
      roles_type: string, page?: number, per_page?: number, role_id?: number | string, filters?: any
    } = {
      roles_type: params.type,
      page: params.page || 1,
      per_page: params.perPage || 10
    }

    // If Filter is applied, pass the filter in the body
    if (this.state.isFilterApplied && this.state.filters.length !== 0) {
      data.filters = this.state.filters;
    }

    if (params.type == "similiar_job") {
      data['role_id'] = params.roleId;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllListedJobsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllListedJobsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    this.setState({ jobListType: params?.type });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To apply for job by candidate
  applyForJob = (values: any): boolean => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const data = values;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.applyForJobsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.applyForJobAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To mark a job favourite
  handleFavorite = (data: { role_id: number, favourite: boolean }) => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouriteJobApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteJobAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // To get Job details based on job ID
  getJobDetails(id: string | number) {
    const header: any = {
      "Content-Type": "application/json",
    };

    const token = sessionStorage.getItem("Token");
    if (token) {
      header["Token"] = token;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getJobDetailsApiCallId = requestMessage.messageId;

    let endpoint = configJSON.getJobDetailsAPiEndPoint + id;
    if (this.state.userType == 'admin' && this.state.clientID) {
      endpoint = endpoint + (this.clientIDLink ? "&" + this.clientIDLink : "")
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Fetch Filters 
  getFilters = () => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFiltersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFiltersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Fetch Both from CV Based and Job Based
  fetchOthersJobs = () => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchOthersJobsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllListedJobsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        roles_type: "others", page: 1,
        per_page: 6, filters: this.state.filters
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Search Jobs with Filters
  searchJobs = () => {
    if (this.state.filters.length > 0) {
      // Todo: Need to FIX
      this.setState({ isFilterApplied: true });

      const header = {
        "Content-Type": "application/json",
        "Token": sessionStorage.getItem("Token")
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.searchJobsApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllListedJobsAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          roles_type: "cv_based", page: 1,
          per_page: 6, filters: this.state.filters
        })
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

      this.context.startLoader();
      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.fetchOthersJobs(); // fetch others jobs with same Filters
    } else {
      this.openSnackBarHandler('error', "You've to select at least one filter.");
    }
  }

  // Handle Search for mobile
  handleMobileSearch = (value: string) => {
    if (value.trim()) {
      this.handleFilters("Search", value);
      this.searchJobs();
    }
    this.toggleSearchModal();
  }

  //Handle page load
  handlePageLoad = () => {
    let clientID = +this.props.location?.search?.split("clientId=")[1];
    this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID }, () => {
      this.clientIDLink = this.state.userType == 'admin' && this.state.clientID ? "client_id=" + this.state.clientID : "";
      this.getJobDetails(this.props.match?.params?.id);
    });
    window.scrollTo(0, 0);
  }

  checkWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  // Chat Integration
  createCoversation = (candidate_email: string) => {
    this.openSnackBarHandler("success", "Search in the space...");
    if (candidate_email) {
      sessionStorage.setItem("candidate_email", candidate_email);
      const header = {
        "Content-Type": configJSON.jsonApiContentType,
        'token': sessionStorage.getItem('Token')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createConversationAPIId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_twilio/create_conversations"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ "email": candidate_email })
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.openSnackBarHandler("success", "Email not found ...");
    }
  }
  // Customizable Area End

}
