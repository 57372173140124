import React, { FC, useState } from "react";

import { Box, Button, TextField, InputBase } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { FDProps, FilterData } from "./mock/DummyFilter";
import { DropdownProps } from "./Leftfilterdropdown";
import { SearchIcon, LocationIcon, JobIcon, SalaryIcon } from "./assets";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            display: "flex",
            alignItems: "center",
            backgroundColor: "#fff",
            borderTopLeftRadius: "0.625rem",
            borderBottomLeftRadius: "0.625rem",
            marginRight: ".1rem",
            padding: "0 1em",
            "& svg": {
                margin: "0 1rem",
            },
        },

        inputRoot: {
            color: "inherit",
            flex: 0.95,
        },

        dropDown: {
            display: "flex",
            alignItems: "center",
            background: "#fff",
            marginRight: "2px",
            padding: "0px 15px",

            "& div": {
                paddingRight: "0 !important",
            },
        },

        inputField: {
            "& .MuiFilledInput-root": {
                backgroundColor: "#fff",
            },

            "& .MuiFilledInput-underline::before": {
                borderBottom: "none",
            },

            "& .MuiFilledInput-underline::after": {
                borderBottom: "none",
            },

            "& svg": {
                display: "none",
            },
        },

        buttonsearch: {
            color: "white",
            textTransform: "capitalize",
            padding: "1rem 1.8rem",
            background: "#24325F",
            boxShadow: "none",
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            fontSize: "1rem",
            fontWeight: 600,
            width: "7rem",
        },

        MR: {
            marginRight: ".7rem",
        },
    })
);

// TODO: Will remove when Job Type attribute will adeed in the API
const getOptions = (option: string) => {
    const options = FilterData.filter(
        (item: FDProps) =>
            item.filter.toLocaleLowerCase() === option.toLocaleLowerCase()
    );
    return options[0].values;
};

const getData = (filters: DropdownProps["filters"], search: string) => {
    return (
        filters &&
        filters.find(
            (item) =>
                item.filter.toLocaleLowerCase() === search.toLocaleLowerCase()
        )!.values
    );
};

interface JTFProps {
    filterData: DropdownProps["filters"];
    applyFilters: (
        type: "Search" | "Location" | "Work Level" | "Salary Range" | any,
        value: string
    ) => void;
    searchJobs: () => void;
    isFilterApplied: boolean;
    resetFilters: () => void;
    showAlert: (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ) => void;
}

const Jobtopfilter: FC<JTFProps> = (props) => {
    const classes = useStyles();
    const { filterData, applyFilters, searchJobs, isFilterApplied } = props;

    const [formState, setFormState] = useState({
        search: "",
        location: "",
        jobType: "",
        salaryRange: "",
    });

    const handleSubmit = (event : React.FormEvent<HTMLElement>) => {
        event.preventDefault();

        if (isFilterApplied) {
            props.resetFilters();
            setFormState({
                search: "",
                location: "",
                jobType: "",
                salaryRange: "",
            });
        } else {
            if (
                formState.search.trim() === "" &&
                formState.location.trim() === "" &&
                // formState.jobType === "" &&
                formState.salaryRange.trim() === ""
            ) {
                props.showAlert(
                    "warning",
                    "Please enter at least one search criteria"
                );
                return;
            }
            searchJobs();
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            display="flex"
            width="100%"
            justifyContent="center"
        >
            <Box className={classes.search} width="27%">
                <img src={SearchIcon} className={classes.MR} />
                <InputBase
                    placeholder="Search for jobs"
                    classes={{ root: classes.inputRoot }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => {
                        applyFilters("Search", e.target.value);
                        setFormState({ ...formState, search: e.target.value });
                    }}
                    value={formState.search}
                />
            </Box>

            {/* Bug: Replace SearchIcon with LocaitonIcon */}
            <Box className={classes.dropDown} width="17%">
                <img src={LocationIcon} />
                <Autocomplete
                    fullWidth
                    options={
                        getData(filterData, "Location") || [{ name: "Loading" }]
                    }
                    className={classes.inputField}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                        applyFilters("Location", value ? value!.name : "");
                        setFormState({
                            ...formState,
                            location: value ? value!.name : "",
                        });
                    }}
                    value={{
                        name: formState.location,
                        vacancy: 0,
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Location"
                            variant="filled"
                        />
                    )}
                />
            </Box>

            {/* Bug: Replace SearchIcon with JobIcon || Replace getOptions to getData when API will be ready */}
            <Box className={classes.dropDown} width="17%">
                <img src={JobIcon} />
                <Autocomplete
                    fullWidth
                    options={getOptions("Employee Type")}
                    className={classes.inputField}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                        // applyFilters("Work Level", value ? value!.name : ""); // TODO : Will Add when API will be ready
                        setFormState({
                            ...formState,
                            jobType: value ? value!.name : "",
                        });
                    }}
                    value={{
                        id: 1,
                        name: formState.jobType,
                        vacancy: 0,
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Job Type"
                            variant="filled"
                        />
                    )}
                />
            </Box>

            {/* Bug: Replace SalaryIcon IMG with SalaryIcon SVG */}
            <Box className={classes.dropDown} width="17%">
                <img src={SalaryIcon} />
                <Autocomplete
                    fullWidth
                    options={
                        getData(filterData, "Salary Range") || [
                            {
                                name: "Loading",
                            },
                        ]
                    }
                    value={{
                        name: formState.salaryRange,
                        vacancy: 0,
                    }}
                    className={classes.inputField}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                        applyFilters("Salary Range", value ? value!.name : "");
                        setFormState({
                            ...formState,
                            salaryRange: value ? value!.name : "",
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Salary Range"
                            variant="filled"
                        />
                    )}
                />
            </Box>

            <Box>
                <Button
                    variant="contained"
                    type="submit"
                    className={classes.buttonsearch}
                >
                    {isFilterApplied ? "Reset" : "Search"}
                </Button>
            </Box>
        </Box>
    );
};

export default Jobtopfilter;
