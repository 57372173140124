import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { Box, Button, Input, InputAdornment, Modal, TextField, Theme, Typography } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

export interface Props extends WithStyles<typeof muiStyles> {
    // Customizable Area Start
    isOpen: boolean;
    toggleModal: () => void;
    candidate: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    messageBody: string;
    response: string;
    userType: string;
    clientID: number | string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export const muiStyles = (theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderRadius: '1rem',
            boxShadow: theme.shadows[5],
            padding: "1rem 0",
            width: "40%",
            [theme.breakpoints.down('sm')]: {
                width: "90%",
            },

            "& .closeIcon": {
                alignSelf: "flex-end",
                margin: theme.spacing(0, 2, 0, 0),
                cursor: "pointer",
                transition: "all .2s ease-in-out",

                "&:hover": {
                    color: theme.palette.primary.main,
                    transform: "scale(1.2)",
                }
            },

            "& > div": {
                padding: theme.spacing(0, 4, .5),
                width: "85%",
            }
        },
        shareContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

            "& h6": {
                backgroundColor: "#e3e3e3",
                margin: theme.spacing(0, 5, 0, 3),
                padding: theme.spacing(1, 10),
                borderRadius: ".4rem",
            },

            "& > div": {
                width: "100%",
            },

            "& > div > div": {
                "& button": {
                    marginBottom: "1rem",
                    width: "10rem",
                    fontWeight: "bold",

                    "&:last-child": {
                        backgroundColor: "#50cc5c",
                        color: "#fff",
                        marginLeft: "1rem"
                    },

                    "&:disabled": {
                        backgroundColor: "#e3e3e3",
                    }
                }
            }
        },
    });
// Customizable Area End



class WhatsAppModal extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sendMessageApiId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            messageBody: "",
            response: "",
            userType: sessionStorage.getItem("user_role") || "",
            clientID: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.sendMessageApiId) {
                    if (responseJson.status === 500) {
                        this.setState({ response: "Something went wrong" });
                    } else {
                        this.setState({ response: "Your Message has been Sent Successfully", messageBody: "" });
                    }
                }
            }
            else {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentWillUnmount() {
        this.setState({ response: "" });
    }

    async componentDidMount() {
        let clientID = window.location?.search?.split("clientId=")[1];
        this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID });
    }
    // Customizable Area End


    // Customizable Area Start
    sendMsg = () => {
        if (!this.props.candidate.phone_number) {
            this.setState({ response: "Candidate Phone number not found !!" });
            return;
        }

        const header = {
            "Content-Type": "application/json",
            'token': sessionStorage.getItem('Token')
        };

        let httpBody: any = {
            phone_number: this.props.candidate.phone_number,
            message: this.state.messageBody
        }

        if (this.state.userType == "admin" && this.state.clientID) {
            httpBody['client_id'] = this.state.clientID;
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendMessageApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_twilio/custom_message"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

    // Customizable Area Start
    render(): React.ReactNode {
        const { isOpen, toggleModal, classes, candidate } = this.props;

        return (
            <>
                <Modal
                    open={isOpen}
                    onClose={toggleModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    className={classes.modal}
                >
                    <Fade in={isOpen}>
                        <Box className={classes.paper}>
                            <CloseIcon onClick={toggleModal} className="closeIcon" />

                            <Box>
                                <Typography variant="h5">Send Message to {candidate.Title}</Typography>

                                <Box className={classes.shareContainer}>
                                    <Box>
                                        <Box mt={3} mb={4}>
                                            <Input
                                                placeholder="Just write your message here. Don't include the candidate name, your name and email. We'll do it for you."
                                                value={this.state.messageBody}
                                                onChange={(event) => {
                                                    this.setState({ messageBody: event.target.value });
                                                    if (this.state.response) {
                                                        this.setState({ response: "" });
                                                    }
                                                }}
                                                multiline
                                                minRows={5}
                                                maxRows={5}
                                                fullWidth
                                                inputProps={{ maxLength: 900 }}
                                                endAdornment={
                                                    <Box style={{ marginTop: "3rem" }}>
                                                        <InputAdornment
                                                            position="end"
                                                        >
                                                            {this.state.messageBody.length} / 900
                                                        </InputAdornment>
                                                    </Box>
                                                }
                                            />
                                            {this.state.messageBody.length >= 900 && (
                                                <Typography color='error'>You have reach the maximum length.</Typography>
                                            )}

                                            {this.state.response && (
                                                <Box mt={2}>
                                                    <Typography
                                                        style={{ fontWeight: 600 }}
                                                        color='secondary'
                                                    >
                                                        {this.state.response}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>

                                        <Box display="flex" justifyContent="flex-end">
                                            <Button
                                                variant="contained"
                                                onClick={toggleModal}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.sendMsg}
                                                disabled={!this.state.messageBody.length}
                                            >
                                                Send
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(muiStyles)(WhatsAppModal);
// Customizable Area End