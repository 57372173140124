import React from 'react';
import { Box } from '@material-ui/core';
import CloseRoleController, { Props } from './CloseRoleController.web';
import CloseRoleGridItem from './CloseRoleGridItem.web';

class CloseRoleGridView extends CloseRoleController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                {this.props?.jobRoles && this.props?.jobRoles?.map((item: any, index: number) => {
                    return (
                        <Box mb={3} key={item?.id} >
                            {this.props.jobRoles?.length === index + 1 ?
                                <div style={{ marginBottom: "1em" }} key={index} ref={this.lastCloseRoleItem}>
                                    <CloseRoleGridItem
                                        role={item}
                                        classes={classes}
                                        isExpand={this.props?.isExpand}
                                        history={this.props.history}
                                        windowWidth={this.props.windowWidth}
                                        handleViewAll={(id: number | undefined) => this.props.handleViewAll(id)}
                                        candidateListScroll={this.props.candidateListScroll}
                                        hasMoreCandidateData={this.props.hasMoreCandidateData}
                                        infiniteCandidateLoading={this.props.infiniteCandidateLoading}
                                        startLoading={this.props.startLoading}
                                        createCoversation={this.props.createConversation}
                                        clientIDLink={this.props.clientIDLink}
                                    />
                                </div> :
                                <div style={{ marginBottom: "1em" }} key={index}>
                                    <CloseRoleGridItem
                                        role={item}
                                        classes={classes}
                                        isExpand={this.props?.isExpand}
                                        history={this.props.history}
                                        windowWidth={this.props.windowWidth}
                                        handleViewAll={(id: number | undefined) => this.props.handleViewAll(id)}
                                        candidateListScroll={this.props.candidateListScroll}
                                        hasMoreCandidateData={this.props.hasMoreCandidateData}
                                        infiniteCandidateLoading={this.props.infiniteCandidateLoading}
                                        startLoading={this.props.startLoading}
                                        createCoversation={this.props.createConversation}
                                        clientIDLink={this.props.clientIDLink}
                                    />
                                </div>}
                        </Box>
                    );
                })}
            </>
        );
    }
}

export default CloseRoleGridView;