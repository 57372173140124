import React, { Component, PureComponent } from 'react';
import { Switch, Route } from 'react-router';
import ProtectedRoute from './ProtectedRoute.web';
import PrivateRoute from './PrivateRoute.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import PrivacySettings from '../../blocks/PrivacySettings/src/Privacypolicy.web';
import JobDescription from '../../blocks/JobListing2/src/JobDetails.web';
import AccountScoreranking from '../../blocks/AccountScoreranking/src/AccountScoreranking.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import EmailAccountRegistrationVerification from '../../blocks/email-account-registration/src/EmailAccountRegistrationVerify.web';
import Analytics3 from '../../blocks/Analytics3/src/Analytics3.web';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import CandidateDashboard from '../../blocks/QuestionBank/src/CandidateDashboard.web';
import Terms from '../../blocks/PrivacySettings/src/Terms.web';
import LandingPage2 from '../../blocks/LandingPage2/src/LandingPage2';
import MerchantDashboard2 from '../../blocks/MerchantDashboard2/src/MerchantDashboard2.web';
import ActivityLog from '../../blocks/ActivityLog/src/ActivityLog';
import Teams from '../../blocks/ActivityLog/src/Teams.web';
import OpenRoles from '../../blocks/Location4/src/OpenRoles.web';
import CandidateDetails from '../../blocks/Location4/src/CandidateDetails.web';
import CloseRole from '../../blocks/LinkShare/src/CloseRole';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import CreateRole from '../../blocks/MerchantDashboard2/src/CreateRole.web';
import Scoring from '../../blocks/Scoring/src/Scoring.web';
import AssessmentDetail from '../../blocks/Scoring/src/AssessmentDetail.web';
import JobListing2 from '../../blocks/JobListing2/src/JobListing2';
import Database from '../../blocks/JobListing2/src/Database';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications.web';
import CandidateContext from './Context/CandidateContext.web.tsx';
import CandidateDatabase from '../../blocks/catalogue/src/CandidateDatabase.web';
import AfterInterviewFeedback from '../../blocks/ContentManagement/src/AfterInterviewFeedback.web';
import WhatsappAdmin from '../../blocks/SendAMessage/src/WhatsappAdmin.web';
import ClientsList from '../../blocks/TicketSystem/src/ClientsList.web';
import { Typography } from '@material-ui/core';

class RouteConfig extends Component {
  render() {
    return (
      // <Router>
      <Switch>
        <Route path="/" exact>
          <LandingPage2 />
        </Route>

        <Route path="/terms" exact>
          <Terms />
        </Route>
        <Route path="/login" exact>
          <EmailAccountLoginBlock />
        </Route>
        <Route path="/signup" exact>
          <EmailAccountRegistration />
        </Route>
        <Route path="/about-us" exact>
          <ActivityLog />
        </Route>
        <Route path="/blog" exact>
          <Analytics3 />
        </Route>

        <Route path="/forgot-password" exact>
          <ForgotPassword />
        </Route>
        <Route path="/ForgotPasswordOTP" exact>
          <ForgotPasswordOTP />
        </Route>
        <Route path="/NewPassword" exact>
          <NewPassword />
        </Route>
        <Route path="/Contactus" exact>
          <Contactus />
        </Route>
        <Route path="/AddContactus" exact>
          <AddContactus />
        </Route>
        <Route path="/verticals" exact>
          <AccountScoreranking />
        </Route>
        <Route path="/privacy-policy" exact>
          <PrivacySettings />
        </Route>
        <Route exact path="/terms-and-conditions">
          <Terms />
        </Route>
        <Route path="/database" exact>
          <CandidateDatabase />
        </Route>
        <ProtectedRoute role="client" path="/dashboard" exact>
          <MerchantDashboard2 />
        </ProtectedRoute>
        <ProtectedRoute role="candidate" path="/profile-setting" exact>
          <CandidateDashboard />
        </ProtectedRoute>
        <ProtectedRoute role="candidate" path="/job-listing" exact>
          <JobListing2 />
        </ProtectedRoute>
        <Route path="/job-detail/:id" exact>
          <JobDescription />
        </Route>
        <ProtectedRoute role="client" path="/open-roles" exact>
          <OpenRoles />
        </ProtectedRoute>
        <ProtectedRoute role="client" path="/open-roles/role/:id" exact>
          <OpenRoles />
        </ProtectedRoute>
        <ProtectedRoute role="client" path="/closed-roles" exact>
          <CloseRole />
        </ProtectedRoute>
        <ProtectedRoute role="client" path="/closed-roles/role/:id" exact>
          <CloseRole />
        </ProtectedRoute>
        <ProtectedRoute role="client" path="/createRole" exact>
          <CreateRole />
        </ProtectedRoute>
        <ProtectedRoute role="client" path="/editRole/:id" exact>
          <CreateRole />
        </ProtectedRoute>
        <ProtectedRoute role="client" path="/candidate-scoring" exact>
          <Scoring />
        </ProtectedRoute>
        <ProtectedRoute role="client" path="/assessment-detail/:id" exact>
          <AssessmentDetail />
        </ProtectedRoute>
        <ProtectedRoute role="client" path="/email-notifications" exact>
          <EmailNotifications />
        </ProtectedRoute>
        {/* <ProtectedRoute role="candidate" path="/profile-setting/messages" exact>
          <ClientNotifications />
        </ProtectedRoute> */}
        <ProtectedRoute
          role="client"
          path="/open-roles/candidate-details"
          exact
        >
          <CandidateDetails />
        </ProtectedRoute>
        <ProtectedRoute
          role="client"
          path="/closed-roles/candidate-details"
          exact
        >
          <CandidateDetails />
        </ProtectedRoute>

        {/* New Routes Start */}
        <Route exact path="/database-list">
          <Database />
        </Route>
        <Route exact path="/team">
          <Teams />
        </Route>
        <Route exact path="/candidate-feedback/:id">
          <AfterInterviewFeedback />
        </Route>
        <Route exact path="/whatsapp/admin">
          <WhatsappAdmin />
        </Route>
        {/* New Routes End */}

        <ProtectedRoute path="/profile-setting/chats" role="candidate" exact>
          <EmailNotifications />
        </ProtectedRoute>

        <ProtectedRoute role="client" path="/clients-list" exact>
          <ClientsList />
        </ProtectedRoute>

        <Route path="*" exact>
          <Typography
            align="center"
            variant="h3"
            style={{
              transform: 'translate(-50%, -50%)',
              position: 'fixed',
              top: '50%',
              left: '50%'
            }}
          >
            404 | Route Not Found
          </Typography>
        </Route>
      </Switch>
      //  </Router>
    );
  }
}

RouteConfig.contextType = CandidateContext;

export default RouteConfig;
