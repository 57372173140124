Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TicketSystem";
exports.labelBodyText = "TicketSystem Body";

exports.btnExampleTitle = "CLICK ME";
exports.getClientsListAPiEndPoint = "bx_block_admin/admin_client_dashboard?page=";
exports.searchClientsAPiEndPoint = "bx_block_admin/client/search?query=";
// Customizable Area End