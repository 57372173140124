import { createTheme } from '@material-ui/core/styles';

const t: any = {
    palette: {
        primary: {
            main: "#24325f",
        },
        secondary: {
            main: "#a8d1e7",
        },
        info: {
            main: "#ffffff"
        },
        text: {
            primary: "#7d7c84",
            secondary: "#a2a1a6"
        },
    },
    typography: {
        allVariants: {
            fontFamily: "Poppins-Regular",
            textTransform: "none"
        },
        h2: {
            fontFamily: "Poppins-Medium"
        },
        h4: {
            fontFamily: "Poppins-SemiBold"
        },
        h5: {
            fontFamily: "Poppins-SemiBold"
        },
        body2: {
            fontFamily: "Poppins-SemiBold"
        },
        caption: {
            lineHeight: "1.5rem"
        },
        h6: {
            fontFamily: "Poppins-Regular",
            fontSize: "0.9rem"
        },
        overline: {
            fontFamily: "Roboto-Regular",
            fontSize: "0.9rem",
            lineHeight: "initial"
        },
    },
    overrides: {
        MuiButton: {
            root: {
                boxShadow: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: "none",
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                },
                borderRadius: 10,
                padding: "0.8rem 2rem"
            },
            contained: {
                boxShadow: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: "none",
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                }
            }
        },
        MuiCard: {
            root: {
                borderRadius: "20px",
                boxShadow: "none"
            }
        }
    }
};

let theme: any = createTheme(t)
export default theme;