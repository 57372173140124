import { Box, Modal, Typography } from "@material-ui/core";
import React from "react";
import RoleMangementController, { Props } from "./RoleMangementController.web";
import StepIJD from "./StepIJD.web";
import StepIIJD from "./StepIIJD.web";
import StepIIIJD from "./StepIIIJD.web";
import StepIVJD from "./StepIVJD.web";
import CloseOutlined from '@material-ui/icons/CloseOutlined'
class JobDescriptionParent extends RoleMangementController {
    constructor(props: Props) {
        super(props);
    }
    async componentDidMount() {
        this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: this.props.clientID || "" }, () => {
            this.getJDTemaplateData()
        });

        this.setState({ jdFormData: this.props.onEditJobDescriptionData })
        window.addEventListener('resize', this.checkWindowResize);
        window.scrollTo(0, 0);
    }
    async componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowResize);
    }
    render() {
        return (<Modal
            open={this.props.jobDescription}
            onClose={(event, reason) => reason != 'backdropClick' && this.props.onCloseJobDescription}
            aria-labelledby="job-description-title"
            aria-describedby="job-description"
            className="job-description-modal"
        >
            <Box className="job-descriptio-box">
                {this.state.jdLoader ? (<>
                    <div className="close-jd-div" onClick={this.props.onCloseJobDescription}><CloseOutlined /></div>

                    {/* Step1 */}
                    {this.state.currentStep == 0 && (
                        //@ts-ignore
                        <StepIJD
                            jdHeadingValue={this.props.jdHeadingValue}
                            jobDescriptionData={this.state.jobDescriptionData}
                            setJDValues={this.handleJDFormData}
                            currentStep={this.state.currentStep}
                            nextStep={this.handleNextStep}
                            previousStep={this.props.onCloseJobDescription}
                            fieldData={this.state.jdFormData}
                            windowWidth={this.state.windowWidth}
                        />)}

                    {/* Step2 */}
                    {this.state.currentStep == 1 && (
                        //@ts-ignore
                        <StepIIJD
                            jdHeadingValue={this.props.jdHeadingValue}
                            jobDescriptionData={this.state.jobDescriptionData}
                            setJDValues={this.handleJDFormData}
                            currentStep={this.state.currentStep}
                            nextStep={this.handleNextStep}
                            previousStep={this.handlePreviousStep}
                            fieldData={this.state.jdFormData}
                            windowWidth={this.state.windowWidth}
                        />)}

                    {/* Step3 */}
                    {this.state.currentStep == 2 && (
                        //@ts-ignore
                        <StepIIIJD
                            jdHeadingValue={this.props.jdHeadingValue}
                            jobDescriptionData={this.state.jobDescriptionData}
                            setJDValues={this.handleJDFormData}
                            currentStep={this.state.currentStep}
                            nextStep={this.handleNextStep}
                            previousStep={this.handlePreviousStep}
                            fieldData={this.state.jdFormData}
                            windowWidth={this.state.windowWidth}
                        />)}

                    {/* Step4 */}
                    {this.state.currentStep == 3 && (
                        //@ts-ignore
                        <StepIVJD
                            jdHeadingValue={this.props.jdHeadingValue}
                            jobDescriptionData={this.state.jobDescriptionData}
                            setJDValues={this.handleJDFormData}
                            currentStep={this.state.currentStep}
                            submitFormData={this.props.handleSendJDData}
                            previousStep={this.handlePreviousStep}
                            fieldData={this.state.jdFormData}
                            windowWidth={this.state.windowWidth}
                        />)}

                </>) : <div className="jd-loader-wrap"><div className="jd-loader"></div></div>}
            </Box>
        </Modal>);
    }
}

export default JobDescriptionParent;