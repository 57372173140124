import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location?: any;
  jobRoles?: any;
  handleStatus?: (data: { role_id: number, profile_id: number, isAccepted: boolean }) => void;
  handleSendStatus?: (data: { role_id: number, profile_id: number, isAccepted: boolean }, position: number | string, applied_candidates: number | string) => void;
  handleViewAll: (role_id?: number) => void;
  isExpand: boolean;
  role?: any;
  roleListScroll?: (data: string) => void;
  windowWidth?: any;
  hasMoreRoleData?: boolean;
  infiniteRoleLoading?: boolean;
  hasMoreCandidateData?: boolean;
  infiniteCandidateLoading?: boolean;
  candidateListScroll?: (data: string) => void;
  startLoading: () => void;
  createCoversation: (candidate_email: string) => void;
  clientIDLink?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchText: string;
  snackBar: {
    show: boolean;
    message: string;
    type: any
  };
  rolesData: any;
  filterRolesData: any;
  expandRole: any;
  view: "grid" | "list";
  sort: "asc" | "default";
  viewAll: { isActive: boolean, role_id?: number | null, hasQueryParams?: boolean };
  showConfirmToCloseRoleModal: boolean;
  confirmType: string | undefined;
  profileDataToConfirm: any;
  roleToBeCloseType: string;
  totalReceivedRoles: number;
  rolesPathParam: RoleParams;
  infiniteRoleLoading: boolean;
  hasMoreRole: boolean;
  windowWidth: string | number;
  infiniteCandidateLoading: boolean;
  hasMoreCandidate: boolean;
  totalOpenRoles: number;
  isLoading: boolean;
  showConfirmToDeleteRoleModal: boolean;
  updateingStatusRoleID: any;
  userType: string;
  clientID: number | string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

interface RoleParams {
  candidate_page: number;
  candidate_per_page: number;
  role_page: number;
  role_per_page: number;
  is_closed: boolean;
  role_order?: string | number;
  role_id?: number | string;
  search?: number | string;
  candidate_order?: string | number;
}
export default class Location4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllRolesCallId: string;
  searchRoleCallId: string;
  acceptRejectRoleCallId: string;

  // Customizable Area Start
  fetchCandidatesCallId: string;
  closeJobRoleCallId: string;
  roleObserver: any;
  lastRoleItem: (node: any) => void;
  searchTimer: any;
  createConversationAPIId: string;
  deleteJobRoleCallId: string;
  clientIDLink: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.roleObserver = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchText: "",
      snackBar: {
        show: false,
        message: "",
        type: "",
      },
      rolesData: [],
      filterRolesData: {},
      expandRole: null,
      view: "grid",
      sort: "default",
      viewAll: { isActive: false, role_id: null, hasQueryParams: false },
      showConfirmToCloseRoleModal: false,
      confirmType: "",
      profileDataToConfirm: {},
      roleToBeCloseType: "",
      totalReceivedRoles: 0,
      rolesPathParam: {
        candidate_page: 1,
        candidate_per_page: 3,
        role_page: 1,
        role_per_page: 10,
        is_closed: false,
      },
      infiniteRoleLoading: false,
      hasMoreRole: true,
      windowWidth: window.innerWidth,
      infiniteCandidateLoading: false,
      hasMoreCandidate: true,
      totalOpenRoles: 0,
      isLoading: false,
      showConfirmToDeleteRoleModal: false,
      updateingStatusRoleID: null,
      userType: sessionStorage.getItem("user_role") || "",
      clientID: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.lastRoleItem = (node: any) => {
      if (this.roleObserver.current) this.roleObserver.current.disconnect();

      this.roleObserver.current = new IntersectionObserver(entries => {
        if (this.props.infiniteRoleLoading) return;
        if (entries[0].isIntersecting && this.props.hasMoreRoleData && !this.props.match?.params?.id) {
          this.props.roleListScroll?.("listScrolled")
        }
      });
      if (node) this.roleObserver.current.observe(node);
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        // conversation creation
        if (apiRequestCallId === this.createConversationAPIId) {
          if (responseJson) {
            if (responseJson?.table?.errors.includes("already")) {
              this.openSnackBarHandler("success", "Redirecting to chatting.....");
            } else {
              this.openSnackBarHandler("success", "Conversation Created, Redirecing.....");
            }
            let url: any = "/email-notifications?email=" + sessionStorage.getItem("candidate_email");
            if (this.state.userType == "admin" && this.state.clientID) {
              url += "&clientId=" + this.state.clientID;
            }
            setTimeout(() => {
              this.props.history.push(
                url
              );
            }, 1000);
          } else {
            this.openSnackBarHandler("error", "Someting went wrong ....!");
          }
        }
        //Get All Open Role API
        if (
          apiRequestCallId === this.getAllRolesCallId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.stopLoading();
            if (this.props.match?.params?.id) {
              let candidates: any = [];
              if (this.state.rolesData?.[0]?.attributes?.candidate.length > 0) {
                candidates = [...this.state.rolesData[0]?.attributes?.candidate,
                ...responseJson?.applied_candidates?.data?.attributes?.candidate
                ];
              } else {
                candidates = responseJson?.applied_candidates?.data?.attributes?.candidate;
              }
              let candidateData = {
                ...responseJson?.applied_candidates?.data,
                attributes: { ...responseJson?.applied_candidates?.data?.attributes, candidate: candidates }
              };
              this.setState({
                rolesData: [candidateData],
              });
              this.setState({
                totalReceivedRoles: responseJson?.total_roles,
                infiniteRoleLoading: false,
                hasMoreRole: false,
                infiniteCandidateLoading: false,
                hasMoreCandidate: responseJson?.applied_candidates?.data?.attributes?.candidate?.length > 0,
              });
            } else {
              this.setState((prev) => ({
                rolesData: [...prev.rolesData, ...responseJson?.applied_candidates?.data],
              }))
              this.setState({
                totalReceivedRoles: responseJson?.total_roles,
                infiniteRoleLoading: false,
                hasMoreRole: responseJson?.applied_candidates?.data?.length > 0,
                hasMoreCandidate: false,
                infiniteCandidateLoading: false
              })
            }
          } else {
            this.parseApiCatchErrorResponse(errorReponse);
            this.setState({
              totalReceivedRoles: 0,
              infiniteRoleLoading: false,
              hasMoreRole: false
            })
            const errors = responseJson?.errors;
            if (errors?.name?.[0]) {
              this.openSnackBarHandler('error', errors?.name?.[0]);
            } else if (errors?.[0]) {
              this.openSnackBarHandler('error', errors?.[0]);
              setTimeout(() => { this.props.history.goBack() }, 3000)
            }
          }
        }
        //Accept or Reject candiadte
        if (apiRequestCallId === this.acceptRejectRoleCallId) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.setState((prev) => {
              let allRoles = [...prev.rolesData];
              allRoles = allRoles.map((item: any) => {
                let candidateData = item?.attributes?.candidate || [];
                candidateData = candidateData.map((candidate: any) => {
                  return ((candidate.profile_id == responseJson.data.attributes.profile_id
                    && item.id == this.state.updateingStatusRoleID
                  ) ?
                    { ...candidate, status: responseJson.data.attributes.status }
                    : { ...candidate })
                });
                return { ...item, attributes: { ...item.attributes, candidate: candidateData } };
              });
              return ({ rolesData: [...allRoles], updateingStatusRoleID: null });
            });
            if (responseJson.data.attributes.status === "accepted") {
              this.openSnackBarHandler('success', 'Candidate Accepted');
              if (this.state.roleToBeCloseType == "acceptAndClose") {
                this.closeJobRole(this.state.profileDataToConfirm?.role_id);
              } else if (this.state.roleToBeCloseType == "acceptDontClose") {
                this.setState({ showConfirmToCloseRoleModal: false });
              }
            } else {
              this.openSnackBarHandler('success', 'Candidate Rejected');
            }
          } else {
            this.parseApiCatchErrorResponse(errorReponse);
            const errors = responseJson?.errors;
            if (errors?.name?.[0]) {
              this.openSnackBarHandler('error', errors?.name?.[0]);
            } else if (errors?.trim()?.length > 0) {
              this.openSnackBarHandler('error', errors);

            }
          }
        }

        //Close Role
        if (apiRequestCallId === this.closeJobRoleCallId) {
          this.setState({ showConfirmToDeleteRoleModal: false });
          if (responseJson !== undefined && !responseJson.errors) {
            this.setState({ showConfirmToCloseRoleModal: false });
            this.openSnackBarHandler('success', 'Role closed successfully');
            setTimeout(() => { this.props.history.push(`/closed-roles${this.clientIDLink ? "?" + this.clientIDLink : ""}`) }, 2000)

          } else {
            const errors = responseJson?.errors;
            if (errors?.name?.[0]) {
              this.openSnackBarHandler('error', errors?.name?.[0]);
            } else if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }

        //Delete Role
        if (apiRequestCallId === this.deleteJobRoleCallId) {
          this.setState({ showConfirmToDeleteRoleModal: false });
          if (responseJson !== undefined && !responseJson.error && !responseJson.errors) {
            this.openSnackBarHandler('success', 'Role deleted successfully');
            setTimeout(() => { this.props.history.goBack() }, 2000);

          } else {
            const errors = responseJson?.error;
            if (errors) {
              this.openSnackBarHandler('error', 'Role delete unsuccessfully');
            }
          }
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start



  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };


  // Customizable Area End

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  startLoading = () => {
    this.setState({ isLoading: true });
  }

  stopLoading = () => {
    this.setState({ isLoading: false });
  }

  // get All available roles
  getAllRoles = (params: RoleParams): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllRolesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllRolesAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(params)
    )
    this.setState({
      ...this.state,
      infiniteRoleLoading: true
    })

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To search role
  handleSearchRole = (searchTerm: string) => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchRoleCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchRoleAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ role_name: searchTerm })
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To accept or reject a role
  handleAcceptRejectRole = (data: { role_id: number, profile_id: number, isAccepted: boolean }) => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };
    let httpBody: any = { role_id: data?.role_id, profile_id: data?.profile_id, accepted: data?.isAccepted };

    if (this.state.userType == 'admin' && this.state.clientID) {
      httpBody['client_id'] = this.state.clientID;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptRejectRoleCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptRejectRoleAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.setState({ updateingStatusRoleID: data?.role_id })
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Close Role
  closeJobRole = (role_id: string | number) => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    let httpBody: any = { role_id: role_id, is_closed: true };
    if (this.state.userType == 'admin' && this.state.clientID) {
      httpBody['client_id'] = this.state.clientID;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.closeJobRoleCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.closeJobRoleApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To handle close role pop up
  handleCloseConfirmToCloseRole = (type?: string) => {
    this.setState((prev: any) => ({
      showConfirmToCloseRoleModal: !prev.showConfirmToCloseRoleModal,
      confirmType: type
    }))
  }

  // To handle if to close a role or not
  handleToCloseRoleOrNot = (data: any, toCloseType: string) => {
    this.setState({ roleToBeCloseType: toCloseType }, () => {
      if (toCloseType == "acceptDontClose" || toCloseType == "acceptAndClose") {
        this.handleAcceptRejectRole(data);
      } else {
        this.closeJobRole(data?.role_id)
      }
    });


  }

  // To set parameter based on URL to fetch roles
  checkURLParamsAndId = () => {
    // For Role and Param
    if (this.props.match?.params?.id && (this.props.location?.search)) {
      this.setState({
        viewAll: { isActive: true, role_id: this.props.match?.params?.id, hasQueryParams: true }
      }, () => this.setRoleParameters());
    } else if (!this.props.match?.params?.id && (this.props.location?.search)) {
      this.setState({
        viewAll: { isActive: false, role_id: null, hasQueryParams: true }
      }, () => this.setRoleParameters());
    } else if (this.props.match?.params?.id && !(this.props.location?.search)) {
      this.setState({
        viewAll: { isActive: true, role_id: this.props.match?.params?.id, hasQueryParams: false }
      }, () => this.setRoleParameters());
    } else if (!this.props.match?.params?.id && !(this.props.location?.search)) {
      this.setState({
        viewAll: { isActive: false, role_id: null, hasQueryParams: false }
      }, () => this.setRoleParameters());
    }
  }

  setRoleParameters = () => {
    let params: any = {
      candidate_page: 1,
      candidate_per_page: 3,
      role_page: 1,
      role_per_page: 10,
      is_closed: false,
    }

    //For view type    
    if (this.state.viewAll.hasQueryParams) {
      if (this.props.location.search.includes("view=list")) {
        this.setState({ view: "list" });
      } else {
        this.setState({ view: "grid" });
      }
      if (this.props.location.search.includes("filter=asc")) {
        if (this.state.viewAll?.role_id) {
          params['candidate_order'] = "first_name ASC";
        } else {
          params['role_order'] = "name ASC";
        }
        this.setState({ sort: "asc" });
      } else {
        this.setState({ sort: "default" });
      }
    } else {
      delete params['role_order'];
      delete params['candidate_order'];
    }
    //For sort

    if (this.state.viewAll?.role_id) {
      params['role_id'] = this.props.match?.params?.id;
      params['candidate_per_page'] = 10;
      params['role_per_page'] = 1;
    } else {
      delete params['role_id'];
    }

    //For admin
    if (this.state.userType == 'admin') {
      if (this.state.clientID) {
        params['client_id'] = this.state.clientID
      }
    }

    this.setState({ rolesPathParam: params, rolesData: [] }, () => this.getAllRoles(this.state.rolesPathParam));
  }

  // To filter roles
  filterRoles = (event: any) => {
    this.props.history.push({
      search: `?view=${this.state.view}&filter=${event.target.value}${this.clientIDLink ? "&" + this.clientIDLink : ""}`
    });

    let param = { ...this.state.rolesPathParam };
    let isRoleDetail = this.props.location?.pathname?.includes("/role/") ? true : false;
    if (isRoleDetail) {
      delete param.role_order;
      if (event.target.value === "asc") {
        param['candidate_order'] = "first_name ASC";
      } else if (param?.candidate_order) {
        delete param.candidate_order;
      }
      param['candidate_page'] = 1;
    } else {
      delete param.candidate_order;
      if (event.target.value === "asc") {
        param['role_order'] = "name ASC";
      } else if (param?.role_order) {
        delete param.role_order;
      }
      param['role_page'] = 1;
    }

    this.setState({
      rolesPathParam: param, rolesData: [], sort: event.target.value
    }, () => this.getAllRoles(this.state.rolesPathParam));
  }

  // To handle infinite scroll base don view
  handleListInfiniteScroll = (data: string) => {
    if (data == "listScrolled") {
      this.setState(prev => ({ rolesPathParam: { ...prev.rolesPathParam, role_page: prev.rolesPathParam.role_page + 1 } }), () => {
        this.getAllRoles(this.state.rolesPathParam);
      });
    } else if (data == "candidateScrolled") {
      this.setState(prev => ({ rolesPathParam: { ...prev.rolesPathParam, candidate_page: prev.rolesPathParam.candidate_page + 1 } }), () => {
        this.getAllRoles(this.state.rolesPathParam);
      });
    }
  }
  checkWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  // To search roles
  searchRoles = (keyword: string) => {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      let params = {
        ...this.state.rolesPathParam,
      };

      if (keyword?.trim()?.length > 0) {
        params['search'] = keyword.trim();
      } else {
        delete params['search'];
      }

      if (this.props.match?.params?.id) {
        params['candidate_page'] = 1;
      } else {
        params['role_page'] = 1;
      }
      this.setState({ rolesData: [], rolesPathParam: params }, () => this.getAllRoles(this.state.rolesPathParam));

    }, 1000);
    this.startLoading();
  }

  // Chat Integration
  createCoversation = (candidate_email: string) => {
    this.openSnackBarHandler("success", "Search in the space...");
    if (candidate_email) {
      sessionStorage.setItem("candidate_email", candidate_email);
      const header = {
        "Content-Type": configJSON.jsonApiContentType,
        'token': sessionStorage.getItem('Token')
      };

      let httpBody: any = { "email": candidate_email }

      if (this.state.userType == "admin" && this.state.clientID) {
        httpBody['client_id'] = this.state.clientID;
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createConversationAPIId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_twilio/create_conversations"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.openSnackBarHandler("info", "Email not found ...");
    }
  }

  // To handle delete role popup
  handleCloseConfirmToDeleteRole = (type?: string) => {
    this.setState((prev: any) => ({
      showConfirmToDeleteRoleModal: !prev.showConfirmToDeleteRoleModal,
      confirmType: type
    }))
  }
  // Delete Role
  handleToDeleteJobRole = (role_id: string | number) => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteJobRoleCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteJobRoleApiEndPoint + role_id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    if (this.state.userType == 'admin' && this.state.clientID) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ client_id: this.state.clientID })
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  initialConfig() {
    let clientID = +this.props.location?.search?.split("clientId=")[1];
    this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID }, () => {
      if (this.state.userType == 'admin') {
        if (clientID) {
          this.checkURLParamsAndId();
        } else {
          this.props.history.push(`/clients-list`);
        }
      } else if (this.state.userType == 'client') {
        this.checkURLParamsAndId();
      }
      this.clientIDLink = this.state.userType == "admin" && this.state.clientID ? "clientId=" + this.state.clientID : "";
    });
  }

  // Customizable Area End
}
