// Customizable Area Start
import { Box, CircularProgress, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import CandidateDatabaseController, { Props } from './CandidateDatabaseController.web';
import CatalogueStyles from "./Styles.module.scss";
import Filter from './filter.web';
import CandidateCard from "../../JobListing2/src/common/CandidateCard.web";
import DetailCandidateParent from './DetailCandidateParent.web';
import Pagination from './Pagination.web';
import Carousel from 'react-elastic-carousel';
import { ArrowLeftSharp, ArrowRightSharp } from '@material-ui/icons';
function WarningButton() {
    // return React.createElement(CustomButton, {color: 'red'}, null);
    return <>Next</>;
}
const breakPoints = [
    { width: 600, itemsToShow: 1 },
    { width: 900, itemsToShow: 2 },
    { width: 1000, itemsToShow: 3 },
];
interface ArrowType {
    type: any;
    onClick: any; isEdge: any;
}

const carouselStyle = (theme: any) => ({
    carousel: {
        "& button": {
            backgroundColor: '#FFF',
        },

        "& .rec-arrow": {
            width: "2em",
            height: "2em",
            minWidth: "2em",
            lineHeight: "1em",
            fontSize: "0.8em",
            [theme.breakpoints.down('xs')]: {
                display: "none"
            }
        },
    },
});

class CandidateDatabase extends CandidateDatabaseController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): Promise<void> {
        window.addEventListener('resize', this.checkWindowResize)
        window.scrollTo(0, 0);
        this.setState({ loading: true }, () => this.elasticSearch(0));

        return super.componentDidMount();
    }

    async componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowResize);
    }
    render() {
        const { classes } = this.props;
        return (
            <Box className={CatalogueStyles.continerWrapper}>
                <Grid container spacing={1} >
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className={CatalogueStyles.containerItems}>
                       {/* Database filter component */}
                        <Filter onSearch={(state) => {
                            let allPrams: any = {
                                location: state.location,
                                title: state.title,
                                company: state.company,
                                keywords: state.keyword_OR_name,
                                current: state.cp,
                                watched: state.status
                            }
                            if (state.years_of_exp_from) {
                                allPrams['experience'] = {
                                    started: state.years_of_exp_from,
                                    ended: state.years_of_exp_to
                                }
                            }
                            this.setState({
                                loading: true,
                                onPageLoad: true,
                                eSParams: {
                                    ...allPrams
                                }
                            }, () => this.elasticSearch(0));
                        }}
                            locationList={this.state.locationList}
                            companyList={this.state.companyList}
                            titleList={this.state.titleList}
                            keywordsList={this.state.keywordsList}
                            onSuggessionSearch={this.getFilterSuggessionList}
                            onReset={() => {
                                this.setState({
                                    loading: true,
                                    onPageLoad: true,
                                    eSParams: {}
                                }, () => this.elasticSearch(0))
                            }}
                        />
                    </Grid>
                    {!this.state.loading ?
                        this.state.databaseList && this.state.databaseList.length > 0 ? <>
                            <Grid item xl={4} lg={4} md={3} sm={12} xs={12} className={CatalogueStyles.containerItemsSecond}>
                               {/* Candidate card Pagination handler */}
                                <Pagination totalPages={this.state.totalPages} onPageNumSelect={(page: any) => this.elasticSearch(page - 1)} />
                               {/* Candidate cards for screen size >= 960 */}
                                {this.state.windowWidth >= 960 ?
                                    <Box className={CatalogueStyles.candidatecardListWrap}>
                                        {this.state.databaseList?.map((candidate: any, index: any) => {
                                            return (
                                                <CandidateCard
                                                    key={index}
                                                    candidateData={candidate.attributes}
                                                    isLoggedIn={false}
                                                    marginBottom={2}
                                                    showEmptyImg={false}
                                                    isSelectedCan={candidate?.attributes?.temporary_user_profile?.id == this.state.selectedDBData?.temporary_user_profile?.id}
                                                    sendSelectedCandidateData={(data) => {
                                                        window.scroll(0, 0);
                                                        let currentList = [...this.state.databaseList];
                                                        let newList = currentList.map(item => candidate?.id == item?.id ? { ...item, attributes: { ...item.attributes, watched_records: true } } : { ...item });
                                                        this.setState({ selectedDBData: data, databaseList: [...newList] });
                                                        this.watchedCandidateData(candidate?.id);
                                                    }} />
                                            );
                                        })}
                                    </Box>
                                    :
                                    (<Carousel isRTL={false} breakPoints={breakPoints} className={classes.carousel}>
                                         {/* Candidate cards in Catousel /Slider for screen size < 960 */}
                                        {
                                            this.state.databaseList?.map((candidate: any, index: any) => {
                                                return (
                                                    <CandidateCard
                                                        key={index}
                                                        candidateData={candidate.attributes}
                                                        isLoggedIn={false}
                                                        marginBottom={2}
                                                        showEmptyImg={false}
                                                        sendSelectedCandidateData={(data) => {
                                                            let currentList = [...this.state.databaseList];
                                                            let newList = currentList.map(item => candidate?.id == item?.id ? { ...item, attributes: { ...item.attributes, watched_records: true } } : { ...item });
                                                            this.setState({ selectedDBData: data, databaseList: [...newList] });
                                                            this.watchedCandidateData(candidate?.id);
                                                        }} />
                                                );
                                            })
                                        }
                                    </Carousel>)
                                }

                            </Grid>
                            <Grid item xl={5} lg={5} md={6} sm={12} xs={12} className={CatalogueStyles.containerItems}>
                                {this.state.selectedDBData ? <DetailCandidateParent candidateData={this.state.selectedDBData} /> : ""}
                            </Grid></>
                            : <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                                <Box className={CatalogueStyles.noDataFound}>
                                    <Typography variant='h5'>No Data Found</Typography>
                                </Box>
                            </Grid> :
                        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                            <Box className={CatalogueStyles.noDataFound} height='100%'>
                                <Box position='relative'>
                                    <CircularProgress
                                        size={24}
                                        style={{
                                            color: "#24325f",
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        );
    }
}

export default withStyles(carouselStyle)(CandidateDatabase);
// Customizable Area End