import React, { FC } from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import JobsCards from '../../../components/src/JobsCards';
import { JobProps } from './mock/dummyJobs';
import Pagination from '@material-ui/lab/Pagination';

interface JobListProps {
    heading: string;
    jobs: any;
    type?: string;
    perPage?: number;
    noJobText: string;
    isAppliedForJob: (data: any) => void;
    handleFavorite: (data: { role_id: number, favourite: boolean }) => void;
    handlePagination?: (data: { type: string, page: number, perPage: number }) => void;
    sendEmailToParent: (email: string) => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        "&:not(:last-child)": {
            marginBottom: "2rem",
        },
    },

    heading: {
        marginBottom: "1.5rem",
    },

    jobPagination: {
        "& nav": {
            display: "flex",
            justifyContent: "end",

            [theme.breakpoints.down("xs")]: {
                '& ul li': {
                    marginBottom: '.5rem',
                },

            },
            [theme.breakpoints.down("sm")]: {
                '& .MuiPaginationItem-page:hover': {
                    backgroundColor: '#B5B5BE!important'
                },
                '& .MuiPaginationItem-page': {
                    '&.Mui-selected:hover': {
                        backgroundColor: '#24325F!important',
                    },
                    '&.Mui-selected.Mui-focusVisible': {
                        backgroundColor: '#24325F!important',
                    }
                },

            },

            '& ul li button': {
                backgroundColor: '#B5B5BE',
                color: "#FAFAFB",
                borderColor: '#B5B5BE',
            },

            '& ul li .Mui-selected': {
                backgroundColor: '#24325F',
                color: "#FAFAFB",
                borderColor: '#24325F',
            },
        },
    },

    noDataCard: {
        width: "100%",
        padding: "2rem",
        background: " #fff",
        borderRadius: "1rem",
        fontSize: "1rem",
        fontFamily: 'Poppins-Regular',
        lineHeight: "1.334",
        letterSpacing: "0em",
        textTransform: "none",
        marginBottom: "2em"
    },

    jobCard: {
        ['@media (min-width: 760px) and (max-width: 1280px)']: {
            maxWidth: "50%",
            flexBasis: "50%",
        }
    }
}));

const JobList: FC<JobListProps> = (
    { heading, jobs, type, perPage, noJobText, isAppliedForJob, handleFavorite, handlePagination, sendEmailToParent }
) => {
    const classes = useStyles();
    let pages = 0;

    if (perPage) {
        if (jobs?.total < perPage) {
            pages = 1;
        } else if (jobs?.total % perPage != 0) {
            pages = ~~(jobs?.total / perPage) + 1;
        } else {
            pages = ~~(jobs?.total / perPage);
        }
    }

    return (
        <>

            <Grid container className={classes.container}>
                <Typography variant='h5' className={classes.heading}>{heading}</Typography>

                {jobs?.total > 0 ?
                    <Grid container justifyContent="flex-start" spacing={2}>
                        {jobs?.roles?.data && jobs?.roles?.data.map((job: JobProps) => (
                            <Grid item lg={4} xs={12} key={job.id} className={classes.jobCard}>
                                <JobsCards
                                    job={job}
                                    isAppliedForJob={(data: any) => { isAppliedForJob(data) }}
                                    showSalary={true}
                                    handleFavorite={(data: any) => { handleFavorite(data) }}
                                    sendEmail={(email: string) => sendEmailToParent(email)}
                                />
                            </Grid>
                        ))}
                    </Grid> :
                    <Box className={classes.noDataCard}>{noJobText}</Box>
                }
            </Grid>
            {handlePagination && jobs?.total > 0 &&
                <Box className={classes.jobPagination}>
                    <Pagination
                        count={pages}
                        variant="outlined"
                        shape="rounded"
                        onChange={(event: any, page: number) => {
                            if (type && perPage)
                                handlePagination({ type: type, page: page, perPage: perPage })
                        }} />
                </Box>
            }
        </>
    );
};

export default JobList;
