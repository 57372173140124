import React, { FC, Fragment, useCallback, useEffect, useRef } from "react";
import { Box, Grid, Theme, Typography, withStyles } from "@material-ui/core";
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import ShimmerLoading from "../../../components/src/ShimmerLoading";
import { NoMsgIcon } from "./assets";
import styles from "./Styles.module.scss";

const muiStyles = (theme: Theme) => ({
    messages: {
        padding: "1rem",
        overflowY: "scroll",
        height: "calc(100% - 11.625rem)",
        overflowX: 'hidden',
    },

    dividerWithText: {
        width: "100%",
        textAlign: "center",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        lineHeight: "0.1em",
        margin: "1.7rem 0",
        color: "#8f8f95",

        "& span": {
            background: "#fff",
            padding: "0 10px",
        },
    },

    message: {
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        padding: ".2rem 0.7rem 0",
        paddingRight: 0,
        borderRadius: "1rem !important",

        "& p": {
            fontSize: ".8rem",
            color: "#44444F",
            marginRight: ".5rem",
            alignSelf: "flex-end",
        },

        "& img": {
            maxWidth: "30rem",
            maxHeight: "10rem",
            objectFit: "cover",
            borderRadius: "1rem",
            cursor: "pointer",
            transition: "all .3s ease-in-out",

            "&:hover": {
                opacity: 0.8,
                transform: "scale(1.04)",
            },
        },

        "& a": {
            textDecoration: "none",
            alignSelf: "flex-end",
        }
    },

    linkPreview: {
        fontFamily: "Roboto, sans-serif",

        "& h3": {
            fontSize: ".8rem",
        },

        "& span": {
            fontSize: ".7rem"
        }
    }
});

interface SingleMessageProps {
    state: {
        body: string;
        timestamp: Date;
        type: "media" | "text";
    };
    author: string;
    messageStyles: any;
    sid: string;
}

interface MessagesProps {
    messages: SingleMessageProps[];
    classes?: any;
    isLoading: boolean;
}

interface MessageProps {
    body: string;
    time: string;
    author: string;
    type: "media" | "text";
    messageStyles: any;
    linkPreview: any;
    sid: string;
    rawMsg: any;
    elementRef?: any;
}

interface FormatMsgType {
    date: string;
    messages: { sid: string; body: string; time: string, author: string, type: "media" | "text" }[];
}

export const formatTime = (date: Date) => {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
};

const formatDate = (date: Date) => {
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
};


const formatMsg = (allMessages: MessagesProps["messages"]) => {
    const newFormatMsgData: FormatMsgType[] = [];

    if (allMessages) {
        allMessages.forEach((msg) => {
            const date = formatDate(msg.state.timestamp);

            const res = newFormatMsgData.find((msg) => msg.date === date);
            const formatNewMsg = {
                sid: msg.sid,
                body: msg.state.body,
                time: formatTime(msg.state.timestamp),
                author: msg.author,
                type: msg.state.type
            };

            if (res) {
                res.messages.push(formatNewMsg);
            } else {
                newFormatMsgData.push({
                    date,
                    messages: [formatNewMsg]
                });
            }
        });
    }
    return newFormatMsgData;
}

const FallBackComponent = () => {
    return (
        <Box>
            <Typography variant="h6">
                Message Can't be load.</Typography>
        </Box >
    )
}

const LinkShow = ({ url, linkPreview }: { url: string, linkPreview: any }) => {
    return (
        <Box className={linkPreview}>
            <LinkPreview
                url={url}
                height="15rem"
                fallback={<FallBackComponent />}
            />
        </Box>
    );
};

interface MLEProps {
    messageStyles: any;
}

const MsgLoadingEffets: FC<MLEProps> = ({ messageStyles }) => {
    return (
        <Box>
            {Array(10).fill(0).map((_, index) => {
                return (
                    <Grid
                        item
                        className={messageStyles}
                        style={{
                            marginLeft: index % 2 ? "auto" : 0,
                            maxWidth: "50%",
                        }}
                        key={index}
                    >
                        <ShimmerLoading height="1.5rem" numberOfShimmer={1} />
                    </Grid>
                );
            })}
        </Box>
    )
}

class Message extends React.Component<MessageProps> {

    state = {
        hasMedia: this.props.type === "media",
        mediaDownloadFailed: false,
        mediaUrl: null,
        isMediaLoading: false,
    };

    componentDidMount = async () => {
        this.setState({
            ...this.state,
            type: (await this.props.rawMsg.getParticipant()).type,
        });
        if (this.state.hasMedia) {
            this.setState({ isMediaLoading: true });
            this.props.rawMsg.media
                .getContentTemporaryUrl()
                .then((url: any) => {
                    this.setState({ mediaUrl: url });
                })
                .catch((e: any) => this.setState({ mediaDownloadFailed: true }))
                .finally(() => this.setState({ isMediaLoading: false }));
        }
        // (document as any)
        //     .getElementById(this.props.sid)
        //     .scrollIntoView({ behavior: "auto" });
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        // (document as any)
        //     .getElementById(this.props.sid)
        //     .scrollIntoView({ behavior: "auto" });
    }


    render(): React.ReactNode {
        const { body, time, author, messageStyles, linkPreview } = this.props;
        let isImg, isLink;

        if (!this.state.hasMedia && body) {
            isImg = body.includes("https://") &&
                (body.includes(".jpg") || body.includes("image") || body.includes(".png") || body.includes(".jpeg") || body.includes(".gif"));
            isLink = body.includes("https://") && !isImg;
        }
        const backgroundColor = author === sessionStorage.getItem("email")
            ? "#D3E7F3"
            : "#f3f3f4";

        return (
            <Grid
                id={this.props.sid}
                item
                className={messageStyles}
                style={{
                    marginLeft:
                        author === sessionStorage.getItem("email") ? "auto" : 0,
                    background: isImg || isLink || this.state.hasMedia ? "#fff" : backgroundColor,
                    maxWidth: isImg || isLink || this.state.hasMedia ? "37%" : "40%",
                }}
                ref={this.props.elementRef}
            >
                {isImg && <a target="_blank" href={body}><img src={body} alt="Invalid Image URL" /></a>}
                {this.state.hasMedia &&
                    this.state.mediaUrl &&
                    <a target="_blank" href={this.state.mediaUrl || ''}>
                        <img src={this.state.mediaUrl || ""} alt="Invalid Image URL" />
                    </a>
                }
                {this.state.isMediaLoading && !this.state.mediaUrl &&
                    <ShimmerLoading height="15rem" width="15rem" numberOfShimmer={1} />}
                {!isImg && isLink && body && <LinkShow url={body} linkPreview={linkPreview} />}
                {!isImg && !isLink && body && <Typography variant="h6">{body}</Typography>}
                <Typography variant="subtitle1" component="p">
                    {time}
                </Typography>
            </Grid>
        );
    }
}

interface RefObject {
    disconnect: () => void;
    observe: (node: any) => void;
}

const Messages: React.FC<MessagesProps> = ({ messages, classes, isLoading }) => {
    let lastMesgRef = useRef<HTMLDivElement>();
    const observer = useRef<RefObject>();

    const loadMessages = () => {
        console.log("strarting loading messages .....");
    }

    const firstMsgRef = useCallback(
        (node: any) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) loadMessages();
            });
            if (node) observer.current.observe(node);
        },
        [loadMessages]
    );

    const executeScroll = () => {
        if (lastMesgRef && lastMesgRef.current) {
            lastMesgRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }

    useEffect(() => {
        executeScroll();
    })

    return (
        <Grid className={`${classes.messages} ${styles.messages}`}>

            {isLoading && <MsgLoadingEffets messageStyles={classes.message} />}

            {!isLoading && messages.length === 0 &&
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    style={{ height: "100%" }}
                >
                    <NoMsgIcon style={{ width: '20rem', height: '16rem' }} />
                    <Typography>No Message Found, Let's Start Chatting ...</Typography>
                </Box>
            }

            {!isLoading && messages.length > 0 &&
                formatMsg(messages).map((chat, index: number) => (
                    <Box key={index}>
                        <Typography variant="h6" className={classes.dividerWithText}>
                            <span>{chat.date}</span>
                        </Typography>
                        {chat.messages.map((message, messindex) => {
                            return (
                                <Fragment key={messindex}>
                                    <Message
                                        sid={message.sid}
                                        author={message.author}
                                        messageStyles={classes.message}
                                        linkPreview={classes.linkPreview}
                                        body={message.body}
                                        type={message.type}
                                        time={message.time}
                                        rawMsg={messages.find(msg => msg.sid === message.sid)}
                                        elementRef={index === 0 ? firstMsgRef : null}
                                    />
                                    {index == formatMsg(messages).length - 1 && messindex == chat.messages.length - 1 && (
                                        <div ref={lastMesgRef as React.RefObject<HTMLDivElement>} />
                                    )}

                                </Fragment>
                            )
                        })}
                    </Box>
                ))}
        </Grid>
    );
}

// @ts-ignore
export default withStyles(muiStyles)(Messages);
