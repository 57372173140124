Object.defineProperty(exports, "__esModule", {
    value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "JobListing2";
exports.labelBodyText = "JobListing2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiMethodTypeAddDetail = "POST";
exports.putMethodTypeAddDetail = "PUT";
exports.validationApiMethodType = "GET";
exports.getAllListedJobsAPiEndPoint = "bx_block_profile/recommended_roles";
exports.applyForJobAPiEndPoint = "bx_block_roles_permissions/applied_jobs";
exports.getJobDetailsAPiEndPoint = "bx_block_roles_permissions/roles?id=";
exports.favouriteJobAPiEndPoint = "bx_block_save_job/save_jobs";
exports.getFiltersAPiEndPoint = "bx_block_profile/filters";
exports.jsonApiContentType = "application/json";
// Customizable Area End