import React from "react";
// Customizable Area Start
import QuestionBankController, { Props } from "./QuestionBankController.web";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box, Container, Grid, Tooltip, Typography } from "@material-ui/core";
import { noProfileImg, EditIcon } from './assets';
import TabComponent from './TabComponent.web';
import EditProfileModal from "./EditProfileModal.web";
import EditOtherDetailModal from "./EditOtherDetailModal.web";
import EditRolePreferenceModal from "./EditRolePreferenceModal.web";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./style.scss";
import ShimmerLoading from "../../../components/src/ShimmerLoading";
// @ts-ignore
import { CandidateConsumer } from "../../../web/src/Context/CandidateContext.web";
import SelectTimeSlotWeb, { trimText } from "./selectTimeSlot.web";
import { withRouter } from 'react-router-dom';
const theme = createTheme({
  typography: {
    h5: {
      fontWeight: 600,
      color: "#171725"
    },
    body1: {
      color: "#8A8A92"
    }
  },
});

// styles for hide text overflow

// Customizable Area End

class CandidateDashboard extends QuestionBankController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.unlisten = this.props.history.listen((location: any, action: any) => {
      if (location.search && location.search.includes("tab")) {
        this.handleTabChange("", Number(location.search.split("=")[1].split("&")[0]), true);
      }
    });


    this.getProfileData();
    this.getAllAvailableMatchingJobs();
    window.scroll(0, 0);
  }

  async componentWillUnmount() {
    this.unlisten();
  }

  handleTabChange = (event: React.ChangeEvent<{}> | any, newValue: any, isMountCall = false) => {
    this.getTabDetails(newValue);
    this.setState({ tabIndex: newValue });
    if (!isMountCall) {
      this.props.history.push(`profile-setting`);
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Box m={3} className="root">
            <Container className="mainContainer" >
              {/* Profile box */}
              <Box position="relative" className="profileBox" >
                <div className="div-wrapper">
                  <Grid container
                    alignItems="center" spacing={2}>
                    <Grid item xs={12} lg={3} md={12}>
                      <CandidateConsumer>
                        {(props) => (
                          <Box display="flex" alignItems="center">
                            {!this.state.profileData ? <ShimmerLoading width="5.5rem" height="5.5rem" /> :
                              <Box>
                                <img src={props.profile_picture || noProfileImg} width={80} style={{ borderRadius: "10px" }} />
                              </Box>
                            }
                            <Box pl={2} style={{ width: "calc(100% - 80px)" }}>
                              <Typography variant="body1">Full Name</Typography>
                              <Tooltip title={this.state.profileData?.full_name || ''} arrow>
                                <Typography variant="h5"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    textTransform: 'capitalize'
                                  }}>
                                  {this.state.profileData?.full_name &&
                                    trimText(this.state.profileData?.full_name, 15) || <ShimmerLoading />}
                                </Typography>
                              </Tooltip>
                            </Box>
                          </Box>
                        )}
                      </CandidateConsumer>
                    </Grid>
                    <Grid item xs={6} lg={2} md={6}>
                      <Box width="8rem">
                        <Typography variant="body1">Current City</Typography>
                        <Tooltip title={this.state.profileData?.current_city || ""} arrow>
                          <Typography variant="h5"
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textTransform: 'capitalize'
                            }}>
                            {this.state.profileData?.current_city || <ShimmerLoading />}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={6} lg={3} md={6}>
                      <Box>
                        <Typography variant="body1">Phone Number</Typography>
                        {!this.state.profileData ? <ShimmerLoading /> :
                          this.state.profileData?.phone_number ?
                            <Box display="flex" gridGap={5}>
                              <Typography variant="h5">{this.state.profileData?.phone_number.toString().slice(0, 3)}</Typography>
                              <Typography variant="h5">{this.state.profileData?.phone_number.toString().slice(3, 8)}</Typography>
                              <Typography variant="h5">{this.state.profileData?.phone_number.toString().slice(8, 13)}</Typography>
                            </Box> : "_"
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={6} lg={3} md={6} className="email">
                      <Box>
                        <Typography variant="body1">Email</Typography>
                        <Tooltip title={this.state.profileData?.email || ""} arrow>
                          <Typography variant="h5" style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}>
                            {this.state.profileData?.email || <ShimmerLoading />}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid container item xs={1} lg={1} md={6} alignItems="flex-end">
                      <Box position="absolute"
                        style={{ top: "2em", right: "2em" }}
                        onClick={this.handleEditProfile}
                      >
                        <EditIcon className="editProfileIcon" />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Box>

              {/* Profile Tabs */}
              <Box>
                <TabComponent
                  handleEditOtherDetailModal={this.handleEditOtherDetail}
                  profileData={this.state.profileData}
                  handleEditRoleModal={this.handleEditRole}
                  handleTabChange={this.handleTabChange}
                  availableJobList={this.state.availableMatchingJobsList}
                  appliedJobList={this.state.appliedJobList}
                  savedJobList={this.state.savedJobList}
                  handleFavorite={this.handleAvaliableRealTime}
                  uploadResume={this.uploadResume}
                  removeResume={this.removeResume}
                  isLoading={this.state.isLoading}
                  tabIndex={this.state.tabIndex}
                  pastJobs={this.state.pastAppliedJobList}
                  handleRealTimeFavorite={this.handleFavoriteRealTime}
                  handleApplyJobs={this.applyForJob}
                  toggleModal={this.toggleModal}
                  isModalOpen={this.state.isModalOpen}
                  isInterviewSet={this.state.isInterviewSet}
                  sendEmailToParent={this.createCoversation}
                />
              </Box>

            </Container>
          </Box>
        </ThemeProvider>

        {this.state.isModalOpen &&
          <SelectTimeSlotWeb {...this.props}
            isOpen={this.state.isModalOpen}
            toggleModal={this.toggleModal}
            slotDetails={this.state.modalData}
            refreshData={this.getAppliedJobList}
          />
        }

        {/* To show edit profile popup */}
        {this.state.profileEditShow ? (
          //@ts-ignore
          <EditProfileModal showEditProfileModal={this.state.profileEditShow} handleCloseEditProfileModal={this.handleEditProfile} profileData={this.state.profileData} sendRequestForUpdateProfile={this.updateProfileData} />
        ) : ""}

        {/* To show edit role popup */}
        {this.state.roleEditShow ? (
          //@ts-ignore
          <EditRolePreferenceModal showEditRoleModal={this.state.roleEditShow} handleCloseEditRoleModal={this.handleEditRole} profileData={this.state.profileData} sendRequestForUpdateRole={this.updateOtherDetails} />
        ) : ""}

        {/* To show edit other details popup */}
        {this.state.otherDetailEditShow ? (
          //@ts-ignore
          <EditOtherDetailModal showEditOtherDetailModal={this.state.otherDetailEditShow} handleCloseEditOtherDetailModal={this.handleEditOtherDetail} profileData={this.state.profileData} sendRequestForUpdateOtherDetail={this.updateOtherDetails} />
        ) : ""}

        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
            style={{ color: "#fff" }}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// @ts-ignore
export default withRouter(CandidateDashboard);
// Customizable Area End
