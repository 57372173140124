import React, { useState } from 'react';
import { useEffect } from 'react';

export interface LoaderontextType {
  startLoader: () => void;
  stopLoader: () => void;
  isLoading: boolean;
}

const LoaderContext = React.createContext<LoaderontextType>({
  startLoader: () => { },
  stopLoader: () => { },
  isLoading: false
});

export const LoaderConsumer = LoaderContext.Consumer;

export const LoaderProvider: React.FC = props => {
  const [isLoading, setLoading] = useState(false);

  const startLoader = () => {
    setLoading(true);
  };

  const stopLoader = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (isLoading) stopLoader();
  }, [window.location.pathname]);

  return (
    <LoaderContext.Provider
      value={{
        startLoader,
        stopLoader,
        isLoading
      }}
    >
      {props.children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
