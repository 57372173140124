import React, { Component } from "react";
import { Box, Button, Grid, Theme, Typography } from "@material-ui/core";
import TopSearchBar from "./common/TopSearch.web";
import { withStyles } from "@material-ui/styles";
import CandidateCard from "./common/CandidateCard.web";

const muiStyles = (theme: Theme) => ({
    root: {
        margin: "3rem 6rem",
    },
});

const dummyCandidate = [
    {
        id: 1,
        name: "Charles Payfrom 1",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar1.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 5
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 41
        }
    },
    {
        id: 2,
        name: "Charles Payfrom 2",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar2.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 4
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 40
        }
    },
    {
        id: 3,
        name: "Charles Payfrom 3",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar3.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 51
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 10
        }
    },
    {
        id: 4,
        name: "Charles Payfrom 4",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar4.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 40
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 4
        }
    },
    {
        id: 5,
        name: "Charles Payfrom 5",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar4.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 40
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 4
        }
    },
    {
        id: 6,
        name: "Charles Payfrom 6",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar4.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 40
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 4
        }
    },
    {
        id: 7,
        name: "Charles Payfrom 7",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar4.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 40
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 4
        }
    },
    {
        id: 8,
        name: "Charles Payfrom 8",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar4.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 40
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 4
        }
    },
    {
        id: 9,
        name: "Charles Payfrom 9",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar4.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 40
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 4
        }
    },
    {
        id: 10,
        name: "Charles Payfrom 10",
        higest_education: "MBA",
        image: "https://www.bootdey.com/img/Content/avatar/avatar4.png",
        summary: "Chief executive officer at gannad insurance lmt, LLC livemore, Califonia, United States, 16 years exprience.",
        prev: {
            data: "Vice President, Program Management at Knight Insurance, Senior Program manager at Knight Insurance Group.",
            more: 40
        },
        skills: {
            data: "Actuarial Science, Analysis, Analytics, Brokers, Business Development, Business Strategy, Casualty, Claim, Calims",
            more: 4
        }
    },
]

interface Props {
    classes: any;
}

class Database extends Component<Props> {

    // WIll to today ::: 3:04 AM
    state = {
        currentIndex: 0,
        maxItems: 4,
        totalItems: dummyCandidate.length,
    }

    moveToNextItmes = () => {
        if (this.state.currentIndex + this.state.maxItems < this.state.totalItems) {
            this.setState({
                currentIndex: this.state.currentIndex + this.state.maxItems,
            }, () => { });
        }
    }

    backToPrevItmes = () => {
        if (this.state.currentIndex - this.state.maxItems >= 0) {
            this.setState({
                currentIndex: this.state.currentIndex - this.state.maxItems,
            }, () => { });
        }
    }

    render() {
        const { classes } = this.props;

        const filterItems = dummyCandidate.slice(this.state.currentIndex, this.state.currentIndex + this.state.maxItems);

        return (
            <Box display="flex" flexDirection="column" className={classes.root}>
                {/* Top Search bar */}
                <TopSearchBar onSearch={(state) => { }} />

                {/* Candidates Cards */}
                <Box mt={7}>
                    <Grid container spacing={2}>
                        {
                            filterItems.map((candidate, index) => {
                                return (
                                    <Grid item lg={3}>
                                        <CandidateCard candidateData={candidate} isLoggedIn={false} key={index} showEmptyImg={true} sendSelectedCandidateData={(data: any) => { }} />
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Box>
            </Box >
        )
    }
}

export default withStyles(muiStyles)(Database);``