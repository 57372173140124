Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.baseURL =
  "https://hiringplatform-74392-ruby.b74392.dev.us-east-1.aws.svc.builder.cafe";

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage2";
exports.labelBodyText = "LandingPage2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.postAPIMethod = "POST";
exports.putAPIMethod = "PUT";
exports.getAPIMethod = "GET";
exports.jsonApiContentType = "application/json";
exports.demorequestAPIEndPoint = "bx_block_requestdemo/request_demos";
exports.fetchContentAPIEndPoint = "bx_block_content_management/get_data?name=";
exports.fetchBannerContent = "bx_block_content_management/get_banner";
exports.contactUsAPIEndPoint="bx_block_contact_us/contacts";
// Customizable Area End
