export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const userimg = require("../assets/userimg.png")
export const Pdf = require("../assets/pdf.png")
export const Whatsapp = require("../assets/whatsapplist_View.png")
export const Messages = require("../assets/Email_list_View.png")
export const Edit = require("../assets/edit.png")
export const ShowMore = require("../assets/show_more.png")
export const Delete = require("../assets/delete.png")
export const Upload = require("../assets/upload.png")
export const Download = require("../assets/download.png");
export const Location = require("../assets/Location.png");
export const DraftImg = require("../assets/ic_Drafts.png");
export const ProfileImg = require("../assets/name.png");
export const PhoneImg = require("../assets/phone.png");
export const SalaryImg = require("../assets/SalaryRange.png");
export const calenderImg = require("../assets/ic_SalaryCalender.png");
export const noProfileImg = require("../assets/profile.jpg")

export { ReactComponent as PdfIcon } from "../assets/pdf.svg";
export { ReactComponent as DownloadIcon } from "../assets/download.svg";
export { ReactComponent as DeleteIcon } from "../assets/delete.svg";
export { ReactComponent as UploadIcon } from "../assets/upload.svg";
export { ReactComponent as EditIcon } from "../assets/edit.svg";