import React from 'react';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import CloseRoleController, { Props } from './CloseRoleController.web';
import ClosedRolesCards from '../../../components/src/Closedrolescards'
import { Link } from "react-router-dom";
interface CloseItemInterface {
    classes: any;
    role: any;
    isExpand: boolean;
    history: any;
    windowWidth: any;
    handleViewAll: (id?: number | undefined) => void;
    hasMoreCandidateData?: boolean;
    infiniteCandidateLoading?: boolean;
    candidateListScroll?: (data: string) => void;
    match?: any;
    startLoading: () => void;
    createCoversation: (email: string) => void;
    clientIDLink?: any;
}

class CloseRoleGridItem extends React.Component<CloseItemInterface> {
    candidateObserver: any;
    lastCandidateItem: (node: any) => void;
    constructor(props: CloseItemInterface) {
        super(props);

        // To set observer and handle infinite scroll start
        this.candidateObserver = React.createRef();
        this.lastCandidateItem = (node: any) => {
            if (this.candidateObserver.current) this.candidateObserver.current.disconnect();

            this.candidateObserver.current = new IntersectionObserver(entries => {
                if (this.props.infiniteCandidateLoading) return;
                if (entries[0].isIntersecting && this.props.hasMoreCandidateData && !this.props.match?.params?.id) {
                    this.props.candidateListScroll?.("candidateScrolled")
                }
            });
            if (node) this.candidateObserver.current.observe(node);
        // To set observer and handle infinite scroll end

        }
    }

    render() {
        const { classes } = this.props;
        let totalRejected = 0;
        return (
            <Box mb={3} key={this.props.role?.id} >
                <Card>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        p={1}
                        px={2}
                        className={classes.BgCardHeader}
                    >
                        <Box>
                            <Link to={`/job-detail/${this.props.role?.id}${this.props.clientIDLink ? "?" + this.props.clientIDLink : ""}`} style={{ textDecoration: "none", color: "#24325F" }}>
                                <Typography
                                    variant="body1"
                                    component='h6'
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {this.props.role?.attributes?.role_name}
                                </Typography>
                            </Link>
                        </Box>
                        <Box>
                            {/* <Typography style={{ cursor: "pointer" }}
                                onClick={(_) => {
                                    this.props.history.push(`/job-detail/${this.props.role?.id}`);
                                }}>VIEW DETAIL</Typography> */}

                            {this.props.isExpand ?
                                <Typography style={{ cursor: "pointer" }}
                                    onClick={(_) => {
                                        this.props.startLoading();
                                        this.props.history.push({
                                            pathname: "/closed-roles",
                                            search: location?.search
                                        });
                                        this.props.handleViewAll?.()
                                    }}>VIEW LESS</Typography> :
                                <Typography style={{ cursor: "pointer" }}
                                    onClick={(_) => {
                                        this.props.startLoading();
                                        this.props.history.push({
                                            pathname: `/closed-roles/role/${this.props.role?.id}`,
                                            search: location?.search
                                        });
                                        this.props.handleViewAll?.(this.props.role?.id)
                                    }}>VIEW ALL</Typography>}
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Grid container spacing={2} className={`${this.props.windowWidth <= 959 && classes.formobileCardContainer}`}>
                            {(this.props.role?.attributes?.candidate?.length > 0 && !this.props.role?.attributes?.candidate?.includes(null)) ?
                                this.props.role?.attributes?.candidate?.map((candidate: any, index: number) => {
                                    // if (candidate?.status == "rejected") {
                                    //     totalRejected += 1;
                                    // }
                                    // if (totalRejected == this.props.role?.attributes?.candidate?.length) {
                                    //     return (<Typography className={classes.fallBackText}>No candidates found.</Typography>)
                                    // }
                                    return (
                                        // candidate?.status !== "rejected" && (
                                        this.props.role?.attributes?.candidate?.length == index + 1 ?
                                            <Grid item xl={3} lg={4} md={6} sm={12} key={index}
                                                className={`${this.props.windowWidth <= 959 && classes.formobileCardItem}`}
                                                ref={this.lastCandidateItem}
                                            >
                                                <ClosedRolesCards
                                                    Title={candidate?.name}
                                                    Date_Open={candidate?.date_opened?.split("T")[0]}
                                                    Date_Close={candidate?.date_closed?.split("T")[0]}
                                                    Initial_stage={candidate?.initial_score}
                                                    Final_score={candidate?.final_score}
                                                    Feedback={candidate?.final_feedback}
                                                    resume={candidate?.resume}
                                                    imageUrl={candidate?.photo ? candidate?.photo : `https://www.bootdey.com/img/Content/avatar/avatar${(index % 8) + 1}.png`}
                                                    assessment_stage={candidate?.assessment_stage}
                                                    role_id={this.props.role?.id}
                                                    email={candidate?.email}
                                                    candidate_id={candidate?.profile_id}
                                                    phone_number={candidate?.phone_number}
                                                    account_id={candidate?.account_id}
                                                    status={candidate?.status}
                                                    windowWidth={this.props.windowWidth}
                                                    createCoversation={this.props.createCoversation}
                                                    clientIDLink={this.props.clientIDLink}
                                                />
                                            </Grid> :
                                            <Grid item xl={3} lg={4} md={6} sm={12} key={index}
                                                className={`${this.props.windowWidth <= 959 && classes.formobileCardItem}`}
                                            >
                                                <ClosedRolesCards
                                                    Title={candidate?.name}
                                                    Date_Open={candidate?.date_opened?.split("T")[0]}
                                                    Date_Close={candidate?.date_closed?.split("T")[0]}
                                                    Initial_stage={candidate?.initial_score}
                                                    Final_score={candidate?.final_score}
                                                    resume={candidate?.resume}
                                                    Feedback={candidate?.final_feedback}
                                                    email={candidate?.email}
                                                    imageUrl={candidate?.photo ? candidate?.photo : `https://www.bootdey.com/img/Content/avatar/avatar${(index % 8) + 1}.png`}
                                                    assessment_stage={candidate?.assessment_stage}
                                                    role_id={this.props.role?.id}
                                                    candidate_id={candidate?.profile_id}
                                                    phone_number={candidate?.phone_number}
                                                    account_id={candidate?.account_id}
                                                    status={candidate?.status}
                                                    windowWidth={this.props.windowWidth}
                                                    createCoversation={this.props.createCoversation}
                                                    clientIDLink={this.props.clientIDLink}
                                                />
                                            </Grid>
                                        // )
                                    )
                                }) : <Typography className={classes.fallBackText}>No candidates found.</Typography>}
                        </Grid>
                    </Box>
                </Card>
            </Box>
        );
    }
}

export default CloseRoleGridItem;