import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import ProfileStyles from '../Styles.module.scss';
interface ProfileTabProps {
    candidateData: any;
    allJobExperiences: any;
}
class ProfileTab extends React.Component<ProfileTabProps>{
    state: any = {
        showAllskills: false
    };
    constructor(props: ProfileTabProps) {
        super(props);
    }
    render() {
        let allSkills = this.props.candidateData?.temporary_user_profile?.skills ? [...this.props.candidateData?.temporary_user_profile?.skills] : [];
        return (
            <Box className={ProfileStyles.detailTabWrap}>
                <Box className={ProfileStyles.skillSec} mt={2}>
                    <Box mb={1}>
                        <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Skills</Typography>
                    </Box>
                    <Box className={ProfileStyles.skillChips} mb={2}>
                        {allSkills?.length > 0 ?
                            [...allSkills]?.splice(0, this.state.showAllskills ? allSkills?.length : 10)?.map((item: any, index: number) =>
                                (<Typography variant='caption' key={index}>{item}</Typography>))
                            : "-"}

                        {(allSkills?.length > 0 && allSkills?.length > 10) && (
                            <Typography variant='h6' className={ProfileStyles.skillMore} onClick={() => this.setState((prev: any) => ({ showAllskills: !prev.showAllskills }))}>
                                {this.state.showAllskills ? "Show less" : `${allSkills?.length - 10} more`}
                            </Typography>)}

                    </Box>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box mb={2} display='flex'>
                                <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Work Experience</Typography>
                                {/* <Typography variant='h6' className={ProfileStyles.detailAction}>+Add Experience</Typography> */}

                            </Box>
                            {this.props.allJobExperiences ? this.props.allJobExperiences?.map((work: any, index: number) => (
                                <Box display="flex" className={ProfileStyles.tabDetailData} mb={2} key={index}>
                                    <Grid container>
                                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                            <Box pr={2}>
                                                <Typography className={ProfileStyles.tabDetailTitle}>{work.started || "NA"} - {work.ended || "NA"}:</Typography>
                                            </Box></Grid>
                                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                                            <Box>
                                                <Typography variant='h5' className={ProfileStyles.tabDetailSubTitle}>
                                                    {work.position || "-"}
                                                </Typography>
                                                <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                    {work.company || "-"}
                                                </Typography>
                                                <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                    {work.location || "-"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )) : "-"}
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Summary</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        <Typography className={ProfileStyles.tabDetailTitle}>{this.props.candidateData?.summary || "-"}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Head Line</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        <Typography className={ProfileStyles.tabDetailTitle}>{this.props.candidateData?.temporary_user_profile?.head_line || "-"}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Courses</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        {this.props.candidateData?.temporary_user_profile?.courses ? this.props.candidateData?.temporary_user_profile?.courses?.map((course: any, index: number) => (
                                            <Typography className={ProfileStyles.tabDetailTitle} key={index}>{course}</Typography>
                                        )) : "-"}
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Education</Typography>
                            </Box>
                            {this.props.candidateData?.temporary_user_profile?.education ?
                                this.props.candidateData?.temporary_user_profile?.education.map((educ: any, index: number) => (
                                    <Box display="flex" className={ProfileStyles.tabDetailData} mb={2} key={index}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Box pr={2}>
                                                    <Typography className={ProfileStyles.tabDetailTitle}>{educ.startedYear || "NA"} - {educ.endedYear || "NA"}:</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Box>
                                                    <Typography variant='h5' className={ProfileStyles.tabDetailSubTitle}>
                                                        {educ.university || "-"}
                                                    </Typography>
                                                    <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                        {educ.faculty || "-"}
                                                    </Typography>
                                                    <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                        {educ.degree && educ.degree.length > 0 ? educ.degree?.join(",") : "-"}
                                                    </Typography>
                                                    {/* <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                Administration (MBA)
                                            </Typography> */}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>))
                                : "-"}
                        </Grid>
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Languages</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        {this.props.candidateData?.temporary_user_profile?.languages ?
                                            this.props.candidateData?.temporary_user_profile?.languages.map((language: any, index: number) => language.name?.trim().length > 0 && (
                                                <Typography className={ProfileStyles.tabDetailTitle} key={index}>{language.name}</Typography>))
                                            : "-"}
                                    </Box>
                                </Box>
                            </Box>
                            <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Certificates</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        {this.props.candidateData?.temporary_user_profile?.certificates ?
                                            this.props.candidateData?.temporary_user_profile?.certificates.map((certificate: any, index: number) => (
                                                <Typography className={ProfileStyles.tabDetailTitle} variant='body1' key={index}>{certificate}</Typography>))
                                            : "-"}
                                    </Box>
                                </Box>
                            </Box>
                            <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Organizations</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        {this.props.candidateData?.temporary_user_profile?.organizations ?
                                            this.props.candidateData?.temporary_user_profile?.organizations.map((organization: any, index: number) => organization.name?.trim().length > 0 && (
                                                <Typography className={ProfileStyles.tabDetailTitle} variant='caption' key={index}>{organization.name}</Typography>))
                                            : "-"}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }
}

export default ProfileTab;